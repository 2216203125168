import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const GridRoot = styled(Grid)(() => ({
  minHeight: '100vh',
  maxHeight: '100vh'
}))

export const LeftGridColumn = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main
}))

export const RightGridColumn = styled(Grid)(() => ({
  backgroundColor: '#FFF'
}))

export const FlexContainerBox = styled(Box)(() => ({
  minHeight: '100vh'
}))

export const StyledButton = styled(Button)(() => ({
  color: '#1E293B',
  fontWeight: 500
}))
export const HomeButton = styled(Button)(() => ({
  color: '#fff',
  fontWeight: 500
}))

export const LanguageButton = styled(Button)(() => ({
  fontWeight: 600
}))

export const FormFlexContainer = styled(Box)(() => ({
  minHeight: '90vh'
}))

export const FixedWidthBox = styled(Box)(() => ({
  minWidth: 320
}))

export const TruckTypeBox = styled(Box)(() => ({
  width: 117,
  height: 70,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.38)',
  borderRadius: '4px'
}))

export const LightButton = styled(Button)(() => ({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
  color: '#0071CD',
  fontWeight: 600
}))

export const PrimaryButton = styled(Button)(() => ({
  background: '0071CD',
  color: '#FFFFFF',
  fontWeight: 600
}))

export const OtpField = styled('input')(() => ({
  width: 44,
  height: 44,
  background: '#FFFFFF',
  borderRadius: 4,
  fontSize: 24,
  color: '#0071CD',
  textAlign: 'center',
  border: 'none',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
}))

export const RedButton = styled(Button)(() => ({
  background: '#F82012',
  color: '#FFFFFF',
  fontWeight: 600
}))

export const StepFlexItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))
