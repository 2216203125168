import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import {
  formatStage,
  getTripStatusColor,
  isEmptyObject
} from '../../../../../utils/functions'
import Avatar from '@mui/material/Avatar'
import useToast from '../../../../../hooks/useToast'
import './index.css'
import Snackbars from '../../../../../components/Snackbar/Snackbar'
import { IconButton } from '@mui/material'
import { Close, StickyNote2 } from '@mui/icons-material'

const ShipperBookingTripCard = (props) => {
  const { data, tripsData } = props
  const { snackbarOpen, setSnackbarOpen, snackbarMessage, snackbarSeverity } =
    useToast()
  const tripStatus = data.tripStatus
  const truckPlate = {
    id: data.truck?.id ? data.truck.id : 0,
    title: data.truck?.licensePlateNoTextEn
      ? data.truck.licensePlateNoTextEn
      : ''
  }
  const driver = {
    id: data.driver?.id ? data.driver.id : 0,
    title: data.driver?.fullName ? data.driver.fullName : '',
    phone: data.driver?.phone ? data.driver.phone : ''
  }

  // const [fareAmount, setFareAmount] = useState(
  //   data.fareAmount ? data.fareAmount : 'N/A'
  // )

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
  }

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClose2 = () => {
    setAnchorEl2(null)
  }

  const handleClose3 = () => {
    setAnchorEl3(null)
  }

  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  const open3 = Boolean(anchorEl3)

  const id = open ? 'simple-popover' : undefined
  const id2 = open2 ? 'simple-popover2' : undefined
  const id3 = open3 ? 'simple-popover3' : undefined

  return (
    <>
      <Box>
        <Box className="booking-metadata-box" mt={2}>
          <Box
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ bgcolor: '#f9f9f9' }}
          >
            <Box>
              <Typography>Trip ID: </Typography>
            </Box>
            <Box flexGrow={1}>
              <Typography color="primary">&nbsp;{data.id}</Typography>
            </Box>
            {/* <Box ml={2}>
              <Typography sx={{ color: '#00000061', fontSize: '14px' }}>
                Advance Payment: BDT 1200
              </Typography>
            </Box>
            <Box ml={1}>
              <Typography
                sx={{ color: '#4CAF50', fontSize: '12px', fontWeight: 600 }}
              >
                Paid
              </Typography>
            </Box> */}

            <Box
              display="flex"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
            >
              {/* <Box>
                <IconButton color="primary">
                  <AttachmentIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton color="primary">
                  <ArticleIcon />
                </IconButton>
              </Box> */}
              <Box>
                <IconButton
                  onClick={handleClick3}
                  disabled={data.cancelReason ? false : true}
                  sx={{ bgcolor: '#f5f5f5' }}
                >
                  <StickyNote2
                    sx={{ color: data.cancelReason ? '#F44335' : '#e3e3e3' }}
                  />
                </IconButton>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id3}
                  open={open3}
                  anchorEl={anchorEl3}
                  onClose={handleClose3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography fontWeight={'bold'} variant="subtitle1">
                        Cancellation Note
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={handleClose3}>
                        <Close sx={{ fill: '#F82012' }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />
                  <Typography p={1}>{data.cancelReason || 'N/A'}</Typography>
                </Popover>
              </Box>
              <Box>
                <Chip
                  label={
                    tripStatus === 'select_vendor' ||
                    tripStatus === 'get_truck_info'
                      ? 'Processing'
                      : formatStage(tripStatus)
                  }
                  sx={{
                    background: getTripStatusColor(tripStatus)['bg'],
                    color: getTripStatusColor(tripStatus)['color']
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            p={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle1">Driver Name</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle1" color="primary">
                    {driver.title || 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle1">Driver Number</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle2" color="primary">
                    {driver.title ? `${driver.title} : ${driver.phone}` : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle1">Truck Plate</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle2" color="primary">
                    {truckPlate.title ? truckPlate.title : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle1">
                    Key Account Manager
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="subtitle2" color="primary">
                    {tripsData.kam?.phone || 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
            alignItems="center"
          >
            <Box display="flex">
              <Box>
                <Avatar
                  aria-describedby={id}
                  onClick={handleClick}
                  onMouseDown={handleClose}
                  sx={{
                    bgcolor: isEmptyObject(data.loadingAgent)
                      ? '#9E9E9E'
                      : '#0071CD',
                    cursor: isEmptyObject(data.loadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.loadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                >
                  L
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Loading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {tripsData.loadingAgent?.fullName
                        ? tripsData.loadingAgent?.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {tripsData.loadingAgent?.phone
                        ? tripsData.loadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              <Box ml={1}>
                <Avatar
                  sx={{
                    bgcolor: isEmptyObject(data.unloadingAgent)
                      ? '#9E9E9E'
                      : '#F82012',
                    cursor: isEmptyObject(data.unloadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.unloadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                  aria-describedby={id2}
                  onClick={handleClick2}
                  onMouseDown={handleClose2}
                >
                  U
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Unloading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {tripsData.unloadingAgent?.fullName
                        ? tripsData.unloadingAgent.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {tripsData.unloadingAgent?.phone
                        ? tripsData.unloadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}
ShipperBookingTripCard.propTypes = {
  data: PropTypes.object,
  tripsData: PropTypes.object
}

export default ShipperBookingTripCard
