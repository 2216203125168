import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { usePostHook } from '../../../hooks/fetchHook'
import Box from '@mui/material/Box'
import { patterns } from '../../../utils/functions'
import FormGroup from '@mui/material/FormGroup'
import OtpInput from '../../ForgetPassword/components/OtpInput'
import Checkbox from '@mui/material/Checkbox'
import Snackbars from '../../../components/Snackbar/Snackbar'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'
import {
  FormFlexContainer,
  PrimaryButton
} from '../../../components/Landing/StyledComponents/StyledComponents'
import { invalidInputsChars } from '../../../data/data'
import PassWordInput from '../../../components/PassWordInput'
// import CustomDatePicker2 from '../../../components/CustomDatePicker2'

const ShipperRegistration = () => {
  const navigate = useNavigate()
  const {
    register,
    formState: { errors, isValid },
    getValues,
    setError
  } = useForm({
    mode: 'onChange'
  })
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showOtpFields, setShowOtpFields] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  // const [dob, setDob] = useState(new Date())
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [pasted, setPasted] = useState(false)
  const [agreedToTerms, setAgreedToTerms] = useState(true)

  // const handleChange = (newValue) => {
  //   setDob(newValue)
  // }

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleSuccess = (response) => {
    if (response.status === 201) {
      setShowOtpFields(true)
    }
  }

  const handleError = (error) => {
    setToast(error?.response?.data?.message, 'error')
  }

  const setManualError = (name, message) => {
    setError(name, {
      type: 'manual',
      message
    })
  }

  const backToForm = () => setShowOtpFields(false)

  const verifyOtp = (otp) => {
    let url = '/lf-user/api/v1/public/user/verify-otp/'

    let data = {
      phoneOrEmail: phone,
      otpNumber: otp
    }
    usePostHook(
      url,
      data,
      () => {
        setToast('User has been registered successfully', 'success')
        setTimeout(() => {
          navigate('/')
        }, 1000)
      },
      (error) => {
        setToast(error?.response?.data?.message, 'error')
      }
    )
  }

  const validateFields = () => {
    let valid = true
    if (getValues('full_name').trim() === '') {
      setManualError('full_name', 'Full Name cannot be empty')
      return false
    }

    if (getValues('company_name').trim() === '') {
      setManualError('company_name', 'Company Name cannot be empty')
      return false
    }

    if (phone.trim() === '') {
      setManualError('phone', 'Phone cannot be empty')
      return false
    }

    if (phone.length !== 11) {
      setManualError('phone', 'Invalid Phone Number')
      return false
    }

    if (password !== confirmPassword) {
      setToast('Password does not match')
      return false
    }
    return valid
  }

  const saveChanges = () => {
    let isValid = validateFields()
    if (!isValid) {
      setToast(
        'Somthing went wrong! Please check if all the inputs are valid!',
        'error'
      )
      return
    }
    // let dobDateInstance = new Date(dob)
    let formData = {
      fullName: getValues('full_name'),
      phone,
      email: getValues('email') ? getValues('email') : null,
      password,
      nid: null,
      dob: null,
      tin: null,
      tln: null,
      companyName: getValues('company_name'),
      designation: getValues('designation'),
      role: 'shipper' //restricted keyword
    }

    let url = '/lf-user/api/v1/public/user/signup/'
    usePostHook(url, formData, handleSuccess, handleError)
  }

  return (
    <>
      {!showOtpFields ? (
        <FormFlexContainer display="flex" alignItems="center">
          <Box pl={2} pr={2}>
            <Box display="flex" mb={2}>
              <Typography variant="h4">Sign up as a</Typography>
              <Typography variant="h4" color="primary">
                &nbsp;Shipper
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  label={'Full Name'}
                  type="text"
                  name={'full_name'}
                  register={register}
                  errors={errors}
                  criterions={{
                    required: {
                      value: true,
                      message: 'Name cannot be empty'
                    },
                    maxLength: {
                      value: 50,
                      message: 'Name cannot be larger than 50 characters'
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={'Company Name'}
                  type="text"
                  name={'company_name'}
                  register={register}
                  errors={errors}
                  criterions={{
                    required: {
                      value: true,
                      message: 'Company Name cannot be empty'
                    },
                    maxLength: {
                      value: 100,
                      message:
                        'Company Name cannot be larger than 100 characters'
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={'Designation'}
                  type="text"
                  name={'designation'}
                  register={register}
                  errors={errors}
                  criterions={{}}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={'Phone Number'}
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  name={'phone'}
                  value={phone}
                  onKeyDown={(e) => {
                    if (invalidInputsChars.includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    !pasted && setPhone(e.target.value)
                    e.target.value.length > 11 && setPhoneError(true)
                    e.target.value.length <= 11 && setPhoneError(false)
                    setPasted(false)
                  }}
                  onPaste={(e) => {
                    setPasted(true)
                    const phoneNumber = e.clipboardData
                      .getData('Text')
                      .replace(/-/g, '')
                    setPhone(phoneNumber)
                  }}
                  fullWidth
                  error={phoneError}
                  helperText={phoneError && 'Phone number must be 11 digits'}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={'Email Address (Optional)'}
                  type="text"
                  name={'email'}
                  register={register}
                  errors={errors}
                  criterions={{
                    pattern: {
                      value: patterns.valid_email,
                      message: 'Invalid Email'
                    }
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={6}>
                <CustomTextField
                  label={'Trade License Number (Optional)'}
                  type="text"
                  name={'trade_license_number'}
                  register={register}
                  errors={errors}
                  criterions={{}}
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <CustomTextField
                  label={'Tax Identification Number (Optional)'}
                  type="text"
                  name={'tin'}
                  register={register}
                  errors={errors}
                  criterions={{}}
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <CustomTextField
                  label={'NID Number (Optional)'}
                  type="text"
                  name={'nid'}
                  register={register}
                  errors={errors}
                  criterions={{}}
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <CustomDatePicker2
                  label="Date of Birth (Optional)"
                  onChange={handleChange}
                  disableFuture
                />
              </Grid> */}
              <Grid item xs={6}>
                <PassWordInput
                  required
                  label={'Password'}
                  error={password.length > 20 ? true : false}
                  helperText={
                    password.length > 20 ? 'Must be less than 20 character' : ''
                  }
                  onChange={(value) => setPassword(value)}
                />
              </Grid>
              <Grid item xs={6}>
                <PassWordInput
                  required
                  label={'Re-Type Password'}
                  error={confirmPassword.length > 20 ? true : false}
                  helperText={
                    confirmPassword.length > 20
                      ? 'Must be less than 20 character'
                      : ''
                  }
                  onChange={(value) => setConfirmPassword(value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                            size="small"
                          />
                        }
                        label="Agreed to"
                      />
                    </FormGroup>
                  </Box>
                  <Box>
                    <Typography color="primary">
                      Terms and Conditions
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <PrimaryButton
                  variant="contained"
                  disabled={!isValid || !agreedToTerms}
                  fullWidth
                  onClick={saveChanges}
                >
                  SIGNUP NOW
                </PrimaryButton>
              </Grid>
            </Grid>
          </Box>
        </FormFlexContainer>
      ) : (
        <FormFlexContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <OtpInput
              submitOtp={verifyOtp}
              phoneOrEmail={phone}
              goBack={backToForm}
              setToast={setToast}
            />
          </Box>
        </FormFlexContainer>
      )}

      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default ShipperRegistration
