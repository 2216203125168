import React, { useState, useEffect, Fragment } from 'react'
import produce from 'immer'
import { useGetHook, usePostHook } from '../../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import TextField from '@mui/material/TextField'
import { getLoggedInUser, isEmptyArray } from '../../../utils/functions'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { getAccessLevel } from '../../../accesscontrol'
import { useParams } from 'react-router-dom'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import { Search } from '@mui/icons-material'
import { Button, Chip, InputAdornment } from '@mui/material'
import GlobalButton from '../../../components/GlobalButton'

const VendorList = (props) => {
  const { booking, setToast, viewMode } = props
  const params = useParams()
  const [vendors, setVendors] = useState([])
  const [initialVendors, setInitialVendors] = useState([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const user = getLoggedInUser()

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/vendors?bookingId=${booking.id}`

    // ========== BOOKING ITEM DOESN'T PASS VIEWMODE ========= //
    // ====== UNDEFINED IS A VALID VALUE FOR VIEMODE ========== //
    // ====== UNDEFINED WILL BE PASS BY BOOKING ITEM BAT ====== //
    // ==== HOWEVER OTHER COMPONENTS WILL PASS UNDEFINED TOO === //
    // === TO BE 100% ACCURATE PASS VIEWMODE => BOOKINGS FROM BOOKINGITEM === //
    // ========= THEN HERE CHECK FOR VIEWMODE === BOOKINGS ========== //

    // if (viewMode !== 'trips' && params.vendorId) {
    //   url += `&vendorId=${params.vendorId}`
    // }

    if (debouncedSearch) url += `&search=${debouncedSearch}`

    useGetHook(
      url,
      (res) => {
        let results = res.data.data
        results = results.map((obj) => {
          let rObj = { ...obj }
          rObj.qty = 0
          rObj.editMode = false
          rObj.amount = 0
          rObj.advancedPerTruck = 0
          return rObj
        })

        setVendors(results)
        setInitialVendors(results)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  const onQtyChange = (item, op) => {
    if (op === 'add') {
      let qty = item.qty + 1
      setVendors(
        produce((draft) => {
          const vendor = draft.find((x) => x.id === item.id)
          vendor.qty = parseInt(qty)
        })
      )
    }
    if (op === 'substract') {
      let qty = item.qty - 1
      setVendors(
        produce((draft) => {
          const vendor = draft.find((x) => x.id === item.id)
          vendor.qty = parseInt(qty)
        })
      )
    }
  }

  const onVendorRowClick = (item) => {
    setVendors(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.editMode = true
      })
    )
  }

  const onProposedAmountChange = (e, item) => {
    setVendors(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.amount = e.target.value
      })
    )
  }

  const onAdvanceAmountChange = (e, item) => {
    setVendors(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.advancedPerTruck = e.target.value
      })
    )
  }

  const cancelChanges = (item, index) => {
    let vendorItem = initialVendors[index]
    setVendors(
      produce((draft) => {
        const vendor = draft.find((x) => x.id === item.id)
        vendor.amount = vendorItem.amount
        vendor.qty = vendorItem.qty
        vendor.editMode = false
        vendor.advancedPerTruck = vendorItem.advancedPerTruck
      })
    )
  }

  const savePrice = (item) => {
    let data = {
      bookingId: booking.id,
      vendorId: item.userId,
      numberOfTrucks: item.qty,
      proposedAmountPerTruck: item.amount
    }

    if (parseInt(item.advancedPerTruck))
      data['advancedPerTruck'] = item.advancedPerTruck

    let url = '/lf-booking/api/v1/admin/booking/bidding'

    usePostHook(
      url,
      data,
      (res) => {
        let updatedVendors = vendors.filter((x) => x.userId !== item.userId)
        updatedVendors = updatedVendors.map((obj) => {
          let rObj = { ...obj }
          rObj.editMode = false
          return rObj
        })
        setVendors(updatedVendors)
        setInitialVendors(updatedVendors)
        setToast(res.data.message, 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const searchVendorList = (e) => {
    e.preventDefault()
    if (user.role === 'kam' && user.team !== 'vendor_team') {
      setToast(
        'Forbidden! Vendor search only applicable for admin, gm or cluster head',
        'error'
      )
      return
    }

    // ========= INITIAL ROUTE FOR ADMIN / GM / PRO ======== //
    if (search) {
      let url = `/lf-sup/api/v1/admin/supplier/?page=1&limit=10&query=${search}&status=active&isEnabled=true&isVerified=true`

      if (
        user.role === 'cluster_head' ||
        (user.role === 'kam' && user.team == 'vendor_team')
      ) {
        url = `/lf-sup/api/v1/crm/supplier/list?page=1&limit=10&query=${search}&status=active&isEnabled=true&isVerified=true`
      }

      useGetHook(
        url,
        (res) => {
          let results = res.data.data
          results = results.map((obj) => {
            let rObj = { ...obj }
            rObj.qty = 0
            ;(rObj.editMode = false), (rObj.amount = 0)
            return rObj
          })

          setVendors(results)
          setInitialVendors(results)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }
  }

  const addFeedToSupplier = (supplierId) => {
    const payload = {
      supplierId,
      bookingId: booking.id
    }
    let url = '/lf-booking/api/v1/admin/booking/supplier-feed'

    usePostHook(
      url,
      payload,
      () => {
        setToast('Feed has been added successfully!', 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  const vendorTeam = user.role === 'kam' && user.team === 'vendor_team'

  return (
    <>
      {getAccessLevel('searchVendorsList') && (
        <Box display="flex" justifyContent="flex-end" m={2}>
          <form onSubmit={searchVendorList}>
            <TextField
              color="primary"
              size="small"
              label="Search Vendors"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Box>
      )}
      <Divider light />
      <Grid container p={1}>
        <Grid item xs={3}>
          <Typography variant="body2">Vendor Name</Typography>
        </Grid>
        <Grid item xs={3}>
          {vendorTeam && (
            <Typography textAlign="center" variant="body2">
              Advance Payment
            </Typography>
          )}
        </Grid>
        <Grid item xs={2.5}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2">Quantity</Typography>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="body2" textAlign="end">
            Per Truck Amount
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignItems="center" p={1} spacing={1}>
        {!isEmptyArray(vendors) ? (
          <>
            {vendors.map((item, index) => (
              <Fragment key={item.id}>
                <Grid item xs={3}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box>
                      <Avatar
                        sx={{ width: '65px', height: '65px' }}
                        alt={item.fullName}
                        src={item.profilePicture ? item.profilePicture : 'as'}
                      />
                    </Box>
                    <Box flex={1}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                        gap={1}
                      >
                        <Typography className="company-name">
                          {item.companyName ? item.companyName : 'N/A'}
                        </Typography>
                        {item.feedType === 'return_feed' && (
                          <Chip
                            label={
                              <Typography textAlign="center" variant="body2">
                                Return Matched
                              </Typography>
                            }
                            size="small"
                            sx={{
                              bgcolor: '#F82012',
                              color: '#fff',
                              height: '20px',
                              borderRadius: '4px'
                            }}
                          />
                        )}
                      </Box>

                      <Typography className="company-contact">
                        {item.fullName}
                      </Typography>
                      <Typography className="company-phone">
                        {item.phone}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  {vendorTeam && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      {!item.editMode ? (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            justifyContent="center"
                          >
                            <Typography textAlign={'center'} color="primary">
                              BDT {item.advancedPerTruck}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box display="flex" justifyContent="center">
                            <TextField
                              size="small"
                              value={item.advancedPerTruck}
                              onChange={(e) => onAdvanceAmountChange(e, item)}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={2.5}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    {item.editMode ? (
                      <Box>
                        <IconButton
                          onClick={() => onQtyChange(item, 'substract')}
                          disabled={item.qty === 0}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                    ) : null}

                    <Typography>{item.qty}</Typography>
                    {item.editMode ? (
                      <Box>
                        <IconButton onClick={() => onQtyChange(item, 'add')}>
                          <AddIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={3.5} direction="row" alignSelf="center">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={1}
                  >
                    {!item.editMode ? (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          justifyContent="flex-end"
                        >
                          <Typography
                            textAlign={'end'}
                            component="div"
                            color="primary"
                          >
                            BDT {item.amount}
                          </Typography>
                          {getAccessLevel('createBidding') && (
                            <Box>
                              <IconButton
                                onClick={() => onVendorRowClick(item, index)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <TextField
                              size="small"
                              value={item.proposedAmountPerTruck}
                              onChange={(e) => onProposedAmountChange(e, item)}
                            />
                          </Box>
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => cancelChanges(item, index)}
                            >
                              <CloseIcon sx={{ color: '#F82012' }} />
                            </IconButton>
                          </Box>
                          <Box>
                            <GlobalButton
                              iconButton
                              size="small"
                              onClick={() => savePrice(item)}
                              variant="contained"
                              name={<DoneIcon color="success" />}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    {debouncedSearch && (
                      <Button
                        onClick={() => addFeedToSupplier(item.userId)}
                        size="small"
                        variant="contained"
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </>
        ) : (
          <Box
            display="flex"
            sx={{ width: '100%' }}
            justifyContent="center"
            mt={1}
          >
            <Typography component="div">No Data Available</Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}

VendorList.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func,
  viewMode: PropTypes.string
}

export default VendorList
