import { useState, useEffect } from 'react'
import loopAxios from '../utils/loop-axios'
import axios from 'axios'
import {
  clean,
  getApiEndPoint,
  getRequestHeaders,
  isEmptyArray
} from '../utils/functions'

/**
 * url = api url
 * authorized = set value if the request is authorized or not
 * ={} = the query param name
 * queryValue = the actual value against query param
 * ***/

function useFetch(url, authorized = false, queryName = {}, queryValue = []) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const [reload, setReload] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setLoading(true)
    let apiBase = getApiEndPoint()
    let fullUrl = apiBase + url
    const source = axios.CancelToken.source()
    let headers = getRequestHeaders(authorized)
    let axiosOptions = {
      cancelToken: source.token,
      headers: {
        ...headers
      }
    }

    if (!isEmptyArray(queryValue)) {
      const query = clean(queryName)
      axiosOptions = {
        ...axiosOptions,
        params: {
          ...query
        }
      }
    }

    loopAxios
      .get(fullUrl, axiosOptions)
      .then((res) => {
        res.data.data && setData(res.data.data)
        setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setData([])
        setTotal(0)
        setPageCount(0)
        setError(err.response?.data?.message)

        // === RESPONSE 401 MEANS INVALID TOKEN === //
        if (err.response?.status === 401) {
          localStorage.clear()
          sessionStorage.clear()
          window.location.href = '/'
        }
      })
    return () => {
      source.cancel()
    }
  }, [url, reload, ...queryValue])

  return [
    {
      data,
      loading,
      error,
      pageCount,
      total
    },
    setReload
  ]
}

export default useFetch
