/* eslint-disable indent */
import React, { Fragment, useEffect, useState } from 'react'
import { useGetHook, usePostHook, usePutHook } from '../../../hooks/fetchHook'
import {
  formatStage,
  getLoggedInUser,
  isEmptyArray
} from '../../../utils/functions'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import produce from 'immer'
import { getAccessLevel } from '../../../accesscontrol'
import GlobalButton from '../../../components/GlobalButton'

const VendorResponse = (props) => {
  const {
    setToast,
    booking,
    setFareHistoryStatus,
    updateBookingStage,
    setBaseAmount
  } = props
  const user = getLoggedInUser()
  const [initialBiddings, setInitialBiddings] = useState([])
  const [biddings, setBiddings] = useState([])
  const [genPlanData, setGenPlanData] = useState([])
  const [averageFare, setAverageFare] = useState(0)
  const [reloadBiddings, setReloadBiddings] = useState(false)

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/${booking.id}/biddings`

    useGetHook(
      url,
      (res) => {
        let results = res.data.data.biddings
        results = results.map((obj) => {
          let rObj = { ...obj }
          rObj.editMode = false
          rObj.isChecked = false
          return rObj
        })
        setInitialBiddings(results)
        setBiddings(results)
        res.data.data.bookingStatus === 'shipper_approved' &&
          setFareHistoryStatus('shipper_approved')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [reloadBiddings])

  const handleChange = (e, item) => {
    setBiddings(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.isChecked = e.target.checked
      })
    )
    if (e.target.checked) {
      const newGenPlanData = {
        id: item.id,
        numberOfTrucks: item.numberOfTrucks,
        proposedAmountPerTruck: item.proposedAmountPerTruck
      }
      setGenPlanData([...genPlanData, newGenPlanData])
    } else {
      const localGenPlan = [...genPlanData]
      const filterGen = localGenPlan.filter((plan) => plan.id !== item.id)
      setGenPlanData(filterGen)
    }
  }

  const generatePlan = () => {
    const payload = {
      bookingId: booking.id,
      biddings: genPlanData
    }

    let url = '/lf-booking/api/v1/booking/fare-history/calculate'

    usePostHook(
      url,
      payload,
      (res) => {
        setAverageFare(res.data.data.proposedAmountPerTruck)
        setToast('Successsfully genereted plan', 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  const onTruckQtyChange = (item, op) => {
    if (op === 'add') {
      let qty = item.numberOfTrucks + 1
      setBiddings(
        produce((draft) => {
          const bidding = draft.find((x) => x.id === item.id)
          bidding.numberOfTrucks = parseInt(qty)
        })
      )
      setGenPlanData((prev) =>
        prev.map((prevItem) =>
          prevItem.id === item.id
            ? { ...prevItem, numberOfTrucks: parseInt(qty) }
            : prevItem
        )
      )
    }
    if (op === 'substract') {
      let qty = item.numberOfTrucks - 1
      setBiddings(
        produce((draft) => {
          const bidding = draft.find((x) => x.id === item.id)
          bidding.numberOfTrucks = parseInt(qty)
        })
      )
      setGenPlanData((prev) =>
        prev.map((prevItem) =>
          prevItem.id === item.id
            ? { ...prevItem, numberOfTrucks: parseInt(qty) }
            : prevItem
        )
      )
    }
  }

  const onEditClick = (item) => {
    setBiddings(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.editMode = true
      })
    )
  }

  const saveBidding = (item) => {
    let data = {
      vendorId: item.vendor.userId,
      bookingId: booking.id,
      biddingId: item.id,
      numberOfTrucks: item.numberOfTrucks,
      proposedAmountPerTruck: item.proposedAmountPerTruck
    }
    let url = '/lf-booking/api/v1/admin/booking/bid-confirm'

    usePostHook(
      url,
      data,
      (res) => {
        // let results = res.data.data.biddings
        // results = results.map((obj) => {
        //   let rObj = { ...obj }
        //   rObj.editMode = false
        //   rObj.isChecked = false
        //   return rObj
        // })
        // setBiddings(results)
        // setInitialBiddings(results)
        setReloadBiddings(!reloadBiddings)
        setToast('Bidding has been updated', 'success')
        updateBookingStage(res.data.data.stage)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
      },
      true
    )
  }

  // const savePrice = (item) => {
  //   let data = {
  //     bookingId: booking.id,
  //     vendorId: item.vendor.userId,
  //     numberOfTrucks: item.numberOfTrucks,
  //     proposedAmountPerTruck: item.proposedAmountPerTruck
  //   }

  //   // let url = '/lf-booking/api/v1/admin/booking/bidding'
  //   let url = '/lf-booking/api/v1/admin/booking/bid-confirm'

  //   usePostHook(
  //     url,
  //     data,
  //     (res) => {
  //       setBiddings(
  //         produce((draft) => {
  //           const bidding = draft.find((x) => x.id === item.id)
  //           bidding.editMode = false
  //         })
  //       )
  //       setToast(res.data.message, 'success')
  //     },
  //     (err) => setToast(err.response?.data?.message, 'error'),
  //     true
  //   )
  // }

  const rejectBid = (bidding) => {
    let url = '/lf-booking/api/v1/admin/booking/bidding-reject'

    let data = {
      biddingId: bidding.id
    }
    usePutHook(
      url,
      data,
      (res) => {
        setBiddings(
          produce((draft) => {
            const bidding = draft.find((x) => x.id === res.data.data.id)
            bidding.biddingStatus = res.data.data.biddingStatus
            bidding.confirmed = res.data.data.confirmed
          })
        )
        setToast('Bid rejected successfully!', 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const onProposedAmountChange = (e, item) => {
    setBiddings(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.proposedAmountPerTruck = e.target.value
      })
    )
    setGenPlanData((prev) =>
      prev.map((prevItem) =>
        prevItem.id === item.id
          ? { ...prevItem, proposedAmountPerTruck: parseInt(e.target.value) }
          : prevItem
      )
    )
  }

  const cancelChanges = (item, index) => {
    let biddingItem = initialBiddings[index]
    setBiddings(
      produce((draft) => {
        const bidding = draft.find((x) => x.id === item.id)
        bidding.proposedAmountPerTruck = biddingItem.proposedAmountPerTruck
        bidding.numberOfTrucks = biddingItem.numberOfTrucks
        bidding.editMode = false
      })
    )
  }

  const setFairAmount = () => {
    averageFare && setBaseAmount(averageFare)
  }

  const showAcceptBtn = (item) => {
    return (
      item.biddingStatus === 'responded' || item.biddingStatus === 'rejected'
    )
  }

  return (
    <>
      <Grid container p={1}>
        <Grid item xs={2}>
          {user.team !== 'fulfillment_team' && 'Fare Calculator'}
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body2">Vendor Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography textAlign="center" variant="body2">
            Bid Quantity
          </Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography textAlign="center" variant="body2">
            Accepted Quantity
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" textAlign="end">
            Per Truck Amount
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignItems="center" p={1} spacing={1}>
        {!isEmptyArray(biddings) ? (
          <>
            {biddings.map((item, index) => (
              <Fragment key={item.id}>
                <Grid item xs={2}>
                  {user.team !== 'fulfillment_team' && (
                    <Checkbox
                      checked={item.isChecked}
                      onChange={(e) => handleChange(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={item.numberOfTrucks === 0}
                    />
                  )}
                </Grid>
                <Grid item xs={2.5}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box>
                      <Avatar
                        sx={{ width: '65px', height: '65px' }}
                        alt={item.vendor.fullName}
                        src={
                          item.vendor.profilePicture
                            ? item.vendor.profilePicture
                            : 'as'
                        }
                      />
                    </Box>
                    <Box flex={1}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'space-between',
                          width: '100%',
                          gap: 1
                        }}
                      >
                        <Typography className="company-name">
                          {item.companyName ? item.companyName : 'N/A'}
                        </Typography>
                        {item.tripType === 'return_trip' && (
                          <Chip
                            label={
                              <Typography textAlign="center" variant="body2">
                                Return Trip
                              </Typography>
                            }
                            size="small"
                            sx={{
                              bgcolor: '#F82012',
                              color: '#fff',
                              width: '100px',
                              height: '20px',
                              borderRadius: '4px'
                            }}
                          />
                        )}
                      </Box>

                      <Typography className="company-contact">
                        {item.vendor.fullName}
                      </Typography>
                      <Typography className="company-phone">
                        {item.vendor.phone}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} alignSelf="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography>{item.originalNumberOfTrucks}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2.5} alignSelf="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    {item.biddingStatus !== 'accepted' && item.editMode ? (
                      <Box>
                        <IconButton
                          onClick={() => onTruckQtyChange(item, 'substract')}
                          disabled={item.numberOfTrucks === 0}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                    ) : null}

                    <Box>
                      <Typography>{item.numberOfTrucks}</Typography>
                    </Box>
                    {item.biddingStatus !== 'accepted' && item.editMode ? (
                      <Box>
                        <IconButton
                          onClick={() => onTruckQtyChange(item, 'add')}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={3} alignSelf="center">
                  {!item.editMode ? (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        justifyContent="flex-end"
                      >
                        <Typography
                          textAlign={'end'}
                          component="div"
                          color="primary"
                        >
                          {item.proposedAmountPerTruck}
                        </Typography>
                        {item.biddingStatus !== 'accepted' &&
                          getAccessLevel('editBid') && (
                            <Box>
                              <IconButton
                                onClick={() => onEditClick(item, index)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          )}
                      </Box>
                      <Box
                        sx={{ float: 'right' }}
                        display="flex"
                        alignItems="center"
                      >
                        {showAcceptBtn(item) ? (
                          <GlobalButton
                            variant="contained"
                            onClick={() => saveBidding(item)}
                            disabled={!getAccessLevel('acceptAndEditBid')}
                            size="small"
                            name="Accept"
                          />
                        ) : (
                          item.biddingStatus === 'accepted' &&
                          getAccessLevel('rejectBid') && (
                            <Button
                              variant="contained"
                              onClick={() => rejectBid(item)}
                              sx={{ background: '#E31B0C' }}
                              size="small"
                            >
                              Reject
                            </Button>
                          )
                        )}
                        {item.biddingStatus !== 'responded' && (
                          <Typography
                            color={getBiddingStatusColor(item.biddingStatus)}
                            ml={1}
                          >
                            {formatStage(item.biddingStatus)}
                          </Typography>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          <TextField
                            size="small"
                            value={item.proposedAmountPerTruck}
                            onChange={(e) => onProposedAmountChange(e, item)}
                            onKeyDown={(event) =>
                              event.key === '.' && event.preventDefault()
                            }
                          />
                        </Box>
                        <IconButton
                          size="small"
                          onClick={() => cancelChanges(item, index)}
                        >
                          <CloseIcon sx={{ color: '#F82012' }} />
                        </IconButton>
                        <GlobalButton
                          iconButton
                          size="small"
                          onClick={() => saveBidding(item)}
                          variant="contained"
                          name={<DoneIcon color="success" />}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Fragment>
            ))}
          </>
        ) : (
          <Box
            display="flex"
            sx={{ width: '100%' }}
            justifyContent="center"
            mt={1}
          >
            <Typography component="div">No Data Available</Typography>
          </Box>
        )}
      </Grid>
      {!isEmptyArray(biddings) && getAccessLevel('fareCalculator') && (
        <Box p={2}>
          <Box my={2} gap={0.5} display="flex" alignItems="center">
            <Typography variant="body1">Average Fare:</Typography>
            <Typography variant="body1" color="primary">
              ৳{averageFare}
            </Typography>
          </Box>
          <Box mb={2} display="flex" gap={2} alignItems="center">
            <Button
              onClick={generatePlan}
              disabled={!getAccessLevel('acceptAndEditBid')}
              variant="contained"
            >
              Generate Plan
            </Button>
            <Button
              onClick={setFairAmount}
              disabled={!getAccessLevel('acceptAndEditBid')}
              variant="contained"
            >
              Select Fare
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

export default VendorResponse
VendorResponse.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func,
  setFareHistoryStatus: PropTypes.func,
  updateBookingStage: PropTypes.func,
  setBaseAmount: PropTypes.func
}

const getBiddingStatusColor = (status) => {
  switch (status) {
    case 'rejected':
      return 'error'

    case 'accepted':
      return 'green'

    default:
      return 'green'
  }
}
