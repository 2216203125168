/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import Typography from '@mui/material/Typography'
import './booking-detail.css'
import { Chip } from '@mui/material'
import { formatStage } from '../../../utils/functions'
import { Delete } from '@mui/icons-material'
import { usePutHook } from '../../../hooks/fetchHook'

const DocumentItem = (props) => {
  const { data, setToast, setDocuments } = props
  const onClose = () => {}
  const deleteDocument = () => {
    let url = '/lf-sup/api/v1/admin/supplier/delete-document'

    let payload = {
      id: data.id
    }

    usePutHook(
      url,
      payload,
      () => {
        setDocuments((prev) => prev.filter((doc) => doc.id !== data.id))
        setToast('Document deleted successfully!', 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Box p={1}>
      <Box
        display="flex"
        alignItems="center"
        className="doc-item-container"
        p={1}
        gap={1}
      >
        <Avatar
          alt={data.imageName}
          src={data.imageUrl ? data.imageUrl : 'asa'}
        />
        <Box flexGrow={1}>
          <Chip
            sx={{ textTransform: 'capitalize' }}
            label={formatStage(data.tag)}
            color="primary"
            size="small"
          />
          <Box>
            <Typography
              variant="subtitle1"
              component="div"
              className="file-name-text"
              sx={{ wordBreak: 'break-all' }}
            >
              {data.imageName}
            </Typography>
          </Box>
        </Box>
        <IconButton color="primary" onClick={deleteDocument}>
          <Delete color="error" />
        </IconButton>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={data.imageUrl}
          download
        >
          <IconButton
            sx={{ background: 'rgba(0, 0, 0, 0.05)' }}
            color="primary"
            onClick={onClose}
          >
            <DownloadIcon color="#fff" />
          </IconButton>
        </a>
      </Box>
    </Box>
  )
}

DocumentItem.propTypes = {
  data: PropTypes.object,
  setToast: PropTypes.func,
  setDocuments: PropTypes.func
}

export default DocumentItem
