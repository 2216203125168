import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import BookingDetailTab from './Tabs/BookingDetailTab'
import BookingDetailHeader from './BookingDetailHeader'
import TripsByBooking from './Tabs/TripsByBooking'
import Snackbars from '../../../components/Snackbar/Snackbar'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const BookingDetailTabsContainer = (props) => {
  const { booking } = props
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [value, setValue] = useState(0)
  const handleTabChange = (event, newValue) => setValue(newValue)

  const setToast = (msg, severity) => {
    if (!msg) msg = 'Something went wrong'
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  return (
    <>
      <Box sx={{ width: '70vw' }}>
        <BookingDetailHeader booking={booking} />
        <Divider />
        <Box>
          <Box>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Booking Details" {...a11yProps(0)} />
              <Tab label="Trips" {...a11yProps(1)} />
              {/* <Tab label="Document" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BookingDetailTab booking={booking} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TripsByBooking booking={booking} setToast={setToast} />
          </TabPanel>

          {/* <TabPanel value={value} index={2}>
            <Documents booking={booking} setToast={setToast} />
          </TabPanel> */}
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

BookingDetailTabsContainer.propTypes = {
  booking: PropTypes.object,
  updateBooking: PropTypes.func,
  updateBookingStage: PropTypes.func
}

export default BookingDetailTabsContainer
