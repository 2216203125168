import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../../hooks/fetchHook'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AddTruckPlate from '../../AddTruckPlate/AddTruckPlate'
import useDebouncedValue from '../../../hooks/useDebounceValue'

import {
  formatStage,
  getLoggedInUser,
  getTripStatusColor
} from '../../../utils/functions'
import { Chip, Stack, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const filter = createFilterOptions()

const TruckPlateAutoComplete = (props) => {
  const {
    vendorId,
    setTruckPlate,
    truckPlate,
    booking,
    refreshTruckList,
    fixedVendor,
    setToast
  } = props
  const [truckPlates, setTruckPlates] = useState([])
  const [open, toggleOpen] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const user = getLoggedInUser()

  useEffect(() => {
    if (vendorId) {
      let url = `/lf-supa/api/v1/admin/truck/supplier/${vendorId}/?truckSize=${booking.truckSize.nameEn}`

      if (user.role === 'cluster_head' || user.role === 'kam') {
        url = `/lf-supa/api/v1/crm/truck/supplier/${vendorId}/?truckSize=${booking.truckSize.nameEn}`
      }

      if (debouncedSearch) url = url + `&query=${debouncedSearch}`

      useGetHook(
        url,
        (res) => {
          let results = res.data.data.map((loc) => {
            return {
              id: loc.id,
              title: loc.licensePlateNoTextEn,
              truckAvailability: loc.truckAvailability
            }
          })
          setTruckPlates(results)
        },
        () => {},
        true
      )
    }
  }, [vendorId, debouncedSearch])

  const onTruckPlateSelect = (newValue) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true)
      })
    } else if (newValue && newValue.title === 'Add Truck Plate') {
      toggleOpen(true)
    } else {
      if (newValue) {
        setTruckPlate(newValue)
      } else {
        setTruckPlate({
          id: 0,
          title: ''
        })
      }
    }
  }

  return (
    <>
      <Autocomplete
        value={truckPlate}
        onChange={(event, newValue) => onTruckPlateSelect(newValue)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          filtered.push({
            inputValue: params.inputValue,
            title: 'Add Truck Plate'
          })

          return filtered
        }}
        id="free-solo-dialog-demowdh"
        sx={{ width: '180px' }}
        options={truckPlates}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.title
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack sx={{ borderBottom: '1px solid #e9e9e9', width: '100%' }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography> {option.title}</Typography>
                {option.title !== 'Add Truck Plate' ? (
                  <Chip
                    size="small"
                    label={formatStage(option.truckAvailability)}
                    sx={{
                      bgcolor: getTripStatusColor(option.truckAvailability)[
                        'bg'
                      ],
                      color: getTripStatusColor(option.truckAvailability)[
                        'color'
                      ]
                    }}
                  />
                ) : (
                  <AddCircleIcon size="small" color="success" />
                )}
              </Stack>

              <Typography>{option.phone}</Typography>
            </Stack>
          </li>
        )}
        size="small"
        freeSolo
        renderInput={(params) => (
          <TextField {...params} placeholder="Search Truckplates" />
        )}
      />
      <AddTruckPlate
        open={open}
        onClose={toggleOpen}
        setToast={setToast}
        refreshData={refreshTruckList}
        fixedVendor={fixedVendor}
        fixedTruckSize={booking.truckSize}
      />
    </>
  )
}

TruckPlateAutoComplete.propTypes = {
  setToast: PropTypes.func,
  vendorId: PropTypes.number,
  setTruckPlate: PropTypes.func,
  truckPlate: PropTypes.object,
  booking: PropTypes.object,
  refreshTruckList: PropTypes.func,
  fixedVendor: PropTypes.object
}

export default TruckPlateAutoComplete
