import React from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getLoggedInUser } from '../../utils/functions'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import GppGood from '@mui/icons-material/GppGood'

const VendorAutoComplete = (props) => {
  const { vendors, vendor, setVendor, setVendors, disabled, refetch } = props
  const user = getLoggedInUser()

  const onVendorSelect = (newValue) => {
    if (newValue) {
      setVendor({
        ...newValue
      })
    } else {
      setVendor({
        id: 0,
        title: '',
        phone: ''
      })
    }
  }

  const onVendorChange = (val) => {
    if (val.length > 1) {
      let url
      url = `/lf-sup/api/v1/admin/supplier?fullName=${val}`
      if (user.role === 'cluster_head' || user.role === 'kam') {
        url = `/lf-sup/api/v1/crm/supplier/list?query=${val}`
      }
      useGetHook(
        url,
        (res) => {
          let results = res.data.data.map((loc) => ({
            id: loc.userId,
            title: loc.fullName,
            phone: loc.phone,
            securitySponsor: loc.securitySponsor
          }))

          setVendors(results)
        },
        () => {},
        true
      )
    }
  }

  return (
    <Autocomplete
      value={vendor}
      fullWidth
      onChange={(event, newValue) => {
        onVendorSelect(newValue)
      }}
      onInputChange={(event, newInputValue, reason) => {
        reason === 'clear' && refetch()
        onVendorChange(newInputValue)
      }}
      options={vendors}
      disabled={disabled}
      label="Vendor"
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        return (
          <Box
            {...props}
            key={option.id}
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="body2">
              {option.title} - {option.phone}
            </Typography>
            {option.securitySponsor && (
              <GppGood sx={{ ml: 'auto' }} fontSize="small" color="success" />
            )}
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search Vendors" fullWidth />
      )}
    />
  )
}

VendorAutoComplete.propTypes = {
  vendors: PropTypes.array,
  setVendors: PropTypes.func,
  vendor: PropTypes.object,
  setVendor: PropTypes.func,
  disabled: PropTypes.any,
  refetch: PropTypes.func
}

export default VendorAutoComplete
