import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const AvTimer = (props) => {
  return (
    <SvgIcon
      {...props}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41.25 63.7495C41.25 65.812 42.9375 67.4995 45 67.4995C47.0625 67.4995 48.75 65.812 48.75 63.7495C48.75 61.687 47.0625 59.9995 45 59.9995C42.9375 59.9995 41.25 61.687 41.25 63.7495ZM41.25 11.2495V26.2495H48.75V19.0495C61.4625 20.887 71.25 31.762 71.25 44.9995C71.25 59.512 59.5125 71.2495 45 71.2495C30.4875 71.2495 18.75 59.512 18.75 44.9995C18.75 38.6995 20.9625 32.9245 24.675 28.4245L45 48.7495L50.2875 43.462L24.7875 17.962V18.037C16.575 24.187 11.25 33.937 11.25 44.9995C11.25 63.637 26.325 78.7495 45 78.7495C63.6375 78.7495 78.75 63.637 78.75 44.9995C78.75 26.362 63.6375 11.2495 45 11.2495H41.25ZM67.5 44.9995C67.5 42.937 65.8125 41.2495 63.75 41.2495C61.6875 41.2495 60 42.937 60 44.9995C60 47.062 61.6875 48.7495 63.75 48.7495C65.8125 48.7495 67.5 47.062 67.5 44.9995ZM22.5 44.9995C22.5 47.062 24.1875 48.7495 26.25 48.7495C28.3125 48.7495 30 47.062 30 44.9995C30 42.937 28.3125 41.2495 26.25 41.2495C24.1875 41.2495 22.5 42.937 22.5 44.9995Z" />
    </SvgIcon>
  )
}

export default AvTimer
