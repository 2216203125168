import React from 'react'
import PropTypes from 'prop-types'
import TotalWeightIcon from '../../components/SvgIcons/Reporting/TotalWeightIcon'
import AvgFullfillmentRateIcon from '../../components/SvgIcons/Reporting/AvgFullfillmentRateIcon'
import TotalKiloIcon from '../../components/SvgIcons/Reporting/TotalKiloIcon'
import CostPerKmIcon from '../../components/SvgIcons/Reporting/CostPerKmIcon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getPropValue } from '../../utils/functions'
import humanizeNumber from 'humanize-number'

const CompanyAdditionalMetrics = (props) => {
  const { metrics } = props
  return (
    <>
      <Box
        mt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5" mb={2}>
            Additional Matrics
          </Typography>
        </Box>
        <Box></Box>
      </Box>
      <Box mt={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <TotalWeightIcon sx={{ width: '75px', height: '75px' }} />
            </Box>
            <Box>
              <Box display="flex" gap={1}>
                <Typography color="primary" variant="h5" component="div">
                  {humanizeNumber(getPropValue('totalWeight', metrics), {
                    delimiter: ',',
                    separator: '.'
                  })}
                </Typography>
                <Typography color="primary" variant="h6" component="div">
                  Tonnes
                </Typography>
              </Box>
              <Typography variant="subtitle1" component="div">
                Total Weight
              </Typography>
            </Box>
          </Box>
          {/* <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <AvgLeadTimeIcon sx={{ width: '75px', height: '75px' }} />
            </Box>
            <Box>
              <Box display="flex" gap={1}>
                <Typography color="primary" variant="h5" component="div">
                  {getPropValue('avgLeadTime', metrics)}
                </Typography>
                <Typography color="primary" variant="h6" component="div">
                  Hours
                </Typography>
              </Box>

              <Typography variant="subtitle1" component="div">
                AVG Lead Time
              </Typography>
            </Box>
          </Box> */}
          <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <AvgFullfillmentRateIcon sx={{ width: '75px', height: '75px' }} />
            </Box>

            <Box>
              <Box display="flex" gap={1}>
                <Typography color="primary" variant="h5" component="div">
                  {getPropValue('avgFulfillmentRate', metrics)}
                </Typography>
                <Typography color="primary" variant="h6" component="div">
                  %
                </Typography>
              </Box>

              <Typography variant="subtitle1" component="div">
                AVG Fulfillment Rate
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Box display="flex" alignItems="center" gap={1}>
          {/* <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <AvgIncidenceRateIcon sx={{ width: '75px', height: '75px' }} />
            </Box>
            <Box>
              <Typography variant="h5" component="div">
                {getPropValue('avgIncidentRate', metrics)}
              </Typography>
              <Typography variant="subtitle1" component="div">
                AVG Incidence Rate
              </Typography>
            </Box>
          </Box> */}
          <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <TotalKiloIcon sx={{ width: '75px', height: '75px' }} />
            </Box>
            <Box>
              <Box display="flex" gap={1}>
                <Typography color="primary" variant="h5" component="div">
                  {humanizeNumber(getPropValue('totalKm', metrics), {
                    delimiter: ',',
                    separator: '.'
                  })}
                </Typography>
                <Typography color="primary" variant="h6" component="div">
                  Km
                </Typography>
              </Box>

              <Typography variant="subtitle1" component="div">
                Total Kilometer
              </Typography>
            </Box>
          </Box>
          <Box
            className="card-box card-additional"
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            flex={1}
          >
            <Box>
              <CostPerKmIcon sx={{ width: '75px', height: '75px' }} />
            </Box>
            <Box>
              <Box>
                <Typography color="primary" variant="h5" component="div">
                  ৳&nbsp;{getPropValue('costPerKm', metrics)}
                </Typography>
              </Box>
              <Typography variant="subtitle1" component="div">
                Cost Per KM
              </Typography>
            </Box>
          </Box>
          {/* <Box display="flex" alignItems="center" gap={2} p={2} flex={1}></Box> */}
        </Box>
      </Box>
    </>
  )
}

CompanyAdditionalMetrics.propTypes = {
  metrics: PropTypes.object
}

export default CompanyAdditionalMetrics
