/* eslint-disable react/prop-types */
import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, Typography } from '@mui/material'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   console.log(error, errorInfo)
  // }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          minHeight="100vh"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap={2}
          bgcolor="#f7f7f7"
        >
          <ErrorIcon color="error" sx={{ height: '120px', width: '120px' }} />
          <Typography variant="h3" fontWeght={300} color="error">
            Something went wrong!
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
              size="large"
            >
              Reload
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => (window.location.href = '/')}
              size="large"
            >
              Home
            </Button>
          </Box>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
