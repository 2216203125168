import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PaymentIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#F1F5F9"
      />
      <path
        d="M27.8335 23.6667V17C27.8335 16.0834 27.0835 15.3334 26.1668 15.3334H14.5002C13.5835 
15.3334 12.8335 16.0834 12.8335 17V23.6667C12.8335 24.5834 13.5835 25.3334
 14.5002 25.3334H26.1668C27.0835 25.3334 27.8335 24.5834 27.8335 23.6667ZM20.3335
  22.8334C18.9502 22.8334 17.8335 21.7167 17.8335 20.3334C17.8335 18.95
   18.9502 17.8334 20.3335 17.8334C21.7168 17.8334 22.8335 18.95 22.8335 20.3334C22.8335 21.7167 21.7168 22.8334 20.3335 22.8334ZM31.1668 17.8334V27C31.1668 27.9167 30.4168 28.6667 29.5002 28.6667H15.3335V27H29.5002V17.8334H31.1668Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default PaymentIcon
