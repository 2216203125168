import React from 'react'
import PropTypes from 'prop-types'
import { getHumanReadableDate, formatStage } from '../../utils/functions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { Chip, Grid } from '@mui/material'

const Truck = (props) => {
  const { data } = props
  const navigate = useNavigate()

  const gotoTruckProfile = () => {
    navigate('/home/truck-list/truck-profile/' + data.id)
  }

  return (
    <Box onClick={gotoTruckProfile} className="truck-item" mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography className="truck-item-label" variant="body2">
            Truck Type
          </Typography>
          <Typography variant="body1">
            {data.truckSize?.nameEn ? data.truckSize?.nameEn : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className="truck-item-label" variant="body2">
            Truck Plate
          </Typography>
          <Typography variant="body1">
            {data.licensePlateNoTextEn ? data.licensePlateNoTextEn : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              textAlign="left"
              className="truck-item-label"
              variant="body2"
            >
              Truck Status
            </Typography>
            <Chip
              className="truck-item-label"
              mt={1}
              label={`${formatStage(data.truckAvailability)} ${
                data.truckAvailability === 'in_trip'
                  ? `ID: ${data.tripId || 'N/A'}`
                  : ''
              } `}
              size="small"
              color={
                data.truckAvailability === 'available' ? 'success' : 'error'
              }
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography className="truck-item-label" variant="body2">
              Mode
            </Typography>
            <Chip
              className="truck-item-label"
              mt={1}
              label={data.status === 'active' ? 'Enabled' : 'Disabled'}
              size="small"
              color={data.status === 'active' ? 'success' : 'error'}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography className="truck-item-label" variant="body2">
            Created At
          </Typography>
          <Typography variant="body1">
            {getHumanReadableDate(data.createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

Truck.propTypes = {
  data: PropTypes.object
}

export default Truck
