import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import VendorList from '../VendorList/VendorList'
import Box from '@mui/material/Box'
import ShipperList from '../../features/ShipperList/ShipperList'
import TruckList from '../TruckList/TruckList'
import DriverList from '../DriverList/DriverList'
import Snackbars from '../../components/Snackbar/Snackbar'
import OperationPersonnelList from '../OperationPersonals/OperationPersonnelList'
import { getLoggedInUser, canAccessTab } from '../../utils/functions'
import { useSearchParams } from 'react-router-dom'
import useDebouncedValue from '../../hooks/useDebounceValue'
import ProList from '../ProList'
import OTPList from '../OTP'

function getTabs(loggedInUser) {
  switch (loggedInUser?.role) {
    case 'kam':
      if (loggedInUser.team === 'vendor_team') {
        return ['vendor', 'trucklist', 'driverlist']
      } else if (loggedInUser.team === 'fulfillment_team') {
        return ['shipper', 'trucklist', 'driverlist']
      } else return ['shipper']
    case 'admin':
      return [
        'shipper',
        'vendor',
        'gm',
        'cluster_head',
        'kam',
        'pro',
        'trucklist',
        'driverlist',
        'otp'
      ]
    case 'gm':
      return [
        'shipper',
        'vendor',
        'cluster_head',
        'kam',
        'pro',
        'trucklist',
        'driverlist'
      ]
    case 'cluster_head':
      return ['shipper', 'vendor', 'kam', 'pro', 'trucklist', 'driverlist']
    case 'pro':
      return ['shipper', 'trucklist', 'driverlist']

    default:
      break
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const TabsContainer = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  let tab = searchParams.get('tab')
  const loggedInUser = getLoggedInUser()
  const debouncedTab = useDebouncedValue(tab, 500)
  const [value, setValue] = useState(tab ? tab : 0)
  const [gmList, setGmList] = useState([])
  const [totalGmCount, setTotalGmCount] = useState(0)
  const [clusterHeadList, setClusterHeadList] = useState([])
  const [totalClusterHeadCount, setTotalClusterHeadCount] = useState(0)
  const [kamList, setKamList] = useState([])
  const [totalKamCount, setTotalKamCount] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const tabs = getTabs(loggedInUser)

  useEffect(() => {
    if (debouncedTab) setValue(parseInt(debouncedTab))
  }, [debouncedTab])

  const handleTabChange = (event, newValue) => {
    setSearchParams({ tab: newValue })
  }

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  return (
    <>
      <Box p={2}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {canAccessTab('shipper') && (
              <Tab label="Shipper" {...a11yProps(tabs.indexOf('shipper'))} />
            )}

            {canAccessTab('vendor') && (
              <Tab label="Vendor" {...a11yProps(tabs.indexOf('vendor'))} />
            )}

            {canAccessTab('gm') && (
              <Tab label="GM" {...a11yProps(tabs.indexOf('gm'))} />
            )}

            {canAccessTab('cluster_head') && (
              <Tab
                label="Cluster Head"
                {...a11yProps(tabs.indexOf('cluster_head'))}
              />
            )}

            {canAccessTab('kam') && (
              <Tab label="KAM" {...a11yProps(tabs.indexOf('kam'))} />
            )}

            {canAccessTab('pro') && (
              <Tab label="CX" {...a11yProps(tabs.indexOf('pro'))} />
            )}

            {canAccessTab('trucklist') && (
              <Tab
                label="Truck List"
                {...a11yProps(tabs.indexOf('trucklist'))}
              />
            )}

            {canAccessTab('driverlist') && (
              <Tab
                label="Driver List"
                {...a11yProps(tabs.indexOf('driverlist'))}
              />
            )}

            {canAccessTab('otp') && (
              <Tab label="Otp" {...a11yProps(tabs.indexOf('otp'))} />
            )}
          </Tabs>
        </Box>

        {canAccessTab('shipper') && (
          <TabPanel value={value} index={tabs.indexOf('shipper')}>
            <ShipperList />
          </TabPanel>
        )}
        {canAccessTab('vendor') && (
          <TabPanel value={value} index={tabs.indexOf('vendor')}>
            <VendorList />
          </TabPanel>
        )}
        {canAccessTab('gm') && (
          <TabPanel value={value} index={tabs.indexOf('gm')}>
            <OperationPersonnelList
              role={'gm'}
              gmList={gmList}
              setGmList={setGmList}
              clusterHeadList={clusterHeadList}
              setClusterHeadList={setClusterHeadList}
              totalGmCount={totalGmCount}
              setTotalGmCount={setTotalGmCount}
              totalClusterHeadCount={totalClusterHeadCount}
              setTotalClusterHeadCount={setTotalClusterHeadCount}
              kamList={kamList}
              setKamList={setKamList}
              totalKamCount={totalKamCount}
              setTotalKamCount={setTotalKamCount}
              showLastLogin={true}
            />
          </TabPanel>
        )}
        {canAccessTab('cluster_head') && (
          <TabPanel value={value} index={tabs.indexOf('cluster_head')}>
            <OperationPersonnelList
              role={'cluster_head'}
              gmList={gmList}
              setGmList={setGmList}
              clusterHeadList={clusterHeadList}
              setClusterHeadList={setClusterHeadList}
              totalGmCount={totalGmCount}
              setTotalGmCount={setTotalGmCount}
              totalClusterHeadCount={totalClusterHeadCount}
              setTotalClusterHeadCount={setTotalClusterHeadCount}
              kamList={kamList}
              setKamList={setKamList}
              totalKamCount={totalKamCount}
              setTotalKamCount={setTotalKamCount}
              showLastLogin={true}
            />
          </TabPanel>
        )}
        {canAccessTab('kam') && (
          <TabPanel value={value} index={tabs.indexOf('kam')}>
            <OperationPersonnelList
              role={'kam'}
              gmList={gmList}
              setGmList={setGmList}
              clusterHeadList={clusterHeadList}
              setClusterHeadList={setClusterHeadList}
              totalGmCount={totalGmCount}
              setTotalGmCount={setTotalGmCount}
              totalClusterHeadCount={totalClusterHeadCount}
              setTotalClusterHeadCount={setTotalClusterHeadCount}
              kamList={kamList}
              setKamList={setKamList}
              totalKamCount={totalKamCount}
              setTotalKamCount={setTotalKamCount}
              showLastLogin={true}
            />
          </TabPanel>
        )}

        {canAccessTab('pro') && (
          <TabPanel value={value} index={tabs.indexOf('pro')}>
            <ProList />
          </TabPanel>
        )}
        {canAccessTab('trucklist') && (
          <TabPanel value={value} index={tabs.indexOf('trucklist')}>
            <TruckList setToast={setToast} />
          </TabPanel>
        )}

        {canAccessTab('driverlist') && (
          <TabPanel value={value} index={tabs.indexOf('driverlist')}>
            <DriverList setToast={setToast} />
          </TabPanel>
        )}

        {canAccessTab('otp') && (
          <TabPanel value={value} index={tabs.indexOf('otp')}>
            <OTPList />
          </TabPanel>
        )}
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default TabsContainer
