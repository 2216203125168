import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const GreenLogistic = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 77 90" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.3679 38.0983C55.3173 41.8 54.8872 45.7223 53.2933 49.4239C50.5355 55.7241 45.4754 58.9844 38.5936 59.7444C37.9864 59.8179 37.4045 59.8669 36.7973 59.8915C35.1275 59.965 34.0901 58.9109 34.4949 57.342C34.8238 56.0918 35.3299 54.817 36.013 53.7139C38.3659 49.9387 41.5791 46.8254 44.7923 43.7611C45.0959 43.4669 45.4248 43.1728 45.7537 42.8786C46.538 42.1432 46.5633 41.1136 45.8296 40.3536C45.1718 39.6672 44.2357 39.6672 43.4007 40.3781C36.9238 45.7958 31.3324 51.8753 27.6385 59.4747C26.4494 61.9507 25.5891 64.5492 25.2096 67.2703C25.0578 68.3734 24.4506 69.0353 23.5398 69.1088C22.7049 69.1824 21.9965 68.8147 21.7435 68.0547C21.5663 67.5399 21.6169 66.9025 21.7181 66.3387C22.1989 63.397 23.0085 60.5043 24.4759 57.8813C25.4626 56.1408 25.3614 54.8415 24.4 53.0275C20.6555 46.0164 21.794 39.324 26.6012 33.171C28.9794 30.1312 32.4962 28.881 36.2154 28.1455C38.9478 27.6062 41.6803 27.1159 44.3622 26.5031C46.5633 25.9883 48.5114 24.8852 50.1813 23.3898C51.5222 22.1886 52.9897 22.6053 53.4704 24.3458C54.6595 28.7339 55.292 33.2445 55.3679 38.0983Z" />
      <path d="M43.9828 78.3508C48.3345 77.6644 52.3067 76.3896 55.9246 74.2324C68.1701 66.9026 74.2928 56.1899 73.1543 42.2658C72.0917 29.3959 65.0581 20.0804 53.0657 14.3196C52.6862 14.148 52.3067 14.0009 51.9524 13.8293C50.9151 13.339 50.5103 12.4075 50.9404 11.476C51.3705 10.5689 52.3572 10.2257 53.4199 10.6425C57.1644 12.1378 60.5546 14.197 63.616 16.771C81.4276 31.7737 81.4782 58.151 63.6666 73.1782C58.1258 77.8605 51.6741 80.7286 44.4129 81.8073C43.8057 81.9053 43.5527 82.1014 43.5021 82.7143C43.3503 84.5529 43.1225 86.3669 42.9201 88.2055C42.8442 88.9899 42.49 89.6273 41.6804 89.897C40.8455 90.1666 40.1371 89.897 39.5805 89.2106C37.4552 86.6611 35.33 84.1116 33.23 81.5376C32.3951 80.5325 32.5216 79.65 33.5589 78.8165C36.1902 76.7573 38.8214 74.6981 41.4527 72.6389C42.0852 72.1486 42.7683 72.0261 43.4768 72.3448C44.2358 72.7125 44.5394 73.3498 44.4635 74.1588C44.2864 75.5316 44.1346 76.8554 43.9828 78.3508Z" />
      <path d="M33.0275 11.4759C30.6998 12.1133 28.4987 12.53 26.4494 13.339C13.9256 18.1683 6.31009 27.0179 4.08364 39.9124C1.47768 54.768 9.87748 69.5991 24.1217 75.7522C25.7156 76.4386 26.2469 77.2966 25.7662 78.3752C25.3108 79.4293 24.2229 79.6745 22.6796 78.9636C10.1305 73.1782 2.56562 63.6667 0.440368 50.3555C-2.6463 30.9157 10.8136 12.481 30.7757 8.48516C31.3071 8.3871 31.8384 8.24002 32.3697 8.24002C33.2805 8.24002 33.5082 7.77425 33.5588 6.98979C33.7106 5.24928 33.9383 3.50878 34.1154 1.76827C34.1913 0.910268 34.5961 0.321928 35.4563 0.0767862C36.2913 -0.168356 36.9238 0.199357 37.4551 0.812213C39.555 3.33718 41.6803 5.86214 43.7549 8.41162C44.6152 9.46573 44.514 10.3728 43.4513 11.2062C40.8707 13.2409 38.29 15.2511 35.7094 17.2613C35.0515 17.7761 34.3178 18.0212 33.5335 17.629C32.7239 17.2122 32.4962 16.5258 32.5974 15.6923C32.7492 14.3931 32.8757 13.0938 33.0275 11.4759Z" />
    </SvgIcon>
  )
}

export default GreenLogistic
