import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import CustomTextField from '../../components/CustomTextField/CustomTextField'
import MenuItem from '@mui/material/MenuItem'
import { useGetHook, usePostHook } from '../../hooks/fetchHook'
import { useForm } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { getRoles, getLoggedInUser, getAboveRoles } from '../../utils/functions'
import { offices } from '../../data/data'

const AddUserModal = (props) => {
  const { open, onClose, setToast, refreshList, defaultRole } = props
  const [department] = useState('operation')
  const [role, setRole] = useState(defaultRole || '')
  const [office, setOffice] = useState('')
  const [user, setUser] = useState('')
  const [team, setTeam] = useState(null)
  const [users, setUsers] = useState([])
  const [designation, setDesignation] = useState('')

  const {
    register,
    formState: { errors, isValid },
    getValues,
    resetField
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    if (role) {
      let user = getLoggedInUser()
      if (getAboveRoles(role)[0].value === user.role) {
        setUsers([
          {
            userId: user.userId,
            fullName: user.fullName
          }
        ])
        setUser(user.userId)
      } else {
        let url = `/lf-user/api/v1/admin/hierarchy/user/${
          getAboveRoles(role)[0].value
        }?page=1&count=100`
        useGetHook(
          url,
          (res) => {
            setUsers(res.data.data.result)
          },
          () => {},
          true
        )
      }
    }
  }, [role])

  const onRoleSelect = (e) => {
    setRole(e.target.value)
  }

  const saveUser = () => {
    let payload = {
      role,
      fullName: getValues('fullName'),
      email: getValues('email'),
      phone: getValues('phone'),
      assignedTo: user,
      office: office,
      department,
      designation,
      team: role === 'kam' ? team : null
    }

    let url = '/lf-user/api/v1/admin/hierarchy/user'
    usePostHook(
      url,
      payload,
      () => {
        setRole('')
        setOffice('')
        setUser('')
        setTeam(null)
        setUsers([])
        setDesignation('')
        resetField('fullName')
        resetField('email')
        resetField('phone')
        setToast('User has been created', 'success')
        onClose(false)
        refreshList(role)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Add User</Typography>
        <IconButton onClick={() => onClose(false)}>
          <CloseIcon sx={{ fill: 'red' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select value={role} label="User Type" onChange={onRoleSelect}>
              {getRoles(getLoggedInUser().role).map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {role == 'kam' && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label26xx">
                Select Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-label26xx"
                id="demo-simple-select26xx"
                value={team}
                label="Select Team"
                onChange={(e) => {
                  setTeam(e.target.value)
                }}
              >
                <MenuItem value={'business_team'}>Business Team</MenuItem>
                <MenuItem value={'vendor_team'}>Vendor Team</MenuItem>
                <MenuItem value={'fulfillment_team'}>Fulfilment Team</MenuItem>
              </Select>
            </FormControl>
          )}
          <CustomTextField
            label={'Full Name*'}
            type="text"
            name={'fullName'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Name cannot be empty'
              },
              maxLength: {
                value: 50,
                message: 'Name cannot be larger than 50 characters'
              }
            }}
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label26xx">
              Designation*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label26xx"
              id="demo-simple-select26xx"
              value={designation}
              label="Designation*"
              onChange={(e) => {
                setDesignation(e.target.value)
              }}
            >
              <MenuItem value={'General manager'}>General manager</MenuItem>
              <MenuItem value={'Sr. Officer'}>Sr. Officer</MenuItem>
              <MenuItem value={'Officer'}>Officer</MenuItem>
              <MenuItem value={'Executive'}>Executive</MenuItem>
              <MenuItem value={'Sr. Executive'}>Sr. Executive</MenuItem>
              <MenuItem value={'Sr. Officer'}>Sr. Officer</MenuItem>
              <MenuItem value={'Assistant Manager'}>Assistant Manager</MenuItem>
              <MenuItem value={'Manager'}>Manager</MenuItem>
              <MenuItem value={'Senior manager'}>Senior Manager</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Department</InputLabel>
            <Select
              value={department}
              label="Department"
              disabled
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={'operation'}>Operations</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Assign</InputLabel>
            <Select
              value={user}
              label="Assign"
              onChange={(e) => setUser(e.target.value)}
            >
              {users.map((item) => (
                <MenuItem key={item.userId} value={item.userId}>
                  {item.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Zone</InputLabel>
            <Select
              value={office}
              label="Zone"
              onChange={(e) => setOffice(e.target.value)}
            >
              {offices.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CustomTextField
            label={'Phone'}
            type="text"
            name={'phone'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Phone cannot be empty'
              },
              maxLength: {
                value: 14,
                message: 'Phone cannot be larger than 50 characters'
              }
            }}
            fullWidth
          />
          <CustomTextField
            label={'Email'}
            type="text"
            name={'email'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Email cannot be empty'
              }
            }}
            fullWidth
          />
        </Stack>
        <Box mt={2} display="flex" flexDirection="row-reverse">
          <Box>
            <Button
              variant="contained"
              color={'primary'}
              onClick={saveUser}
              disabled={!isValid || !office}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

AddUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  refreshList: PropTypes.func,
  defaultRole: PropTypes.string
}

export default AddUserModal
