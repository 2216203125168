import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Snackbars from '../../components/Snackbar/Snackbar'
import { getLoggedInUser } from '../../utils/functions'
import { useSearchParams } from 'react-router-dom'
import useToast from '../../hooks/useToast'
import Reports from './Reports'
import ProReport from './ProReport'
import ClusterHeadReports from '../Reporting/ClusterHeadReports'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const baseAccess = ['company', 'pro', 'metrics']

const keyValue = {
  cluster_head: baseAccess.filter((item) => item !== 'pro'),
  gm: baseAccess.filter((item) => item !== 'pro'),
  admin: baseAccess
}

export const canAccessTab = (tabName) => {
  let role = getLoggedInUser().role
  if (keyValue[role].length === 0) return false
  if (keyValue[role].indexOf(tabName) > -1) return true
  return false
}

function getTabs(role) {
  switch (role) {
    case 'admin':
      return baseAccess
    case 'gm':
      return baseAccess.filter((item) => item !== 'pro')
    case 'cluster_head':
      return baseAccess.filter((item) => item !== 'pro')
    default:
      break
  }
}

const ReportTabsContainer = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  let tab = searchParams.get('tab')
  const [value, setValue] = useState(parseInt(tab) ? parseInt(tab) : 0)
  const tabs = getTabs(getLoggedInUser().role)

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    setSearchParams({ tab: newValue })
  }

  return (
    <>
      <Box p={2}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {canAccessTab('company') && (
              <Tab
                label="Company Report"
                {...a11yProps(tabs.indexOf('company'))}
              />
            )}
            {canAccessTab('pro') && (
              <Tab label="Pro Report" {...a11yProps(tabs.indexOf('pro'))} />
            )}
            {canAccessTab('metrics') && (
              <Tab
                label="Ops Metrics"
                {...a11yProps(tabs.indexOf('metrics'))}
              />
            )}
          </Tabs>
        </Box>
        {canAccessTab('company') && (
          <TabPanel value={value} index={tabs.indexOf('company')}>
            <Reports />
          </TabPanel>
        )}
        {canAccessTab('pro') && (
          <TabPanel value={value} index={tabs.indexOf('pro')}>
            <ProReport />
          </TabPanel>
        )}
        {canAccessTab('metrics') && (
          <TabPanel value={value} index={tabs.indexOf('metrics')}>
            <ClusterHeadReports setToast={setToast} />
          </TabPanel>
        )}
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default ReportTabsContainer
