import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const MultiLocation = (props) => {
  return (
    <SvgIcon
      {...props}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M75 3.74951V14.9995H86.25V22.4995H75V33.7495H67.5V22.4995H56.25V14.9995H67.5V3.74951H75ZM45 48.7495C49.125 48.7495 52.5 45.3745 52.5 41.2495C52.5 37.1245 49.125 33.7495 45 33.7495C40.875 33.7495 37.5 37.1245 37.5 41.2495C37.5 45.3745 40.875 48.7495 45 48.7495ZM52.5 12.187V26.2495H63.75V37.4995H74.7C74.8875 38.962 75 40.462 75 41.9995C75 54.4495 64.9875 69.187 45 86.2495C25.0125 69.187 15 54.4495 15 41.9995C15 23.3245 29.25 11.2495 45 11.2495C47.55 11.2495 50.0625 11.5495 52.5 12.187Z" />
    </SvgIcon>
  )
}

export default MultiLocation
