/* eslint-disable indent */
import config from '../config/config.json'

export const getLoggedInUser = () =>
  JSON.parse(localStorage.getItem('loop-user'))

export const getConfigurationByKey = (key) => {
  return config[process.env.REACT_APP_NODE_ENV][key]
}

export const getGoogleMapApiKey = () => {
  return config[process.env.REACT_APP_NODE_ENV]['REACT_APP_GOOGLE_MAPS_API_KEY']
}

export const getApiEndPoint = () => getConfigurationByKey('apiEndPoint')

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function removeUrlParameter(paramKey) {
  const url = new URL(location)
  url.searchParams.delete(paramKey)
  history.replaceState(null, null, url)
}

export const getFormattedDate = (date) => {
  let options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  }
  return date.toLocaleDateString('en-US', options)
}

const keyValue = {
  pro: ['shipper', 'trucklist', 'driverlist'],
  kam: ['shipper'], // default value set to shipper team
  cluster_head: ['shipper', 'vendor', 'kam', 'pro', 'trucklist', 'driverlist'],
  gm: ['shipper', 'cluster_head', 'kam', 'vendor', 'pro'],
  admin: [
    'shipper',
    'gm',
    'cluster_head',
    'kam',
    'vendor',
    'pro',
    'otp',
    'driverlist',
    'trucklist'
  ]
}

export const canAccessTab = (tabName) => {
  let user = getLoggedInUser()

  let values = keyValue[user.role]

  if (user.role === 'kam' && user.team === 'vendor_team') {
    values = ['vendor', 'trucklist', 'driverlist']
  }

  if (user.role === 'kam' && user.team === 'fulfillment_team') {
    values = ['shipper', 'trucklist', 'driverlist']
  }

  if (values.length === 0) return false
  if (values.indexOf(tabName) > -1) return true
  return false
}

export const toProperCase = function (str) {
  if (!str) return ''
  str = str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })

  return str.replaceAll('_', ' ')
}

export const getHumanReadableDate = (dateTime) => {
  if (!dateTime) return ''
  let date = new Date(dateTime)
  var year = date.getFullYear()
  var month = date.toLocaleString('default', { month: 'long' })
  var day = date.getDate()
  var strTime = `${day} ${month}, ${year}`
  return strTime
}

export const formatStage = (str) => {
  if (!str) return 'N/A'
  let i,
    frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export const formatCammelCase = (str) => {
  if (!str) return 'N/A'
  let i,
    frags = str.split(/(?=[A-Z])/)
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export const formatReportStage = (str) => {
  if (!str) return 'N/A'
  let i,
    frags = str.split(/(?=[A-Z])/)
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export const getQueryParams = (queryString) => {
  try {
    return new URLSearchParams(queryString).get('tab')
  } catch (ex) {
    return 'home'
  }
}

export const getLastDayOfMonth = () => {
  let today = new Date()
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  return lastDayOfMonth
}

export const getPercentageArray2 = (arr) => {
  let perCentageArray = [
    {
      createdAt: '',
      statusText: 'Requested',
      percentage: 0
    },
    {
      createdAt: '',
      statusText: 'Active',
      percentage: 0
    },
    {
      createdAt: '',
      statusText: 'On way',
      percentage: 0
    },
    {
      createdAt: '',
      statusText: 'Completed',
      percentage: 0
    }
  ]
  !isEmptyArray(arr) &&
    arr.forEach((item, i) => {
      perCentageArray.splice(i, 1, { ...item, percentage: 100 })
    })
  return perCentageArray
}

export const getPercentageArray = (status) => {
  switch (status) {
    case 'processing':
    case 'select_vendor':
    case 'get_truck_info':
      return [100, 0, 0, 0]
    case 'activated':
      return [100, 100, 0, 0]
    case 'goods_unloaded':
    case 'goods_loaded':
    case 'on_way':
      return [100, 100, 100, 0]
    case 'completed':
      return [100, 100, 100, 100]
    default:
      return [0, 0, 0, 0]
  }
}

export function getShipperStatusColor(stage) {
  switch (stage) {
    case 'requested':
      return {
        bg: '#FFB547',
        color: '#FFFFFF'
      }
    case 'cancelled':
      return {
        bg: '#F82012',
        color: '#FFFFFF'
      }
    case 'expired':
      return {
        bg: '#D50D11',
        color: '#FFFFFF'
      }
    case 'completed':
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }

    default:
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }
  }
}

export function getStageColor(stage) {
  switch (stage) {
    case 'look_at_bid':
      return {
        bgcolor: '#FFB547',
        color: '#FFFFFF'
      }
    case 'on_way':
    case 'activated':
    case 'processing':
      return {
        bgcolor: '#0071CD',
        color: '#FFFFFF'
      }
    case 'quote_shipper':
      return {
        bgcolor: '#ED6C02',
        color: '#FFFFFF'
      }
    case 'select_vendor':
      return {
        bgcolor: '#F82012',
        color: '#FFFFFF'
      }
    case 'get_truck_info':
      return {
        bgcolor: '#800800',
        color: '#FFFFFF'
      }
    case 'completed':
      return {
        bgcolor: '#4CAF50',
        color: '#FFFFFF'
      }
    case 'cancelled':
      return {
        bgcolor: '#F82012',
        color: '#FFFFFF'
      }
    case 'in_trip':
      return {
        bgcolor: '#F82012',
        color: '#FFFFFF'
      }
    case 'expired':
      return {
        bgcolor: '#D50D11',
        color: '#FFFFFF'
      }

    default:
      return {
        bgcolor: '#A569BD',
        color: '#FFFFFF'
      }
  }
}

// 'admin',
//   'gm',
//   'cluster_head',
//   'kam',

export const getRoles = (role) => {
  switch (role) {
    case 'admin':
      return [
        { title: 'GM', value: 'gm' },
        { title: 'Cluster Head', value: 'cluster_head' },
        { title: 'Key Account Manager', value: 'kam' }
      ]
    case 'gm':
      return [
        { title: 'Cluster Head', value: 'cluster_head' },
        { title: 'Key Account Manager', value: 'kam' }
      ]
    default:
      return []
  }
}

export const getAboveRoles = (role) => {
  switch (role) {
    case 'admin':
      return []
    case 'gm':
      return [{ title: 'Admin', value: 'admin' }]
    case 'cluster_head':
      return [{ title: 'GM', value: 'gm' }]
    case 'kam':
      return [{ title: 'Cluster Head', value: 'cluster_head' }]
    default:
      return []
  }
}

export const getPaymentGateways = () => {
  return [
    {
      value: 'bkash',
      title: 'Bkash'
    },
    {
      value: 'upay',
      title: 'Upay'
    },
    {
      value: 'nagad',
      title: 'Nagad'
    },
    {
      value: 'rocket',
      title: 'Rocket'
    }
  ]
}

export const getPropValue = (prop, obj) => {
  if (!obj) return 'N/A'
  if (obj[prop] === 0) return 0
  if (obj[prop]) return obj[prop]
  return 'N/A'
}

export const patterns = {
  link: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm,
  not_allow_white_space: /^[^\s]+/i,
  not_allow_special_char: /^[^\s]+[^~`!@#$%^&*)(}{:"/\\.,=+_><]+$/i,
  all_language_without_special_char: /^[^\s]+[^~`!@#$%^&*)(}{:"/\\.,=+_><]+$/gi,
  genera_text: /^[a-zA-Z0-9]*$/i,
  digit: /^\d+$/,
  allow_white_space: '',
  not_special_only_underscore: /^[^\s]+[^~`!@#$%^&*)(}{:"/\\., =+><]+$/i,
  valid_email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export function getFormattedDate2(
  date,
  prefomattedDate = false,
  hideYear = false,
  hideTime = false
) {
  if (!date) return 'N/A'
  const day = date.getDate()
  const month = MONTH_NAMES[date.getMonth()]
  const year = date.getFullYear()
  let hours = date.getHours()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  let minutes = date.getMinutes()

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes} ${ampm}`
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day} ${month} at ${hours}:${minutes} ${ampm}`
  }
  if (hideTime) {
    return `${day} ${month} ${year}`
  }

  // 10. January 2017. at 10:20

  return `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`
}

// --- Main function
export const timesAgo = (dateParam) => {
  if (!dateParam) {
    return 'N/A'
  }

  try {
    new Date(dateParam) instanceof Date
  } catch (ex) {
    return 'N/A'
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today - DAY_IN_MS)
  const seconds = Math.round((today - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 5) {
    return 'now'
  } else if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (seconds < 90) {
    return 'about a minute ago'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (isToday) {
    return getFormattedDate2(date, 'Today') // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate2(date, 'Yesterday') // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate2(date, false, true) // 10. January at 10:20
  }

  return getFormattedDate2(date) // 10. January 2017. at 10:20
}

// export enum TripStatus {
//   PROCESSING = 'processing',
//   ACTIVATED = 'activated',
//   GOOD_LOADED = 'goods_loaded',
//   GOOD_UNLOADED = 'goods_unloaded',
//   COMPLETED = 'completed',
//   ON_WAY = 'on_way',
//   CANCELLED = 'cancelled',
//   EXPIRED = 'expired',
// }

export const getTripStatusColor = (stage) => {
  switch (stage) {
    case 'processing':
    case 'available':
      return {
        bg: '#4CAF50',
        color: '#FFFFFF'
      }
    case 'select_vendor':
      return {
        bg: '#F82112',
        color: '#FFFFFF'
      }
    case 'get_truck_info':
      return {
        bg: '#800800',
        color: '#FFFFFF'
      }
    case 'activated':
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }
    case 'on_way':
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }
    case 'completed':
      return {
        bg: '#4CAF50',
        color: '#FFFFFF'
      }
    case 'in_trip':
    case 'cancelled':
      return {
        bg: '#F82012',
        color: '#FFFFFF'
      }
    case 'expired':
      return {
        bg: '#D50D11',
        color: '#FFFFFF'
      }

    default:
      return {
        bg: '#A569BD',
        color: '#FFFFFF'
      }
  }
}

export const processObject = (obj, keys, { isFormdata = false } = {}) => {
  if (isFormdata) {
    const formData = new FormData()
    for (const key of keys) {
      if (obj[key] || obj[key] == false || obj[key] == 0)
        formData.append(key, obj[key])
    }
    return formData
  }

  const result = {}
  for (const key of keys) {
    if (obj[key] || obj[key] == false || obj[key] == 0) result[key] = obj[key]
  }
  return result
}

export const isEmptyArray = (array) =>
  Boolean(!Array.isArray(array) || !array.length)

export const isEmptyObject = (obj) => {
  if (!obj) return true
  const isEmpty = Object.keys(obj).length === 0
  return isEmpty
}

export const genUniuqeKey = (pre) => {
  return `${pre ? pre : 'id'}_${new Date().getTime()}`
}

export function clean(obj) {
  if (!obj) return {}
  for (var propName in obj) {
    if (!obj[propName]) {
      delete obj[propName]
    }
  }
  return obj
}

export const toQueryString = (object) => {
  if (!object) return ''
  else {
    let result = clean(object)
    return (
      '?' +
      Object.keys(result)
        .map((key) => `${key}=${result[key].toString()}`)
        .join('&')
    )
  }
}

export const toQueryString2 = (object) => {
  if (!object) return ''
  else
    return (
      '?' +
      Object.keys(object)
        .map((key) => {
          if (object[key]) return `${key}=${object[key].toString()}`
        })
        .join('&')
    )
}

export const getUserRole = () => {
  let user = getLoggedInUser()
  if (
    user.role === 'kam' ||
    user.role === 'gm' ||
    user.role === 'cluster_head'
  ) {
    return 'kam'
  }
  return user.role
}

export const getRequestHeaders = (authorized) => {
  if (authorized) {
    return {
      Authorization: localStorage.getItem('loop-token'),
      'X-client-info': window.navigator.userAgent
    }
  } else
    return {
      'Content-Type': 'application/json',
      'X-client-info': window.navigator.userAgent
    }
}

export const preventMutiClick = (e) => {
  const btn = document.getElementById('multiClick')
  if (e.detail == 1) btn.disabled = true
  else e.preventDefault()
  setTimeout(() => {
    btn.disabled = false
  }, 1000)
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export function play() {
  var audio = new Audio('https://cdn.loopfreight.io/public/default_sound.mp3')
  audio.play()
}

export const getFeedName = (str) => {
  const string = str.split(/(?=[A-Z])/)
  const newString = string.filter((item) => {
    if (
      item.toLowerCase() == 'amount' ||
      item.toLowerCase() == 'total' ||
      item.toLowerCase() == 'count'
    ) {
      return false
    }
    return true
  })

  return newString.join(' ')
}

export const getFeedNumber = (obj, feedName) => {
  if (
    feedName === 'totalDedicatedFeedCount' ||
    feedName === 'marketPlaceFeedCount' ||
    feedName === 'manualFeedCount'
  ) {
    return obj[feedName]
  }
  return `৳${obj[feedName]}`
}

export const getFloatingNum = (num, toFixed = 2) => {
  if (!num) return 0
  return parseFloat(num).toFixed(toFixed)
}

export const hasChanged = (a, b) => {
  if (a !== b) return true
  return false
}

export const getGridWiseData = (a, b) => {
  let dopArray = []
  let meregdArray = []
  const newArrayA = [...a]
  const newArrayB = [...b]
  const aLen = newArrayA.length
  const bLen = newArrayB.length

  if (isEmptyArray(newArrayA) && isEmptyArray(newArrayB)) return []

  if (isEmptyArray(newArrayA)) {
    dopArray = new Array(bLen).fill().map(() => {
      return { address: '' }
    })
    meregdArray = [...dopArray, ...newArrayB]
  }

  if (isEmptyArray(newArrayB)) {
    dopArray = new Array(aLen).fill().map(() => {
      return { address: '' }
    })
    meregdArray = [...newArrayA, ...dopArray]
  }

  if (!isEmptyArray(newArrayA) && !isEmptyArray(newArrayB)) {
    if (aLen > bLen) {
      let diff = aLen - bLen
      while (diff > 0) {
        newArrayB.push({ address: '' })
        diff--
      }
    }

    if (bLen > aLen) {
      let diff = bLen - aLen
      while (diff > 0) {
        newArrayA.push({ address: '' })
        diff--
      }
    }

    meregdArray = [...newArrayA, ...newArrayB]
  }

  const newArray = meregdArray.map((item, i) => {
    if (i % 2 !== 0) {
      if (isEmptyArray(newArrayB)) {
        return dopArray.shift()
      } else return newArrayB.shift()
    } else {
      if (isEmptyArray(newArrayA)) {
        return dopArray.shift()
      } else return newArrayA.shift()
    }
  })

  const finalArray = newArray.map((item, i) => {
    if (i % 2 !== 0) {
      const obj = {
        ...item,
        hasChanged: hasChanged(item.address, newArray[i - 1].address)
      }
      return obj
    }
    return item
  })

  return finalArray
}

export const getFutureDate = (num) => {
  var targetDate = new Date()
  targetDate.setDate(targetDate.getDate() + num)
  return targetDate
}
