import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const MenuBox = styled(Box)(() => ({
  width: '100%',
  paddingBottom: 10,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 8,
  cursor: 'pointer'
}))

export const ShipperRowItem = styled(Box)(() => ({
  background: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: 16,
  borderRadius: 4,
  cursor: 'pointer'
}))
