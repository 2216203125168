import { Box, Pagination, Typography } from '@mui/material'
import React, { useState } from 'react'
import CompanyAutoComplete2 from '../../../components/CompanyAutoComplete2'
import Loader from '../../../components/Loader/Loader'
import useFetch from '../../../hooks/useFetch'
import DownloadListTable from './Table'

const BillingDownloadList = () => {
  const [page, setPage] = useState(1)
  const [company, setCompany] = useState('')

  let url = '/lf-booking/api/v1/admin/booking/summary-req'
  const [{ data, loading, pageCount }] = useFetch(
    url,
    true,
    {
      companyId: company?.id,
      page
    },
    [company?.id, page]
  )

  const handleChange = (event, value) => {
    setPage(value)
  }
  const onCompanyChange = (value) => {
    setCompany(value)
  }

  return (
    <Box p={2}>
      <Typography mb={2} variant="h6">
        Download List
      </Typography>
      <Box border="1px solid rgba(0, 0, 0, 0.12)" pt={1} borderRadius="4px">
        <Box mb={1} mx={2} maxWidth="400px">
          <CompanyAutoComplete2
            label="Search by Company"
            onCompanyChange={onCompanyChange}
          />
        </Box>
        {/* <TextField
          fullWidth
          sx={{ maxWidth: '400px', mx: 2, mb: 1 }}
          label="Search by Company"
          size="small"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          InputProps={{
            endAdornment: <Search />
          }}
        /> */}
        {loading ? <Loader /> : <DownloadListTable data={data} />}
      </Box>
      <Box display="flex" justifyContent="end" alignItems="end" py={2}>
        <Pagination
          count={pageCount}
          color="primary"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

export default BillingDownloadList
