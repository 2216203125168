import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const CustomTextField = (props) => {
  const { name, placeholder, register, criterions, errors, ...rest } = props

  return (
    <TextField
      placeholder={placeholder}
      error={name in errors}
      helperText={name in errors ? errors[name].message : ''}
      {...register(name, {
        ...criterions
      })}
      fullWidth
      {...rest}
    />
  )
}

CustomTextField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object,
  criterions: PropTypes.object
}

export default CustomTextField
