import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useGetHook } from '../../hooks/fetchHook'
import Typography from '@mui/material/Typography'
import AdditionalMetrics from './AdditionalMetrics'
import CircularProgress from '@mui/material/CircularProgress'
import './reporting.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { isEmptyArray, toQueryString } from '../../utils/functions'
import pronai from '../../assests/pronai.svg'
import CustomDatePicker2 from '../../components/CustomDatePicker2'

function getEarlierDate() {
  let date = new Date()
  date.setDate(date.getDate() - 14)
  return date
}

const ProReport = () => {
  const [loading, setLoading] = useState(false)
  const [metrics, setMetrics] = useState({
    actionCounts: ''
  })
  const [proList, setProList] = useState([])
  const [fromDate, setFromDate] = useState(getEarlierDate())
  const [toDate, setToDate] = useState(new Date())
  const [pro, setPro] = useState('')

  useEffect(() => {
    let prolistUrl = '/lf-user/api/v1/admin/user?role=pro'
    useGetHook(
      prolistUrl,
      (res) => {
        setProList(res.data.data.result)
      },
      () => {
        setLoading(false)
      },
      true
    )
  }, [])

  useEffect(() => {
    if (pro) {
      setLoading(true)
      let from = ''
      let to = ''

      if (fromDate.c) {
        let { year, month, day } = fromDate.c
        from = `${year}-${month}-${day}`
      } else {
        from = new Date(fromDate).toISOString().split('T')[0]
      }

      if (toDate.c) {
        let { year, month, day } = toDate.c
        to = `${year}-${month}-${day}`
      } else {
        to = new Date(toDate).toISOString().split('T')[0]
      }

      const queryObject = {
        fromDate: from,
        toDate: to,
        proUserId: pro
      }

      let url = `/lf-analytics/api/v1/pro-report${toQueryString(queryObject)}`

      useGetHook(
        url,
        (res) => {
          setMetrics(res.data.data)
          setLoading(false)
        },
        () => {
          setLoading(false)
        },
        true
      )
    }
  }, [fromDate, toDate, pro])

  const handleFromDateChange = (newValue) => setFromDate(newValue)
  const handleToDateChange = (newValue) => setToDate(newValue)

  return (
    <Box px={2} py={4}>
      <Box
        display="flex"
        mb={2}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexGrow={1}>
          <Typography variant="h4">Pro Report</Typography>
        </Box>
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel id="demo-simple-select-label26">Select Pro</InputLabel>
          <Select
            labelId="demo-simple-select-label26"
            id="demo-simple-select26"
            value={pro}
            label="Select Pro"
            onChange={(e) => {
              setPro(e.target.value)
            }}
          >
            {!isEmptyArray(proList) &&
              proList.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.userId}>
                    {item.fullName}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
        <Box>
          <CustomDatePicker2
            label="From Date*"
            onChange={handleFromDateChange}
            disableFuture
          />
        </Box>
        <Box>
          <CustomDatePicker2
            label="To Date*"
            onChange={handleToDateChange}
            disableFuture
          />
        </Box>
      </Box>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '90vh' }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {!loading && (
        <>
          <Typography mb={2} textAlign="left" variant="h5" color="primary">
            {pro.fullName}
          </Typography>
          {pro ? (
            <>
              <AdditionalMetrics metrics={metrics} />
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              m="auto"
              alignItems="center"
              flexDirection="column"
            >
              <img className="fluid" src={pronai} />
              <Typography
                textAlign="center"
                variant="subtitle1"
                color="primary"
              >
                Please select a pro to view Analytics.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default ProReport
