import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './index.css'
import { getFloatingNum } from '../../../utils/functions'
function MetricCard({ value, text }) {
  return (
    <Box className="metricCard">
      <Typography color="primary" variant="h5">
        {value}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        {text}
      </Typography>
    </Box>
  )
}

MetricCard.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string
}

export default function Metrics({ data }) {
  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      <MetricCard
        value={data.tripsCreatedToday}
        text={'Requested Trips (Month)'}
      />
      <MetricCard
        value={`${getFloatingNum(data.acceptanceRate)}%`}
        text={'Acceptance Rate'}
      />
      <MetricCard
        value={`${getFloatingNum(data.avgSuccessRate)}%`}
        text={'Success Rate'}
      />
      {/* <MetricCard value="120" text={'Total Due Amount'} /> */}
      <MetricCard
        value={`${getFloatingNum(data.avgBiddingRate)}%`}
        text={'AVG Organic Bidding Rate'}
      />
    </Box>
  )
}

Metrics.propTypes = {
  data: PropTypes.object
}
