import React from 'react'
import { getPropValue } from '../../utils/functions'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { ShipperRowItem } from '../../components/HomeContainer/StyledComponents/StyledComponents'
import { Chip, Grid } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import useToggle from '../../hooks/useToggle'
import { useEffect } from 'react'

const Vendor = (props) => {
  const { data } = props
  const NOW_IN_MS = new Date().getTime()
  const FutureTime_IN_MS = new Date(data.otpValidTill).getTime()
  const { open, toggle } = useToggle()

  useEffect(() => {
    const timer = () =>
      setTimeout(() => {
        toggle()
      }, 10000)
    open && timer()
  }, [open])

  const getStatus = () => {
    if (data.otpVerified)
      return (
        <Chip
          className="truck-item-label"
          mt={1}
          label="Verified"
          size="small"
          color="success"
        />
      )
    if (NOW_IN_MS > FutureTime_IN_MS)
      return (
        <Chip
          className="truck-item-label"
          mt={1}
          label="Expired"
          size="small"
          color="error"
        />
      )
    return (
      <Chip
        className="truck-item-label"
        mt={1}
        label="Pending"
        size="small"
        sx={{
          bgcolor: '#FFB547',
          color: '#fff'
        }}
      />
    )
  }

  return (
    <Box pt={2}>
      <ShipperRowItem
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Avatar
              alt={data.userFullName}
              src={data.userProfilePicture ? data.userProfilePicture : 'as'}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography className="col-head" variant="body2">
              Name
            </Typography>
            <Typography className="col-value" variant="body">
              {data.userFullName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="col-head" variant="body2">
              Mobile Number
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('userPhone', data)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              OTP
            </Typography>

            <Box display="flex" gap={1} alignItems="center">
              <Typography
                color={open ? 'primary' : 'rgba(0, 0, 0, 0.38)'}
                className="col-value"
                variant="body"
              >
                {open ? getPropValue('otpNumber', data) : '******'}
              </Typography>
              <VisibilityIcon
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={toggle}
                color={open ? 'primary' : 'disabled'}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              OTP Status
            </Typography>

            {getStatus()}
          </Grid>
        </Grid>
      </ShipperRowItem>
    </Box>
  )
}

Vendor.propTypes = {
  data: PropTypes.object
}

export default Vendor
