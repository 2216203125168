import { Box, Grid, Popover, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import PayableBatchRequestListItem from './PayableBatchRequestListItem'
import { getFormattedDate2, timesAgo } from '../../../utils/functions'
import { More } from '@mui/icons-material'
import CustomPopover from '../../../components/CustomPopover'

const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return '#4CAF50'
    case 'pending':
      return '#ED6C02'
    default:
      return '#D50D11'
  }
}

const PayableBatchRequestListItemsCard = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box
      sx={{
        boxShadow: '0px 4px 8px rgba(148, 163, 184, 0.24)',
        borderRadius: '4px',
        position: 'relative',
        mb: 3,
        height: '90px',
        overflow: 'hidden'
      }}
    >
      <Grid container>
        <Grid item xs={2.5}>
          <PayableBatchRequestListItem
            textColor="#0071cd"
            bgcolor="#fff"
            color="#1E293B"
            title={data.supplierCompanyName}
            count={data.supplierName}
          />
        </Grid>
        <Grid item xs={2}>
          <PayableBatchRequestListItem
            bgcolor="#fafafa"
            color="#1E293B"
            title="Posting Date"
            count={getFormattedDate2(
              new Date(data.postingDate),
              false,
              false,
              true
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <PayableBatchRequestListItem
            bgcolor="#fff"
            color="#4CAF50"
            title="Amount"
            count={`৳${Math.round(data.amount)}`}
          />
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: 1,
              flexGrow: 1,
              bgcolor: '#fafafa',
              p: 3
            }}
          >
            <Typography color="#9E9E9E" fontWeight={500} variant="body2">
              Description
            </Typography>
            <Typography
              textTransform="capitalize"
              fontWeight={500}
              variant="body2"
            >
              {data.description ? (
                data.description.length > 20 ? (
                  <span>
                    {data.description.substring(0, 20)}
                    <CustomPopover
                      icon={<More fontSize="small" color="info" />}
                    >
                      <Box p={2}>
                        <Typography
                          fontWeight={500}
                          variant="body2"
                          gutterBottom
                        >
                          Description
                        </Typography>
                        <Typography
                          sx={{
                            wordBreak: 'break-all',
                            maxWidth: 360
                          }}
                          variant="body2"
                        >
                          {data.description}
                        </Typography>
                      </Box>
                    </CustomPopover>
                  </span>
                ) : (
                  data.description
                )
              ) : (
                'N/A'
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            onMouseEnter={handleClick}
            onMouseLeave={handleClose}
            height={'100%'}
          >
            <PayableBatchRequestListItem
              color={getStatusColor(data.requestStatus)}
              title="Status"
              count={data.requestStatus}
            />
            {data.requestStatus === 'failed' && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                disableRestoreFocus
                sx={{
                  pointerEvents: 'none'
                }}
              >
                <Box p={2}>
                  <Typography
                    fontWeight={500}
                    color="error"
                    variant="body2"
                    gutterBottom
                  >
                    Failure Reason
                  </Typography>
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                      maxWidth: 360
                    }}
                    variant="body2"
                  >
                    {data.failureReason}
                  </Typography>
                </Box>
              </Popover>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <PayableBatchRequestListItem
            bgcolor="#fafafa"
            color="#1E293B"
            title="Uploaded Date"
            count={timesAgo(data.createdAt)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

PayableBatchRequestListItemsCard.propTypes = {
  data: PropTypes.object
}

export default PayableBatchRequestListItemsCard

/*
amount
: 
"10000.000000"
createdAt
: 
"2023-03-21T09:45:31.311Z"
description
: 
"Final Test 01"
failureReason
: 
"Supplier name does not match"
id
: 
73
postingDate
: 
"2022-01-10T18:00:00.000Z"
requestId
: 
15
requestStatus
: 
"failed"
supplierCompanyName
: 
"Md. Jewel Rana (GZP)"
supplierId
: 
20
supplierName
: 
"tete"
*/
