import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'

const PassWordInput = (props) => {
  const { onChange, label, ...rest } = props
  const [showPassword, setShowPassWord] = useState(false)
  const [password, setPassword] = useState('')

  const handleChange = (e) => {
    const { value } = e.target
    setPassword(value)
    onChange && onChange(value)
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password1">
        {label || 'Password'}
      </InputLabel>
      <OutlinedInput
        id={`outlined-adornment-password${new Date().getMilliseconds()}`}
        error={password.length > 20 ? true : false}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handleChange}
        {...rest}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassWord(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label={label || 'Password'}
      />
    </FormControl>
  )
}

PassWordInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string
}

export default PassWordInput
