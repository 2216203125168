import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CustomTabPanel from '../../components/CustomTabPanel'
import Snackbars from '../../components/Snackbar/Snackbar'
import useToast from '../../hooks/useToast'
import { a11yProps } from '../../utils/functions'
import PayableBatchRequestList from './PayableBatchRequestList'
import VendorPayable from './VendorPayable'
import VendorTaxFormula from './VendorTaxFormula'

const Finance = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const [value, setValue] = useState(parseInt(tab) ? parseInt(tab) : 0)
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setSearchParams({ tab: newValue })
  }

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Vendor Payable" {...a11yProps(0)} />
          <Tab label="Payable Batch Request" {...a11yProps(1)} />
          <Tab label="VAT TAX Fourmula" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <VendorPayable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PayableBatchRequestList setToast={setToast} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <VendorTaxFormula />
      </CustomTabPanel>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  )
}

export default Finance
