/* eslint-disable indent */
import React from 'react'
import Box from '@mui/material/Box'
import LoopIcon from '../../../assests/loop-icon-big.svg'
import TruckShapeIcon from '../../../assests/truck-shape.svg'
import ShipperIcon from '../../../assests/ShipperBg.svg'
import VendorIcon from '../../../assests/VendorBg.svg'

import Typography from '@mui/material/Typography'
import { FlexContainerBox } from '../StyledComponents/StyledComponents'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import GPlayStoreBtn from '../../../homepage/GPlayStoreBtn'

const PosterColumn = () => {
  const location = useLocation()
  const [bg, setBg] = useState(TruckShapeIcon)

  useEffect(() => {
    setBg(
      location.pathname == '/signup/shipper'
        ? ShipperIcon
        : location.pathname == '/signup/vendor'
        ? VendorIcon
        : TruckShapeIcon
    )
  }, [location.pathname])

  return (
    <>
      <FlexContainerBox
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        p={2}
        gap={4}
      >
        <Box alignSelf="center">
          <img src={LoopIcon} style={{ width: '215px' }} />
        </Box>
        <Box alignSelf="center">
          <img src={bg} style={{ width: '400px' }} />
        </Box>
        {location.pathname !== '/' && (
          <Box display="flex" alignSelf="center" justifyContent="center" mb={2}>
            <a
              href={`${
                location.pathname == '/signup/shipper'
                  ? 'https://play.google.com/store/apps/details?id=io.loopfreight.mobile.shipper'
                  : 'https://play.google.com/store/apps/details?id=io.loopfreight.mobile.vendor'
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GPlayStoreBtn />
            </a>
          </Box>
        )}

        <Box justifySelf="left">
          <Typography
            variant="body2"
            style={{ color: '#FFFFFF', fontWeight: 800, textAlign: 'left' }}
          >
            Copyright © {new Date().getFullYear()} Loop Technologies Pte. Ltd.
          </Typography>
        </Box>
      </FlexContainerBox>
    </>
  )
}

export default PosterColumn
