import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import './index.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PropTypes from 'prop-types'
import useFocus from '../../../hooks/useFocus'
import { getAccessLevel } from '../../../accesscontrol'
import { usePutHook } from '../../../hooks/fetchHook'

const AddressCard = ({ data, setToast }) => {
  const [editMode, setEditMode] = useState({
    shortFormEn: false,
    textEn: false,
    latLong: false
  })
  const [shortFormEn, setShortFormEn] = useState(data.shortFormEn)
  const [textEn, setTextEn] = useState(data.textEn)
  const [latLong, setLongLat] = useState(`${data.latitude},${data.longitude}`)
  const [inputRef1, setInputFocus1] = useFocus()
  const [inputRef2, setInputFocus2] = useFocus()
  const [inputRef3, setInputFocus3] = useFocus()

  const handleDoubleClick = (e, type) => {
    let canWrite = getAccessLevel('updateAddress')
    if (!canWrite) return
    if (e.detail == 2) {
      switch (type) {
        case 'shortFormEn':
          {
            setEditMode({
              ...editMode,
              shortFormEn: true
            })
            setTimeout(() => {
              setInputFocus1()
            }, 500)
          }
          break

        case 'textEn':
          {
            setEditMode({
              ...editMode,
              textEn: true
            })
            setTimeout(() => {
              setInputFocus2()
            }, 500)
          }
          break

        case 'latLong':
          {
            setEditMode({
              ...editMode,
              latLong: true
            })
            setTimeout(() => {
              setInputFocus3()
            }, 500)
          }
          break

        default:
          setEditMode({
            ...editMode
          })
      }
    }
  }

  const handleSubmit = (e, name) => {
    e.preventDefault()
    const value = e.target[name].value
    handleOnBlur(name, value)
  }

  const handleOnBlur = (name, value) => {
    let url = `/lf-geolocation/api/v1/admin/address/${data.id}`

    let payload = {
      [name]: value
    }

    value &&
      data[name] !== value &&
      usePutHook(
        url,
        payload,
        (res) => {
          if (name == 'shortFormEn') setShortFormEn(res.data.data.shortFormEn)
          if (name == 'textEn') setTextEn(res.data.data.textEn)
          if (name == 'latLong')
            setLongLat(`${res.data.data.latitude},${res.data.data.longitude}`)
          setToast('Successfully updated address', 'success')
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    setEditMode({ ...editMode, [name]: false })
  }

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setToast('Copied!', 'success')
    } catch (err) {
      setToast('Failed to copy!', 'error')
    }
  }

  return (
    <>
      <Box p={1} mb={2} className="addressCard">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box display="flex" mb={1} gap={1} alignItems="center">
              <Typography variant="caption">Short form</Typography>
              <ContentCopyIcon
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => copyToClipBoard(shortFormEn)}
                color="action"
                fontSize="small"
              />
            </Box>
            {editMode.shortFormEn ? (
              <form onSubmit={(e) => handleSubmit(e, 'shortFormEn')}>
                <TextField
                  size="small"
                  label="Short Form"
                  fullWidth
                  variant="outlined"
                  value={shortFormEn}
                  name="shortFormEn"
                  onBlur={(e) => {
                    const { name, value } = e.target
                    handleOnBlur(name, value)
                  }}
                  onChange={(e) => setShortFormEn(e.target.value)}
                  inputRef={inputRef1}
                />
                <button style={{ display: 'none' }} type="submit" />
              </form>
            ) : (
              <Typography
                onClick={(e) => handleDoubleClick(e, 'shortFormEn')}
                variant="body2"
              >
                {shortFormEn || 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" mb={1} gap={1} alignItems="center">
              <Typography variant="caption">Long form</Typography>
              <ContentCopyIcon
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => copyToClipBoard(textEn)}
                color="action"
                fontSize="small"
              />
            </Box>
            {editMode.textEn ? (
              <form onSubmit={(e) => handleSubmit(e, 'textEn')}>
                <TextField
                  size="small"
                  label="Long Form"
                  fullWidth
                  variant="outlined"
                  name="textEn"
                  onBlur={(e) => {
                    const { name, value } = e.target
                    handleOnBlur(name, value)
                  }}
                  value={textEn}
                  onChange={(e) => setTextEn(e.target.value)}
                  inputRef={inputRef2}
                />
                <button style={{ display: 'none' }} type="submit" />
              </form>
            ) : (
              <Typography
                onClick={(e) => handleDoubleClick(e, 'textEn')}
                variant="body2"
              >
                {textEn || 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" mb={1} gap={1} alignItems="center">
              <Typography variant="caption">Lat,Long</Typography>
              <ContentCopyIcon
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => copyToClipBoard(latLong)}
                color="action"
                fontSize="small"
              />
            </Box>
            {editMode.latLong ? (
              <form onSubmit={(e) => handleSubmit(e, 'latLong')}>
                <TextField
                  size="small"
                  label="Lat Long"
                  fullWidth
                  variant="outlined"
                  name="latLong"
                  onBlur={(e) => {
                    const { name, value } = e.target
                    handleOnBlur(name, value)
                  }}
                  value={latLong}
                  onChange={(e) => setLongLat(e.target.value)}
                  inputRef={inputRef3}
                />
                <button style={{ display: 'none' }} type="submit" />
              </form>
            ) : (
              <Typography
                onClick={(e) => handleDoubleClick(e, 'latLong')}
                variant="body2"
              >
                {latLong || 'N/A'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AddressCard
AddressCard.propTypes = {
  data: PropTypes.object,
  setToast: PropTypes.func
}
