import { Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'

const UserListTitle = ({ name, count }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography textTransform={'capitalize'} variant="h6">
        {name}
      </Typography>
      <Chip
        sx={{ width: 'fit-content', bgcolor: '#707070', px: 0.5 }}
        label={count || 0}
        size="small"
        color="primary"
      />
    </Box>
  )
}

export default UserListTitle

UserListTitle.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number
}
