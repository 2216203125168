import { ArrowBack, More } from '@mui/icons-material'
import { Box, IconButton, TablePagination, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetHook } from '../../../hooks/fetchHook'
import { isEmptyArray, toQueryString } from '../../../utils/functions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import styled from '@emotion/styled'
import useToast from '../../../hooks/useToast'
import Snackbars from '../../../components/Snackbar/Snackbar'
import humanize from 'humanize-number'
import { ledgerEntryType } from '../../../constants'
import { useRef } from 'react'
import CustomPopover from '../../../components/CustomPopover'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CustomDatePicker2 from '../../../components/CustomDatePicker2'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#0071cd',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const VendorDCTable = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()
  const [loading, setLoading] = useState(false)
  const [dcList, setDcList] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(25)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const tableHeadRef = useRef(false)
  const [stickyHeader, setStickyHeader] = useState(false)
  const [sort, setSort] = useState(null)

  useEffect(() => {
    const onScroll = () => {
      if (tableHeadRef.current.offsetTop) {
        if (tableHeadRef.current.offsetTop < window.scrollY)
          setStickyHeader(true)
        else setStickyHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    setLoading(true)
    const query = {
      page,
      limit: rowsPerPage
    }

    if (sort !== null) {
      query['sort'] = sort
    }

    if (startDate && endDate) {
      const startDateInstance = new Date(startDate)
      const endDateInstance = new Date(endDate)

      query['fromDate'] = `${startDateInstance.getFullYear()}-${
        startDateInstance.getMonth() + 1
      }-${startDateInstance.getDate()}`

      query['toDate'] = `${endDateInstance.getFullYear()}-${
        endDateInstance.getMonth() + 1
      }-${endDateInstance.getDate()}`
    }

    let url = `/lf-acc/api/v1/management/suppliers/${
      params.vendorId
    }/transactions${toQueryString(query)}`

    useGetHook(
      url,
      (res) => {
        setCount(res.data.count)
        setDcList(res.data.data)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setToast(error.response.data.message, 'error')
      },
      true
    )
  }, [page, startDate, endDate, rowsPerPage, sort])

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <Box p={2}>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap={1} alignItems="center">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </IconButton>
          <Box flexGrow={1}>
            <Typography variant="h5">
              {sessionStorage.getItem('companyName') == 'null'
                ? 'N/A'
                : sessionStorage.getItem('companyName')}
            </Typography>
            <Typography variant="body1">Trips Payable</Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          <CustomDatePicker2
            disableFuture
            size="small"
            defaultDate={startDate ? startDate : null}
            label="From Date"
            onChange={(val) => setStartDate(val)}
          />
          <CustomDatePicker2
            disableFuture
            size="small"
            defaultDate={endDate ? endDate : null}
            label="To Date"
            onChange={(val) => setEndDate(val)}
          />
        </Box>
      </Box>
      <TablePagination
        component="div"
        count={count}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <TableContainer component={Paper}>
        {!loading && isEmptyArray(dcList) && (
          <Typography color="error">No Data Found!</Typography>
        )}
        {!loading && !isEmptyArray(dcList) && (
          <Table size="small" padding="normal" aria-label="simple table">
            <TableHead
              ref={tableHeadRef}
              style={{
                position: stickyHeader ? 'fixed' : 'static',
                width: '100%',
                top: 60
              }}
            >
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start'
                  }}
                >
                  Posting Date{' '}
                  {sort === null && (
                    <UnfoldMoreIcon
                      sx={{ width: '17px' }}
                      onClick={() => setSort('asc')}
                    />
                  )}
                  {sort === 'asc' && (
                    <ArrowUpwardIcon
                      sx={{ width: '17px' }}
                      onClick={() => setSort('desc')}
                    />
                  )}
                  {sort === 'desc' && (
                    <ArrowDownwardIcon
                      sx={{ width: '17px' }}
                      onClick={() => setSort(null)}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">Entry Type</StyledTableCell>
                <StyledTableCell align="left">Description</StyledTableCell>
                <StyledTableCell align="right">Debit</StyledTableCell>
                <StyledTableCell align="right">Credit</StyledTableCell>
                <StyledTableCell align="right">Total Earnings</StyledTableCell>
                <StyledTableCell align="right">Total Paid</StyledTableCell>
                <StyledTableCell align="right">Balance</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {dcList.map((row) => (
                <StyledTableRow
                  key={row.journalId}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                      {row.enterDate}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                      {row.postingDate}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="body2">
                      {ledgerEntryType[row.entryType]}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      sx={{ wordBreak: 'break-all', maxWidth: 180 }}
                      variant="body2"
                    >
                      {row.description ? (
                        row.description.length > 77 ? (
                          <span>
                            {row.description.substring(0, 77)}
                            <CustomPopover
                              icon={<More fontSize="small" color="info" />}
                            >
                              <Typography
                                sx={{
                                  wordBreak: 'break-all',
                                  maxWidth: 360,
                                  p: 2
                                }}
                                variant="body2"
                              >
                                {row.description}
                              </Typography>
                            </CustomPopover>
                          </span>
                        ) : (
                          row.description
                        )
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="body2" color="primary">
                      {row.amountType === 'D' &&
                        `${humanize(row.amount, {
                          delimiter: ',',
                          separator: '.'
                        })}`}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="body2" color="error">
                      {row.amountType === 'C' &&
                        `${humanize(row.amount, {
                          delimiter: ',',
                          separator: '.'
                        })}`}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="body2">
                      {humanize(row.totalEarningsAmount, {
                        delimiter: ',',
                        separator: '.'
                      })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="body2">
                      {humanize(row.totalReceivedAmount, {
                        delimiter: ',',
                        separator: '.'
                      })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="body2" color="#4CAF50">
                      {humanize(row.balanceAmount, {
                        delimiter: ',',
                        separator: '.'
                      })}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  )
}

export default VendorDCTable
