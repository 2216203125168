import { TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePutHook } from '../../hooks/fetchHook'
import GlobalButton from '../GlobalButton'

const AdditionalPaymentRemarks = ({ tripId, setToast }) => {
  const [paymentRemark, setPaymentRemark] = useState('')

  const onRemarkAdd = () => {
    let url = '/lf-booking/api/v1/admin/booking/trip-remark'
    const query = {
      tripId,
      paymentRemark
    }

    usePutHook(
      url,
      query,
      (res) => {
        setPaymentRemark('')
        setToast(res.data.message, 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box sx={{ bgcolor: '#0071CD', py: 1, px: 2 }}>
        <Typography variant="subtitle1" color="#fff">
          Additional Payment Remarks
        </Typography>
      </Box>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        gap={2}
      >
        <Typography color="textSecondary" variant="body2">
          Trip ID: {tripId}
        </Typography>
        <TextField
          label="Add Remarks"
          value={paymentRemark}
          onChange={(e) => setPaymentRemark(e.target.value)}
          fullWidth
        />
        <GlobalButton
          size="large"
          onClick={onRemarkAdd}
          variant="contained"
          name="Submit Now"
          disabled={!paymentRemark}
        />
      </Box>
    </>
  )
}

AdditionalPaymentRemarks.propTypes = {
  tripId: PropTypes.object,
  setToast: PropTypes.func
}

export default AdditionalPaymentRemarks
