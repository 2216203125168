/* eslint-disable indent */
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import VendorList from './VendorList'
import { usePostHook, useGetHook, usePutHook } from '../../../hooks/fetchHook'
import { formatStage, getLoggedInUser } from '../../../utils/functions'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import { useParams, useSearchParams } from 'react-router-dom'
import useToggle from '../../../hooks/useToggle'
import usePreventMultiClick from '../../../hooks/usePreventMultiClick'
import { getAccessLevel } from '../../../accesscontrol'
import VendorResponse from './VendorResponse'

function getInitialValue(obj, key) {
  let keys = Object.keys(obj)
  if (keys.length === 0) return ''
  return obj[key]
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const FareDetails = (props) => {
  const { booking, setToast, updateBooking, updateBookingStage } = props
  const params = useParams()
  const { preventMultiClick, clicked } = usePreventMultiClick()
  let user = getLoggedInUser()
  const [currentQueryParameters] = useSearchParams()
  const { open, handleClose, handleOpen } = useToggle()
  const [value, setValue] = useState(0)
  const [fareHistoryStatus, setFareHistoryStatus] = useState(
    booking.fareHistory.fareHistoryStatus
      ? booking.fareHistory.fareHistoryStatus
      : ''
  )
  const [netMarginAmount, setNetMarginAmount] = useState(
    getInitialValue(booking.fareHistory, 'netMarginAmount')
  )
  const [fareHistory, setFareHistory] = useState(booking.fareHistory)

  const [baseAmount, setBaseAmount] = useState(
    getInitialValue(booking.fareHistory, 'baseAmount')
  )

  const [margin, setMargin] = useState(
    getInitialValue(booking.fareHistory, 'marginAmount')
  )

  const [marginAmount, setMarginAmount] = useState(
    getInitialValue(booking.fareHistory, 'marginAmount')
  )

  const [vat, setVat] = useState(0)
  const [ait, setAit] = useState(0)

  const [vatAmount, setVatAmount] = useState(
    getInitialValue(booking.fareHistory, 'vatAmount')
  )

  const [aitAmount, setAitAmount] = useState(
    getInitialValue(booking.fareHistory, 'aitAmount')
  )

  const [total, setTotal] = useState(
    getInitialValue(booking.fareHistory, 'fareAmount')
  )

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/${booking.id}/biddings`

    useGetHook(
      url,
      (res) => {
        res.data.data.bookingStatus === 'shipper_approved' &&
          setFareHistoryStatus('shipper_approved')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  useEffect(() => {
    //for ait and vat read only
    useGetHook(
      `/lf-company/api/v1/admin/company/${
        params.companyId || currentQueryParameters.get('companyId')
      }/fare-amount/info/`,
      (res) => {
        setAit(res.data.data.ait ? res.data.data.ait : 0)
        setVat(res.data.data.vat ? res.data.data.vat : 0)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  const getFare = (e) => {
    preventMultiClick(e)
    let url = '/lf-booking/api/v1/booking/fare-history'
    let data = {
      baseAmount: baseAmount,
      marginAmount: margin,
      vatPercent: vat,
      aitPercent: ait,
      bookingId: booking.id
    }
    usePostHook(
      url,
      data,
      (res) => {
        let result = res.data.data
        setNetMarginAmount(result.netMarginAmount)
        setMarginAmount(result.marginAmount)
        setVatAmount(result.vatAmount)
        setAitAmount(result.aitAmount)
        setTotal(result.fareAmount)
        setToast(res.data.message, 'success')
        setFareHistory(result)
        setFareHistoryStatus(result.fareHistoryStatus)
        updateBooking(result)
        updateBookingStage(result.booking.stage)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const handleTabChange = (event, newValue) => setValue(newValue)

  const approve = () => {
    let url = `/lf-booking/api/v1/booking/fare-history/${fareHistory.id}`
    let data = {
      fareHistoryStatus: 'shipper_approved'
    }
    usePutHook(
      url,
      data,
      (res) => {
        setToast(res.data.message, 'success')
        setFareHistoryStatus(res.data.data.fareHistoryStatus)
        updateBookingStage(res.data.data.booking.stage)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }
  // const vendorCluster =
  //   user.role === 'cluster_head' && user.team === 'vendor_team'

  const vendorCluster =
    user.role === 'admin' ||
    user.role === 'gm' ||
    user.role === 'cluster_head' ||
    (user.role === 'kam' && user.team === 'business_team')

  return (
    <Box p={2}>
      {vendorCluster && (
        <Box className="booking-metadata-box">
          <Box display="flex" p={1}>
            <Box>
              <Typography variant="body1">Fare asd Amount Per Truck</Typography>
            </Box>
          </Box>
          <Divider />
          <Box p={2}>
            <Box display="flex" gap={2}>
              <Box flex={1}>
                <TextField
                  size="small"
                  label="Truck Fare Amount"
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  value={baseAmount}
                  onChange={(e) => setBaseAmount(e.target.value)}
                  fullWidth
                  disabled={user.team === 'fulfillment_team'}
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-', '.'].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Box>
              <Box flex={1}>
                <TextField
                  size="small"
                  label="Margin BDT"
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  value={margin}
                  disabled={user.team === 'fulfillment_team'}
                  onChange={(e) => setMargin(e.target.value)}
                  fullWidth
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '.'].includes(e.key) && e.preventDefault()
                  }
                />
              </Box>
            </Box>
            <Box display="flex" gap={2} pt={2}>
              <Box flex={1}>
                <TextField
                  size="small"
                  label="VAT"
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  value={vat}
                  fullWidth
                  disabled
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-', '.'].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Box>
              <Box flex={1}>
                <TextField
                  size="small"
                  label="AIT"
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  value={ait}
                  fullWidth
                  disabled
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-', '.'].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Box>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={800}>
                  Truck Fare Amount
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {baseAmount ? `BDT ${baseAmount}` : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={800}>
                  VAT
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {vatAmount || vatAmount === 0 ? `BDT ${vatAmount}` : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={800}>
                  AIT
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {aitAmount || aitAmount === 0 ? `BDT ${aitAmount}` : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={800}>
                  Margin
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {marginAmount || marginAmount === 0
                    ? `BDT ${marginAmount}`
                    : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={800}>
                  Net Margin
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {netMarginAmount || netMarginAmount === 0
                    ? `BDT ${netMarginAmount}`
                    : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box className="total-amount-div">
                <Typography className="total-amount-text">
                  Total Amount
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mt={2}
              gap={1}
              flexDirection={fareHistoryStatus ? 'row' : 'row-reverse'}
            >
              {fareHistoryStatus && (
                <>
                  <Box>
                    <Chip
                      label={
                        fareHistoryStatus
                          ? formatStage(fareHistoryStatus)
                          : 'Pending'
                      }
                      sx={{
                        background:
                          fareHistoryStatus == 'shipper_approved'
                            ? '#388e3c'
                            : '#FFB547',
                        color: '#fff'
                      }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    {open && fareHistoryStatus !== 'shipper_approved' ? (
                      <Box display="flex" gap={1} alignItems="center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={approve}
                          size="small"
                          sx={{ borderRadius: '16px' }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                          size="small"
                          sx={{ borderRadius: '16px' }}
                        >
                          No
                        </Button>
                      </Box>
                    ) : (
                      user.team !== 'fulfillment_team' &&
                      fareHistoryStatus !== 'shipper_approved' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                          size="small"
                        >
                          Approve
                        </Button>
                      )
                    )}
                  </Box>
                </>
              )}
              {fareHistoryStatus ? (
                <>
                  <Box>
                    <InfoIcon />
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      Fare History&nbsp;(Per Truck)
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary" variant="body1">
                      {total ? total : 'N/A'}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Typography color="primary" variant="body1">
                      {total ? total : 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      Fare History BDT&nbsp;(Per Truck)
                    </Typography>
                  </Box>
                  <Box>
                    <InfoIcon />
                  </Box>
                </>
              )}
            </Box>
            <Box mt={1}>
              {getAccessLevel('quoteShipper') && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={getFare}
                  disabled={
                    clicked ||
                    fareHistoryStatus == 'shipper_approved' ||
                    !baseAmount ||
                    !margin
                  }
                  fullWidth
                >
                  SUBMIT NOW
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box className="booking-metadata-box" mt={3}>
        <Box>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Vendor Responses" {...a11yProps(0)} />
            <Tab label="Vendor List" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            <VendorResponse
              setToast={setToast}
              booking={booking}
              setFareHistoryStatus={setFareHistoryStatus}
              updateBookingStage={updateBookingStage}
              setBaseAmount={setBaseAmount}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VendorList booking={booking} setToast={setToast} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  )
}

FareDetails.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func,
  updateBooking: PropTypes.func,
  updateBookingStage: PropTypes.func
}

export default FareDetails
