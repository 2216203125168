import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import '../booking-detail.css'

const DocumentItem = (props) => {
  const { data } = props

  const download = () => {
    fetch(data.docUrl)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // the filename you want
        a.download = data.docUrl.split('/').pop()
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => alert('Something went wrong'))
  }

  return (
    <Box p={1}>
      <Box
        display="flex"
        alignItems="center"
        className="doc-item-container"
        p={1}
        gap={1}
      >
        <Avatar alt="D" src={data.docUrl ? data.docUrl : 'ass'} />
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Box>
              <Button variant="contained" size="small" disableElevation>
                {data.title}
              </Button>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                component="div"
                className="file-name-text"
              >
                Trip ID#{data.trip.id}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              component="div"
              className="file-name-text"
            >
              {data.docUrl.split('/').pop()}
            </Typography>
          </Box>
        </Box>
        <Box>
          <IconButton onClick={download}>
            <DownloadIcon sx={{ fill: 'rgba(0, 0, 0, 0.23)' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

DocumentItem.propTypes = {
  data: PropTypes.object
}

export default DocumentItem
