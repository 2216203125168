/* eslint-disable indent */
/* eslint-disable no-duplicate-case */
import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import ClockIcon from '../../../components/SvgIcons/ClockIcon'
import {
  formatStage,
  getLoggedInUser,
  timesAgo
} from '../../../utils/functions'
import './booking-detail.css'

function getColor(stage) {
  switch (stage) {
    case 'select_vendor':
      return {
        bg: '#F82012',
        color: '#FFFFFF'
      }
    case 'get_truck_info':
      return {
        bg: '#4CAF50',
        color: '#FFFFFF'
      }
    case 'cancelled':
      return {
        bg: '#F82112',
        color: '#FFFFFF'
      }
    case 'completed':
    case 'shipper_approved':
    case 'look_at_bid':
    case 'quote_shipper':
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }

    default:
      return {
        bg: '#A569BD',
        color: '#FFFFFF'
      }
  }
}

const BookingDetailHeader = (props) => {
  const { booking } = props
  const role = getLoggedInUser()?.role

  return (
    <Box
      display="flex"
      p={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography color="primary" variant="subtitle2" fontWeight={'bold'}>
          Booking ID: {booking.id}
        </Typography>
      </Box>
      {/* <Box sx={{ width: '26px', height: '26px' }}>
        <ChatIcon />
      </Box> */}
      {role !== 'shipper' && (
        <Box display="flex" alignItems="center" gap={1}>
          <Box className={`circle ${booking.biddingCount >= 3 ? 'green' : ''}`}>
            <Typography
              sx={{
                color: '#fff',
                textAlign: 'center',
                marginTop: '2px',
                fontWeight: 'bold'
              }}
              variant="body2"
            >
              {booking.biddingCount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Number of Bids</Typography>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        ml="auto"
        alignItems="center"
        gap={1}
        mr={4}
      >
        <Box>
          <ClockIcon />
        </Box>
        <Box>
          <Typography variant="subtitle2">
            {timesAgo(booking.createdAt)}
          </Typography>
        </Box>
      </Box>

      {/* <Box
        sx={{
          background: '#FFB547',
          borderRadius: '40px',
          padding: 1
        }}
      >
        <Typography sx={{ color: '#fff' }} variant="body2">
          {formatStage(booking.stage)}
        </Typography>
      </Box> */}
      <Box>
        <Chip
          label={
            booking.bookingStatus === 'shipper_approved'
              ? 'Activated'
              : booking.bookingStatus === 'look_at_bid'
              ? 'Requested'
              : formatStage(booking.bookingStatus)
          }
          sx={{
            background: getColor(booking.bookingStatus)['bg'],
            color: getColor(booking.bookingStatus)['color']
          }}
        />
      </Box>
    </Box>
  )
}

BookingDetailHeader.propTypes = {
  booking: PropTypes.object
}

export default BookingDetailHeader
