import ArrowForward from '@mui/icons-material/ArrowForward'
import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'
import humanizeNumber from 'humanize-number'

const CostPerRoute = (props) => {
  const { costPerRoutes } = props
  return (
    <Box mt={3}>
      <Typography variant="h5" mb={3}>
        Cost Per Route
      </Typography>
      <Box className="info_table">
        <Grid px={2} py={1} container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6">From</Typography>
          </Grid>
          <Grid item xs={2}>
            <ArrowForward color="primary" />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">To</Typography>
          </Grid>
          <Grid textAlign="center" item xs={4}>
            <Typography variant="h6">Average cost</Typography>
          </Grid>
        </Grid>
        <Divider />
        {costPerRoutes?.map((item, i) => (
          <Grid px={2} py={1} container alignItems="center" key={i} spacing={2}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                {item.fromDistrictName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ArrowForward color="primary" />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                {item.toDistrictName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                textAlign="center"
                color="primary"
                variant="subtitle1"
              >
                {humanizeNumber(item.avgRate, {
                  delimiter: ',',
                  separator: '.'
                })}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}

CostPerRoute.propTypes = {
  costPerRoutes: PropTypes.array
}
export default CostPerRoute
