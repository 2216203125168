import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { isEmptyArray } from '../../../../utils/functions'
import { useGetHook } from '../../../../hooks/fetchHook'
import Box from '@mui/material/Box'
import { useStore } from '../../../../store'
import ShipperBookingTripCard from './TripCard'

const TripsByBooking = (props) => {
  const { booking, setToast } = props
  const { bookingStore } = useStore()
  const [tripsData, setTripsData] = useState([])
  // const [recall, setRecall] = useState(false)
  // const [kam, setKam] = useState({})

  useEffect(() => {
    // === NEED TO PASS COMPANY ID FOR GETTING TRIPS OF A BOOKING (BELONGS TO) === //
    // ========================== PASSING FROM API CALL ========================== //
    let url = `/lf-booking/api/v1/admin/booking/${booking.id}/trips?companyId=${booking.company.id}`

    useGetHook(
      url,
      (res) => {
        setTripsData(res.data.data)
        bookingStore.updateBookingDetails(res.data.data)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  return (
    <Box p={2} mb={1}>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <Typography>Total Trips ({tripsData.trips?.length})</Typography>
        </Box>
      </Box>

      {!isEmptyArray(tripsData.trips) ? (
        <>
          {tripsData.trips?.map((item) => (
            <ShipperBookingTripCard
              key={item.id}
              tripsData={tripsData}
              data={item}
            />
          ))}
        </>
      ) : (
        <Box mt={1}>
          <Typography>No Data Available</Typography>
        </Box>
      )}
    </Box>
  )
}

TripsByBooking.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func
}

export default TripsByBooking
