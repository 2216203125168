export const EDIT_ACCEPTED = 'edit_accepted'
export const EDIT_REJECTED = 'edit_rejected'
export const SUCCESS = 'success'
export const ERROR = 'error'

/* ROLES */
export const KAM = 'kam'
export const CLUSTER_HEAD = 'cluster_head'

/* TEAMS */
export const BUSINESS_TEAM = 'business_team'
export const FULLFILLMENT_TEAM = 'fulfillment_team'

/* BOOKING STEPS */
export const LOOK_AT_BID = 'Look at Bid'
export const LOOK_AT_BID_PROP = 'lookAtBid'

export const QUOTE_SHIPPER = 'Quote Shipper'
export const QUOTE_SHIPPER_PROP = 'quoteShipper'

export const SELECT_VENDOR = 'Select Vendor'
export const SELECT_VENDOR_PROP = 'selectVendor'

export const GET_TRUCK_INFO = 'Get Truck Info'
export const GET_TRUCK_INFO_PROP = 'getTruckInfo'

export const ACTIVE_TRIP = 'Active Trip'
export const ACTIVE_TRIP_PROP = 'activeTrip'

export const MONTHLY_TRIP = 'Monthly Trip'
export const MONTHLY_TRIP_PROP = 'monthlyTripCreate'

export const COMPLETED_TRIP = 'Completed'
export const COMPLETED_TRIP_PROP = 'monthlyTripCompleted'

export const CHALLAN_HARD_COPY = 'Challan Hardcopy'
export const CHALLAN_HARD_COPY_PROP = 'receiptHardCopyCount'

export const BID_ACCEPTED = 'Bid Accepted'
export const BID_ACCEPTED_PROP = 'bidAccepted'

export const onlyNumRegex = /[0-9]|\./

/* STATUS FILTER FOR SERVER */
export const bookingStatusDict = {
  [LOOK_AT_BID_PROP]: 'look_at_bid',
  [QUOTE_SHIPPER_PROP]: 'quote_shipper',
  [SELECT_VENDOR_PROP]: 'select_vendor',
  [GET_TRUCK_INFO_PROP]: 'get_truck_info',
  [ACTIVE_TRIP_PROP]: 'activated',
  [COMPLETED_TRIP_PROP]: 'completed',
  [MONTHLY_TRIP_PROP]: 'completed',
  [BID_ACCEPTED_PROP]: 'bidAccepted',
  [CHALLAN_HARD_COPY_PROP]: 'receiptHardCopyCount'
}

/* API FILTERS */
export const bookingFilters = {
  LOOK_AT_BID: 'look_at_bid',
  GET_TRUCK_INFO: 'get_truck_info',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  SHIPPER_APPROVED: 'shipper_approved',
  QUOTE_SHIPPER: 'quote_shipper'
}

export const subsTypes = {
  Tin: 'tin',
  Bronze: 'bronze',
  Silver: 'silver',
  Gold: 'gold',
  Platinum: 'platinum',
  Diamond: 'diamond'
}

export const ledgerEntryType = {
  trip_payable: 'Add Due',
  trip_payout: 'Payout Due',
  trip_payable_reverse: 'Reverse Due',
  trip_payout_reverse: 'Reverse Payout'
}

export const getFullNames = {
  gm: 'General Manager',
  pro: 'Customer Experience',
  kam: 'Key Account Manager'
}
