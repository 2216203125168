import { makeObservable, observable, action } from 'mobx'
import { getLoggedInUser } from '../utils/functions'

class UserStore {
  profilePhotoUrl = ''
  fullName = ''
  currentMenu = null

  constructor() {
    makeObservable(this, {
      profilePhotoUrl: observable,
      updateProfilePhotoUrl: action,
      fullName: observable,
      updateFullName: action,
      setCurrentMenu: action
    })

    this.profilePhotoUrl = getLoggedInUser()?.profilePicture
    this.fullName = getLoggedInUser()?.fullName
  }

  updateProfilePhotoUrl(url) {
    this.profilePhotoUrl = url
  }

  updateFullName(fullName) {
    this.fullName = fullName
  }

  setCurrentMenu(menu) {
    this.currentMenu = menu
  }
}

export default UserStore
