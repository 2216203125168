import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function Snackbars(props) {
  const { open, setOpen, severity, message } = props
  const vertical = 'bottom'
  const horizontal = 'center'

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical, horizontal }}
      key={'top right'}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

Snackbars.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  severity: PropTypes.string,
  message: PropTypes.string
}

export default Snackbars
