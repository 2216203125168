import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useGetHook } from '../../hooks/fetchHook'
import Typography from '@mui/material/Typography'
import TripCounts from './TripCounts'
import CircularProgress from '@mui/material/CircularProgress'
import './reporting.css'
import CostPerRoute from './CostPerRoute'
import RoutesUsed from './RoutesUsed'
import { Grid } from '@mui/material'
import CompanyAutoComplete2 from '../../components/CompanyAutoComplete2'
import { toQueryString } from '../../utils/functions'
import CompanyAdditionalMetrics from './CompanyAdditionalMetrics'
import companyNaiga from '../../assests/companynai.svg'
import CustomDatePicker2 from '../../components/CustomDatePicker2'

function getEarlierDate() {
  let date = new Date()
  date.setDate(date.getDate() - 14)
  return date
}

const Reports = () => {
  const [tripCounts, setTripCounts] = useState({})
  const [loading, setLoading] = useState(false)
  const [metrics, setMetrics] = useState({})
  const [fromDate, setFromDate] = useState(getEarlierDate())
  const [toDate, setToDate] = useState(new Date())
  const [costPerRoutes, setCostPerRoutes] = useState([])
  const [routesUsed, setRoutesUsed] = useState([])
  const [company, setCompany] = useState({ id: '' })

  useEffect(() => {
    if (company.id) {
      setLoading(true)
      let from = ''
      let to = ''

      if (fromDate.c) {
        let { year, month, day } = fromDate.c
        from = `${year}-${month}-${day}`
      } else {
        from = new Date(fromDate).toISOString().split('T')[0]
      }

      if (toDate.c) {
        let { year, month, day } = toDate.c
        to = `${year}-${month}-${day}`
      } else {
        to = new Date(toDate).toISOString().split('T')[0]
      }

      const queryObject = {
        fromDate: from,
        toDate: to,
        companyId: company.id
      }

      let url = `/lf-analytics/api/v1/company${toQueryString(queryObject)}`

      useGetHook(
        url,
        (res) => {
          setTripCounts(res.data.data.tripCounts)
          setMetrics(res.data.data.additionalMetrics)
          setCostPerRoutes(res.data.data.routesCost)
          setRoutesUsed(res.data.data.routesUsed)
          setLoading(false)
        },
        () => {
          setLoading(false)
        },
        true
      )
    }
  }, [fromDate, toDate, company.id])

  const onCompanyChange = (value) => {
    setCompany(value)
  }
  const handleFromDateChange = (newValue) => setFromDate(newValue)
  const handleToDateChange = (newValue) => setToDate(newValue)

  return (
    <Box px={2} py={4}>
      <Box
        display="flex"
        mb={2}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexGrow={1}>
          <Typography variant="h4">Company Report</Typography>
        </Box>
        <CompanyAutoComplete2
          label="Select Company"
          onCompanyChange={onCompanyChange}
        />
        <Box>
          <CustomDatePicker2
            label="From Date*"
            onChange={handleFromDateChange}
            disableFuture
          />
        </Box>
        <Box>
          <CustomDatePicker2
            label="To Date*"
            onChange={handleToDateChange}
            disableFuture
          />
        </Box>
      </Box>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '90vh' }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {!loading && (
        <>
          <Typography mb={2} textAlign="left" variant="h5" color="primary">
            {company.title}
          </Typography>
          {company.id ? (
            <>
              <TripCounts tripCounts={tripCounts} />
              <CompanyAdditionalMetrics metrics={metrics} />
              <Grid container alignItems="stretch" spacing={2}>
                <Grid item xs={6}>
                  <CostPerRoute costPerRoutes={costPerRoutes} />
                </Grid>
                <Grid item xs={6}>
                  <RoutesUsed routesUsed={routesUsed} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              m="auto"
              alignItems="center"
              flexDirection="column"
            >
              <img className="fluid" src={companyNaiga} />
              <Typography
                textAlign="center"
                variant="subtitle1"
                color="primary"
              >
                Please select a company to view Analytics.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default Reports
