import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TotalWeightIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 37.5C0 16.7893 16.7893 0 37.5 0V0C58.2107 0 75 16.7893 75 37.5V37.5C75 58.2107 58.2107 75 37.5 75V75C16.7893 75 0 58.2107 0 37.5V37.5Z"
        fill="#0071CD"
      />
      <path
        d="M17 58C17 54.4333 17.5167 51.35 18.55 48.75C19.5833 46.15 20.9 43.9917 22.5 42.275C24.1 40.5583 25.875 39.225 27.825 38.275C29.775 37.325 31.6667 36.7167 33.5 36.45V29.5C28.9333 28.9333 25.0417 27.6083 21.825 25.525C18.6083 23.4417 17 20.9333 17 18H57C57 20.9333 55.3917 23.4417 52.175 25.525C48.9583 27.6083 45.0667 28.9333 40.5 29.5V36.45C42.3333 36.7167 44.225 37.325 46.175 38.275C48.125 39.225 49.9 40.5583 51.5 42.275C53.1 43.9917 54.4167 46.15 55.45 48.75C56.4833 51.35 57 54.4333 57 58H45V55H53.85C53.25 49.9333 51.4 46.025 48.3 43.275C45.2 40.525 41.4333 39.15 37 39.15C32.5667 39.15 28.8 40.525 25.7 43.275C22.6 46.025 20.75 49.9333 20.15 55H29V58H17ZM37 26.75C40.5333 26.75 43.8 26.1417 46.8 24.925C49.8 23.7083 51.7833 22.4 52.75 21H21.25C22.2167 22.4 24.2 23.7083 27.2 24.925C30.2 26.1417 33.4667 26.75 37 26.75ZM37 58C35.9 58 34.9583 57.6083 34.175 56.825C33.3917 56.0417 33 55.1 33 54C33 53.4333 33.1083 52.9167 33.325 52.45C33.5417 51.9833 33.8333 51.5667 34.2 51.2C35 50.4 36.375 49.5667 38.325 48.7C40.275 47.8333 42.5 46.9333 45 46C44.0333 48.5 43.125 50.725 42.275 52.675C41.425 54.625 40.6 56 39.8 56.8C39.4333 57.1667 39.0167 57.4583 38.55 57.675C38.0833 57.8917 37.5667 58 37 58Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default TotalWeightIcon
