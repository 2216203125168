/* eslint-disable indent */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { StyledFlexDiv } from './StyledComponents/StyledComponents'
import Divider from '@mui/material/Divider'
import { getAccessLevel } from '../../accesscontrol'
import {
  toProperCase,
  getPropValue,
  timesAgo,
  getStageColor,
  isEmptyArray
} from '../../utils/functions'
import { Grid } from '@mui/material'
import BookingCancelModal from './BookingCancelModal'
import LocationAndTruckInfo from '../../components/molecules/LocationAndTruckInfo'
import useToast from '../../hooks/useToast'
import Snackbars from '../../components/Snackbar/Snackbar'
import CustomPopover from '../../components/CustomPopover'

const BookingCard = (props) => {
  const { booking, onItemClick } = props
  const navigate = useNavigate()
  const {
    company,
    pickupDate,
    id,
    minPrice,
    maxPrice,
    fromLocation,
    toLocation,
    stoppages,
    truckSize,
    truckQuantity,
    pickupTime,
    biddingCount,
    createdAt,
    trips,
    bookingStatus
  } = booking

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  const [openCancelModal, toggleOpen] = useState(false)
  // const [anchorEl, setAnchorEl] = useState(null)

  // const open = Boolean(anchorEl)

  // const popOverId = open ? 'simple-popover' : undefined

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  const handleClose = () => {
    // setAnchorEl(null)
  }

  const getShierApprovedStatuses = () => {
    //Later >> if length > 1 then return ['shipper_approved'] else return bookingStatus
    const statuses = [
      ...new Set(
        trips
          .map((item) =>
            item.tripStatus === 'select_vendor' ||
            item.tripStatus === 'get_truck_info' ||
            item.tripStatus === 'activated' ||
            item.tripStatus === 'on_way'
              ? item.tripStatus === 'on_way'
                ? 'activated'
                : item.tripStatus
              : ''
          )
          .filter(Boolean)
      )
    ]
    return statuses
  }
  // const user = getLoggedInUser()

  const navigateToEdit = (e) => {
    e.stopPropagation()
    navigate('/home/booking-edit/' + id)
  }

  return (
    <>
      <Box className="return-trip-box" onClick={() => onItemClick(booking)}>
        <Grid
          container
          p={1}
          alignItems="center"
          spacing={1}
          className="card-header"
        >
          <Grid item xs={3}>
            <StyledFlexDiv gap={1}>
              <Avatar alt={company?.name} src={company?.companyPicture} />
              <Typography color="primary">
                {getPropValue('name', company)}
              </Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1}>
            <StyledFlexDiv>
              <Typography>B-ID:</Typography>
              <Typography color="primary">{id}</Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={3}>
            <StyledFlexDiv justifyContent="flex-start" alignItems="flex-start">
              <Typography>B-Date:</Typography>
              <Typography color="primary">{timesAgo(createdAt)}</Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={2}>
            <StyledFlexDiv justifyContent="flex-start" alignItems="flex-start">
              <Box>
                <Typography>Range: </Typography>
              </Box>
              <Box>
                <Typography color="primary">
                  ৳{minPrice} - ৳{maxPrice}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.7}>
            <StyledFlexDiv justifyContent={'flex-end'} alignItems="flex-start">
              {bookingStatus === 'shipper_approved' ? (
                !isEmptyArray(trips) &&
                getShierApprovedStatuses().map((item, i) => (
                  <Chip
                    key={i}
                    sx={getStageColor(item)}
                    label={toProperCase(item)}
                  />
                ))
              ) : (
                <Chip
                  sx={getStageColor(bookingStatus)}
                  label={toProperCase(bookingStatus)}
                />
              )}
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" alignItems="center" justifyContent="end">
              <Box display="flex" gap={'3px'} alignItems="center">
                {bookingStatus !== 'completed' &&
                  bookingStatus !== 'cancelled' &&
                  bookingStatus !== 'expired' &&
                  bookingStatus !== 'shipper_approved' && (
                    <>
                      <Chip
                        sx={{
                          bgcolor:
                            biddingCount === 0
                              ? '#858C94'
                              : biddingCount > 2
                              ? '#4CAF50'
                              : '#FFB400',
                          color: '#fff'
                        }}
                        label={biddingCount}
                      />
                      <Typography variant="body2">Bids</Typography>
                    </>
                  )}
              </Box>
              {/* {bookingStatus === 'cancelled' && (
                <CustomPopover icon={<TextSnippetIcon color="error" />}>
                  <Typography p={2} variant="body1">
                    {cancelReason || 'N/A'}
                  </Typography>
                </CustomPopover>
              )} */}
            </Box>
          </Grid>
          <Grid item xs={0.3}>
            <StyledFlexDiv justifyContent="center">
              {getAccessLevel('showEditBtn') &&
                bookingStatus !== 'cancelled' &&
                bookingStatus !== 'expired' && (
                  <CustomPopover icon={<MoreVertIcon />}>
                    {getAccessLevel('editBooking') && (
                      <Typography
                        p={1}
                        sx={{
                          cursor: 'pointer',
                          bgcolor: '#fff',
                          '&:hover': {
                            bgcolor: '#0071cd',
                            color: '#fff'
                          }
                        }}
                        onClick={navigateToEdit}
                      >
                        Edit
                      </Typography>
                    )}
                    {getAccessLevel('cancelBooking') &&
                      bookingStatus !== 'completed' && (
                        <Typography
                          p={1}
                          sx={{
                            cursor: 'pointer',
                            bgcolor: '#fff',
                            '&:hover': {
                              bgcolor: '#0071cd',
                              color: '#fff'
                            }
                          }}
                          color="red"
                          onClick={(e) => {
                            toggleOpen(true)
                            e.stopPropagation()
                          }}
                        >
                          Cancel
                        </Typography>
                      )}
                  </CustomPopover>
                )}
            </StyledFlexDiv>
          </Grid>
        </Grid>
        <Divider />
        <LocationAndTruckInfo
          fromLocation={fromLocation}
          stoppages={stoppages}
          toLocation={toLocation}
          truckSize={truckSize}
          truckQuantity={truckQuantity}
          pickupTime={pickupTime}
          pickupDate={pickupDate}
        />
      </Box>
      <BookingCancelModal
        open={openCancelModal}
        setToast={setToast}
        onClose={() => toggleOpen(false)}
        bookingId={id}
        handleClose={handleClose}
      />
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

BookingCard.propTypes = {
  booking: PropTypes.object,
  status: PropTypes.string,
  onItemClick: PropTypes.func
}

export default BookingCard
