import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getHumanReadableDate, getPropValue } from '../../utils/functions'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { ShipperRowItem } from '../../components/HomeContainer/StyledComponents/StyledComponents'
import { Chip, Grid } from '@mui/material'
// import './styles.css'
import GppGoodIcon from '@mui/icons-material/GppGood'
const Vendor = (props) => {
  const navigate = useNavigate()
  const { data } = props

  const navigateToDetail = () => {
    let route = `/home/vendor-profile/${data.userId}`
    navigate(route)
  }

  return (
    <Box pt={2}>
      <ShipperRowItem
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={navigateToDetail}
      >
        <Grid container spacing={1}>
          <Grid item xs={0.5}>
            <Avatar
              alt={data.fullName}
              src={data.profilePicture ? data.profilePicture : 'as'}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Box display="flex" gap={0.5} alignItems="center">
              <Typography className="col-head" variant="body2">
                Name
              </Typography>
              {data.securitySponsor && (
                <GppGoodIcon fontSize="small" color="success" />
              )}
            </Box>
            <Typography className="col-value" variant="body">
              {data.fullName}
            </Typography>
          </Grid>

          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Company Name
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('companyName', data)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography className="col-head" variant="body2">
              Class Type
            </Typography>
            <Typography
              sx={{
                textTransform: 'capitalize'
              }}
              className="col-value"
              variant="body"
            >
              {getPropValue('subsType', data)}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Mobile Number
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('phone', data)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography className="col-head" variant="body2">
              Zone
            </Typography>
            <Chip
              color="primary"
              size="small"
              label={getPropValue('zone', data)}
              sx={{
                width: '100px',
                textAlign: 'center',
                textTransform: 'capitalize'
              }}
            />
          </Grid>

          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Assigned KAM
            </Typography>

            <Typography className="col-value" variant="body">
              {getPropValue('fullName', data.assignKam)}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Assigned Cluster
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('fullName', data.assignCluster)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="col-head" variant="body2">
                Status
              </Typography>
              <Chip
                className="truck-item-label"
                mt={1}
                label={data.isVerified ? 'Verified' : ' Not verified'}
                size="small"
                color={data.isVerified ? 'success' : 'error'}
                sx={{ width: '100px', textAlign: 'center' }}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Typography className="col-head" variant="body2">
              Created At
            </Typography>
            <Typography className="col-verified" variant="body">
              {data.createdAt ? getHumanReadableDate(data.createdAt) : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </ShipperRowItem>
    </Box>
  )
}

Vendor.propTypes = {
  data: PropTypes.object
}

export default Vendor
