/* eslint-disable indent */
import { Download, Search } from '@mui/icons-material'
import { Box, Button, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BookingItem from '../../../components/BookingItem'
import StatusCount from '../../../components/StatusCount'
import { useGetHook } from '../../../hooks/fetchHook'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import { toQueryString } from '../../../utils/functions'
import useToast from '../../../hooks/useToast'
import Snackbars from '../../../components/Snackbar/Snackbar'
import VendorPayableModal from './VendorPayableModal'
import CommonPagination from '../../LatestBookings/CommonPagination'
import { useNavigate } from 'react-router-dom'

const VendorPayable = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [supplierExternalId, setSupplierExternalId] = useState('')
  const [supplierCompanyName, setSupplierCompanyName] = useState('')
  const [vendorDues, setVendorDues] = useState([])
  const debouncedSearch = useDebouncedValue(search, 500)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState()
  // const [total, setTotal] = useState(0)
  const handlePageChange = (event, value) => setPage(value)

  const handleOpen = (id, companyName) => {
    setSupplierExternalId(id)
    setSupplierCompanyName(companyName)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  useEffect(() => {
    const query = {
      limit: 10,
      page,
      search: debouncedSearch
    }
    let url = `/lf-acc/api/v1/management/suppliers/summaries${toQueryString(
      query
    )}`
    useGetHook(
      url,
      (res) => {
        // setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setVendorDues(res.data.data)
      },
      () => {},
      true
    )
  }, [debouncedSearch, page])

  const updateCardOnPayment = (newResponse) => {
    setVendorDues([
      ...vendorDues.map((item) => {
        return item.userId === newResponse.userId
          ? {
              ...item,
              totalDue: newResponse.totalDue,
              totalEarnings: newResponse.totalEarnings,
              totalReceived: newResponse.totalReceived
            }
          : item
      })
    ])
  }

  const gotoVendorDc = (userId, companyName) => {
    sessionStorage.setItem('companyName', companyName)
    navigate(`/home/finance/${userId}/dc`)
  }

  const handleDownload = () => {
    let url = '/lf-acc/api/v1/management/suppliers/supplier-list-download'
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'supplier_list'
    useGetHook(
      url,
      (res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        )
        link.click()
      },
      () => {},
      true,
      {
        responseType: 'blob'
      }
    )
  }

  return (
    <>
      <Box my={2}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            onClick={handleDownload}
            startIcon={<Download />}
            variant="contained"
          >
            Vendor List
          </Button>

          <TextField
            outlined
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {vendorDues?.map((item) => (
          <BookingItem
            key={item.userId}
            companyName={item.companyName}
            phone={item.phone}
            name={item.fullName}
            photo={item.profilePicture}
            column={3}
            onClick={() => gotoVendorDc(item.userId, item.companyName)}
          >
            <StatusCount
              type="green"
              count={item.totalEarnings?.split('.')[0]}
              status="Total Earnings"
            />
            <StatusCount
              type="blue"
              count={item.totalReceived?.split('.')[0]}
              status="Total Paid"
            />
            <StatusCount
              type="red"
              count={item.totalDue?.split('.')[0]}
              status="Total Due"
            />
            <StatusCount
              type="orange"
              count={item.payoutDay}
              status="Payout Day"
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <Button
                onClick={() => handleOpen(item.userId, item.companyName)}
                variant="contained"
              >
                Add Payment
              </Button>
            </Box>
          </BookingItem>
        ))}
      </Box>

      <CommonPagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
      />

      <VendorPayableModal
        open={open}
        setToast={setToast}
        handleClose={handleClose}
        supplierExternalId={supplierExternalId}
        supplierCompanyName={supplierCompanyName}
        updateCardOnPayment={updateCardOnPayment}
      />

      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default VendorPayable
