import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import CustomTabPanel from '../../components/CustomTabPanel'
import { a11yProps } from '../../utils/functions'
import DailyFeed from './DailyFeed'
import VendorFeed from './VendorFeed'

const FeedReports = () => {
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box p={2}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Monthly Feed" {...a11yProps(0)} />
        <Tab label="All Vendor Feed" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <DailyFeed />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <VendorFeed />
      </CustomTabPanel>
    </Box>
  )
}

export default FeedReports
