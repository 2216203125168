import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const BorderedColumn = styled(Box)(() => ({
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  background: '#FFFFFF'
}))

export const FileBorderedColumn = styled(Box)(() => ({
  borderRadius: '4px',
  border: '1px dashed rgba(0, 0, 0, 0.12)',
  background: '#FFFFFF'
}))

export const IconAvatar = styled(Avatar)(() => ({
  backgroundColor: 'rgba(63, 81, 181, 0.08)'
}))

export const IconAvatarSecondary = styled(Avatar)(() => ({
  backgroundColor: 'rgba(76, 175, 80, 0.08);'
}))

export const LabelText = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px'
}))

export const ValueText = styled(Typography)(() => ({
  color: '#1E293B',
  fontSize: '16px'
}))

export const ProfileInfoWrapper = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginTop: props.marginTopNone ? '0px' : '16px'
}))

export const FileInputButton = styled('input')({
  display: 'none'
})

export const TripIdCommonLabel = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '14px',
  fontWeight: 500
}))

export const CommonLabel = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
  fontWeight: 500
}))

export const PaidLabel = styled(Typography)(() => ({
  color: '#4CAF50',
  fontSize: '14px',
  fontWeight: 'bold'
}))

export const ActiveButton = styled(Button)(() => ({
  borderRadius: 40,
  background: '#0071CD',
  fontWeight: 'bold',
  width: 111
}))

export const InfoLabel = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#1E293B',
  fontWeight: 'bold'
}))
