import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { formatStage, toQueryString } from '../../utils/functions'
import { useGetHook } from '../../hooks/fetchHook'
import Pagination from '@mui/material/Pagination'
import OperationPersonnel from './OperationPersonnel'
import { PrimaryButton } from '../../components/Landing/StyledComponents/StyledComponents'
import Snackbars from '../../components/Snackbar/Snackbar'
import AddUserModal from '../AddUser/AddUserModal'
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import useDebouncedValue from '../../hooks/useDebounceValue'
import Search from '@mui/icons-material/Search'
import UserListTitle from '../../components/UserListTitle'
import { getFullNames } from '../../constants'

const OperationPersonnelList = (props) => {
  const {
    role,
    gmList,
    setGmList,
    clusterHeadList,
    setClusterHeadList,
    totalGmCount,
    setTotalGmCount,
    totalClusterHeadCount,
    setTotalClusterHeadCount,
    setKamList,
    setTotalKamCount,
    showLastLogin
  } = props

  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()
  const [pageCount, setPageCount] = useState(0)
  const [userList, setUserList] = useState([])
  const [team, setTeam] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [kw, setKw] = useState('')
  const debounedKw = useDebouncedValue(kw, 500)

  useEffect(() => {
    const query = {
      team: team !== null ? team : '',
      page,
      count: 10,
      search: debounedKw
    }

    let url = `/lf-user/api/v1/admin/hierarchy/user/${role}${toQueryString(
      query
    )}`

    if (role === 'gm') {
      if (gmList.length === 0) {
        useGetHook(
          url,
          (res) => {
            setGmList(res.data.data.result)
            setTotalGmCount(res.data.data.total)

            setUserList(res.data.data.result)
            setTotal(res.data.data.total)

            let count = Math.ceil(res.data.data.total / 10)
            setPageCount(count)
          },
          (err) => setToast(err.response?.data?.message, 'error'),
          true
        )
      } else {
        let cachedData = JSON.parse(JSON.stringify(gmList))
        setUserList(cachedData)

        setTotal(totalGmCount)

        let count = Math.ceil(totalGmCount / 10)
        setPageCount(count)
      }
    }

    if (role === 'cluster_head') {
      if (clusterHeadList.length === 0) {
        useGetHook(
          url,
          (res) => {
            setClusterHeadList(res.data.data.result)
            setTotalClusterHeadCount(res.data.data.total)

            setUserList(res.data.data.result)
            setTotal(res.data.data.total)

            let count = Math.ceil(res.data.data.total / 10)
            setPageCount(count)
          },
          (err) => setToast(err.response?.data?.message, 'error'),
          true
        )
      } else {
        let cachedData = JSON.parse(JSON.stringify(clusterHeadList))
        setUserList(cachedData)

        setTotal(totalClusterHeadCount)

        let count = Math.ceil(totalClusterHeadCount / 10)
        setPageCount(count)
      }
    }
    if (role === 'kam') {
      useGetHook(
        url,
        (res) => {
          setKamList(res.data.data.result)
          setTotalKamCount(res.data.data.total)

          setUserList(res.data.data.result)
          setTotal(res.data.data.total)

          let count = Math.ceil(res.data.data.total / 10)
          setPageCount(count)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }
  }, [team, debounedKw])
  const handleChange = (event, value) => {
    let url = `/lf-user/api/v1/admin/hierarchy/user/${role}?page=${value}&count=10`
    useGetHook(
      url,
      (res) => {
        if (role === 'gm') {
          setGmList(res.data.data.result)
        }
        if (role === 'kam') {
          setKamList(res.data.data.result)
        }
        if (role === 'cluster_head') {
          setClusterHeadList(res.data.data.result)
        }

        setUserList(res.data.data.result)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
    setPage(value)
  }

  const setToast = (msg, severity) => {
    if (!msg) msg = 'Something went wrong'
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const refreshList = (newRole) => {
    let url = `/lf-user/api/v1/admin/hierarchy/user/${newRole}?page=1&count=10`
    if (newRole === 'gm') {
      useGetHook(
        url,
        (res) => {
          setGmList(res.data.data.result)
          setTotalGmCount(res.data.data.total)

          setUserList(res.data.data.result)
          setTotal(res.data.data.total)

          let count = Math.ceil(res.data.data.total / 10)
          setPageCount(count)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }

    if (newRole === 'cluster_head') {
      useGetHook(
        url,
        (res) => {
          setClusterHeadList(res.data.data.result)
          setTotalClusterHeadCount(res.data.data.total)

          setUserList(res.data.data.result)
          setTotal(res.data.data.total)

          let count = Math.ceil(res.data.data.total / 10)
          setPageCount(count)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }

    if (newRole === 'kam') {
      useGetHook(
        url,
        (res) => {
          setKamList(res.data.data.result)
          setTotalKamCount(res.data.data.total)

          setUserList(res.data.data.result)
          setTotal(res.data.data.total)

          let count = Math.ceil(res.data.data.total / 10)
          setPageCount(count)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }
  }

  return (
    <>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <UserListTitle
            count={total}
            name={getFullNames[role] || formatStage(role)}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <PrimaryButton variant="contained" onClick={() => setOpen(true)}>
            Add User
          </PrimaryButton>
          {role === 'kam' && (
            <>
              <FormControl sx={{ minWidth: 140 }} size="small">
                <InputLabel id="demo-simple-select-label26xx">
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label26xx"
                  id="demo-simple-select26xx"
                  value={team}
                  label="Select Team"
                  onChange={(e) => {
                    setTeam(e.target.value)
                    setPage(1)
                  }}
                >
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={'business_team'}>Business Team</MenuItem>
                  <MenuItem value={'vendor_team'}>Vendor Team</MenuItem>
                  <MenuItem value={'fulfillment_team'}>
                    Fulfilment Team
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                color="primary"
                size="small"
                label="Search Kam"
                value={kw}
                onChange={(e) => {
                  setKw(e.target.value)
                  setPage(1)
                }}
                sx={{ minWidth: 160 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </>
          )}
        </Box>
      </Box>
      <Box>
        {userList.map((item) => (
          <OperationPersonnel
            key={item.userId}
            data={item}
            showLastLogin={showLastLogin}
          />
        ))}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <AddUserModal
        open={open}
        defaultRole={role}
        onClose={setOpen}
        setToast={setToast}
        refreshList={refreshList}
      />
    </>
  )
}

OperationPersonnelList.propTypes = {
  role: PropTypes.string.isRequired,
  gmList: PropTypes.array,
  setGmList: PropTypes.func,
  clusterHeadList: PropTypes.array,
  setClusterHeadList: PropTypes.func,
  totalGmCount: PropTypes.number,
  setTotalGmCount: PropTypes.func,
  totalClusterHeadCount: PropTypes.number,
  setTotalClusterHeadCount: PropTypes.func,
  kamList: PropTypes.array,
  setKamList: PropTypes.func,
  totalKamCount: PropTypes.number,
  setTotalKamCount: PropTypes.func,
  showLastLogin: PropTypes.any
}

export default OperationPersonnelList
