import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import './bookings.css'
import tin from '../../assests/subsType/tin.svg'
import gold from '../../assests/subsType/gold.svg'
import silver from '../../assests/subsType/silver.svg'
import bronze from '../../assests/subsType/bronze.svg'
import platinum from '../../assests/subsType/platinum.svg'
import { Chip } from '@mui/material'

const subsTypesImg = {
  tin,
  bronze,
  silver,
  gold,
  platinum
}

const BookingItem = ({
  name,
  companyName,
  phone,
  photo,
  column,
  children,
  subsType,
  onClick,
  zone
}) => {
  return (
    <Paper className="paper_root">
      <Grid container>
        <Grid sx={{ cursor: 'pointer' }} item xs={column}>
          <Box
            display="flex"
            p={2}
            gap={2}
            alignItems="center"
            onClick={onClick}
          >
            <Box>
              <Avatar
                sx={{ width: '80px', height: '80px' }}
                alt={companyName}
                src={photo ? photo : 'asd'}
              />
            </Box>
            <Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="body1" color="primary" fontWeight={800}>
                  {companyName || 'N/A'}
                </Typography>
                {subsType && (
                  <Avatar
                    alt={'n/a'}
                    src={subsType ? subsTypesImg[subsType] : 'as'}
                    sx={{ width: '18px', height: '18px' }}
                  />
                )}
              </Box>
              {name && <Typography variant="body2">{name}</Typography>}
              {phone && (
                <Typography variant="body2" className="phone">
                  {phone}
                </Typography>
              )}
              {zone && (
                <Chip
                  sx={{ mt: 1, textTransform: 'capitalize' }}
                  label={zone}
                  size="small"
                  color={'primary'}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ cursor: 'pointer' }} item xs={12 - column}>
          <Box display="flex" height={'100%'}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

BookingItem.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  photo: PropTypes.string,
  companyName: PropTypes.string,
  phone: PropTypes.string | PropTypes.any,
  id: PropTypes.number,
  column: PropTypes.number,
  children: PropTypes.node,
  userId: PropTypes.string,
  subsType: PropTypes.string,
  onClick: PropTypes.func,
  zone: PropTypes.string
}

export default BookingItem
