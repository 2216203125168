import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SupervisorAccount = (props) => {
  return (
    <SvgIcon
      {...props}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M61.875 44.9995C67.05 44.9995 71.2125 40.7995 71.2125 35.6245C71.2125 30.4495 67.05 26.2495 61.875 26.2495C56.7 26.2495 52.5 30.4495 52.5 35.6245C52.5 40.7995 56.7 44.9995 61.875 44.9995ZM33.75 41.2495C39.975 41.2495 44.9625 36.2245 44.9625 29.9995C44.9625 23.7745 39.975 18.7495 33.75 18.7495C27.525 18.7495 22.5 23.7745 22.5 29.9995C22.5 36.2245 27.525 41.2495 33.75 41.2495ZM61.875 52.4995C55.0125 52.4995 41.25 55.9495 41.25 62.812V71.2495H82.5V62.812C82.5 55.9495 68.7375 52.4995 61.875 52.4995ZM33.75 48.7495C25.0125 48.7495 7.5 53.137 7.5 61.8745V71.2495H33.75V62.812C33.75 59.6245 34.9875 54.037 42.6375 49.7995C39.375 49.1245 36.225 48.7495 33.75 48.7495Z" />
    </SvgIcon>
  )
}

export default SupervisorAccount
