import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const AlphaAutoComplete = (props) => {
  const { alphas, alpha, setAlpha } = props

  const onAlphaSelect = (newValue) => {
    if (newValue) {
      setAlpha({
        ...newValue
      })
    } else {
      setAlpha({
        id: 0,
        title: ''
      })
    }
  }

  return (
    <Autocomplete
      value={alpha}
      fullWidth
      onChange={(event, newValue) => {
        onAlphaSelect(newValue)
      }}
      // onInputChange={(event, newInputValue) => {
      //   onCityChange(newInputValue)
      // }}
      options={alphas}
      label="Select Alpha"
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select Alpha" fullWidth />
      )}
    />
  )
}

AlphaAutoComplete.propTypes = {
  alphas: PropTypes.array,
  setAlphas: PropTypes.func,
  alpha: PropTypes.object,
  setAlpha: PropTypes.func
}

export default AlphaAutoComplete
