import { useState } from 'react'

const usePreventMultiClick = () => {
  const [clicked, setClicked] = useState(false)

  const preventMultiClick = (e) => {
    if (e.detail === 1) {
      setClicked(true)
    }
    setTimeout(() => {
      setClicked(false)
    }, 1000)
  }

  return {
    preventMultiClick,
    clicked
  }
}

export default usePreventMultiClick
