import { IconButton, Popover } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CustomPopover = (props) => {
  const { icon, children, disabled } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const id = open ? `simple-popover${Math.random()}` : undefined
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        disabled={disabled}
        color="primary"
        onClick={handleClick}
        aria-describedby={id}
      >
        {icon}
      </IconButton>
      <Popover
        sx={{
          my: 1,
          overflow: 'scroll',
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          borderRadius: '4px'
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {children}
      </Popover>
    </>
  )
}
CustomPopover.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  disabled: PropTypes.bool
}
export default CustomPopover
