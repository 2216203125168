import React, { useEffect, useState } from 'react'
import { useGetHook, usePostHook } from '../../hooks/fetchHook'
import { PrimaryButton } from '../../components/Landing/StyledComponents/StyledComponents'
import CityAutoComplete from '../../components/CityAutoComplete/CityAutoComplete'
import AlphaAutoComplete from '../../components/AlphaAutoComplete/AlphaAutoComplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import VendorAutoComplete from '../DriverList/VendorAutoComplete'
import { getLoggedInUser } from '../../utils/functions'

const AddTruckPlate = (props) => {
  const { open, onClose, setToast, refreshData, fixedVendor, fixedTruckSize } =
    props

  const [truckType, setTruckType] = useState('')
  const [truckSize, setTruckSize] = useState(
    fixedTruckSize ? fixedTruckSize.id : ''
  )
  const [plate, setPlate] = useState('')
  const [brand, setBrand] = useState('')
  const [vendor, setVendor] = useState({
    id: fixedVendor ? fixedVendor.userId : 0,
    title: fixedVendor ? fixedVendor.fullName : '',
    phone: fixedVendor ? fixedVendor.phone : ''
  })
  const [city, setCity] = useState({
    id: 0,
    title: ''
  })
  const [alpha, setAlpha] = useState({
    id: 0,
    title: ''
  })

  const [truckTypes, setTruckTypes] = useState([])
  const [truckSizes, setTruckSizes] = useState([])
  const [cities, setCities] = useState([])
  const [alphas, setAlphas] = useState([])
  const [vendors, setVendors] = useState([])
  const user = getLoggedInUser()

  useEffect(() => {
    if (!fixedTruckSize) {
      useGetHook(
        '/lf-supa/api/v1/public/truck-category',
        (res) => {
          setTruckTypes(res.data.data)
        },
        () => setToast('Something went wrong', 'error'),
        true
      )
    } else {
      setTruckSizes([
        {
          id: fixedTruckSize.id,
          nameEn: fixedTruckSize.nameEn
        }
      ])
    }

    if (!fixedVendor) {
      let url
      url = '/lf-sup/api/v1/admin/supplier'

      if (user.role === 'cluster_head' || user.role === 'kam') {
        url = '/lf-sup/api/v1/crm/supplier/list'
      }
      useGetHook(
        url,
        (res) => {
          let results = res.data.data.map((loc) => ({
            id: loc.userId,
            title: loc.fullName,
            phone: loc.phone,
            securitySponsor: loc.securitySponsor
          }))
          setVendors(results)
        },
        (err) => setToast(err?.response?.data?.message, 'error'),
        true
      )
    }

    let cityUrl = '/lf-supa/api/v1/license-city?page=1&limit=200'
    useGetHook(
      cityUrl,
      (res) => {
        let results = res.data.data.map((loc) => {
          return {
            id: loc.id,
            title: loc.label
          }
        })

        setCities(results)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
    let alphaUrl = '/lf-supa/api/v1/license-alpha'
    useGetHook(
      alphaUrl,
      (res) => {
        let results = res.data.data.map((loc) => {
          return {
            id: loc.id,
            title: loc.label
          }
        })
        setAlphas(results)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [fixedVendor, fixedTruckSize])

  const getTruckSizes = (id) => {
    let url = '/lf-supa/api/v1/public/truck-category/' + id
    useGetHook(
      url,
      (res) => {
        if (res.data.data?.truckSizes) setTruckSizes(res.data.data?.truckSizes)
      },
      () => setToast('Something went wrong', 'error')
    )
  }

  const onTruckTypeSelect = (e) => {
    setTruckType(e.target.value)
    getTruckSizes(e.target.value)
  }

  const saveChanges = () => {
    let payload = {
      supplierId: vendor.id,
      licensePlateNoEn: plate,
      truckSizeId: truckSize,
      licenseCityId: city.id,
      licenseAlphaId: alpha.id,
      truckTypeId: truckType.id
    }

    let url = '/lf-supa/api/v1/admin/truck'

    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = '/lf-supa/api/v1/crm/truck'
    }

    usePostHook(
      url,
      payload,
      (res) => {
        refreshData(res.data.data)
        setToast('Truck has been added', 'success')
        onClose(false)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5">Add Truck</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => onClose(false)}>
            <CloseIcon sx={{ fill: 'red' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Stack spacing={2}>
          <VendorAutoComplete
            vendors={vendors}
            vendor={vendor}
            setVendor={setVendor}
            setVendors={setVendors}
            disabled={fixedVendor ? true : false}
          />
          {!fixedTruckSize && (
            <FormControl fullWidth>
              <InputLabel>Truck Category</InputLabel>
              <Select
                value={truckType}
                label="Truck Category"
                onChange={onTruckTypeSelect}
              >
                {truckTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth>
            <InputLabel>Truck Size</InputLabel>
            <Select
              value={truckSize}
              label="Truck Category"
              onChange={(e) => setTruckSize(e.target.value)}
              disabled={fixedTruckSize ? true : false}
            >
              {truckSizes.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nameEn}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <CityAutoComplete
            cities={cities}
            city={city}
            setCity={setCity}
            setCities={setCities}
          />

          <AlphaAutoComplete
            setAlphas={setAlphas}
            alphas={alphas}
            alpha={alpha}
            setAlpha={setAlpha}
          />

          <TextField
            fullWidth
            value={plate}
            label="Truck Plate"
            type="number"
            onWheel={(event) => event.target.blur()}
            onChange={(e) => {
              setPlate(e.target.value)
            }}
            error={
              plate.length > 0
                ? plate.match(/^[0-9]{6}$/)
                  ? false
                  : true
                : false
            }
            helperText={
              plate.length > 0
                ? plate.match(/^[0-9]{6}$/)
                  ? ''
                  : 'Truck plate number must be 6 digits'
                : ''
            }
            onKeyDown={(e) =>
              ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
            }
          />

          <TextField
            fullWidth
            value={brand}
            label="Truck Brand"
            onChange={(e) => setBrand(e.target.value)}
          />
        </Stack>
        <Box mt={3} display="flex" alignItems="center">
          <Box flex={1}></Box>
          <Box flex={1}>
            <PrimaryButton
              disabled={!plate || !truckSize || vendor.id === 0}
              fullWidth
              variant="contained"
              onClick={saveChanges}
            >
              Save
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

AddTruckPlate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  refreshData: PropTypes.func,
  fixedVendor: PropTypes.object,
  fixedTruckSize: PropTypes.any
}

export default AddTruckPlate
