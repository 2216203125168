import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loader() {
  return (
    <Box p={2}>
      <Box
        display="flex"
        height="80vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  )
}
