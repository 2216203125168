import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'

const Remarks = ({ remarks }) => {
  return (
    <>
      <Box sx={{ py: 1, px: 2 }}>
        <Typography fontWeight={700} variant="subtitle1">
          Additional Payment Remarks
        </Typography>
      </Box>
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        gap={2}
      >
        <Typography color="textSecondary" variant="body2">
          {remarks || 'N/A'}
        </Typography>
      </Box>
    </>
  )
}

Remarks.propTypes = {
  tripId: PropTypes.string,
  remarks: PropTypes.string
}

export default Remarks
