import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { StyledButton } from '../StyledComponents/StyledComponents'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const TopHeader = () => {
  const navigate = useNavigate()
  const [signUpAnchorEl, setSignUpAnchorEl] = useState(null)
  // const [languageAnchorEl, setLanguageAnchorEl] = useState(null)

  const openSignup = Boolean(signUpAnchorEl)
  // const openLang = Boolean(languageAnchorEl)

  const handleClick = (event) => {
    setSignUpAnchorEl(event.currentTarget)
  }

  const handleClose = (route) => {
    setSignUpAnchorEl(null)
    navigate(route)
  }

  // const onLangChange = (event) => {
  //   setLanguageAnchorEl(event.currentTarget)
  // }

  // const closelangMenu = () => {
  //   setLanguageAnchorEl(null)
  // }

  return (
    <Box display="flex" gap={1} flexDirection="row-reverse" mt={4} mr={2}>
      <Box>
        {/* <LanguageButton
          id="lang-button"
          aria-controls="lang-menu"
          aria-haspopup="true"
          aria-expanded={openLang ? 'true' : undefined}
          onClick={onLangChange}
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
        >
          English
        </LanguageButton> */}
        {/* <Menu
          id="lang-menu"
          anchorEl={languageAnchorEl}
          open={openLang}
          onClose={closelangMenu}
          MenuListProps={{
            'aria-labelledby': 'lang-button'
          }}
        >
          <MenuItem onClick={closelangMenu}>Bangla</MenuItem>
        </Menu> */}
      </Box>
      <Box>
        <StyledButton
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={openSignup ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Sign Up
        </StyledButton>
        <Menu
          id="basic-menu"
          anchorEl={signUpAnchorEl}
          open={openSignup}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={() => handleClose('/signup/shipper')}>
            Shipper
          </MenuItem>
          <MenuItem onClick={() => handleClose('/signup/vendor')}>
            Vendor
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        <StyledButton variant="text" onClick={() => navigate('/')}>
          Login
        </StyledButton>
      </Box>
      <Box>
        <StyledButton variant="text" onClick={() => navigate('/')}>
          Home
        </StyledButton>
      </Box>
    </Box>
  )
}

export default TopHeader
