import { createTheme } from '@mui/material/styles'

const LoopThemeLight = createTheme({
  palette: {
    primary: {
      main: '#0071CD'
    }
  }
})

export default LoopThemeLight
