import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import Loader from '../../../components/Loader/Loader'
import { isEmptyArray, toQueryString } from '../../../utils/functions'
import PropTypes from 'prop-types'
import { useGetHook } from '../../../hooks/fetchHook'

export default function LineChartView({ setToast, date, chUserId }) {
  // const [{ data, loading }] = useFetch(url, true)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    setLoading(true)
    const query = {
      date,
      chUserId
    }

    let url = `/lf-analytics/api/v1/operational-metrics/history-chart${toQueryString(
      query
    )}`

    useGetHook(
      url,
      (res) => {
        setData(res.data.data)
        setLoading(false)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
        setLoading(false)
      },
      true
    )
  }, [date, chUserId])

  const lineChartData = []

  if (!isEmptyArray(data?.result)) {
    for (let i = 0; i < data.result.length; i++) {
      let { trip_month, trip_completed } = data.result[i]
      let item = {
        name: new Date(trip_month).toLocaleString('default', {
          month: 'long'
        }),
        trip_completed: Number(trip_completed)
      }
      lineChartData.push(item)
    }
  }
  return (
    <Box>
      {!loading && !data && (
        <Typography color="error" textAlign="center" variant="subtitle1">
          Sorry cant load data!
        </Typography>
      )}
      {loading && <Loader />}
      {!loading && data && (
        <>
          <Typography mt={3}>History Chart of Trips</Typography>
          <Box mt={3}>
            <ResponsiveContainer width={'100%'} height={250}>
              <LineChart data={lineChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="trip_completed"
                  stroke="#8884d8"
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </>
      )}
    </Box>
  )
}

LineChartView.propTypes = {
  date: PropTypes.string,
  chUserId: PropTypes.string,
  setToast: PropTypes.func
}
