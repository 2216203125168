import React, { useState, useEffect } from 'react'
import { useGetHook, usePostHook } from '../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import VendorAutoComplete from './VendorAutoComplete'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import CustomTextField from '../../components/CustomTextField/CustomTextField'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { getLoggedInUser } from '../../utils/functions'
import { AttachFile } from '@mui/icons-material'
import CustomDatePicker2 from '../../components/CustomDatePicker2'

const AddDriverModal = (props) => {
  const {
    onClose,
    open,
    setToast,
    refreshDriverList,
    fixedVendor,
    reloadData
  } = props
  const [dob, setDob] = useState('')
  const [refetch, setRefetch] = useState(false)
  const [vendors, setVendors] = useState([])
  const [nidImageFront, setNidImageFront] = useState({ name: '' })
  const [nidImageBack, setNidImageBack] = useState({ name: '' })
  const [vendor, setVendor] = useState({
    id: fixedVendor ? fixedVendor.userId : 0,
    title: fixedVendor ? fixedVendor.fullName : ''
  })
  const user = getLoggedInUser()

  const {
    register,
    formState: { errors, isValid },
    getValues,
    reset,
    setValue
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    let url = '/lf-sup/api/v1/admin/supplier'
    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = '/lf-sup/api/v1/crm/supplier/list'
    }
    useGetHook(
      url,
      (res) => {
        let results = res.data.data.map((loc) => {
          return {
            id: loc.userId,
            title: loc.fullName,
            phone: loc.phone,
            securitySponsor: loc.securitySponsor
          }
        })
        setVendors(results)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [refetch])

  const handlePickUpDateChange = (newValue) => setDob(newValue)

  const handleModalClose = () => {
    reset({
      fullName: '',
      phone: '',
      nickName: '',
      nid: ''
    })
    setDob(null)
    setNidImageFront({ name: '' })
    setNidImageBack({ name: '' })
    onClose(false)
  }

  const saveChanges = () => {
    const form = new FormData()
    form.append('fullName', getValues('fullName'))
    form.append('phone', getValues('phone'))
    form.append('supplierId', vendor.id)
    form.append('nickName', getValues('fullName'))
    form.append('vendorName', vendor.title)
    if (getValues('nid')) {
      form.append('nid', getValues('nid'))
    }
    if (dob) {
      form.append('dob', new Date(dob).toISOString())
    }
    if (nidImageFront.name) form.append('nidImageFront', nidImageFront)
    if (nidImageBack.name) form.append('nidImageBack', nidImageBack)

    let url = '/lf-supa/api/v1/admin/driver'

    if (user.role == 'cluster_head' || user.role == 'kam') {
      url = '/lf-supa/api/v1/crm/driver'
    }

    usePostHook(
      url,
      form,
      (res) => {
        setNidImageFront({ name: '' })
        setNidImageBack({ name: '' })
        refreshDriverList(res.data.data)
        reloadData && reloadData()
        setToast('Driver has been created', 'success')
        handleModalClose()
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }
  const onNIDFrontChange = (event) => setNidImageFront(event.target.files[0])
  const onNIDBackChange = (event) => setNidImageBack(event.target.files[0])
  const handleRefetch = () => setRefetch(!refetch)

  return (
    <Dialog fullWidth onClose={handleModalClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Add Driver</Typography>
        <IconButton onClick={handleModalClose}>
          <CloseIcon sx={{ fill: 'red' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={'Full Name*'}
              type="text"
              name={'fullName'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Name cannot be empty'
                },
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: 'Please Enter Full name as per NID'
                },
                maxLength: {
                  value: 50,
                  message: 'Name cannot be larger than 50 characters'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <VendorAutoComplete
              vendors={vendors}
              vendor={vendor}
              setVendor={setVendor}
              setVendors={setVendors}
              refetch={handleRefetch}
              disabled={fixedVendor ? true : false}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Phone*'}
              type="number"
              onWheel={(event) => event.target.blur()}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
              }
              name={'phone'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Phone cannot be empty'
                },
                maxLength: {
                  value: 11,
                  message: 'Phone cannot be larger than 11 characters'
                }
              }}
              fullWidth
              onPaste={(e) => {
                const phoneNumber = e.clipboardData
                  .getData('Text')
                  .replace(/-/g, '')
                setValue('phone', phoneNumber, { shouldValidate: true })
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'NID Number'}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
              }
              type="number"
              name={'nid'}
              register={register}
              errors={errors}
              criterions={{
                maxLength: {
                  value: 18,
                  message: 'NID number cannot be larger than 18 characters'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatePicker2
              defaultDate={dob ? dob : null}
              label="Date of Birth (DD/MM//YYYY)"
              onChange={handlePickUpDateChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Upload NID Front"
              value={nidImageFront.name}
              fullWidth
              InputProps={{
                endAdornment: (
                  <label htmlFor="nidFront">
                    <TextField
                      id="nidFront"
                      label="Upload NID Front"
                      onChange={onNIDFrontChange}
                      accept="image/*"
                      type="file"
                      sx={{ display: 'none' }}
                    />
                    <AttachFile />
                  </label>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Upload NID Back"
              value={nidImageBack.name}
              fullWidth
              InputProps={{
                endAdornment: (
                  <label htmlFor="nidBack">
                    <TextField
                      id="nidBack"
                      label="Upload NID Back"
                      onChange={onNIDBackChange}
                      accept="image/*"
                      type="file"
                      sx={{ display: 'none' }}
                    />
                    <AttachFile />
                  </label>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: '100%' }}
              disabled={!isValid}
              onClick={saveChanges}
              color="primary"
            >
              Save Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

AddDriverModal.propTypes = {
  reloadData: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setToast: PropTypes.func,
  refreshDriverList: PropTypes.func,
  fixedVendor: PropTypes.any
}

export default AddDriverModal
