import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const Stars = (props) => {
  return (
    <SvgIcon
      {...props}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M44.9625 7.49951C24.2625 7.49951 7.5 24.2995 7.5 44.9995C7.5 65.6995 24.2625 82.4995 44.9625 82.4995C65.7 82.4995 82.5 65.6995 82.5 44.9995C82.5 24.2995 65.7 7.49951 44.9625 7.49951ZM60.8625 67.4995L45 57.937L29.1375 67.4995L33.3375 49.462L19.35 37.3495L37.8 35.7745L45 18.7495L52.2 35.737L70.65 37.312L56.6625 49.4245L60.8625 67.4995Z" />
    </SvgIcon>
  )
}

export default Stars
