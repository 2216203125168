import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const StoppageIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-1.85615e-07 16C-8.31028e-08 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 -2.88128e-07 24.8366 -1.85615e-07 16Z"
        fill="#FFB400"
      />
      <path
        d="M16 6C12.13 6 9 9.13 9 13C9 18.25 16 26 16 26C16 26 23 18.25 23 13C23 9.13 19.87 6 16 6ZM16 15.5C14.62 15.5 13.5 14.38 13.5 13C13.5 11.62 14.62 10.5 16 10.5C17.38 10.5 18.5 11.62 18.5 13C18.5 14.38 17.38 15.5 16 15.5Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default StoppageIcon
