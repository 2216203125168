import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'
import { getPaymentGateways } from '../../../utils/functions'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'
import { PrimaryButton } from '../../../components/Landing/StyledComponents/StyledComponents'
import { usePutHook } from '../../../hooks/fetchHook'
import { useParams } from 'react-router-dom'
import { BANKS } from '../../../data/banks2'
import DistricAutoComplete from '../../../components/DistricAutoComplete'

const gateways = getPaymentGateways()

const PaymentInfo = ({ setPaymentInfo, paymentInfo, setToast }) => {
  const params = useParams()
  const [district, setDistrict] = useState({
    id: 1,
    title: paymentInfo?.generalBanking?.bankDistrict
  })

  const getBankName = () => {
    if (paymentInfo.generalBanking?.bankName) {
      let name = BANKS.find(
        (item) => item.value == paymentInfo.generalBanking?.bankName
      )
      return name.label
    }
    return ''
  }
  const [bank, setBank] = useState({
    value: paymentInfo.generalBanking?.bankName || '',
    label: getBankName()
  })
  const [gateway, setGateway] = useState(paymentInfo.mobileBanking?.type)
  const defaultProps = {
    options: BANKS,
    getOptionLabel: (option) => option.label
  }

  const {
    register,
    formState: { errors },
    getValues
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone: paymentInfo.mobileBanking?.phone,
      gateway: paymentInfo.mobileBanking?.type,
      accountHolderName: paymentInfo.generalBanking?.accountHolderName,
      accountNumber: paymentInfo.generalBanking?.accountNumber,
      branchName: paymentInfo.generalBanking?.branchName,
      routingNumber: paymentInfo.generalBanking?.routingNumber
    }
  })

  const updateVendorPayment = () => {
    const payload = {
      mobileBanking: {
        type: gateway,
        phone: getValues('phone')
      },
      generalBanking: {
        bankName: bank.value,
        branchName: getValues('branchName'),
        accountNumber: getValues('accountNumber'),
        accountHolderName: getValues('accountHolderName'),
        routingNumber: getValues('routingNumber'),
        bankDistrict: district.title
      }
    }

    if (!getValues('phone')) {
      delete payload.mobileBanking.phone
      payload.mobileBanking = null
    }

    let url = `/lf-user/api/v1/admin/user/${params.vendorId}/banking`
    usePutHook(
      url,
      payload,
      (res) => {
        setPaymentInfo(res.data.data)
        setToast('Payment Info has been updated successfully', 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    paymentInfo && (
      <>
        <Typography mt={2} gutterBottom variant="h6">
          Mobile Banking
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                List of Mobile Banking *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={gateway}
                label="List of Mobile Banking *"
                onChange={(e) => setGateway(e.target.value)}
              >
                {gateways.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Phone Number *'}
              type="number"
              onWheel={(event) => event.target.blur()}
              name={'phone'}
              register={register}
              errors={errors}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography mt={2} gutterBottom variant="h6">
          General Banking
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              {...defaultProps}
              value={bank}
              onChange={(event, newValue) => {
                setBank(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Bank Name*" />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Branch Name *'}
              type="text"
              name={'branchName'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Branch Name cannot be empty'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Routing Number *'}
              type="text"
              name={'routingNumber'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Routing Number cannot be empty'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Account Holder Number *'}
              type="text"
              name={'accountHolderName'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Account Holder Number cannot be empty'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Account Number *'}
              type="text"
              name={'accountNumber'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Account Number cannot be empty'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <DistricAutoComplete
              label="Bank District"
              district={district}
              setDistrict={setDistrict}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <PrimaryButton
              onClick={updateVendorPayment}
              disabled={false}
              variant="contained"
              fullWidth
            >
              Update
            </PrimaryButton>
          </Grid>
        </Grid>
      </>
    )
  )
}

PaymentInfo.propTypes = {
  setPaymentInfo: PropTypes.func,
  setToast: PropTypes.func,
  paymentInfo: PropTypes.object
}

export default PaymentInfo
