import { Box } from '@mui/material'
import React from 'react'
import BatchRowItemBox from './BatchRowItemBox'
import PropTypes from 'prop-types'
import { timesAgo } from '../../../utils/functions'
import humanizeNumber from 'humanize-number'
import GlobalButton from '../../../components/GlobalButton'
import { useNavigate } from 'react-router-dom'

const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return '#4CAF50'
    case 'pending':
      return '#ED6C02'
    default:
      return '#D50D11'
  }
}

const BatchRowItem = ({ data, onApplyClick }) => {
  const navigate = useNavigate()
  return (
    <Box
      display="flex"
      boxShadow="0px 4px 8px rgba(148, 163, 184, 0.24)"
      borderRadius="4px"
      mb={1}
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/home/finance/payable-batch-requests-item-list/${data.id}`)
      }}
    >
      <BatchRowItemBox title="Date" count={timesAgo(data.createdAt)} />
      <BatchRowItemBox
        title="Request Type"
        count={data.requestType === 'add_due' ? 'Due' : data.requestType}
        color="error"
      />
      <BatchRowItemBox
        title="Amount"
        count={`৳${humanizeNumber(Math.round(data.totalAmount), {
          delimiter: ',',
          separator: '.'
        })}`}
        color="#4CAF50"
      />
      <BatchRowItemBox title="Vendor Count" count={data.itemCount} />
      <BatchRowItemBox
        title="Pending"
        count={data.pendingCount}
        color="#FFB547"
      />
      <BatchRowItemBox
        title="Processing"
        count={data.processingCount}
        color="primary"
      />
      <BatchRowItemBox
        title="Completed"
        count={data.completedCount}
        color="#4CAF50"
      />
      <BatchRowItemBox title="Failed" count={data.failedCount} color="error" />
      <BatchRowItemBox
        title="Status"
        count={data.requestStatus}
        color={getStatusColor(data.requestStatus)}
      />
      <Box p={2} m="auto">
        <GlobalButton
          disabled={data.requestStatus !== 'pending'}
          variant="contained"
          name="Apply"
          onClick={(e) => {
            e.stopPropagation()
            onApplyClick(data.id)
          }}
        />
      </Box>
    </Box>
  )
}

BatchRowItem.propTypes = {
  data: PropTypes.object,
  onApplyClick: PropTypes.func
}

export default BatchRowItem

// {
//   "id": 10,
//   "requestType": "add_due",
//   "totalAmount": "0.000000",
//   "itemCount": 2,
//   "pendingCount": 0,
//   "processingCount": 0,
//   "completedCount": 2,
//   "failedCount": 0,
//   "requestStatus": "completed",
//   "createdAt": "2023-03-05T08:49:47.179Z"
// }
