import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ClockIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#F1F5F9"
      />
      <path
        d="M23.99 14C18.47 14 14 18.48 14 24C14 29.52 18.47 34 23.99 34C29.52 34 34 29.52 34 24C34 18.48 29.52 14 23.99 14ZM27.29 28.71L23 24.41V19H25V23.59L28.71 27.3L27.29 28.71Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default ClockIcon
