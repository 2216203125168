import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PosterColumn from './PosterColumn/PosterColumn'
import TopHeader from './TopHeader/TopHeader'
import {
  GridRoot,
  LeftGridColumn,
  RightGridColumn
} from './StyledComponents/StyledComponents'
import { useStore } from '../../store'

const Landing = () => {
  const { userStore } = useStore()
  const navigate = useNavigate()
  const [renderPage, setRenderPage] = useState(false)

  useEffect(() => {
    const detectLocalStorageChanges = () => {
      let token = localStorage.getItem('loop-token')

      setRenderPage(true)

      // ======== IF USER IS LOGGED IN ======== //
      if (token) {
        // ===== DECODE TOKEN FIRST TIME TOKEN SET ON LOCAL STORAGE ===== //
        const userProfile = jwt_decode(token)

        userStore.updateFullName(userProfile.fullName)
        userStore.updateProfilePhotoUrl(userProfile.profilePicture)

        if (userProfile.role === 'shipper') {
          navigate('/home/shipper/booking-details')
        } else if (userProfile.role === 'finance') {
          navigate('/home/finance')
        } else if (
          userProfile.role === 'kam' &&
          userProfile.team === 'vendor_team'
        ) {
          navigate('/home/vendor')
        } else if (userProfile.role === 'billing') {
          navigate('/home/billing')
        } else {
          navigate('/home/bookings')
        }
      }
    }

    detectLocalStorageChanges()

    window.addEventListener('storage', detectLocalStorageChanges)
  }, [])

  if (renderPage) {
    return (
      <GridRoot container>
        <LeftGridColumn item xs={6}>
          <PosterColumn />
        </LeftGridColumn>
        <RightGridColumn item xs={6}>
          <TopHeader />
          <Outlet />
        </RightGridColumn>
      </GridRoot>
    )
  }
  return <div></div>
}

export default Landing
