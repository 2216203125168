/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useGetHook, usePutHook } from '../../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'
import { useForm } from 'react-hook-form'
import {
  PrimaryButton,
  TruckTypeBox
} from '../../../components/Landing/StyledComponents/StyledComponents'
import UserAutoComplete from '../../../components/UserAutoComplete'
import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'
import OpenTruckIcon from '../../../components/SvgIcons/OpenTruck'
import CoveredVanIcon from '../../../components/SvgIcons/CoveredVan'
import TrailerTruckIcon from '../../../components/SvgIcons/TrailerTruck'
import DrumTruckIcon from '../../../components/SvgIcons/DrumTruck'
import FreezerVanIcon from '../../../components/SvgIcons/FreezerVan'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import { getLoggedInUser, isEmptyArray } from '../../../utils/functions'
import { userShape } from '../../../data/data'
import ActiveSlugList from '../../../components/ActiveSlugList'
import { subsTypes } from '../../../constants'
import CustomDatePicker2 from '../../../components/CustomDatePicker2'

const Profile = (props) => {
  const { vendor, setVendor, setToast } = props
  const params = useParams()
  const [dob, setDob] = useState(vendor.dob ? new Date(vendor.dob) : null)
  const handleChange = (newValue) => setDob(newValue)
  const [truckTypes, setTruckTypes] = useState([])
  let truckCats = vendor.truckCategories?.map((item) => ({
    ...item,
    id: item.truckCategoryId
  }))
  const [selectedTruckTypes, setSelectedTruckTypes] = useState(truckCats)
  // const [allDistricts, setAllDistricts] = useState([])
  // const [districtsToSave, setDistrictsToSave] = useState([])
  const [truckLocations, setTruckLocations] = useState([])
  const [truckLocationSearchStr, setTruckLocationSearchStr] = useState('')
  const denoucedTruckLoc = useDebouncedValue(truckLocationSearchStr, 500)
  // const locationContainerRef = useRef()
  const [truckStand, setTruckStand] = useState({
    id: vendor.originDistrict?.id,
    title: vendor.originDistrict?.nameEn
  })
  // const [editRoutes, setEditRoutes] = useState(false)
  // const [selectAllRoutes, setSelectAllRoutes] = useState(false)
  // const [originDistricts, setOriginDistricts] = useState(vendor.originDistricts)
  // const [divisionList, setDivisionList] = useState([])
  // const [divisionToDistrictsMap, setDivisionToDistrictsMap] = useState(
  //   new Map()
  // )
  // const [selectedDistrictsMap, setSelectedDistrictsMap] = useState(new Map())
  // const [assignClusterHead, setAssignClusterHead] = useState(
  //   vendor.assignCluster ? vendor.assignCluster : { ...userShape }
  // )
  const [assignKam, setAssignKam] = useState(
    vendor.assignKam ? vendor.assignKam : { ...userShape }
  )
  const [slug, setSlug] = useState(null)
  const [selectedSlugs, setSelectedSlugs] = useState(
    vendor.slugs ? vendor.slugs : []
  )
  const [makePayout, setMakePayout] = useState(vendor.makePayout)
  const [subsType, setSubsType] = useState(vendor.subsType)
  const user = getLoggedInUser()
  const [zone, setZone] = useState(vendor.zone)

  // const updateDataCacheToSavedState = (vendor, allDistricts) => {
  //   const savedDistricts = new Set()
  //   let selectedDistrictsMap = new Map()
  //   let districtsToSaveList = []
  //   vendor.districtPreferences.forEach((district) => {
  //     savedDistricts.add(district.districtId)
  //     districtsToSaveList.push(district.districtId)
  //   })

  //   for (const district of allDistricts) {
  //     const division = district.division
  //     //saved Divsions stored
  //     if (savedDistricts.has(district.id)) {
  //       // map for onClick toggling district and division
  //       let districtSet = new Set()
  //       if (selectedDistrictsMap.has(division.id)) {
  //         districtSet = selectedDistrictsMap.get(division.id)
  //       } else {
  //         selectedDistrictsMap.set(division.id, new Set())
  //         districtSet = selectedDistrictsMap.get(division.id)
  //       }
  //       districtSet.add(district.id)
  //     }
  //   }
  //   setDistrictsToSave(districtsToSaveList)
  //   setSelectedDistrictsMap(selectedDistrictsMap)
  // }

  // useEffect(() => {
  //   let url = '/lf-geolocation/api/v1/public/district?page=1&limit=64'
  //   useGetHook(
  //     url,
  //     (response) => {
  //       setAllDistricts(response.data.data)
  //       let divisionList = []
  //       let divisionToDistrictsMap = new Map()

  //       for (var district of response.data.data) {
  //         const division = district.division
  //         let districtList

  //         if (divisionToDistrictsMap.has(division.id)) {
  //           districtList = divisionToDistrictsMap.get(division.id)
  //         } else {
  //           divisionList.push(division)
  //           divisionToDistrictsMap.set(division.id, [])
  //           districtList = divisionToDistrictsMap.get(division.id)
  //         }
  //         districtList.push(district)
  //       }
  //       setDivisionList(divisionList)
  //       setDivisionToDistrictsMap(divisionToDistrictsMap)
  //       // updateDataCacheToSavedState(vendor, response.data.data)
  //     },
  //     (error) => console.log('err: ', error)
  //   )
  // }, [vendor])

  useEffect(() => {
    ///lf-geolocation/api/v1/public/district?nameEn=aka
    let url = '/lf-geolocation/api/v1/public/district'
    if (denoucedTruckLoc) {
      url = url + '?nameEn=' + denoucedTruckLoc
    }
    useGetHook(
      url,
      (res) => {
        let locations = res.data.data.map((loc) => {
          return {
            id: loc.id,
            title: loc.nameEn
          }
        })
        setTruckLocations(locations)
      },
      () => {
        // console.log('err: ', err)
      }
    )
  }, [denoucedTruckLoc])

  useEffect(() => {
    useGetHook(
      '/lf-supa/api/v1/public/truck-category',
      (res) => {
        setTruckTypes(res.data.data)
      },
      () => {}
    )
  }, [])

  const onTruckStandSelect = (newValue) => {
    if (newValue) {
      setTruckStand({
        ...newValue
      })
    } else {
      setTruckStand({
        id: 0,
        title: ''
      })
    }
  }

  const onTruckStandChange = (val) => {
    setTruckLocationSearchStr(val)
  }

  // const move = (dir) => {
  //   if (dir === 'right') {
  //     locationContainerRef.current.scrollLeft += 90
  //   } else locationContainerRef.current.scrollLeft -= 90
  // }

  const getTruckTypeIndex = (type) => {
    let idx = selectedTruckTypes?.findIndex((x) => x.id === type.id)
    return idx
  }

  const getSelectedCssClass = (type) => {
    let index = getTruckTypeIndex(type)
    if (index > -1) return { backgroundColor: '#0071CD', color: '#fff' }
    return ''
  }

  const {
    register,
    formState: { errors },
    getValues,
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      designation: 'vendor',
      tln: vendor.tln,
      companyName: vendor.companyName,
      email: vendor.email,
      fullName: vendor.fullName,
      phone: vendor.phone,
      nid: vendor.nid,
      vehicleProvide: vendor.vehicleProvide
    }
  })

  const watchFields = watch([
    'companyName',
    'fullName',
    'vehicleProvide',
    'tln'
  ])

  const filterSlectedTruckTypes = () => {
    const truckCatIds = selectedTruckTypes.map((item) => item.id)
    return truckCatIds.filter((item) => !!item)
  }

  const updateVendorProfile = () => {
    const data = {
      fullName: getValues('fullName'),
      // phone: getValues('phone'),
      tln: getValues('tln'),
      origin: truckStand.id,
      companyName: getValues('companyName'),
      designation: getValues('designation'),
      // districtPreference: districtsToSave,
      truckCategories: filterSlectedTruckTypes(),
      assignKamId: assignKam.userId,
      // assignClusterId: assignClusterHead.userId,
      vehicleProvide: getValues('vehicleProvide'),
      subsType,
      makePayout
    }

    if (zone) data['zone'] = zone
    if (!vendor.email && getValues('email')) {
      data['email'] = getValues('email')
    }

    if (!vendor.nid && getValues('nid')) {
      data['nid'] = getValues('nid')
    }

    if (!vendor.dob && dob) {
      data['dob'] = dob
    }

    let url = `/lf-sup/api/v1/admin/supplier/${params.vendorId}`
    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = `/lf-sup/api/v1/crm/supplier/${params.vendorId}`
    }
    usePutHook(
      url,
      data,
      (res) => {
        // updateDataCacheToSavedState(res.data.data, allDistricts)
        setTruckStand({
          id: '',
          title: ''
        })
        setVendor(res.data.data)
        // setEditRoutes(false)
        setToast('Vendor profile has been updated', 'success')
      },
      (error) => {
        setToast(error?.response?.data?.message, 'error')
      },
      true
    )
  }

  const toggleTruck = (truckType) => {
    let index = getTruckTypeIndex(truckType)
    if (index === -1) {
      setSelectedTruckTypes((oldItems) => [...oldItems, truckType])
    } else {
      let updatedTruckTypes = selectedTruckTypes.filter(
        (x) => x.id !== truckType.id
      )
      setSelectedTruckTypes(updatedTruckTypes)
    }
  }

  const getTruckIcon = (item) => {
    switch (item.truckCategoryEn) {
      case 'Open Truck':
        return <OpenTruckIcon sx={{ width: 40 }} />
      case 'Covered Van':
        return <CoveredVanIcon sx={{ width: 40 }} />
      case 'Trailer':
        return <TrailerTruckIcon sx={{ width: 40 }} />
      case 'Dump Truck':
        return <DrumTruckIcon sx={{ width: 40 }} />
      case 'Freezer van':
        return <FreezerVanIcon sx={{ width: 40 }} />
      default:
        return <FreezerVanIcon sx={{ width: 40 }} />
    }
  }

  // const handleEditRoutes = () => {
  //   if (editRoutes) {
  //     updateDataCacheToSavedState(vendor, allDistricts)
  //     setEditRoutes(false)
  //   } else {
  //     setEditRoutes(true)
  //   }
  // }

  // const handleOriginDelete = (origin) => {
  //   let url = `/lf-sup/api/v1/admin/vendor/${params.vendorId}/delete`

  //   let payload = {
  //     origin
  //   }

  //   usePutHook(
  //     url,
  //     payload,
  //     () => {
  //       let updateOrigins = originDistricts.filter(
  //         (x) => x.districtId !== origin
  //       )
  //       setOriginDistricts(updateOrigins)
  //     },
  //     (err) => setToast(err.response?.data?.message, 'error'),
  //     true
  //   )
  // }

  const handleSlugDelete = (slug) => {
    if (user.role !== 'cluster_head' || user.role !== 'kam') {
      let url = '/lf-sup/api/v1/admin/supplier/delete-tag'

      let payload = {
        slugId: slug.id
      }

      usePutHook(
        url,
        payload,
        () => {
          const filteredSlugs = selectedSlugs.filter(
            (item) => item.id !== slug.id
          )
          setSelectedSlugs(filteredSlugs)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }
  }

  // const getDistricts = () => {
  //   let districtsResult = []
  //   for (const [divisionId, districtsSet] of selectedDistrictsMap) {
  //     const districts = divisionToDistrictsMap.get(divisionId)

  //     let result = districts?.map((district) => {
  //       if (editRoutes) {
  //         return (
  //           <Button
  //             sx={{ minWidth: 'fit-content', fontWeight: '600' }}
  //             key={district.id}
  //             variant={districtsSet.has(district.id) ? 'contained' : 'outlined'}
  //             color={districtsSet.has(district.id) ? 'success' : 'primary'}
  //             onClick={() => editRoutes && updateDistricts(district)}
  //             endIcon={
  //               districtsSet.has(district.id) ? <CheckCircleIcon /> : null
  //             }
  //           >
  //             {district.nameEn}
  //           </Button>
  //         )
  //       } else if (districtsSet.has(district.id)) {
  //         return (
  //           <Button
  //             sx={{ minWidth: 'fit-content', fontWeight: '600' }}
  //             key={district.id}
  //             variant={'contained'}
  //             color={'success'}
  //             endIcon={<CheckCircleIcon />}
  //           >
  //             {district.nameEn}
  //           </Button>
  //         )
  //       }
  //     })
  //     if (result) districtsResult.push(...result)
  //   }

  //   // console.log('districtsResult', districtsResult)
  //   return districtsResult
  // }

  // const updateDivisions = (division) => {
  //   const selectedDistrictSet = selectedDistrictsMap.get(division.id)
  //   if (selectedDistrictSet) {
  //     // do unselect
  //     let newSavedDistrict = districtsToSave.filter(
  //       (val) => !selectedDistrictSet.has(val)
  //     )
  //     setDistrictsToSave(newSavedDistrict)
  //     setSelectedDistrictsMap((prev) => {
  //       const selectedDistricts = new Map(prev)
  //       selectedDistricts.delete(division.id)
  //       return selectedDistricts
  //     })
  //   } else {
  //     // do select
  //     if (!selectedDistrictsMap.has(division.id)) {
  //       setSelectedDistrictsMap((prev) => {
  //         const selectedDistricts = new Map(prev)
  //         selectedDistricts.set(division.id, new Set())
  //         return selectedDistricts
  //       })
  //     }
  //   }
  // }

  // const updateDistricts = (district) => {
  //   const divisionId = district.division.id
  //   const selectedDistrictsSet = selectedDistrictsMap.get(divisionId)
  //   const isDistrictSelected = selectedDistrictsSet.has(district.id)
  //   if (isDistrictSelected) {
  //     //do unselect district
  //     setDistrictsToSave((prev) =>
  //       prev.filter((districtId) => districtId !== district.id)
  //     )
  //     setSelectedDistrictsMap((prev) => {
  //       const selectedDistricts = new Map(prev)
  //       const selectedDistrictsSet2 = selectedDistricts.get(divisionId)
  //       selectedDistrictsSet2.delete(district.id)
  //       if (selectedDistrictsSet2.size === 0) {
  //         selectedDistricts.delete(divisionId)
  //       }
  //       return selectedDistricts
  //     })
  //   } else {
  //     // do select district
  //     if (selectedDistrictsMap.has(divisionId)) {
  //       setSelectedDistrictsMap((prev) => {
  //         const selectedDistricts = new Map(prev)
  //         const selectedDistrictsSet2 = selectedDistricts.get(divisionId)
  //         selectedDistrictsSet2.add(district.id)
  //         return selectedDistricts
  //       })
  //       setDistrictsToSave((prev) => [...prev, district.id])
  //     }
  //   }
  // }

  // const onSelectAllRoutes = () => {
  //   if (selectAllRoutes) {
  //     // do unselect all
  //     const vendor = {
  //       districtPreferences: []
  //     }
  //     updateDataCacheToSavedState(vendor, allDistricts)
  //     setSelectAllRoutes(false)
  //   } else {
  //     // do select all
  //     const vendor = {
  //       districtPreferences: allDistricts.map((item) => ({
  //         ...item,
  //         districtId: item.id
  //       }))
  //     }
  //     updateDataCacheToSavedState(vendor, allDistricts)
  //     setSelectAllRoutes(true)
  //   }
  // }

  // const onSelectedSlugDelete = (slug) => {
  //   setSelectedSlugs((prev) =>
  //     prev.filter((prevSlug) => prevSlug.companyId !== slug.companyId)
  //   )
  // }

  const didTruckTypeChanged = () => {
    const truckCatIds = vendor.truckCategories
      .map((item) => item.truckCategoryId)
      .sort()
    const selectedTruckIds = selectedTruckTypes.map((item) => item.id).sort()

    return (
      JSON.stringify([...new Set(truckCatIds)]) !==
      JSON.stringify([...new Set(selectedTruckIds)])
    )
  }

  const shouldEnableBtn = () => {
    return !(
      vendor.zone !== zone ||
      vendor.assignKam.userId !== assignKam.userId ||
      // vendor.assignCluster.userId !== assignClusterHead.userId ||
      vendor.makePayout !== makePayout ||
      vendor.subsType !== subsType ||
      vendor.originDistrict?.id !== truckStand.id ||
      vendor.companyName !== watchFields[0] ||
      vendor.fullName !== watchFields[1] ||
      vendor.vehicleProvide !== parseInt(watchFields[2]) ||
      vendor.tln !== watchFields[3] ||
      didTruckTypeChanged()
    )
  }

  const businessCluster =
    user.role === 'cluster_head' && user.team === 'business_team'

  return (
    <>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={'Company Name*'}
              type="text"
              name={'companyName'}
              register={register}
              errors={errors}
              fullWidth
              disabled={businessCluster}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Full Name*'}
              type="text"
              name={'fullName'}
              register={register}
              errors={errors}
              fullWidth
              disabled={businessCluster}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Designation (optional)'}
              type="text"
              name={'designation'}
              register={register}
              errors={errors}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Phone Number*'}
              type="text"
              name={'phone'}
              register={register}
              errors={errors}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Email'}
              type="text"
              name={'email'}
              register={register}
              errors={errors}
              disabled={getValues('email') || businessCluster}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Trade License Number'}
              type="number"
              name={'tln'}
              register={register}
              errors={errors}
              fullWidth
              disabled={businessCluster}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'NID Number'}
              type="text"
              name={'nid'}
              register={register}
              errors={errors}
              fullWidth
              disabled={getValues('nid') || businessCluster}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomDatePicker2
              label="Date of Birth"
              onChange={handleChange}
              defaultDate={vendor.dob}
              disableFuture
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={businessCluster}
              value={truckStand}
              onChange={(event, newValue) => {
                onTruckStandSelect(newValue)
              }}
              onInputChange={(event, newInputValue) => {
                onTruckStandChange(newInputValue)
              }}
              options={truckLocations}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Where your trucks are located"
                />
              )}
            />
            {/* <Box
              mt={2}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              gap={1}
            >
              <Typography variant="subtitle1">Saved zones:</Typography>
              {!isEmptyArray(originDistricts) &&
                originDistricts.map((item, i) => (
                  <Chip
                    key={i}
                    size="small"
                    color="primary"
                    label={item.nameEn}
                    onDelete={() => handleOriginDelete(item.districtId)}
                  />
                ))}
              {isEmptyArray(originDistricts) && (
                <Typography color="error" variant="subtitle2">
                  No saved zones found!!
                </Typography>
              )}
            </Box> */}
          </Grid>
          <Grid item xs={6}>
            <FormControl
              disabled={businessCluster}
              sx={{ minWidth: 140 }}
              fullWidth
            >
              <InputLabel id="demo-simple-select-label">Zone</InputLabel>
              <Select
                value={zone}
                label="Zone"
                onChange={(e) => setZone(e.target.value)}
              >
                {['gazipur', 'narayanganj', 'khulna', 'chittagong'].map(
                  (item, i) => (
                    <MenuItem key={i} value={item}>
                      <Typography variant="body1" textTransform={'capitalize'}>
                        {item}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography mb={2} variant="h6">
              Assign
            </Typography>
            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <UserAutoComplete
                  user={assignClusterHead}
                  setValue={setAssignClusterHead}
                  label="Assign Clusterhead "
                  team=""
                  role="cluster_head"
                  required={true}
                />
              </Grid> */}
              <Grid item xs={6}>
                <UserAutoComplete
                  user={assignKam}
                  setValue={setAssignKam}
                  label="Assign VAT"
                  team="vendor_team"
                  role="kam"
                  required={true}
                  disabled={businessCluster}
                  // parentUserId={assignClusterHead.userId}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={2} variant="h6">
              Special Tag & Truck Capacity
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ActiveSlugList
                  slug={slug}
                  setSlug={setSlug}
                  setSelectedSlugs={setSelectedSlugs}
                  label="Search Company for Special tag"
                  required={true}
                  setToast={setToast}
                  disabled={businessCluster}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label="Vendor Truck Capacity Per Day"
                  type="number"
                  name="vehicleProvide"
                  register={register}
                  errors={errors}
                  fullWidth
                  disabled={businessCluster}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="body1">Saved Tags:</Typography>
                  {!isEmptyArray(selectedSlugs) &&
                    selectedSlugs.map((item, i) => (
                      <Chip
                        key={i}
                        size="small"
                        color="primary"
                        label={item.text || 'N/A'}
                        onDelete={() => handleSlugDelete(item)}
                      />
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={2} variant="h6">
              Make Payout & Membership
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl disabled={businessCluster} fullWidth>
                  <InputLabel>Payout Day</InputLabel>
                  <Select
                    value={makePayout}
                    label="Payout Day"
                    onChange={(e) => setMakePayout(e.target.value)}
                  >
                    <MenuItem value={'monday'}>Monday</MenuItem>
                    <MenuItem value={'wednesday'}>Wednesday</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl disabled={businessCluster} fullWidth>
                  <InputLabel>Membership</InputLabel>
                  <Select
                    value={subsType}
                    label="Membership"
                    onChange={(e) => setSubsType(e.target.value)}
                  >
                    {Object.keys(subsTypes).map((type, i) => (
                      <MenuItem key={i} value={subsTypes[type]}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Your routes */}
          {/* <Grid item xs={12}>
            {editRoutes && (
              <Box
                display="flex"
                mt={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant={selectAllRoutes ? 'contained' : 'outlined'}
                  startIcon={<CheckCircleIcon fontSize="small" />}
                  color={'primary'}
                  size="small"
                  onClick={onSelectAllRoutes}
                >
                  Select all routes
                </Button>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              mt={2}
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography variant="h6">Your routes</Typography>
                {!editRoutes ? (
                  <IconButton sx={{ bgcolor: '#F6FAFD' }} color="primary">
                    {<EditIcon onClick={handleEditRoutes} />}
                  </IconButton>
                ) : (
                  <IconButton sx={{ bgcolor: '#F6FAFD' }} color="primary">
                    {<ClearIcon onClick={handleEditRoutes} />}
                  </IconButton>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                width="100%"
                mt={1}
              >
                <Box>
                  <IconButton variant="contained" onClick={() => move('left')}>
                    <ChevronLeftIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton variant="contained" onClick={() => move('right')}>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              gap={1}
              mt={1}
              ref={locationContainerRef}
              sx={{
                maxWidth: '800px',
                overflow: 'scroll',
                scrollBehavior: 'smooth'
              }}
            >
              {!isEmptyArray(divisionList) &&
                divisionList.map((division) => {
                  if (editRoutes) {
                    return (
                      <Button
                        sx={{ minWidth: 'fit-content', fontWeight: '600' }}
                        key={division.id}
                        variant={
                          selectedDistrictsMap.has(division.id)
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => editRoutes && updateDivisions(division)}
                        endIcon={
                          selectedDistrictsMap.has(division.id) ? (
                            <CheckCircleIcon />
                          ) : null
                        }
                      >
                        {division.nameEn}
                      </Button>
                    )
                  } else if (selectedDistrictsMap.has(division.id)) {
                    return (
                      <Button
                        sx={{ minWidth: 'fit-content', fontWeight: '600' }}
                        key={division.id}
                        variant={'contained'}
                        endIcon={<CheckCircleIcon />}
                      >
                        {division.nameEn}
                      </Button>
                    )
                  }
                })}
            </Box>
            <Box mt={3} display="flex" gap={2} flexWrap="wrap">
              {getDistricts()}
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box mt={3}>
              <Typography variant="h6" component="div">
                Your truck type
              </Typography>
              <Box display="flex" gap={2} mt={2}>
                {truckTypes.map((item) => (
                  <TruckTypeBox
                    key={item.id}
                    onClick={() => toggleTruck(item)}
                    sx={getSelectedCssClass(item)}
                  >
                    {getTruckIcon(item)}
                    <Box>
                      <Typography variant="body2">{item.nameEn}</Typography>
                    </Box>
                  </TruckTypeBox>
                ))}
              </Box>
            </Box>
            {/* <Box 
              display="flex"
              gap={1}
              mt={1}
            >
              {!isEmptyArray(divisionList) &&
                divisionList.map((division) => {
                  if (editRoutes) {
                    return (
                      <Button
                        sx={{ minWidth: 'fit-content', fontWeight: '600' }}
                        key={division.id}
                        variant={
                          selectedDistrictsMap.has(division.id)
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => editRoutes && updateDivisions(division)}
                        endIcon={
                          selectedDistrictsMap.has(division.id) ? (
                            <CheckCircleIcon />
                          ) : null
                        }
                      >
                        {division.nameEn}
                      </Button>
                    )
                  } else if (selectedDistrictsMap.has(division.id)) {
                    return (
                      <Button
                        sx={{ minWidth: 'fit-content', fontWeight: '600' }}
                        key={division.id}
                        variant={'contained'}
                        endIcon={<CheckCircleIcon />}
                      >
                        {division.nameEn}
                      </Button>
                    )
                  }
                })}
            </Box>
            <Box mt={3} display="flex" gap={2} flexWrap="wrap">
              {getDistricts()}
            </Box>
            */}
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <PrimaryButton
              // disabled={true}
              variant="contained"
              onClick={updateVendorProfile}
              fullWidth
              disabled={shouldEnableBtn()}
            >
              Update
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

Profile.propTypes = {
  setVendor: PropTypes.func,
  vendor: PropTypes.object,
  setToast: PropTypes.func
}

export default Profile
