import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { getLoggedInUser } from '../../../utils/functions'
import { useGetHook } from '../../../hooks/fetchHook'
import Box from '@mui/material/Box'
import TripCard from './TripCard'
import { useStore } from '../../../store'
import { useParams } from 'react-router-dom'
import UserListTitle from '../../../components/UserListTitle'

const TripsByBooking = (props) => {
  const { booking, setToast } = props
  const params = useParams()
  const { bookingStore } = useStore()
  const [trips, setTrips] = useState([])
  const [recall, setRecall] = useState(false)

  useEffect(() => {
    let user = getLoggedInUser()
    let url = `/lf-booking/api/v1/admin/booking/${booking.id}/trips`

    if (user?.team === 'vendor_team' && params.vendorId)
      url += `?vendorId=${params.vendorId}`

    useGetHook(
      url,
      (res) => {
        setTrips(res.data.data.trips)
        bookingStore.updateBookingDetails(res.data.data)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [recall])

  return (
    <Box p={2} mb={1}>
      <Box display="flex" alignItems="center">
        <UserListTitle count={trips.length} name="Trips" />
      </Box>

      {trips.length > 0 ? (
        <>
          {trips.map((item) => (
            <TripCard
              key={item.id}
              data={item}
              booking={booking}
              setToast={setToast}
              setRecall={setRecall}
            />
          ))}
        </>
      ) : (
        <Box mt={1}>
          <Typography>No Data Available</Typography>
        </Box>
      )}
    </Box>
  )
}

TripsByBooking.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func
}

export default TripsByBooking
