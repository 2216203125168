/* eslint-disable indent */
import {
  Avatar,
  Chip,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import FinanceDrawer from '../FinanceDrawer'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import './companyCard.css'
import PropTypes from 'prop-types'

const CompanyCard = ({ data, setCompanies }) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [formulaStatus, setFormulaStatus] = useState(data.formula)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const closeDrawer = () => {
    setShowDrawer(false)
  }
  return (
    <>
      <Box p={1} className="paper_root">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" p={2} gap={2} alignItems="center">
              <Box>
                <Avatar
                  sx={{ width: '80px', height: '80px' }}
                  alt={data.fullName}
                  src={
                    data.companyPicture
                      ? data.companyPicture
                      : data.profilePicture
                      ? data.profilePicture
                      : 'abc'
                  }
                />
              </Box>
              <Box>
                <Typography variant="body1" color="primary" fontWeight={800}>
                  {data.companyName}
                </Typography>
                <Typography variant="subtitle2" fontWeight={800}>
                  {data.fullName}
                </Typography>
                <Typography className="company-contact">
                  {data.phone}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Box>
                <Chip
                  label={formulaStatus ? 'Applied' : 'Not Applied'}
                  color={formulaStatus ? 'success' : 'error'}
                  sx={{ mb: 1, width: '100px' }}
                />
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  fontSize={'14px'}
                  className="status_subtitle"
                >
                  Formula
                </Typography>
              </Box>
              <MoreVertIcon id="basic-menu12" onClick={handleClick} />
            </Box>

            <Menu
              id="basic-menu12"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setShowDrawer(true)
                  handleClose()
                }}
              >
                Apply Formula
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor={'right'} open={showDrawer} onClose={closeDrawer}>
        <FinanceDrawer
          onClose={closeDrawer}
          data={data}
          setFormulaStatus={setFormulaStatus}
          setCompanies={setCompanies}
        />
      </Drawer>
    </>
  )
}

CompanyCard.propTypes = {
  data: PropTypes.object,
  setCompanies: PropTypes.func
}

export default CompanyCard
