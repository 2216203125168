import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

const PreviewModal = (props) => {
  const { open, onClose, dismiss, cropAndUploadPhoto } = props
  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <div style={{ maxWidth: '100%', height: '100%' }}>
        <img
          id="output"
          alt="none"
          style={{ display: 'block', maxWidth: '100%', height: '100%' }}
        />
      </div>
      <Box p={2} display="flex" gap={1} flexDirection="row-reverse">
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={cropAndUploadPhoto}
          >
            Crop and Save Photo
          </Button>
        </Box>
        <Box>
          <Button color="error" onClick={dismiss}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

PreviewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dismiss: PropTypes.func,
  cropAndUploadPhoto: PropTypes.func
}

export default PreviewModal
