import jwt_decode from 'jwt-decode'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Snackbars from '../../../components/Snackbar/Snackbar'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'
import { useForm } from 'react-hook-form'

import { usePutHook } from '../../../hooks/fetchHook'
import { PrimaryButton } from '../../../components/Landing/StyledComponents/StyledComponents'
import { patterns } from '../../../utils/functions'
import { useStore } from '../../../store'

const Profile = (props) => {
  const { userStore } = useStore()
  const { userDetail, setUserDetail } = props
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const {
    register,
    formState: { errors, isValid },
    getValues,
    setError,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      full_name: userDetail.fullName,
      zone: userDetail.zone,
      designation: userDetail.designation,
      phone: userDetail.phone,
      department: userDetail.department,
      email: userDetail.email
    }
  })

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const setManualError = (name, message) => {
    setError(name, {
      type: 'manual',
      message
    })
  }

  const validateFields = () => {
    let valid = true
    if (getValues('full_name').trim() === '') {
      setManualError('full_name', 'Full Name cannot be empty')
      return false
    }
    return valid
  }

  const saveChanges = handleSubmit(() => {
    let isValid = validateFields()
    if (!isValid) return
    let formData = {
      fullName: getValues('full_name')
    }
    if (!userDetail.email) formData['email'] = getValues('email')

    let url = '/lf-user/api/v1/users'
    usePutHook(
      url,
      formData,
      (res) => {
        setToast('User profile updated', 'success')
        let { fullName, phone, email } = res.data.data.user
        setUserDetail((s) => ({
          ...s,
          fullName,
          phone,
          email
        }))
        localStorage.setItem('loop-token', res.data.data.accessToken)
        localStorage.setItem('loop-refresh-token', res.data.data.refreshToken)
        let userProfile = jwt_decode(res.data.data.accessToken)
        localStorage.setItem('loop-user', JSON.stringify(userProfile))
        userStore.updateFullName(res.data.data.user.fullName)
      },
      (error) => setToast(error?.response?.data?.message, 'error'),
      true
    )
  })

  return (
    <>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={'Full Name*'}
              type="text"
              name={'full_name'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Name cannot be empty'
                },
                maxLength: {
                  value: 50,
                  message: 'Name cannot be larger than 50 characters'
                }
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Phone Number*'}
              type="text"
              name={'phone'}
              disabled
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Phone Number cannot be empty'
                },
                maxLength: {
                  value: 14,
                  message: 'Phone Number cannot be larger than 14 characters'
                }
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Email Address'}
              type="text"
              name={'email'}
              disabled={userDetail.email}
              register={register}
              errors={errors}
              fullWidth
              criterions={{
                required: {
                  value: true,
                  message: 'Email cannot be empty'
                },
                pattern: {
                  value: patterns.valid_email,
                  message: 'Invalid Email'
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              style={{ height: '100%' }}
              variant="contained"
              fullWidth
              disabled={!isValid}
              onClick={saveChanges}
            >
              Save Changes
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

Profile.propTypes = {
  userDetail: PropTypes.object,
  setUserDetail: PropTypes.func
}

export default Profile
