import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const NoteIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#F1F5F9"
      />
      <path
        d="M31 15H16.99C15.89 15 15 15.9 15 17L15.01 31C15.01 32.1 15.9 33 17 33H27L33 27V17C33 15.9 32.1 15 31 15ZM19 20H29V22H19V20ZM24 26H19V24H24V26ZM26 31.5V26H31.5L26 31.5Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default NoteIcon
