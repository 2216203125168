import React, { useState, useEffect } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Vendor from './Vendor'
import Snackbars from '../../components/Snackbar/Snackbar'
import useDebouncedValue from '../../hooks/useDebounceValue'
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  getLoggedInUser,
  isEmptyArray,
  toQueryString
} from '../../utils/functions'
import { subsTypes } from '../../constants'
import UserListTitle from '../../components/UserListTitle'
import { establishedOffices } from '../../data/data'

const VendorList = () => {
  const [page, setPage] = useState(1)
  const [vendors, setVendors] = useState([])
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [isVerified, setIsVerified] = useState(null)
  const [districts, seDistricts] = useState([])
  const [district, setDistrict] = useState('')
  const [isEnabled, setEnabled] = useState(null)
  const [subsType, setSubsType] = useState('')
  const [zone, setZone] = useState('')
  const [kw, setKw] = useState('')
  const debounedKw = useDebouncedValue(kw, 500)
  const user = getLoggedInUser()

  useEffect(() => {
    let url = '/lf-geolocation/api/v1/public/district'

    useGetHook(
      url,
      (res) => {
        let locations = res.data.data.map((loc) => {
          return {
            id: loc.id,
            title: loc.nameEn
          }
        })
        seDistricts(locations)
      },
      () => {}
    )
  }, [])

  useEffect(() => {
    let queryObject = {
      page,
      limit: 10,
      isVerified: `${isVerified !== null ? isVerified : ''}`,
      isEnabled: `${isEnabled !== null ? isEnabled : ''}`,
      query: debounedKw,
      originDistrict: district,
      // assignCluster: clusterHead,
      subsType,
      zone
    }

    let url = `/lf-sup/api/v1/admin/supplier${toQueryString(queryObject)}`
    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = `/lf-sup/api/v1/crm/supplier/list${toQueryString(queryObject)}`
    }

    useGetHook(
      url,
      (res) => {
        setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setVendors(res.data.data)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [zone, page, debounedKw, district, isVerified, isEnabled, subsType])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={3}>
          <Box>
            <UserListTitle count={total} name="Vendors" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            alignItems="center"
          >
            {/* <ClusterHeadSelect onChange={onClusterHeadChange} /> */}

            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Zone</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={zone}
                label="Zone"
                onChange={(e) => setZone(e.target.value)}
              >
                {establishedOffices.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label24">
                Membership Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label24"
                id="demo-simple-select24"
                value={subsType}
                label="Membership Type"
                onChange={(e) => {
                  setSubsType(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.keys(subsTypes).map((key) => (
                  <MenuItem key={key} value={subsTypes[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label26">
                Select Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label26"
                id="demo-simple-select26"
                value={isEnabled}
                label="Select Mode"
                onChange={(e) => {
                  setEnabled(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label24">
                Select Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label24"
                id="demo-simple-select24"
                value={isVerified}
                label="Select Status"
                onChange={(e) => {
                  setIsVerified(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={true}>Verified</MenuItem>
                <MenuItem value={false}>Not Verified</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label25">
                Select District
              </InputLabel>
              <Select
                labelId="demo-simple-select-label25"
                id="demo-simple-select25"
                value={district}
                label="Select Status"
                onChange={(e) => {
                  setDistrict(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={null}>All</MenuItem>
                {districts?.map((item) => (
                  <MenuItem key={item.id} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              color="primary"
              size="small"
              sx={{ minWidth: 120 }}
              label="Search Vendors"
              value={kw}
              fullWidth
              onChange={(e) => {
                setKw(e.target.value)
                setPage(1)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        {isEmptyArray(vendors) && (
          <Box display="flex" p={2} alignItems="center" justifyContent="center">
            <Typography color="error" textAlign="center" variant="sutitle1">
              No data found!
            </Typography>
          </Box>
        )}
        {!isEmptyArray(vendors) &&
          vendors.map((item) => <Vendor key={item.id} data={item} />)}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default VendorList
