import { Close, Search } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import AddressCard from './AddressCard'
import './index.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useGetHook } from '../../hooks/fetchHook'
import { isEmptyArray } from '../../utils/functions'
import { getAccessLevel } from '../../accesscontrol'
import useDebouncedValue from '../../hooks/useDebounceValue'
import useToast from '../../hooks/useToast'
import Snackbars from '../../components/Snackbar/Snackbar'
import CreateAddressModal from './CreateAddressModal'
import UserListTitle from '../../components/UserListTitle'

const Address = () => {
  const [address, setAddress] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState()
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setLoading(true)
    let url = '/lf-geolocation/api/v1/admin/address'
    if (page) url += `?page=${page}&count=10`
    if (debouncedSearch) url += `&text=${debouncedSearch}`
    if (filter) url += `&undefined=${filter}`

    useGetHook(
      url,
      (res) => {
        setAddress(res.data.data.result)
        setTotal(res.data.data.total)
        let count = Math.ceil(res.data.data.total / 10)
        setPageCount(count)
        setLoading(false)
      },
      () => {
        setLoading(false)
      },
      true
    )
  }, [debouncedSearch, page, filter, refetch])
  const handleRefetch = () => {
    setRefetch(!refetch)
  }
  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleFilterSubmit = (e, name) => {
    e.preventDefault()
    const value = e.target[name].value
    setFilter(value)
  }
  const handleModalClose = () => setOpenModal(false)
  return (
    <Box p={2}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignitems="center">
          <Typography variant="h5">Address</Typography>
          <UserListTitle count={total} name="" />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            onClick={() => setOpenModal(true)}
            color="primary"
            fullWidth
            variant="contained"
            disabled={!getAccessLevel('addNewAddress')}
          >
            Add New Address
          </Button>
          <TextField
            color="primary"
            size="small"
            label="Search Address"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <IconButton>
            <FilterAltIcon
              color={filter ? 'primary' : 'inherit'}
              onClick={handleClick}
            />
          </IconButton>
          <Popover
            sx={{ marginTop: 2 }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box p={1} width="230px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography fontWeight={'bold'} variant="subtitle1">
                  Filter
                </Typography>
                <IconButton onClick={handleClose}>
                  <Close sx={{ fill: '#F82012' }} />
                </IconButton>
              </Box>
              <Divider />
              <form
                style={{ width: '100%' }}
                onSubmit={(e) => handleFilterSubmit(e, 'filter')}
              >
                <Box
                  width="100%"
                  py={2}
                  display="flex"
                  gap={2}
                  flexDirection="column"
                >
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Filter</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Filter"
                      name="filter"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="shortFormEn">
                        Unidentified Short FormEn
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Stack direction="row" gap={1}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => setFilter('')}
                    >
                      Reset
                    </Button>

                    <Button
                      type="submit"
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Apply
                    </Button>
                  </Stack>
                </Box>
              </form>
            </Box>
          </Popover>
        </Box>
      </Box>

      {loading && (
        <Stack
          p={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && isEmptyArray(address) && (
        <Typography mt={2} textAlign="center">
          No data found
        </Typography>
      )}
      {!loading &&
        !isEmptyArray(address) &&
        address.map((item, i) => (
          <AddressCard key={i} data={item} setToast={setToast} />
        ))}
      {!loading && (
        <Box display="flex" justifyContent="end" alignItems="center" py={2}>
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      )}
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CreateAddressModal
        handleRefetch={handleRefetch}
        openModal={openModal}
        handleModalClose={handleModalClose}
        setToast={setToast}
      />
    </Box>
  )
}

export default Address
