import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const CityAutoComplete = (props) => {
  const { cities, city, setCity } = props

  const onCitySelect = (newValue) => {
    if (newValue) {
      setCity({
        ...newValue
      })
    } else {
      setCity({
        id: 0,
        title: ''
      })
    }
  }

  return (
    <Autocomplete
      value={city}
      fullWidth
      onChange={(event, newValue) => {
        onCitySelect(newValue)
      }}
      options={cities}
      label="Select City"
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select City" fullWidth />
      )}
    />
  )
}

CityAutoComplete.propTypes = {
  cities: PropTypes.array,
  city: PropTypes.object,
  setCity: PropTypes.func
}

export default CityAutoComplete
