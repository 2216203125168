/* eslint-disable indent */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import {
  getFeedName,
  getFeedNumber,
  isEmptyObject,
  toQueryString
} from '../../utils/functions'
import { establishedOffices } from '../../data/data'
import FeedCard from '../../components/FeedCard'
import NoSearchResult from '../../components/NoSearchResult'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'

const minDate = dayjs('2020-01-01T00:00:00.000')
const maxDate = dayjs('2025-01-01T00:00:00.000')

const DailyFeed = () => {
  const [dailyFeeds, setDailyFeeds] = useState(null)
  const [zone, setZone] = useState('')
  const [month, setMonth] = useState(
    dayjs(`2023-${new Date().getMonth() + 1}-02`)
  )

  const onDateChange = (newVal) => setMonth(newVal)

  useEffect(() => {
    const query = {
      zone,
      startDate: new Date(month).toISOString()
    }

    let url = `/lf-fdr/api/v1/supplier-feed/summary${toQueryString(query)}`
    useGetHook(
      url,
      (res) => {
        setDailyFeeds(res.data.data)
      },
      () => {},
      true
    )
  }, [zone, month])

  return (
    <Box p={2}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Typography variant="h5">Monthly Feed Monitoring</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <FormControl sx={{ minWidth: 140 }} size="small">
            <InputLabel id="demo-simple-select-label">Zone</InputLabel>
            <Select
              value={zone}
              label="Zone"
              onChange={(e) => setZone(e.target.value)}
            >
              {establishedOffices.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year']}
              label="Year and Month"
              minDate={minDate}
              maxDate={maxDate}
              value={month}
              onChange={onDateChange}
              renderInput={(params) => (
                <TextField size="small" {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {isEmptyObject(dailyFeeds) && <NoSearchResult />}
      <Grid container spacing={2}>
        {!isEmptyObject(dailyFeeds) &&
          Object.keys(dailyFeeds).map((feed, i) => (
            <Grid key={i} item xs={12} lg={3}>
              <FeedCard
                number={getFeedNumber(dailyFeeds, feed)}
                title={getFeedName(feed)}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default DailyFeed
