import React from 'react'
import PropTypes from 'prop-types'
import { FunnelChart, Funnel, LabelList, ResponsiveContainer } from 'recharts'

export default function FunnelChartView({ data: funnel }) {
  const data = [
    {
      value: 1700,
      name: 'Trips in Flow: ' + (funnel.dailyTripFlow || 'N/A'),
      fill: '#0057B0'
    },
    {
      value: 1500,
      name: 'Quotation: ' + (funnel.quotation || 'N/A'),
      fill: '#0071CD'
    },
    {
      value: 1300,

      name: 'Rate Accepted: ' + (funnel.rateAccepted || 'N/A'),
      fill: '#389EE1'
    },
    {
      value: 1100,
      name: 'Successful Trips: ' + (funnel.successfulTrips || 'N/A'),
      fill: '#60BFF0'
    }
  ]

  return (
    <ResponsiveContainer height={350}>
      <FunnelChart>
        {/* <Tooltip /> */}
        <Funnel dataKey="value" data={data}>
          <LabelList
            position="inside"
            fill="#fff"
            stroke="none"
            dataKey="name"
          />
        </Funnel>
      </FunnelChart>
    </ResponsiveContainer>
  )
}

FunnelChartView.propTypes = {
  data: PropTypes.object
}
