import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledFlexDiv = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}))

export const ParentDiv = styled(Box)(() => ({
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))
