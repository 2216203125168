import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import VendorBasicInfo from './VendorBasicInfo'
import Snackbars from '../../components/Snackbar/Snackbar'
import VendorProfileTabsContainer from './VendorProfileTabsContainer'
import { useNavigate } from 'react-router-dom'
import { getLoggedInUser } from '../../utils/functions'

const VendorProfile = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [vendor, setVendor] = useState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const user = getLoggedInUser()

  useEffect(() => {
    let url = `/lf-sup/api/v1/admin/supplier/${params.vendorId}`
    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = `/lf-sup/api/v1/crm/supplier/${params.vendorId}`
    }
    useGetHook(
      url,
      (res) => setVendor(res.data.data),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  return (
    <>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="body1">Vendor Profile</Typography>
          </Box>
          {/* <Box>
            <Button variant="contained">Add Vendor</Button>
          </Box> */}
        </Box>
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {vendor && (
                <VendorBasicInfo vendor={vendor} setToast={setToast} />
              )}
            </Grid>
            <Grid item xs={9}>
              {vendor && (
                <VendorProfileTabsContainer
                  vendor={vendor}
                  setVendor={setVendor}
                  setToast={setToast}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default VendorProfile
