import axios from 'axios'
import {
  checkTokenVerification,
  refreshTokenAndResetLocalStorage
} from './token'

let refreshingToken = null

const axiosApiInstance = axios.create()

const interceptorFunc = async (config) => {
  let loggedIn = localStorage.getItem('loop-token')

  // ========= WAIT FOR TOKEN TO BE REFRESHED ========= //
  if (refreshingToken !== null) {
    config.headers.Authorization = await refreshingToken
    refreshingToken = null
  }

  let tokenVerification = checkTokenVerification()

  if (loggedIn && !tokenVerification) {
    refreshingToken = refreshTokenAndResetLocalStorage()
    try {
      config.headers.Authorization = await refreshingToken
      refreshingToken = null
    } catch (error) {
      // console.log(error)
    }
  }

  return config
}

axiosApiInstance.interceptors.request.use(interceptorFunc, (error) => {
  return Promise.reject(error)
})

export default axiosApiInstance
