import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import importDataBg from '../../../assests/importDataBg.svg'
import UploadModal from '../UploadModal'

const ImportData = () => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  return (
    <Box
      bgcolor="#FFFFFF"
      boxShadow="0px 0px 30px -6px rgba(0, 0, 0, 0.15)"
      border="20px"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      gap={2}
      py={4}
    >
      <img src={importDataBg} alt="import data" />
      <Typography variant="body1">Upload Excel or CSV updated file </Typography>
      <Button size="large" onClick={onOpen} variant="contained">
        Upload Data
      </Button>
      <UploadModal open={open} onClose={onClose} />
    </Box>
  )
}

export default ImportData
