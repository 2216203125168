import { TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AttachFile } from '@mui/icons-material'
import { usePostHook } from '../../../hooks/fetchHook'
import GlobalButton from '../../../components/GlobalButton'

const UploadChallan = ({ tripId, setToast }) => {
  const [challanImage, setChallanImage] = useState({ name: '' })

  const onChallanImageUpload = () => {
    let url = '/lf-booking/api/v1/admin/booking/receipt-upload'
    const form = new FormData()
    form.append('tripId', tripId)
    form.append('receiptUrl', challanImage)
    usePostHook(
      url,
      form,
      () => {
        setChallanImage({ name: '' })
        setToast('Challan Uploaded Successfully', 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box sx={{ bgcolor: '#0071CD', py: 1, px: 2 }}>
        <Typography variant="subtitle1" color="#fff">
          Upload Challan
        </Typography>
      </Box>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        gap={2}
      >
        <Typography color="textSecondary" variant="body2">
          Trip ID: {tripId}
        </Typography>
        <TextField
          label="Upload Challan"
          value={challanImage.name}
          fullWidth
          InputProps={{
            endAdornment: (
              <label htmlFor="challanImage">
                <TextField
                  id="challanImage"
                  label="Upload Challan"
                  onChange={(e) => setChallanImage(e.target.files[0])}
                  accept="image/*"
                  type="file"
                  sx={{ display: 'none' }}
                />
                <AttachFile />
              </label>
            )
          }}
        />
        <GlobalButton
          size="large"
          onClick={onChallanImageUpload}
          variant="contained"
          name="Submit Now"
          disabled={!challanImage.name}
        />
      </Box>
    </>
  )
}

UploadChallan.propTypes = {
  tripId: PropTypes.object,
  setToast: PropTypes.func
}

export default UploadChallan
