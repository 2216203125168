import React from 'react'
import { Button, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'

function GlobalButton({ name, onClick, disabled, iconButton, ...rest }) {
  const [isDisabled, setIsDisabled] = useState(false)

  return iconButton ? (
    <IconButton
      {...rest}
      onClick={(e) => {
        setIsDisabled(true)
        onClick(e)
        setTimeout(() => {
          setIsDisabled(false)
        }, 2000)
      }}
      disabled={isDisabled || disabled}
    >
      {name}
    </IconButton>
  ) : (
    <Button
      {...rest}
      onClick={(e) => {
        setIsDisabled(true)
        onClick(e)
        setTimeout(() => {
          setIsDisabled(false)
        }, 2000)
      }}
      disabled={isDisabled || disabled}
    >
      {name}
    </Button>
  )
}

GlobalButton.propTypes = {
  name: PropTypes.string | PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconButton: PropTypes.bool
}

export default GlobalButton
