/* eslint-disable indent */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { getAccessLevel } from '../../../accesscontrol'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { usePutHook } from '../../../hooks/fetchHook'
import {
  formatStage,
  getLoggedInUser,
  getTripStatusColor,
  isEmptyObject
} from '../../../utils/functions'
import InfoIcon from '@mui/icons-material/Info'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import Avatar from '@mui/material/Avatar'
import TruckPlateAutoComplete from './TruckPlateAutoComplete'
import DriverAutoComplete from './DriverAutoComplete'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CancelTripModal from './CancelTripModal'

import { Close, Done, PostAdd } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import CustomPopover from '../../../components/CustomPopover'
import UploadChallan from '../../LatestBookings/ChallanComponents/UploadChallan'
import DemurrageViewModal from '../../LatestBookings/ChallanComponents/DemurrageViewModal'
import Demurrage from '../../LatestBookings/ChallanComponents/Demurrage'
import ChallanViewModal from '../../LatestBookings/ChallanComponents/ChallanViewModal'
import Remarks from '../../../components/AdditionalPaymentRemarks/Remarks'
import AdditionalPaymentRemarks from '../../../components/AdditionalPaymentRemarks'

const TripCard = (props) => {
  const { booking, data, setToast, setRecall } = props
  const user = getLoggedInUser()
  const [tripStatus, setTripStatus] = useState(data.tripStatus)
  const [tripStatusByKam, setTripStatusByKam] = useState('')
  const [openCancelModal, toggleOpen] = useState(false)
  const [truckPlate, setTruckPlate] = useState({
    id: data.truck?.id ? data.truck.id : 0,
    title: data.truck?.licensePlateNoTextEn
      ? data.truck.licensePlateNoTextEn
      : ''
  })
  const [driver, setDriver] = useState({
    id: data.driver?.id ? data.driver.id : 0,
    title: data.driver?.fullName ? data.driver.fullName : '',
    phone: data.driver?.phone ? data.driver.phone : ''
  })
  const [vendor, setVendor] = useState({
    id: data.bidding?.vendor?.userId ? data.bidding.vendor.userId : 0,
    title: data.bidding?.vendor?.fullName
      ? data.bidding?.vendor?.fullName
      : 'N/A'
  })
  const [fareAmount, setFareAmount] = useState(
    data.fareAmount ? data.fareAmount : 'N/A'
  )
  const [challanOpen, setChallanOpen] = useState(false)
  const [currTripData, setCurrTripData] = useState(false)
  const [openDemurrageModal, toggleOpenDemurrageModal] = useState(false)
  const [type, setType] = useState('demurrage')
  const [openDemurrageExtraPayViewModal, toggleOpenDemurrageExtraPayModal] =
    useState(false)

  const [rowEdit, setRowEdit] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  // const [anchorEl3, setAnchorEl3] = useState(null)
  const [anchorEl4, setAnchorEl4] = useState(null)
  const [anchorEl5, setAnchorEl5] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const saveChanges = () => {
    let payload = {
      tripId: data.id,
      truckId: truckPlate.id ? truckPlate.id : null,
      driverId: driver.id ? driver.id : null,
      vendorId: vendor.id ? vendor.id : null,
      fareAmount
    }

    let url = '/lf-booking/api/v1/admin/booking/activate-trip/'

    usePutHook(
      url,
      payload,
      (res) => {
        setToast('Trip information has been updated', 'success')
        setRowEdit(false)
        setTripStatus(res.data.data.tripStatus)
        setRecall((prev) => !prev)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const updateTripStatus = () => {
    let url = '/lf-booking/api/v1/admin/booking/trip-update'

    let payload = {
      tripStatus: tripStatusByKam,
      tripId: data.id
    }
    usePutHook(
      url,
      payload,
      (res) => {
        setToast('Trip status has been updated', 'success')
        setTripStatus(res.data.data.tripStatus)
        // handleClose3()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const cancelChanges = () => {
    setTruckPlate({
      id: data.truck?.id ? data.truck.id : 0,
      title: data.truck?.licensePlateNoTextEn
        ? data.truck.licensePlateNoTextEn
        : 'N/A'
    })
    setDriver({
      id: data.driver?.id ? data.driver.id : 0,
      title: data.driver?.fullName ? data.driver.fullName : 'N/A',
      phone: data.driver?.phone ? data.driver.phone : 'N/A'
    })
    setVendor({
      id: data.bidding?.vendor?.userId ? data.bidding.vendor.userId : 0,
      title: data.bidding?.vendor?.fullName
        ? data.bidding?.vendor?.fullName
        : 'N/A'
    })
    setFareAmount(data.fareAmount ? data.fareAmount : 'N/A')
    setRowEdit(false)
  }

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
  }

  // const handleClick3 = (event) => {
  //   setAnchorEl3(event.currentTarget)
  // }

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget)
  }
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClose2 = () => {
    setAnchorEl2(null)
  }

  // const handleClose3 = () => {
  //   setAnchorEl3(null)
  // }

  const handleClose4 = () => {
    setAnchorEl4(null)
  }
  const handleClose5 = () => {
    setAnchorEl5(null)
  }

  const refreshTruckList = (data) => {
    let newItem = {
      id: data.id,
      title: data.licensePlateNoTextEn
    }
    setTruckPlate(newItem)
  }

  const refreshDriverList = (data) => {
    let newItem = {
      id: data.id,
      title: data.fullName,
      phone: data.phone
    }
    setDriver(newItem)
  }

  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  // const open3 = Boolean(anchorEl3)
  const open4 = Boolean(anchorEl4)
  const open5 = Boolean(anchorEl5)

  const id = open ? 'simple-popover' : undefined
  const id2 = open ? 'simple-popover2' : undefined
  // const id3 = open ? 'simple-popover3' : undefined
  const id4 = open ? 'simple-popover4' : undefined
  const id5 = open ? 'simple-popover5' : undefined

  const vendorCluster =
    user.role === 'cluster_head' && user.team === 'vendor_team'

  return (
    <>
      <Box>
        <Box
          className={`booking-metadata-box ${
            data.tripType === 'return_trip' ? 'return_trip' : ''
          }`}
          mt={2}
        >
          <Box
            p={1}
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography>Trip ID: </Typography>
            </Box>
            <Box flexGrow={3}>
              <Typography color="primary">&nbsp;{data.id}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                onClick={handleClick5}
                disabled={!data.cancelReason}
                size="small"
              >
                <StickyNote2Icon
                  sx={{ color: data.cancelReason ? 'red' : '' }}
                />
              </IconButton>
              <Typography variant="body2">Cancel Notes</Typography>
            </Box>
            <Popover
              id={id5}
              open={open5}
              anchorEl={anchorEl5}
              onClose={handleClose5}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <Box
                p={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography fontWeight={'bold'} variant="subtitle1">
                    Cancellation Note
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={handleClose5}>
                    <CloseIcon sx={{ fill: '#F82012' }} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Typography p={1} sx={{ cursor: 'pointer' }}>
                {data.cancelReason || 'N/A'}
              </Typography>
            </Popover>
            {data.tripType === 'return_trip' && (
              <Chip
                label={
                  <Typography textAlign="center" variant="body2">
                    R
                  </Typography>
                }
                size="small"
                sx={{
                  bgcolor: '#F82012',
                  color: '#fff',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  mx: 1
                }}
              />
            )}
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Chip
                label={formatStage(tripStatus)}
                sx={{
                  background: getTripStatusColor(tripStatus)['bg'],
                  color: getTripStatusColor(tripStatus)['color']
                }}
              />
              <Box>
                {user.role !== 'shipper' && (
                  <IconButton
                    color="primary"
                    onClick={handleClick4}
                    aria-describedby={id4}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id4}
                  open={open4}
                  anchorEl={anchorEl4}
                  onClose={handleClose4}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  {getAccessLevel('cancelTrip') &&
                    tripStatus !== 'completed' &&
                    tripStatus !== 'cancelled' &&
                    tripStatus !== 'expired' && (
                      <Typography
                        p={1}
                        sx={{ cursor: 'pointer' }}
                        color="red"
                        onClick={() => toggleOpen(true)}
                      >
                        Cancel
                      </Typography>
                    )}
                  <Typography
                    p={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      setType('demurrage')
                      toggleOpenDemurrageModal(true)
                      e.stopPropagation()
                    }}
                  >
                    Demurrage
                  </Typography>
                  <Typography
                    p={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      setType('extra_cost')
                      toggleOpenDemurrageModal(true)
                      e.stopPropagation()
                    }}
                  >
                    Extra Payment
                  </Typography>
                </Popover>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box p={1}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} md={2}>
                {user.role !== 'shipper' && (
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Typography variant="subtitle1">Vendor</Typography>
                      </Box>
                      <Box ml={1}>
                        <InfoIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Typography variant="subtitle1" color="primary">
                          {vendor.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">Driver Info</Typography>
                  </Box>
                  <Box ml={1}>
                    <InfoIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                  </Box>
                </Box>
                <Box display="flex" flexGrow={1} alignItems="center">
                  <Box>
                    {!rowEdit ? (
                      <Typography variant="subtitle2" color="primary">
                        {driver.title
                          ? `${driver.title} : ${driver.phone}`
                          : 'N/A'}
                      </Typography>
                    ) : (
                      <DriverAutoComplete
                        setToast={setToast}
                        driver={driver}
                        setDriver={setDriver}
                        fixedVendor={data?.bidding?.vendor}
                        vendorId={vendor.id}
                        refreshDriverList={refreshDriverList}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">Truck Plate</Typography>
                  </Box>
                  <Box ml={1}>
                    <InfoIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    {!rowEdit ? (
                      <Typography variant="subtitle2" color="primary">
                        {truckPlate.title ? truckPlate.title : 'N/A'}
                      </Typography>
                    ) : (
                      <TruckPlateAutoComplete
                        setToast={setToast}
                        vendorId={vendor.id}
                        truckPlate={truckPlate}
                        setTruckPlate={setTruckPlate}
                        booking={booking}
                        refreshTruckList={refreshTruckList}
                        fixedVendor={data?.bidding?.vendor}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                {user.role !== 'shipper' && (
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Typography variant="subtitle1">
                          Truck Amount
                        </Typography>
                      </Box>
                      <Box ml={1}>
                        <InfoIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        {!rowEdit ? (
                          <Typography variant="subtitle2" color="primary">
                            {fareAmount}
                          </Typography>
                        ) : (
                          <TextField
                            disabled
                            type="number"
                            onWheel={(event) => event.target.blur()}
                            size="small"
                            value={fareAmount}
                            onChange={(e) => setFareAmount(e.target.value)}
                            onKeyDown={(e) =>
                              ['e', 'E', '+', '-', '.'].includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={1}>
                <Box display="flex" alignItems="center">
                  {!rowEdit ? (
                    <Box>
                      {getAccessLevel('activeTrip') && (
                        <IconButton
                          onClick={() => setRowEdit(true)}
                          disabled={
                            tripStatus === 'completed' ||
                            tripStatus === 'cancelled' ||
                            user.role === 'shipper'
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Box>
                        <IconButton onClick={saveChanges}>
                          <DoneIcon sx={{ fill: '#4CAF50' }} />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton onClick={cancelChanges}>
                          <CloseIcon sx={{ fill: '#F82012' }} />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider />
          <Box
            display="flex"
            p={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Box>
                <Avatar
                  aria-describedby={id}
                  onClick={handleClick}
                  sx={{
                    bgcolor: isEmptyObject(data.loadingAgent)
                      ? '#9E9E9E'
                      : '#0071CD',
                    cursor: isEmptyObject(data.loadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.loadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                >
                  L
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Loading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {booking.loadingAgent?.fullName
                        ? booking.loadingAgent?.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {booking.loadingAgent?.phone
                        ? booking.loadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              <Box ml={1}>
                <Avatar
                  sx={{
                    bgcolor: isEmptyObject(data.unloadingAgent)
                      ? '#9E9E9E'
                      : '#F82012',
                    cursor: isEmptyObject(data.unloadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.unloadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                  aria-describedby={id2}
                  onClick={handleClick2}
                >
                  U
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Unloading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {booking.unloadingAgent?.fullName
                        ? booking.unloadingAgent.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {booking.unloadingAgent?.phone
                        ? booking.unloadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              {getAccessLevel('showRemarks') && (
                <>
                  <Box flexGrow={1} display="flex" gap={1} alignItems="center">
                    <CustomPopover
                      disabled={user.role !== 'kam'}
                      icon={<PostAdd color="info" size="small" />}
                    >
                      {data.paymentRemark ? (
                        <Remarks remarks={data.paymentRemark} />
                      ) : (
                        <AdditionalPaymentRemarks
                          tripId={data.id}
                          setToast={setToast}
                        />
                      )}
                    </CustomPopover>
                    <Typography color="primary" variant="body2">
                      {data.paymentRemark ? `${data.paymentRemark}...` : ''}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Box display="flex" gap={2} alignItems="center">
              {(tripStatus === 'completed' || tripStatus === 'cancelled') && (
                <Box display="flex" gap={1} alignItems="center">
                  <Chip
                    sx={{
                      bgcolor: '#ED6C02',
                      color: '#FFFFFF'
                    }}
                    label={'C'}
                    disabled={data.receiptStatus == 'pending'}
                    onClick={(e) => {
                      e.stopPropagation()
                      setCurrTripData(data)
                      setChallanOpen(true)
                    }}
                  />

                  <Chip
                    color="primary"
                    label={'D'}
                    disabled={!data.supplierDemurrage}
                    onClick={(e) => {
                      e.stopPropagation()
                      setType('demurrage')
                      toggleOpenDemurrageExtraPayModal(true)
                    }}
                  />

                  <Chip
                    sx={{
                      color: '#FFFFFF'
                    }}
                    color="error"
                    label={'E'}
                    disabled={!data.supplierExtraCost}
                    onClick={(e) => {
                      e.stopPropagation()
                      setType('extra_cost')
                      toggleOpenDemurrageExtraPayModal(true)
                    }}
                  />

                  {user.role === 'kam' && user.team === 'vendor_team' && (
                    <CustomPopover
                      icon={
                        <Button
                          size="small"
                          sx={{
                            bgcolor: '#E5F1FA',
                            color: '#0071CD',
                            borderRadius: '40px'
                          }}
                          color="primary"
                          disabled={user.role !== 'kam'}
                          variant="outlined"
                        >
                          Upload Challan
                        </Button>
                      }
                    >
                      <UploadChallan tripId={data.id} setToast={setToast} />
                    </CustomPopover>
                  )}
                </Box>
              )}
              {(vendorCluster
                ? data.tripStatus !== 'completed'
                : getAccessLevel('changeTripStatus')) && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FormControl
                    fullWidth
                    sx={{ minWidth: 140 }}
                    disabled={
                      !getAccessLevel('changeTripStatus') ||
                      // !getAccessLevel('activeTrip') ||
                      data.tripStatus === 'completed'
                    }
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label23">
                      Select Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label23"
                      id="demo-simple-select23"
                      value={tripStatusByKam}
                      label="Select Status"
                      onChange={(e) => {
                        setTripStatusByKam(e.target.value)
                      }}
                    >
                      <MenuItem
                        disabled={data.tripStatus === 'on_way'}
                        value={'goods_loaded'}
                      >
                        Goods Loaded
                      </MenuItem>
                      <MenuItem value={'goods_unloaded'}>
                        Goods Unloaded
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box>
                    <IconButton
                      disabled={!getAccessLevel('updateTripStatus')}
                      onClick={updateTripStatus}
                    >
                      <Done sx={{ fill: '#4CAF50' }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton onClick={() => setTripStatusByKam('')}>
                      <Close sx={{ fill: '#F82012' }} />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {challanOpen && (
        <ChallanViewModal
          tripData={currTripData}
          open={challanOpen}
          onClose={() => {
            setChallanOpen(false)
          }}
          setToast={setToast}
        />
      )}
      {openDemurrageExtraPayViewModal && (
        <DemurrageViewModal
          tripData={data}
          open={openDemurrageExtraPayViewModal}
          onClose={() => {
            toggleOpenDemurrageExtraPayModal(false)
          }}
          type={type}
          setToast={setToast}
        />
      )}
      <Demurrage
        onClose={() => {
          toggleOpenDemurrageModal(false)
        }}
        open={openDemurrageModal}
        setToast={setToast}
        tripId={data.id}
        type={type}
      />
      <CancelTripModal
        open={openCancelModal}
        setToast={setToast}
        onClose={() => toggleOpen(false)}
        tripId={data.id}
        setTripStatus={(val) => setTripStatus(val)}
        handleClose={handleClose4}
      />
    </>
  )
}

TripCard.propTypes = {
  setRecall: PropTypes.func,
  booking: PropTypes.object,
  data: PropTypes.object,
  setTruckPlates: PropTypes.func,
  drivers: PropTypes.array,
  setDrivers: PropTypes.func,
  setToast: PropTypes.func,
  defaultDrivers: PropTypes.array
}

export default TripCard
