import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import DocumentItem from './DocumentItem'
import Typography from '@mui/material/Typography'
import { useGetHook, usePostHook } from '../../../hooks/fetchHook'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { documentTypes } from '../../../data/data'

const Input = styled('input')({
  display: 'none'
})

const Documents = (props) => {
  const { booking, setToast } = props
  const [type, setType] = useState('invoice')
  const [loading, setLoading] = useState(false)
  const [tripId, setTripId] = useState('')
  const [file, setFile] = useState(null)
  const [documents, setDocuments] = useState([])
  const [trips, setTrips] = useState([])

  useEffect(() => {
    //BOOKING DOCS API CALL
    let bookingDocsApi = `/lf-booking/api/v1/admin/booking/${booking.id}/document`

    useGetHook(
      bookingDocsApi,
      (res) => setDocuments(res.data.data),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )

    //TRIP OPTIONS API CALL
    let url = `/lf-booking/api/v1/admin/booking/${booking.id}/trips`

    useGetHook(
      url,
      (res) => setTrips(res.data.data.trips),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  const onFileChange = (event) => setFile(event.target.files[0])

  const getDocumentsByTripId = (e) => {
    let id = e.target.value
    setTripId(id)
    setLoading(true)
    let url = `/lf-booking/api/v1/admin/booking/document?tripId=${id}`

    useGetHook(
      url,
      (res) => {
        setDocuments(res.data.data)
        setLoading(false)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
        setLoading(false)
      },
      true
    )
  }

  const saveChanges = () => {
    const formData = new FormData()
    formData.append('docUrl', file, file.name)
    formData.append('tripId', tripId)
    formData.append('type', type)
    let url = '/lf-booking/api/v1/admin/booking/document'

    usePostHook(
      url,
      formData,
      (res) => {
        let uploadedDoc = res.data.data
        setDocuments((s) => [...s, uploadedDoc])
        setToast(res?.data?.message, 'success')
        setFile(null)
        setType()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            {loading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {documents.length > 0 ? (
                  <>
                    {documents.map((item) => (
                      <DocumentItem data={item} key={item.id} />
                    ))}
                  </>
                ) : (
                  <Typography variant="h5" gutterBottom component="div">
                    No Documents Available
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={5}>
            <Box mb={3}>
              <FormControl fullWidth>
                <InputLabel>Select Document Type</InputLabel>
                <Select
                  value={type}
                  label="Select Document Type"
                  onChange={(e) => setType(e.target.value)}
                >
                  {documentTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <InputLabel>Select Trip ID</InputLabel>
                <Select
                  value={tripId}
                  label="Select Trip ID"
                  onChange={getDocumentsByTripId}
                >
                  {trips.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      ID#{item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={3}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={onFileChange}
                />
                <Button variant="contained" fullWidth component="span">
                  Upload Document {file ? `(${file.name})` : ''}
                </Button>
              </label>
            </Box>
            <Box>
              <Button
                variant="contained"
                fullWidth
                component="span"
                disabled={!file || !type || !tripId}
                onClick={saveChanges}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

Documents.propTypes = {
  setToast: PropTypes.func,
  booking: PropTypes.object
}

export default Documents
