/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { Dialog, Divider, IconButton, Typography } from '@mui/material'
import { isEmptyArray, timesAgo } from '../../../utils/functions'
import { useGetHook } from '../../../hooks/fetchHook'
import Carousel from 'react-material-ui-carousel'
import { Fragment } from 'react'

const ChallanViewModal = (props) => {
  const { onClose, open, tripData, setToast } = props
  const [receiptData, setReceiptData] = useState([])
  const [createdAt, setCreatedAt] = useState('')
  const [receiptUrl, setReceiptUrl] = useState('')

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/receipt-upload?tripId=${tripData.id}`

    useGetHook(
      url,
      (res) => {
        setReceiptData(res.data.data)
        setCreatedAt(res.data.data[0]?.createdAt)
        setReceiptUrl(res.data.data[0]?.receiptUrl)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
      },
      true
    )
  }, [tripData.id])

  const onImageChange = (now) => {
    setCreatedAt(receiptData[now]?.createdAt)
    setReceiptUrl(receiptData[now]?.receiptUrl)
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        flexDirection={'row'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection={'row'} alignItems="center">
          <Typography variant="subtitle1">Trip ID: &nbsp;</Typography>
          <Typography variant="subtitle1" color="primary">
            {tripData.id}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={'row'} alignItems="center">
          <Typography variant="subtitle1">Upload Date:&nbsp;</Typography>
          <Typography variant="subtitle1">{timesAgo(createdAt)}</Typography>
        </Box>
        <Box display="flex" flexDirection={'row'} gap={2} alignItems="center">
          <a target="_blank" rel="noreferrer" href={receiptUrl} download>
            <IconButton>
              <DownloadIcon
                sx={{
                  fill: '#fff',
                  backgroundColor: '#0071CD',
                  borderRadius: '50%',
                  padding: '4px',
                  cursor: 'pointer'
                }}
              />
            </IconButton>
          </a>

          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fill: 'red' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box
        component={'div'}
        sx={{ width: 'auto', height: 'auto', padding: '10px' }}
      >
        {!isEmptyArray(receiptData) && (
          <Carousel
            onChange={onImageChange}
            autoPlay={false}
            navButtonsAlwaysVisible={!isEmptyArray(receiptData)}
            indicators
            fullHeightHover={false}
          >
            {receiptData.map((item) => (
              <Fragment key={item.id}>
                {item.receiptUrl.split('.')[
                  item.receiptUrl.split('.').length - 1
                ] == 'pdf' ? (
                  <object
                    data={item.receiptUrl}
                    type="application/pdf"
                    height="600"
                    width="100%"
                  >
                    <iframe
                      loading="lazy"
                      height="600"
                      width="100%"
                      src={`https://docs.google.com/viewer?url=${item.receiptUrl}&embedded=true`}
                    ></iframe>
                  </object>
                ) : (
                  <Box
                    component="img"
                    src={item.receiptUrl ? item.receiptUrl : 'ass'}
                    alt={'No Image'}
                    sx={{
                      width: '100%',
                      height: '600px',
                      objectFit: 'contain',
                      zIndex: 1
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Carousel>
        )}
      </Box>
    </Dialog>
  )
}

ChallanViewModal.propTypes = {
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  open: PropTypes.bool,
  tripData: PropTypes.object
}

export default ChallanViewModal
