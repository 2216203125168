/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { Dialog, Divider, IconButton, Typography } from '@mui/material'
import { isEmptyObject, timesAgo } from '../../../utils/functions'
import { useGetHook } from '../../../hooks/fetchHook'
import { Fragment } from 'react'

const DemurrageViewModal = (props) => {
  const { onClose, open, tripData, setToast, type } = props
  const [receiptData, setReceiptData] = useState({})

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/charge?tripId=${tripData.id}&chargeType=${type}`

    useGetHook(
      url,
      (res) => {
        setReceiptData(res.data.data)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
      },
      true
    )
  }, [tripData.id, type])

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        flexDirection={'row'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection={'column'}>
          <Typography color="textSecondary" variant="subtitle1">
            Trip ID: &nbsp;
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {tripData.id}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={'column'}>
          <Typography color="textSecondary" variant="subtitle1">
            Document Upload Date:&nbsp;
          </Typography>
          <Typography variant="subtitle1">
            {timesAgo(receiptData?.createdAt)}
          </Typography>
        </Box>

        <Box display="flex" flexDirection={'column'}>
          <Typography color="textSecondary" variant="subtitle1">
            Payable Demmurage:&nbsp;
          </Typography>
          <Typography color="primary" variant="subtitle1">
            ৳{receiptData?.supplierCharge}
          </Typography>
        </Box>

        <Box display="flex" flexDirection={'column'}>
          <Typography color="textSecondary" variant="subtitle1">
            Receivable Demmurage:&nbsp;
          </Typography>
          <Typography color="primary" variant="subtitle1">
            ৳{receiptData?.shipperCharge}
          </Typography>
        </Box>

        <a
          target="_blank"
          rel="noreferrer"
          href={receiptData?.supDocUrl}
          download
        >
          <IconButton>
            <DownloadIcon
              sx={{
                fill: '#ffffff',
                backgroundColor: '#0071CD',
                borderRadius: '30px',
                padding: '4px',
                cursor: 'pointer'
              }}
            />
          </IconButton>
        </a>
        <CloseIcon onClick={onClose} sx={{ fill: 'red' }} />
      </Box>
      <Divider />
      <Box
        component={'div'}
        sx={{ width: 'auto', height: 'auto', padding: '10px' }}
      >
        {!isEmptyObject(receiptData) && (
          <>
            {receiptData.supDocUrl.split('.')[
              receiptData.supDocUrl.split('.').length - 1
            ] == 'pdf' ? (
              <object
                data={receiptData.supDocUrl}
                type="application/pdf"
                height="600"
                width="100%"
              >
                <iframe
                  loading="lazy"
                  height="600"
                  width="100%"
                  src={`https://docs.google.com/viewer?url=${receiptData.supDocUrl}&embedded=true`}
                ></iframe>
              </object>
            ) : (
              <Fragment>
                <Box
                  component="img"
                  src={receiptData.supDocUrl ? receiptData.supDocUrl : 'ass'}
                  alt={'No Image'}
                  sx={{
                    width: '100%',
                    height: '600px',
                    objectFit: 'contain'
                  }}
                />
              </Fragment>
            )}
          </>
        )}
      </Box>
    </Dialog>
  )
}

DemurrageViewModal.propTypes = {
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  open: PropTypes.bool,
  tripData: PropTypes.object,
  type: PropTypes.string
}

export default DemurrageViewModal
