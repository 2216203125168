import { useState } from 'react'

const useToggle = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const toggle = () => setOpen(!open)

  return {
    open,
    handleClose,
    handleOpen,
    toggle
  }
}

export default useToggle
