/* eslint-disable indent */
import { Search } from '@mui/icons-material'
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import BookingItem from '../../components/BookingItem'
import StatusCount from '../../components/StatusCount'
import { useGetHook } from '../../hooks/fetchHook'
import useDebouncedValue from '../../hooks/useDebounceValue'
import { toQueryString } from '../../utils/functions'
import useToast from '../../hooks/useToast'
import Snackbars from '../../components/Snackbar/Snackbar'
import CommonPagination from '../LatestBookings/CommonPagination'
import { subsTypes } from '../../constants'
import { establishedOffices } from '../../data/data'

const FeedReports = () => {
  const [search, setSearch] = useState('')
  const [vendorDues, setVendorDues] = useState([])
  const debouncedSearch = useDebouncedValue(search, 500)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState()
  // const [total, setTotal] = useState(0)
  const [subsType, setSubsType] = useState('')
  const [zone, setZone] = useState('')
  const handlePageChange = (event, value) => setPage(value)

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
  } = useToast()

  useEffect(() => {
    const query = {
      limit: 10,
      page,
      query: debouncedSearch,
      subsType,
      zone
    }

    let url = `/lf-fdr/api/v1/supplier-feed${toQueryString(query)}`
    useGetHook(
      url,
      (res) => {
        // setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setVendorDues(res.data.data)
      },
      () => {},
      true
    )
  }, [debouncedSearch, page, subsType, zone])

  return (
    <>
      <Box p={2}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Typography variant="h5">Monthly Feed Monitoring</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label">Zone</InputLabel>
              <Select
                value={zone}
                label="Zone"
                onChange={(e) => setZone(e.target.value)}
              >
                {establishedOffices.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 180 }} size="small">
              <InputLabel id="demo-simple-select-label24">
                Membership Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label24"
                id="demo-simple-select24"
                value={subsType}
                label="Membership Type"
                onChange={(e) => {
                  setSubsType(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.keys(subsTypes).map((key) => (
                  <MenuItem key={key} value={subsTypes[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              outlined
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
        {vendorDues?.map((item) => (
          <BookingItem
            key={item.userId}
            companyName={item.user.fullName}
            phone={item.user.phone}
            name={item.user.fullName}
            photo={item.user.profilePicture}
            column={3}
            onClick={() => {}}
          >
            <StatusCount
              type="white"
              count={item.manualFeedCount}
              status="Manual Feed"
              statusColor="rgba(0,0,0,0.6)"
            />
            <StatusCount
              type="white"
              count={item.marketPlaceFeedCount}
              status="Marketplace Feed"
              statusColor="rgba(0,0,0,0.6)"
            />
            <StatusCount
              type="white"
              count={item.totalFeedCount}
              status="Dedicated Feed"
              statusColor="rgba(0,0,0,0.6)"
            />
            <StatusCount
              type="white"
              count={item.subsType}
              status="Membership"
              statusColor="rgba(0,0,0,0.6)"
            />
            <StatusCount
              type="white"
              count={item.makePayout}
              status="Payout Day"
              statusColor="rgba(0,0,0,0.6)"
            />
          </BookingItem>
        ))}
      </Box>

      <CommonPagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
      />

      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default FeedReports
