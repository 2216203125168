import React, { useEffect, useState } from 'react'
import { usePutHook } from '../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { TextField } from '@mui/material'
import useDebouncedValue from '../../hooks/useDebounceValue'

const BookingCancelModal = (props) => {
  const { onClose, open, setToast, bookingId, handleClose } = props
  const [cancelReason, setReason] = useState('')
  const [reason, setCancelReason] = useState('')
  const [disabled, setDisabled] = useState(false)
  const debouncedReason = useDebouncedValue(reason, 500)

  const reasons = [
    'Truck was not provided on time',
    'Rate Issue',
    'Truck Damaged',
    'Driver Issue',
    'Cancel by customer',
    'Others'
  ]

  useEffect(() => {
    if (cancelReason == 'Others' && debouncedReason.length <= 1) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [cancelReason, debouncedReason])

  const cancelBooking = () => {
    let payload = {
      bookingId,
      cancelReason,
      reason: cancelReason !== 'Others' ? '' : reason
    }
    let url = '/lf-booking/api/v1/admin/booking/cancel/'

    usePutHook(
      url,
      payload,
      () => {
        setToast('Booking has been cancelled', 'success')
        setReason('')
        handleClose()
        onClose()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog
      sx={{ maxWidth: '390px', marginLeft: 'auto', marginRight: 'auto' }}
      onClose={onClose}
      open={open}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography fontWeight={'bold'} variant="subtitle1">
            Booking Cancellation Reasons
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fill: 'red' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container spacing={2}>
          {reasons.map((item, i) => {
            return (
              <Grid key={i} item xs={12}>
                <Box
                  sx={{
                    padding: '8px',
                    background:
                      item == cancelReason
                        ? '#0071CD'
                        : 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
                    color: item == cancelReason ? '#fff' : '#0071CD',
                    fontSize: '16px',
                    borderRadius: '4px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => setReason(item)}
                >
                  <Typography variant="body2" textAlign="center">
                    {item}
                  </Typography>
                </Box>
              </Grid>
            )
          })}

          {cancelReason == 'Others' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-nameada2"
                label="Cancel Note"
                value={reason}
                onChange={(e) => {
                  setCancelReason(e.target.value)
                }}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: '100%' }}
              onClick={onClose}
              color="error"
            >
              No
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: '100%' }}
              onClick={cancelBooking}
              color="primary"
              disabled={disabled}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

BookingCancelModal.propTypes = {
  bookingId: PropTypes.string,
  setRequestDetails: PropTypes.func,
  requestedShipperData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setToast: PropTypes.func,
  refreshDriverList: PropTypes.func,
  fixedVendor: PropTypes.any,
  handleClose: PropTypes.func
}

export default BookingCancelModal
