/* eslint-disable indent */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledFlexDiv } from './StyledComponents/StyledComponents'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Divider from '@mui/material/Divider'
import LoadingIcon from '../../components/SvgIcons/LoadingIcon'
import UnLoadingIcon from '../../components/SvgIcons/UnLoadingIcon'
import StoppageIcon from '../../components/SvgIcons/StoppageIcon'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { getAccessLevel } from '../../accesscontrol'
import {
  getFormattedDate,
  timesAgo,
  getPropValue,
  toProperCase,
  getStageColor,
  getLoggedInUser
} from '../../utils/functions'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CancelTripModal from '../BookingDetail/Tabs/CancelTripModal'
import useToast from '../../hooks/useToast'
import Snackbars from '../../components/Snackbar/Snackbar'
import { usePutHook } from '../../hooks/fetchHook'
import { Close, Done } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import CustomPopover from '../../components/CustomPopover'
import UploadChallan from './ChallanComponents/UploadChallan'
import Demurrage from './ChallanComponents/Demurrage'
import ChallanViewModal from './ChallanComponents/ChallanViewModal'
import DemurrageViewModal from './ChallanComponents/DemurrageViewModal'
import './bookingv2.css'
import PostAddIcon from '@mui/icons-material/PostAdd'
import AdditionalPaymentRemarks from '../../components/AdditionalPaymentRemarks'
import Remarks from '../../components/AdditionalPaymentRemarks/Remarks'

const TripCardV2 = (props) => {
  const { tripData, status, onItemClick } = props
  const user = getLoggedInUser()
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  const {
    booking,
    truck,
    bidding,
    driver,
    id: tripId,
    tripStatus,
    tripType,
    paymentRemark,
    receiptHardCopyStatus
  } = tripData
  const { vendor } = bidding ? bidding : {}
  const {
    id,
    company,
    pickupDate,
    createdAt,
    fromLocation,
    toLocation,
    stoppages,
    biddingCount
  } = booking
  const { truckSize, licensePlateNoTextEn } = truck
  const [tripStatusByKam, setTripStatusByKam] = useState('')
  const [openCancelModal, toggleOpen] = useState(false)
  const [challanOpen, setChallanOpen] = useState(false)
  const [currTripData, setCurrTripData] = useState(false)
  const [openDemurrageModal, toggleOpenDemurrageModal] = useState(false)
  const [type, setType] = useState('demurrage')
  const [openDemurrageExtraPayViewModal, toggleOpenDemurrageExtraPayModal] =
    useState(false)

  const updateTripStatus = () => {
    let url = '/lf-booking/api/v1/admin/booking/trip-update'

    let payload = {
      tripStatus: tripStatusByKam,
      tripId
    }

    usePutHook(
      url,
      payload,
      () => {
        setToast('Trip status has been updated', 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const hardCopyUpload = () => {
    let url = '/lf-booking/api/v1/admin/booking/trip-hard-copy/'

    let payload = {
      tripStatus: tripStatusByKam,
      tripId
    }
    usePutHook(
      url,
      payload,
      (response) => {
        setToast(response?.data?.message, 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box
        className={`return-trip-box2 ${
          tripType === 'return_trip' ? 'return_trip' : ''
        }`}
        onClick={() => onItemClick(booking)}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          className="card-header"
          p={1}
        >
          <Grid item xs={4}>
            <StyledFlexDiv>
              <Avatar alt={company?.name} src={company?.companyPicture} />
              <Box>
                <Typography color="primary" fontWeight={'bold'}>
                  {getPropValue('name', company)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={2.5}>
            <StyledFlexDiv>
              <Typography>B-Date:</Typography>
              <Typography color="primary">
                {getFormattedDate(new Date(pickupDate))}
              </Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={2.5}>
            <StyledFlexDiv>
              <AccessTimeIcon sx={{ color: '#0071CD' }} />
              <Typography variant="body2">{timesAgo(createdAt)}</Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1}>
            <StyledFlexDiv alignItems="flex-start" justifyContent="flex-start">
              <Chip
                sx={{
                  bgcolor:
                    biddingCount === 0
                      ? '#858C94'
                      : biddingCount > 2
                      ? '#4CAF50'
                      : '#FFB400',
                  color: '#fff'
                }}
                label={biddingCount}
              />
              <Typography variant="body2">Bids</Typography>
            </StyledFlexDiv>
          </Grid>

          <Grid item xs={1.5}>
            <StyledFlexDiv alignItems="flex-end" justifyContent="flex-end">
              {tripType === 'return_trip' && (
                <Chip
                  label={
                    <Typography textAlign="center" variant="body2">
                      R
                    </Typography>
                  }
                  sx={{
                    bgcolor: '#F82012',
                    color: '#fff'
                  }}
                />
              )}
              <Chip
                sx={getStageColor(tripStatus)}
                label={toProperCase(tripStatus)}
              />
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={0.5}>
            <Box display="flex" justifyContent="end">
              {user.team !== 'business_team' && (
                <CustomPopover icon={<MoreVertIcon />}>
                  {getAccessLevel('cancelTrip') &&
                    status !== 'completed' &&
                    status !== 'cancelled' &&
                    status !== 'expired' && (
                      <Typography
                        p={1}
                        sx={{
                          cursor: 'pointer',
                          bgcolor: '#fff',
                          '&:hover': {
                            bgcolor: '#0071cd',
                            color: '#fff'
                          }
                        }}
                        color="red"
                        onClick={(e) => {
                          toggleOpen(true)
                          e.stopPropagation()
                        }}
                        variant="body1"
                      >
                        Cancel
                      </Typography>
                    )}
                  <Divider />
                  <Typography
                    p={1}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: '#fff',
                      '&:hover': {
                        bgcolor: '#0071cd',
                        color: '#fff'
                      }
                    }}
                    onClick={(e) => {
                      setType('demurrage')
                      toggleOpenDemurrageModal(true)
                      e.stopPropagation()
                    }}
                    variant="body1"
                  >
                    Demurrage
                  </Typography>
                  <Divider />
                  <Typography
                    p={1}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: '#fff',
                      '&:hover': {
                        bgcolor: '#0071cd',
                        color: '#fff'
                      }
                    }}
                    onClick={(e) => {
                      setType('extra_cost')
                      toggleOpenDemurrageModal(true)
                      e.stopPropagation()
                    }}
                    variant="body1"
                  >
                    Extra Payment
                  </Typography>
                </CustomPopover>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} alignItems="center" p={1}>
          <Grid item xs={2}>
            <StyledFlexDiv>
              <Box>
                <LoadingIcon sx={{ width: 40, height: 40 }} />
              </Box>
              <Box>
                <Typography variant="body2">
                  {getPropValue('address', fromLocation)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.5}>
            <StyledFlexDiv>
              <Tooltip
                placement="bottom-start"
                arrow
                title={`${stoppages.length} Stoppages`}
              >
                <StoppageIcon sx={{ width: 40, height: 40 }} />
              </Tooltip>
              <Typography variant="body2">
                {stoppages.length} Stoppages
              </Typography>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={2.5}>
            <StyledFlexDiv>
              <Box>
                <UnLoadingIcon sx={{ width: 40, height: 40 }} />
              </Box>
              <Box>
                <Typography variant="body2">
                  {getPropValue('address', toLocation)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.5}>
            <StyledFlexDiv>
              <Box>
                <Typography>Truck Type</Typography>
                <Typography color="primary">
                  {getPropValue('nameEn', truckSize)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.5}>
            <StyledFlexDiv>
              <Box>
                <Typography>Vendor Number</Typography>
                <Typography color="primary">
                  {getPropValue('phone', vendor)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.5}>
            <StyledFlexDiv>
              <Box>
                <Typography>Driver Number</Typography>
                <Typography color="primary">
                  {getPropValue('phone', driver)}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
          <Grid item xs={1.5}>
            <StyledFlexDiv>
              <Box>
                <Typography>Truck Plate</Typography>
                <Typography color="primary">
                  {licensePlateNoTextEn ? licensePlateNoTextEn : 'N/A'}
                </Typography>
              </Box>
            </StyledFlexDiv>
          </Grid>
        </Grid>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          gap={4}
          onClick={(e) => e.stopPropagation()}
        >
          <Box display="flex" gap={2} alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography>B-ID: </Typography>
              <Typography color="primary">{id}</Typography>
            </Box>

            <Box flexGrow={1} display="flex" alignItems="center">
              <Typography>Trip-ID: </Typography>
              <Typography color="primary">{tripId}</Typography>
            </Box>
            {getAccessLevel('showRemarks') && (
              <>
                <Box flexGrow={1} display="flex" gap={1} alignItems="center">
                  <CustomPopover
                    disabled={user.role !== 'kam'}
                    icon={<PostAddIcon color="info" size="small" />}
                  >
                    {paymentRemark ? (
                      <Remarks remarks={paymentRemark} />
                    ) : (
                      <AdditionalPaymentRemarks
                        tripId={tripId}
                        setToast={setToast}
                      />
                    )}
                  </CustomPopover>
                  <Typography color="primary" variant="body2">
                    {paymentRemark
                      ? `${paymentRemark.substring(0, 25)}...`
                      : ''}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          {(tripStatus === 'completed' || tripStatus === 'cancelled') && (
            <Box display="flex" gap={1} alignItems="center">
              <BootstrapButton
                variant="contained"
                color="warning"
                size="small"
                disabled={tripData.receiptStatus == 'pending'}
                onClick={(e) => {
                  e.stopPropagation()
                  setCurrTripData(tripData)
                  setChallanOpen(true)
                }}
              >
                C
              </BootstrapButton>

              <BootstrapButton
                variant="contained"
                color="primary"
                size="small"
                disabled={!tripData.supplierDemurrage}
                onClick={(e) => {
                  e.stopPropagation()
                  setType('demurrage')
                  toggleOpenDemurrageExtraPayModal(true)
                }}
              >
                D
              </BootstrapButton>

              <BootstrapButton
                variant="contained"
                color="error"
                size="small"
                disabled={!tripData.supplierExtraCost}
                onClick={(e) => {
                  e.stopPropagation()
                  setType('extra_cost')
                  toggleOpenDemurrageExtraPayModal(true)
                }}
              >
                E
              </BootstrapButton>

              {user.role === 'kam' && user.team === 'vendor_team' && (
                <CustomPopover
                  disabled={user.role !== 'kam'}
                  icon={
                    <Button
                      size="small"
                      sx={{
                        bgcolor: '#E5F1FA',
                        color: '#0071CD',
                        borderRadius: '40px'
                      }}
                      color="primary"
                      disabled={
                        !(user.role === 'kam' && user.team === 'vendor_team')
                      }
                      variant="outlined"
                    >
                      Upload Challan
                    </Button>
                  }
                >
                  <UploadChallan tripId={tripId} setToast={setToast} />
                </CustomPopover>
              )}
            </Box>
          )}

          {status === 'activated' && getAccessLevel('changeTripStatus') && (
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              onClick={(e) => e.stopPropagation()}
            >
              <FormControl
                fullWidth
                sx={{ minWidth: 140 }}
                disabled={!getAccessLevel('changeTripStatus')}
                size="small"
              >
                <InputLabel id="demo-simple-select-label23">
                  Select Status sadad
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label23"
                  id="demo-simple-select23"
                  value={tripStatusByKam}
                  label="Select Status"
                  onChange={(e) => {
                    setTripStatusByKam(e.target.value)
                  }}
                >
                  <MenuItem
                    disabled={tripStatus === 'on_way'}
                    value={'goods_loaded'}
                  >
                    Goods Loaded
                  </MenuItem>
                  <MenuItem value={'goods_unloaded'}>Goods Unloaded</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <IconButton
                  disabled={!tripStatusByKam}
                  onClick={updateTripStatus}
                  color="success"
                >
                  <Done />
                </IconButton>
              </Box>
              <Box>
                <IconButton onClick={() => setTripStatusByKam('')}>
                  <Close sx={{ fill: '#F82012' }} />
                </IconButton>
              </Box>
            </Box>
          )}
          {status === 'completed' &&
            getAccessLevel('changeTripStatus') &&
            receiptHardCopyStatus !== 'received' && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                onClick={(e) => e.stopPropagation()}
              >
                <FormControl
                  fullWidth
                  sx={{ minWidth: 140 }}
                  disabled={!getAccessLevel('changeTripStatus')}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-label23">
                    Select Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label23"
                    id="demo-simple-select23"
                    value={tripStatusByKam}
                    label="Select Status"
                    onChange={(e) => {
                      setTripStatusByKam(e.target.value)
                    }}
                  >
                    <MenuItem
                      disabled={tripStatus !== 'completed'}
                      value={'received'}
                    >
                      Challan Hard Copy
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box>
                  <IconButton
                    disabled={!tripStatusByKam}
                    onClick={hardCopyUpload}
                    color="success"
                  >
                    <Done />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton onClick={() => setTripStatusByKam('')}>
                    <Close sx={{ fill: '#F82012' }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          {receiptHardCopyStatus === 'received' && (
            <Box display="flex" mr={2} gap={1} alignItems="center">
              <Done color="success" />
              <Typography variant="body1" fontWeight={400}>
                Challan Hard Copy
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {challanOpen && (
        <ChallanViewModal
          tripData={currTripData}
          open={challanOpen}
          onClose={() => {
            setChallanOpen(false)
          }}
          setToast={setToast}
        />
      )}
      {openDemurrageExtraPayViewModal && (
        <DemurrageViewModal
          tripData={tripData}
          open={openDemurrageExtraPayViewModal}
          onClose={() => {
            toggleOpenDemurrageExtraPayModal(false)
          }}
          type={type}
          setToast={setToast}
        />
      )}
      <Demurrage
        onClose={() => {
          toggleOpenDemurrageModal(false)
        }}
        open={openDemurrageModal}
        setToast={setToast}
        tripId={tripId}
        type={type}
      />
      <CancelTripModal
        open={openCancelModal}
        setToast={setToast}
        onClose={() => {
          toggleOpen(false)
        }}
        setTripStatus={() => {
          // console.log(val)
        }}
        tripId={tripId}
      />
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

TripCardV2.propTypes = {
  tripData: PropTypes.object,
  status: PropTypes.string,
  onItemClick: PropTypes.func
}

export default TripCardV2

const BootstrapButton = styled(Button)({
  borderRadius: '50px',
  minWidth: '33px',
  height: 'auto'
})
