import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import no_search_result from '../../assests/no_search_result.png'
import './NoSearchResult.css'
import PropTypes from 'prop-types'

const NoSearchResult = ({ title, subTitle }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <img
        className="nosearchResultImg"
        src={no_search_result}
        alt="no search result"
      />
      <Typography maxWidth="600px" gutterBottom variant="h4">
        {title || 'Sorry! No result found :('}
      </Typography>
      <Typography variant="body1">
        {subTitle || 'Please try another way'}
      </Typography>
    </Box>
  )
}

NoSearchResult.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string || PropTypes.bool
}

export default NoSearchResult
