/* eslint-disable no-undef */
export const timeSlots = [
  {
    value: 1,
    label: 'Morning (6AM - 11AM)'
  },
  {
    value: 2,
    label: 'Afternoon (12PM - 5PM)'
  },
  {
    value: 3,
    label: 'Evening (6PM - 11PM)'
  },
  {
    value: 4,
    label: 'Night (12AM - 5AM)'
  }
]

export const dailyFeedProps = [
  'Dedicated Feed',
  'Marketplace feed',
  'Platinum Feed',
  'Gold Feed',
  'Bronze Feed',
  'Diamond Feed',
  'Silver Feed',
  'Tin Feed'
]

export const userShape = {
  id: 0,
  fullName: '',
  userId: ''
}

export const truckTypes = [
  { id: 5, label: 'Open Truck' },
  { id: 4, label: 'Covered Van' },
  { id: 3, label: 'Trailer Truck' },
  { id: 2, label: 'Drum Truck' },
  { id: 1, label: 'Freezer Van' }
]

export const teams = [
  { title: 'Business Acceleration', value: 'business_team' },
  { title: 'Vendor Acceleration', value: 'vendor_team' },
  { title: 'Fulfillment', value: 'fulfillment_team' }
]

export const clusterHeadTeams = [
  { title: 'Business Acceleration', value: 'business_team' },
  { title: 'Vendor Acceleration', value: 'vendor_team' }
]

export const offices = [
  { title: 'Head office', value: 'head_office' },
  { title: 'Chittagong', value: 'chittagong' },
  { title: 'Khulna', value: 'khulna' },
  { title: 'Gazipur', value: 'gazipur' },
  { title: 'Narayanganj', value: 'narayanganj' },
  { title: 'Sylhet', value: 'sylhet' }
]

export const establishedOffices = [
  { title: 'All', value: '' },
  { title: 'Chittagong', value: 'chittagong' },
  { title: 'Khulna', value: 'khulna' },
  { title: 'Gazipur', value: 'gazipur' },
  { title: 'Narayanganj', value: 'narayanganj' }
]

export const documentTypes = [
  { title: 'Invoice', value: 'invoice' },
  { title: 'Packing List', value: 'packing_list' },
  { title: 'Transport Challan', value: 'transport_challan' },
  { title: 'Product Challan', value: 'product_challan' },
  { title: 'Scale Copy', value: 'scale_copy' },
  { title: 'Bill Of Entry', value: 'bill_of_entry' }
]

export const companyDocumentTypes = [
  { title: 'TIN', value: 'tin' },
  { title: 'Packing List', value: 'packing_list' },
  { title: 'NID', value: 'nid' },
  { title: 'BIN', value: 'bin' },
  { title: 'TLN', value: 'tln' },
  { title: 'Contract Paper', value: 'contract_paper' },
  { title: 'Agreement Paper', value: 'agreement_paper' },
  { title: 'Route List', value: 'route_list' },
  { title: 'Others', value: 'others' }
]

export const vendorDocumentTypes = [
  { title: 'TIN', value: 'tin_image' },
  { title: 'NID', value: 'nid_image' },
  { title: 'Nominee NID', value: 'nominee_nid_image' },
  { title: 'TLN', value: 'trade_license_image' },
  { title: 'Nominee Image', value: 'nominee_image' },
  { title: 'Security', value: 'security_image' },
  { title: 'Agreement Paper', value: 'agreement_paper' },
  { title: 'Others', value: 'others' }
]

export const driverDocumentTypes = [
  { title: 'NID Front', value: 'nidImageFront' },
  { title: 'NID Back', value: 'nidImageBack' },
  { title: 'Driving License Front', value: 'drivingLicenseImageFront' },
  { title: 'Driving License Back', value: 'drivingLicenseImageBack' }
]

export const profileTripsTabData = [
  {
    tabName: 'requested',
    tabIndex: 0,
    tabValue: 'processing'
  },
  {
    tabName: 'activated',
    tabValue: 'activated',
    tabIndex: 1
  },
  {
    tabName: 'completed',
    tabValue: 'completed',
    tabIndex: 2
  },
  {
    tabName: 'cancelled',
    tabValue: 'cancelled',
    tabIndex: 3
  },
  {
    tabName: 'expired',
    tabValue: 'expired',
    tabIndex: 4
  }
]
export const shipperPannelsTabData = [
  {
    tabName: 'requested',
    tabIndex: 0,
    tabValue: 'look_at_bid'
  },
  {
    tabName: 'quote shipper',
    tabIndex: 1,
    tabValue: 'quote_shipper'
  },
  {
    tabName: 'activated',
    tabValue: 'shipper_approved',
    tabIndex: 2
  },
  {
    tabName: 'completed',
    tabValue: 'completed',
    tabIndex: 3
  },
  {
    tabName: 'cancelled',
    tabValue: 'cancelled',
    tabIndex: 4
  },
  {
    tabName: 'expired',
    tabValue: 'expired',
    tabIndex: 5
  }
]

export const profileTripsSummaryData = {
  activeTrips: 69,
  completedTrips: 720,
  cancelledTrips: 103,
  expiredTrips: 96
}

export const invalidInputsChars = ['-', '+', 'e']

export const homepageFeaturesData = [
  {
    icon: 'GreenLogistic',
    text: 'Green Logistic'
  },
  {
    icon: 'BlockChain',
    text: 'Blockchain'
  },
  {
    icon: 'MultiLocation',
    text: 'Multiple Stoppage'
  },
  {
    icon: 'Stars',
    text: 'User Rating System'
  },
  {
    icon: 'SupervisorAccount',
    text: 'Dedicated KAM'
  },
  {
    icon: 'AvTimer',
    text: 'Real-time Loading and Unloading'
  }
]

export let Components = {}
Components['GreenLogistic'] =
  require('../components/SvgIcons/GreenLogistic').default
Components['BlockChain'] = require('../components/SvgIcons/BlockChain').default
Components['MultiLocation'] =
  require('../components/SvgIcons/MultiLocation').default
Components['Stars'] = require('../components/SvgIcons/Stars').default
Components['SupervisorAccount'] =
  require('../components/SvgIcons/SupervisorAccount').default
Components['AvTimer'] = require('../components/SvgIcons/AvTimer').default

export const homepageWhyLoopData = [
  {
    icon: 'struck.png',
    heading: 'On-Demand Solution',
    text: 'We offer trucking services on a demand basis'
  },
  {
    icon: 'vector.png',
    heading: 'Transparency',
    text: 'Through our competitive & upfront pricing and outstanding customer relationship management- you always know to make the best business decisions.'
  },
  {
    icon: 'payment.png',
    heading: 'Fastest Payment',
    text: 'Loop pays vendors instantly, regardless of who is the shipper.'
  }
]
