import { makeObservable, observable, action } from 'mobx'

class GlobalSearchStore {
  type = 'booking'
  bookings = []
  trips = []

  constructor() {
    makeObservable(this, {
      type: observable,
      bookings: observable,
      updateBookings: action,
      trips: observable,
      updateTrips: action
    })
    this.type = 'booking'
    this.bookings = []
    this.trips = []
  }

  updateSearchType(type) {
    this.type = type
  }

  updateBookings(details) {
    this.bookings = details
  }

  updateTrips(details) {
    this.trips = details
  }
}

export default GlobalSearchStore
