import { Close } from '@mui/icons-material'
import {
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook, usePostHook } from '../../../hooks/fetchHook'
import LocationAutoComplete from '../../../components/LocationAutoComplete'
import CustomGoogleMap from '../../../components/CustomGoogleMap'

const unloadingPoint = {
  id: null,
  latitude: 0,
  longitude: 0,
  placeId: '',
  shortFormEn: '',
  formattedTextEn: '',
  textEn: '',
  type: ''
}

const CreateAddressModal = (props) => {
  const { openModal, handleModalClose, setToast, handleRefetch } = props
  const [call, setCall] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [loadingP, setLoadingP] = useState({
    id: null,
    latitude: 0,
    longitude: 0,
    placeId: '',
    shortFormEn: '',
    formattedTextEn: '',
    textEn: '',
    type: ''
  })

  const [latLong, setLongLat] = useState('')
  // const [latitude, setLatitude] = useState('')
  // const [longitude, setLongitude] = useState('')
  const [textEn, setTextEn] = useState('')
  const [shortFormEn, setShortFormEn] = useState('')

  useEffect(() => {
    if (loadingP.latitude && loadingP.longitude) {
      setShortFormEn(loadingP.shortFormEn)
      setTextEn(loadingP.textEn)
      setLongLat(`${loadingP.latitude},${loadingP.longitude}`)
      // setLatitude(loadingP.latitude)
      // setLongitude(loadingP.longitude)
    }
  }, [loadingP])

  const handleAddressChange = (val) => {
    if (!val) return
    if (val.latitude && val.longitude) {
      setLoadingP({ ...loadingP, ...val })
    } else if (val.placeId) {
      let url = `/lf-geolocation/api/v1/admin/address/detail?sessionId=${sessionId}&placeId=${val.placeId}`
      useGetHook(
        url,
        (res) => {
          setLoadingP({
            ...loadingP,
            ...res.data.data,
            shortFormEn: res.data.data.shortFormEn
              ? res.data.data.shortFormEn
              : ''
          })
        },
        () => setToast('Something went wrong', 'error'),
        true
      )
    } else {
      setToast('Somthing went wrong', 'error')
    }
  }

  const handleLoadingPointDragLocation = (lat, lng) => {
    if (lat && lng) {
      let url = `/lf-geolocation/api/v1/admin/address/reverse-geocoding?latlng=${lat},${lng}`
      useGetHook(
        url,
        (res) => {
          setLoadingP({
            ...loadingP,
            ...res.data.data,
            shortFormEn: res.data.data.shortFormEn
              ? res.data.data.shortFormEn
              : ''
          })
        },
        () => setToast('Something went wrong', 'error'),
        true
      )
    } else {
      setToast('Somthing went wrong', 'error')
    }
  }

  const createAddress = () => {
    const payload = {
      textEn,
      shortFormEn,
      latitude: latLong.split(',')[0],
      longitude: latLong.split(',')[1]
    }
    let url = '/lf-geolocation/api/v1/admin/address'
    usePostHook(
      url,
      payload,
      () => {
        setLoadingP({
          id: null,
          latitude: 0,
          longitude: 0,
          placeId: '',
          shortFormEn: '',
          formattedTextEn: '',
          textEn: '',
          type: ''
        })

        setShortFormEn('')
        setTextEn('')
        setLongLat('')
        // setLatitude('')
        // setLongitude('')
        setToast('Address has been created', 'success')
        handleRefetch()
        handleModalClose(false)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Modal open={openModal} onClose={handleModalClose}>
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography fontWeight={'bold'} color="primary" variant="h6">
            Add New Address
          </Typography>
          <IconButton onClick={handleModalClose}>
            <Close sx={{ fill: '#F82012' }} />
          </IconButton>
        </Box>
        <Divider />
        <Box py={1} display="flex" flexDirection="column" gap={2}>
          <LocationAutoComplete
            size="small"
            label="Search address"
            location={loadingP}
            setLocation={setLoadingP}
            setToast={setToast}
            sessionId={sessionId}
            setSessionId={setSessionId}
            onLocationChange={(val) => handleAddressChange(val)}
          />
          <TextField
            size="small"
            label="Edit Short Form"
            fullWidth
            variant="outlined"
            name="shortFormEn"
            value={shortFormEn}
            onChange={(e) => setShortFormEn(e.target.value)}
          />
          <TextField
            size="small"
            label="Edit Long Form"
            fullWidth
            variant="outlined"
            name="textEn"
            value={textEn}
            onChange={(e) => setTextEn(e.target.value)}
          />
          <TextField
            size="small"
            label="Lat Long"
            fullWidth
            variant="outlined"
            name="latLong"
            value={latLong}
            onChange={(e) => setLongLat(e.target.value)}
          />
          <Box width="100%" sx={{ height: '250px' }}>
            <CustomGoogleMap
              call={call}
              setCall={setCall}
              loadingP={loadingP}
              unloadingP={unloadingPoint}
              handleLoadingPointDragLocation={handleLoadingPointDragLocation}
              handleUnLoadingPointDragLocation={() => {}}
              handleStopageDragLocation={() => {}}
              waypointsList2={[]}
              isAddressModal={true}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={createAddress}
              size="small"
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateAddressModal

CreateAddressModal.propTypes = {
  openModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleRefetch: PropTypes.func,
  setToast: PropTypes.func
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1
}
