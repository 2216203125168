import React, { useState, useEffect } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import OtpCard from './OtpCard'
import Snackbars from '../../components/Snackbar/Snackbar'
import useDebouncedValue from '../../hooks/useDebounceValue'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import UserListTitle from '../../components/UserListTitle'

const OTPList = () => {
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [otpList, setOtpList] = useState([])
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [kw, setKw] = useState('')
  const debounedKw = useDebouncedValue(kw, 500)

  useEffect(() => {
    let url = `/lf-user/api/v1/admin/otp?page=${page}&limit=${pageSize}`
    if (debounedKw) url = url + `&search=${debounedKw}`

    useGetHook(
      url,
      (res) => {
        setTotal(res.data.data.total)
        let count = Math.ceil(res.data.data.total / 10)
        setPageCount(count)
        setOtpList(res.data.data.result)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [page, debounedKw])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>
          <UserListTitle count={total} name="OTP" />
        </Box>
        <Box>
          <TextField
            color="primary"
            size="small"
            label="Search"
            value={kw}
            onChange={(e) => {
              setKw(e.target.value)
              setPage(1)
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <Box>
        {otpList.map((item) => (
          <OtpCard key={item.id} data={item} />
        ))}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default OTPList
