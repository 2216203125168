/* eslint-disable indent */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { ShipperRowItem } from '../../components/HomeContainer/StyledComponents/StyledComponents'
import './styles.css'
import { Chip, Grid } from '@mui/material'
import { formatStage } from '../../utils/functions'

const Shipper = (props) => {
  const navigate = useNavigate()
  const { data } = props

  const navigateToDetail = () => {
    let route = `/home/company-profile/${data.companyId}`
    navigate(route)
  }

  return (
    <Box pt={2}>
      <ShipperRowItem
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={navigateToDetail}
        p={1}
      >
        <Grid container spacing={1}>
          <Grid item xs={0.5}>
            <Avatar
              alt={data.fullName}
              src={data.companyPicture ? data.companyPicture : data.companyName}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Company Name
            </Typography>
            <Typography className="col-value" variant="body">
              {data.companyName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography className="col-head" variant="body2">
              Name
            </Typography>
            <Typography className="col-value" variant="body">
              {data.fullName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Mobile Number
            </Typography>
            <Typography className="col-value" variant="body">
              {data.phone}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Email Address
            </Typography>
            <Typography className="col-value" variant="body">
              {data.email ? data.email : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Assigned KAM(FFT)
            </Typography>
            <Typography className="col-value" variant="body">
              {data.kamFFT ? data.kamFFT : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Assigned KAM(BAT)
            </Typography>
            <Typography className="col-value" variant="body">
              {data.kamBAT ? data.kamBAT : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {/* <Typography className="col-head" variant="body2">
              Cluster Head
            </Typography>
            <Typography className="col-value" variant="body">
              {data.cluster_head ? data.cluster_head : 'N/A'}
            </Typography> */}
            <Typography className="col-head" textAlign="center" variant="body2">
              Zone
            </Typography>
            <Chip
              color="primary"
              size="small"
              label={formatStage(data.zone)}
              sx={{ width: '100px', textAlign: 'center' }}
            />
          </Grid>
          <Grid item xs={1}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="col-head" variant="body2">
                Status
              </Typography>
              <Chip
                mt={1}
                label={data.isVerified ? 'Verified' : ' Not verified'}
                size="small"
                color={data.isVerified ? 'success' : 'error'}
                sx={{ width: '100px', textAlign: 'center' }}
              />
            </Box>
          </Grid>
        </Grid>
      </ShipperRowItem>
    </Box>
  )
}

Shipper.propTypes = {
  data: PropTypes.object
}

export default Shipper
