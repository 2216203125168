import React, { useEffect, useState } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Typography from '@mui/material/Typography'
import Snackbars from '../../components/Snackbar/Snackbar'
import Grid from '@mui/material/Grid'
import UserBasicInfo from './UserBasicInfo'
import UserProfileTabsContainer from './UserProfileTabContainer'

const LoggedInUserProfile = () => {
  const navigate = useNavigate()
  const [userDetail, setUserDetail] = useState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  useEffect(() => {
    let url = '/lf-user/api/v1/users/me/detail'
    useGetHook(
      url,
      (res) => setUserDetail(res.data.data),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }, [])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  return (
    <>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              color="primary"
              s
              aria-label="upload picture"
              component="span"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="body1">My Profile</Typography>
          </Box>
        </Box>
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {userDetail && (
                <UserBasicInfo userDetail={userDetail} setToast={setToast} />
              )}
            </Grid>
            <Grid item xs={9}>
              {userDetail && (
                <UserProfileTabsContainer
                  userDetail={userDetail}
                  setUserDetail={setUserDetail}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default LoggedInUserProfile
