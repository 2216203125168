import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ItemTypeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#F1F5F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 15H16C14.9 15 14 15.9 14 17V31C14 32.1 14.9 33 16 33H32C33.1 33 34 32.1 34 31V17C34 15.9 33.1 15 32 15ZM22 29H17V27H22V29ZM22 25H17V23H22V25ZM22 21H17V19H22V21ZM26.82 27L24 24.16L25.41 22.75L26.82 24.17L29.99 21L31.41 22.42L26.82 27Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default ItemTypeIcon
