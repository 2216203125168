import React, { useState, useEffect } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Pro from './Pro'
import Snackbars from '../../components/Snackbar/Snackbar'
import UserListTitle from '../../components/UserListTitle'
import { Button } from '@mui/material'
import { getAccessLevel } from '../../accesscontrol'
import AddProUserModal from '../AddProUserModal'

const ProList = () => {
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [pros, setPros] = useState([])
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  useEffect(() => {
    let url = `/lf-user/api/v1/admin/user?page=${page}&limit=${pageSize}&role=pro`
    useGetHook(
      url,
      (res) => {
        setTotal(res.data.data.total)
        let count = Math.ceil(res.data.data.count / 10)
        setPageCount(count)
        setPros(res.data.data.result)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [page])

  const refreshList = () => {
    let url = `/lf-user/api/v1/admin/user?page=${1}&limit=${10}&role=pro`

    useGetHook(
      url,
      (res) => {
        setTotal(res.data.data.total)
        let count = Math.ceil(res.data.data.count / 10)
        setPageCount(count)
        setPros(res.data.data.result)
        setPage(1)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <UserListTitle count={total} name="Customer Experience" />
        <Button
          variant="contained"
          disabled={!getAccessLevel('manageUsers')}
          onClick={() => setOpen(true)}
        >
          Add New
        </Button>
      </Box>
      <Box>
        {pros.map((item) => (
          <Pro key={item.id} data={item} />
        ))}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>

      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <AddProUserModal
        open={open}
        onClose={setOpen}
        setToast={setToast}
        refreshList={refreshList}
      />
    </>
  )
}

export default ProList
