import Close from '@mui/icons-material/Close'
import {
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AttachFile } from '@mui/icons-material'
import { usePostHook } from '../../../hooks/fetchHook'

const UploadModal = ({ open, onClose, setToast }) => {
  const [challanImage, setChallanImage] = useState({ name: '' })

  const downloadRequest = () => {
    const form = new FormData()
    form.append('file', challanImage)
    let url = '/lf-booking/api/v1/admin/booking/upload-summary/'
    usePostHook(
      url,
      form,
      (res) => {
        setToast(res.data.message, 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="primary"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Upload excel or CSV file
          </Typography>
          <IconButton size="small" onClick={onClose} color="error">
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <TextField
          label="Upload Challan"
          value={challanImage.name}
          fullWidth
          size="large"
          InputProps={{
            endAdornment: (
              <label htmlFor="challanImage">
                <TextField
                  id="challanImage"
                  label="Upload Challan"
                  onChange={(e) => setChallanImage(e.target.files[0])}
                  accept="image/*"
                  type="file"
                  sx={{ display: 'none' }}
                />
                <AttachFile />
              </label>
            )
          }}
        />
        <Box display="flex" onClick={downloadRequest} justifyContent="end">
          <Button variant="contained">Submit Now</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UploadModal

UploadModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setToast: PropTypes.func
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2
}
