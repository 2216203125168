/* eslint-disable indent */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import DateRangeIcon from '@mui/icons-material/DateRange'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  formatStage,
  timesAgo,
  getLoggedInUser,
  getShipperStatusColor,
  isEmptyObject
} from '../../../utils/functions'
import { StepFlexItem } from '../../../components/Landing/StyledComponents/StyledComponents'
import { Button, Grid, Popover } from '@mui/material'
import './index.css'
import { usePutHook } from '../../../hooks/fetchHook'
import LocationAndTruckInfo from '../../../components/molecules/LocationAndTruckInfo'
import BookingCancelModal from '../../LatestBookings/BookingCancelModal'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

const BookingCard = (props) => {
  const { data, onItemClick, setToast } = props

  const {
    stoppages,
    toLocation,
    fromLocation,
    truckSize,
    truckQuantity,
    pickupTime,
    pickupDate
  } = data

  const role = getLoggedInUser()?.role
  const [openCancelModal, toggleOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)

  const [bookingStatus, setBookingStatus] = useState(data.bookingStatus)
  const [approved, setApproved] = useState(
    data.fareHistory?.fareHistoryStatus === 'shipper_approved'
  )

  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  const open3 = Boolean(anchorEl3)

  const id = open ? 'simple-popover' : undefined
  const id2 = open2 ? 'simple-popover2' : undefined
  const id3 = open3 ? 'simple-popover3' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
  }
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClose2 = () => {
    setAnchorEl2(null)
  }
  const handleClose3 = () => {
    setAnchorEl3(null)
  }
  const approveFairAmount = (id) => {
    let url = `/lf-booking/api/v1/booking/fare-history/${id}`
    usePutHook(
      url,
      {
        fareHistoryStatus: 'shipper_approved'
      },
      () => {
        setToast('Approved', 'success')
        setApproved(true)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box
        className="booking-step-container"
        mb={2}
        onClick={() => onItemClick(data)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          p={1}
          sx={{ bgcolor: '#f9f9f9' }}
        >
          <Grid item xs={3}>
            <StepFlexItem gap={1}>
              <Box>
                <Avatar
                  alt={data.company?.name}
                  src={data.company?.companyPicture}
                />
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                >
                  {data?.company?.name}
                </Typography>
              </Box>
            </StepFlexItem>
          </Grid>
          <Grid item xs={2}>
            <StepFlexItem>
              <Typography color="primary" variant="body2">
                <span style={{ color: '#1E293B' }}>Booking ID:</span>
                {data.id}
              </Typography>
            </StepFlexItem>
          </Grid>
          <Grid item xs={2}>
            {/* <StepFlexItem>
              <Typography color="primary" variant="body2">
                <span style={{ color: '#1E293B' }}>Range:</span>{' '}
                {Math.round(data.minPrice)}tk - {Math.round(data.maxPrice)}
                tk
              </Typography>
            </StepFlexItem> */}
          </Grid>
          <Grid item xs={2}>
            <StepFlexItem justifyContent={'center'} gap={1}>
              <DateRangeIcon
                sx={{
                  bgcolor: '#F1F5F9',
                  padding: '5px',
                  borderRadius: '48px'
                }}
                size="small"
                color="primary"
              />
              <Box>
                <Typography variant="body2">
                  {timesAgo(data.createdAt)}
                </Typography>
              </Box>
            </StepFlexItem>
          </Grid>
          <Grid item xs={3}>
            {role !== 'shipper' && (
              <StepFlexItem gap={1}>
                <Box
                  sx={{
                    background:
                      data.biddingCount >= 3
                        ? '#4CAF50'
                        : 'rgba(0, 0, 0, 0.38)',
                    borderRadius: '100%',
                    width: 26,
                    height: 26
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#fff',
                      textAlign: 'center',
                      marginTop: '3px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.biddingCount}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Bids</Typography>
                </Box>
              </StepFlexItem>
            )}
            <StepFlexItem gap={1} flexDirection="row-reverse">
              <Box>
                {(data.bookingStatus === 'look_at_bid' ||
                  data.bookingStatus === 'quote_shipper') && (
                  <IconButton
                    onClick={(e) => {
                      handleClick3(e)
                      e.stopPropagation()
                    }}
                    color="primary"
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                )}
                <Popover
                  id={id3}
                  open={open3}
                  anchorEl={anchorEl3}
                  onClose={(e) => {
                    handleClose3()
                    e.stopPropagation()
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Typography
                    p={1}
                    sx={{ cursor: 'pointer' }}
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(true)
                    }}
                  >
                    Cancel
                  </Typography>
                </Popover>
              </Box>
              <Box>
                <Chip
                  label={
                    data.bookingStatus === 'shipper_approved'
                      ? 'Activated'
                      : data.bookingStatus === 'look_at_bid'
                      ? 'Requested'
                      : formatStage(bookingStatus)
                  }
                  sx={{
                    background: getShipperStatusColor(bookingStatus)['bg'],
                    color: getShipperStatusColor(bookingStatus)['color']
                  }}
                />
              </Box>
            </StepFlexItem>
          </Grid>
        </Grid>

        <Divider />

        <LocationAndTruckInfo
          fromLocation={fromLocation}
          stoppages={stoppages}
          toLocation={toLocation}
          truckSize={truckSize}
          truckQuantity={truckQuantity}
          pickupTime={pickupTime}
          pickupDate={pickupDate}
        />

        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          p={1}
        >
          <Grid xs={6} item>
            <Box display="flex" alignItems="center" gap={1}>
              <Box>
                <Avatar
                  aria-describedby={id}
                  onClick={(e) => {
                    handleClick(e)
                    e.stopPropagation()
                  }}
                  sx={{
                    bgcolor: isEmptyObject(data.loadingAgent)
                      ? '#9E9E9E'
                      : '#0071CD',
                    cursor: isEmptyObject(data.loadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.loadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                >
                  L
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={(e) => {
                    handleClose()
                    e.stopPropagation()
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Loading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {data?.loadingAgent?.fullName
                        ? data.loadingAgent.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {data?.loadingAgent?.phone
                        ? data.loadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              <Box>
                <Avatar
                  sx={{
                    bgcolor: isEmptyObject(data.unloadingAgent)
                      ? '#9E9E9E'
                      : '#F82012',
                    cursor: isEmptyObject(data.unloadingAgent)
                      ? 'not-allowed'
                      : 'pointer',
                    pointerEvents: isEmptyObject(data.unloadingAgent)
                      ? 'none'
                      : 'auto',
                    '&:hover': {
                      boxShadow: '0 10px 6px -6px #777'
                    }
                  }}
                  aria-describedby={id2}
                  onClick={(e) => {
                    handleClick2(e)
                    e.stopPropagation()
                  }}
                >
                  U
                </Avatar>
                <Popover
                  sx={{ marginTop: 1 }}
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={(e) => {
                    handleClose2(e)
                    e.stopPropagation()
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box>
                    <Typography p={1} color="primary">
                      Unloading Agent
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {data?.unloadingAgent?.fullName
                        ? data.unloadingAgent.fullName
                        : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography p={1}>
                      {data?.unloadingAgent?.phone
                        ? data.unloadingAgent.phone
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Grid>

          <Grid
            xs={6}
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="flex-end"
            item
          >
            {!data.fareHistory?.fareAmount ? (
              <Box display="flex" alignItems="center" gap={1}>
                <IconButton
                  size="small"
                  sx={{ bgcolor: 'rgba(63, 81, 181, 0.08)' }}
                >
                  <HourglassBottomIcon size="small" color="primary" />
                </IconButton>
                <Typography color="primary" fontWeight="bold" variant="body2">
                  Wating for Rates
                </Typography>
              </Box>
            ) : (
              <>
                <Typography color="primary" fontWeight="bold" variant="body2">
                  <span style={{ color: '#1E293B' }}>Per Truck Rate: </span>৳
                  {data.fareHistory?.fareAmount || 'N/A'}
                </Typography>
                {!approved ? (
                  <>
                    {!confirm ? (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: '40px',
                          textTransform: 'capitalize'
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setConfirm(true)
                        }}
                      >
                        Approve
                      </Button>
                    ) : (
                      <Box display="flex" gap={1} alignItems="center">
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ borderRadius: '16px' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            approveFairAmount(data.fareHistory?.id)
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation()
                            setConfirm(false)
                          }}
                          size="small"
                          sx={{ borderRadius: '16px' }}
                        >
                          No
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography color="green" fontWeight="bold" variant="body2">
                    (Approved)
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <BookingCancelModal
        open={openCancelModal}
        setToast={setToast}
        onClose={() => toggleOpen(false)}
        bookingId={data.id}
        setBookingStatus={setBookingStatus}
        handleClose={handleClose}
      />
    </>
  )
}

BookingCard.propTypes = {
  setToast: PropTypes.func,
  data: PropTypes.object,
  onItemClick: PropTypes.func
}

export default BookingCard
