import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { getHumanReadableDate } from '../../../utils/functions'
import { Typography, Divider } from '@mui/material'

const AdditionalCharges = (props) => {
  const { charges } = props
  return (
    <>
      <Box mt={1} mb={1}>
        <Typography variant="body1" color="primary">
          Additional Charges
        </Typography>
      </Box>
      <Divider />
      <Box
        mt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="body1">Charge</Typography>
        </Box>
        <Box>
          <Typography variant="body1">From Date & time </Typography>
        </Box>
        <Box>
          <Typography variant="body1">To Date & Time</Typography>
        </Box>
        <Box>
          <Typography variant="body1">Amount</Typography>
        </Box>
      </Box>
      {charges.map((item) => (
        <Box
          key={item.id}
          sx={{ background: 'rgba(0, 0, 0, 0.04)' }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          mt={1}
        >
          <Box>
            <Typography variant="body1">#{item.id}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              {getHumanReadableDate(item.startDate)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              {getHumanReadableDate(item.endDate)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">BDT {item.shipperAmount}</Typography>
          </Box>
        </Box>
      ))}
    </>
  )
}

AdditionalCharges.propTypes = {
  charges: PropTypes.array
}

export default AdditionalCharges
