import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { usePostHook } from '../../../hooks/fetchHook'
import PropTypes from 'prop-types'
import CustomDatePicker2 from '../../../components/CustomDatePicker2'

const VendorPayableModal = (props) => {
  const {
    open,
    setToast,
    handleClose,
    supplierExternalId,
    supplierCompanyName,
    updateCardOnPayment
  } = props

  const [entryType, setEntryType] = useState('add-due')
  const [postingDate, setPostingDate] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [confirm, setConfirm] = useState('')

  const modalExit = () => {
    handleClose()
    setPostingDate('')
    setAmount('')
    setDescription('')
    setConfirm('')
  }
  const onDateChange = (newVal) => setPostingDate(newVal)

  const submitPayment = () => {
    let url = `/lf-acc/api/v1/suppliers/${supplierExternalId}/${entryType}`

    const payload = {
      description,
      amount,
      postingDate
    }

    !postingDate && delete payload.postingDate

    usePostHook(
      url,
      payload,
      (res) => {
        setToast('Supplier due added successfully', 'success')
        updateCardOnPayment(res.data.data)
        modalExit()
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={modalExit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <Typography fontWeight={'bold'} color="primary" variant="h6">
              {entryType.includes('reverse') && 'Reverse'}{' '}
              {!entryType.includes('payout') ? 'Due' : 'Pay'} to{' '}
              {supplierCompanyName || 'N/A'}
            </Typography>
            <IconButton onClick={modalExit}>
              <Close sx={{ fill: '#F82012' }} />
            </IconButton>
          </Box>
          <Divider />
          <Box display="flex" mt={2} flexDirection="column" gap={2}>
            <FormControl fullWidth sx={{ minWidth: 250, mb: 2 }}>
              <InputLabel id="demo-simple-select-label45">
                Entry Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label45"
                id="demo-simple-select45"
                value={entryType}
                label="Entry Type"
                onChange={(e) => setEntryType(e.target.value)}
              >
                <MenuItem value={'add-due'}>Due Amount</MenuItem>
                <MenuItem value={'payout-due'}>Payout Amount</MenuItem>
                <MenuItem value={'reverse-due-amount'}>
                  Reverse Due Amount
                </MenuItem>
                <MenuItem value={'reverse-payout-amount'}>
                  Reverse Payout Amount
                </MenuItem>
              </Select>
            </FormControl>

            <CustomDatePicker2
              disableFuture
              key={entryType}
              defaultDate={postingDate ? postingDate : null}
              label="Posting Date (Optional)"
              onChange={onDateChange}
            />

            <TextField
              type="number"
              onWheel={(event) => event.target.blur()}
              value={amount}
              label="Amount"
              onChange={(e) => setAmount(e.target.value)}
              onKeyPress={(e) => {
                if (e.target.value.length == 0 && e.key == 0) {
                  e.preventDefault()
                  return false
                }
              }}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
              }
            />
            <TextField
              type="text"
              value={description}
              label="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
            {confirm ? (
              <>
                <Box display="flex" gap={1} alignItems="center">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={submitPayment}
                    sx={{ borderRadius: '16px' }}
                    fullWidth
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={modalExit}
                    sx={{ borderRadius: '16px' }}
                    fullWidth
                  >
                    No
                  </Button>
                </Box>
              </>
            ) : (
              <Button onClick={() => setConfirm(true)} variant="contained">
                Submit Now
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default VendorPayableModal

VendorPayableModal.propTypes = {
  setToast: PropTypes.func,
  handleClose: PropTypes.func,
  supplierExternalId: PropTypes.string,
  supplierCompanyName: PropTypes.string,
  open: PropTypes.bool,
  updateCardOnPayment: PropTypes.func
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2
}
