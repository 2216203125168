/* eslint-disable indent */
import { Box, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { PrimaryButton } from '../../../components/Landing/StyledComponents/StyledComponents'
import PropTypes from 'prop-types'
import { usePostHook, usePutHook } from '../../../hooks/fetchHook'
import useToast from '../../../hooks/useToast'
import Snackbars from '../../../components/Snackbar/Snackbar'

const FinanceDrawer = ({ onClose, data, setFormulaStatus, setCompanies }) => {
  const [formData, setFormData] = useState({
    companyId: data.companyId,
    formula: data.formula,
    aitPercent: data.ait,
    vatPercent: data.vat,
    baseAmount: '',
    marginAmount: ''
  })

  const [previewFairAmount, setPrevieFairAmount] = useState()
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  useEffect(() => {
    const meta = JSON.parse(localStorage.getItem('metaData'))
    if (meta && data.companyId == meta.companyId) {
      setPrevieFairAmount(meta)
      setFormData((prev) => ({
        ...prev,
        formula: meta.formula,
        aitPercent: meta.aitPercent,
        vatPercent: meta.vatPercent,
        baseAmount: meta.baseAmount,
        marginAmount: meta.marginAmount
      }))
    }
  }, [data])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  // V = {(VA + M) / (100 + VPV)} * VPV;A = (VA + M - V) * AP;NM = M - V - A;TA = VA + NM + A + V;

  const testFareAmount = () => {
    let url = '/lf-company/api/v1/admin/company/fare-amount/test'

    usePostHook(
      url,
      formData,
      (res) => {
        localStorage.setItem(
          'metaData',
          JSON.stringify({
            ...res.data.data,
            companyId: data.companyId,
            formula: formData.formula
          })
        )
        setPrevieFairAmount(res.data.data)
      },
      (err) => setToast(err && err.response.data.message, 'error'),
      true
    )
  }

  const saveFareAmount = () => {
    let url = '/lf-company/api/v1/admin/company/fare-amount'
    const payload = {
      companyId: data.companyId,
      formula: formData.formula,
      vatPercent: formData.vatPercent,
      aitPercent: formData.aitPercent
    }
    usePutHook(
      url,
      payload,
      (res) => {
        setCompanies((prev) =>
          prev.map((item) =>
            item.companyId !== res.data.data.companyId
              ? item
              : {
                  ...item,
                  formula: res.data.data.formula,
                  vat: res.data.data.vatPercent,
                  ait: res.data.data.aitPercent
                }
          )
        )
        setFormulaStatus('Yes')
        setToast(res.data.message, 'success')
      },
      (err) => setToast(err && err.response.data.message, 'error'),
      true
    )
  }

  return (
    <Box p={2} sx={{ width: '50vw' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '50vw' }}
        mb={3}
      >
        <Typography color="primary" variant="h6">
          {data.companyName
            ? data.companyName.charAt(0).toUpperCase() +
              data.companyName.slice(1)
            : 'N/A'}
          &nbsp;
          <span style={{ color: '#1E293B' }}> (Fare Amount Formula)</span>
        </Typography>
        <CloseIcon color="error" onClick={onClose} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            name="formula"
            value={formData.formula}
            onChange={handleInputChange}
            label="Input Formula"
            fullWidth
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            type="number"
            onWheel={(event) => event.target.blur()}
            name="baseAmount"
            value={formData.baseAmount}
            onChange={handleInputChange}
            label="Truck Fare Amount"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            type="number"
            onWheel={(event) => event.target.blur()}
            name="marginAmount"
            value={formData.marginAmount}
            onChange={handleInputChange}
            label="Margin"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            type="number"
            onWheel={(event) => event.target.blur()}
            name="vatPercent"
            value={formData.vatPercent}
            onChange={handleInputChange}
            label="VAT %"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            name="aitPercent"
            type="number"
            onWheel={(event) => event.target.blur()}
            value={formData.aitPercent}
            onChange={handleInputChange}
            label="AIT %"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} />
        <Grid mb={2} item xs={6}>
          <PrimaryButton
            style={{ height: '100%' }}
            variant="contained"
            fullWidth
            disabled={
              !formData.companyId ||
              !formData.formula ||
              !formData.aitPercent ||
              !formData.vatPercent ||
              !formData.baseAmount ||
              !formData.marginAmount
            }
            onClick={testFareAmount}
          >
            Test now
          </PrimaryButton>
        </Grid>
      </Grid>
      {previewFairAmount && (
        <>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px'
            }}
          >
            <Typography
              p={1}
              color="primary"
              variant="subtitle1"
              fontWeight={500}
            >
              Preview
            </Typography>
            <Divider />
            <Box
              px={1}
              mt={1}
              mb={1}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="body1" fontWeight={400}>
                  Truck Fare Amount
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.baseAmount} BDT
                </Typography>
              </Box>
            </Box>
            <Box px={1} mb={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={400}>
                  Margin
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.marginAmount} BDT
                </Typography>
              </Box>
            </Box>
            <Box px={1} mb={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={400}>
                  VAT
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.vatAmount} BDT
                </Typography>
              </Box>
            </Box>
            <Box px={1} mb={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={400}>
                  AIT
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.aitAmount} BDT
                </Typography>
              </Box>
            </Box>
            <Box px={1} mb={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1" fontWeight={400}>
                  Net Margin
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.netMarginAmount} BDT
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box p={1} display="flex" justifyContent="space-between">
              <Box>
                <Typography color="primary" variant="body1" fontWeight={400}>
                  Total
                  <span style={{ color: '#000' }}>(Fair Amount)</span>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  {previewFairAmount.fareAmount} BDT
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid mt={2} container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <PrimaryButton
                sx={{ height: '100%' }}
                variant="contained"
                fullWidth
                onClick={saveFareAmount}
              >
                Save now
              </PrimaryButton>
            </Grid>
          </Grid>
        </>
      )}
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  )
}

FinanceDrawer.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  setFormulaStatus: PropTypes.func,
  setCompanies: PropTypes.func
}

export default FinanceDrawer
