import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { ShipperRowItem } from '../../components/HomeContainer/StyledComponents/StyledComponents'
import '../ShipperList/styles.css'
import { Chip, Grid } from '@mui/material'
import { formatStage, timesAgo } from '../../utils/functions'

const OperationPersonnel = (props) => {
  const navigate = useNavigate()
  const { data, showLastLogin } = props
  const [searchParams] = useSearchParams()
  const navigateToDetail = () => {
    let route = `/home/user/${data.role}/${data.userId}`
    navigate(route)
  }

  return (
    <Box pt={2} onClick={navigateToDetail}>
      <ShipperRowItem
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={1}>
          <Grid item xs={0.5}>
            <Avatar
              alt={data.fullName}
              src={data.profilePicture ? data.profilePicture : '/asdas'}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="col-head" variant="body2">
              Name
            </Typography>
            <Typography className="col-value" variant="body">
              {data.fullName}
            </Typography>
          </Grid>
          {(searchParams.get('tab') == 3 || searchParams.get('tab') == 4) && (
            <Grid item xs={1}>
              <Typography className="col-head" variant="body2">
                Team
              </Typography>
              <Typography className="col-value" variant="body">
                {formatStage(data.team)}
              </Typography>
            </Grid>
          )}

          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Mobile Number
            </Typography>
            <Typography className="col-value" variant="body">
              {data.phone}
            </Typography>
          </Grid>
          <Grid item xs={showLastLogin ? 2 : 3}>
            <Typography className="col-head" variant="body2">
              Email Address
            </Typography>
            <Typography className="col-value" variant="body">
              {data.email}
            </Typography>
          </Grid>
          {searchParams.get('tab') == 3 && (
            <Grid item xs={1}>
              <Typography className="col-head" variant="body2">
                Zone
              </Typography>
              <Chip
                color="primary"
                size="small"
                label={formatStage(data.zone)}
                sx={{ width: '100px', textAlign: 'center' }}
              />
            </Grid>
          )}
          <Grid item xs={searchParams.get('tab') == 3 ? 1 : 2}>
            <Typography className="col-head" variant="body2">
              Created By
            </Typography>
            <Typography className="col-verified" variant="body">
              {data.createdByRole === 'admin'
                ? 'Admin'
                : data.createdByFullname}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Last Login
            </Typography>
            <Typography className="col-verified" variant="body">
              {data.lastLogin ? timesAgo(data.lastLogin) : 'N/A'}
            </Typography>
          </Grid>
          {showLastLogin && (
            <Grid item xs={1}>
              <Typography className="col-head" variant="body2">
                Last Activity
              </Typography>
              <Typography className="col-verified" variant="body">
                {data.lastActivity
                  ? timesAgo(data.lastActivity)
                  : timesAgo(data.lastLogin)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ShipperRowItem>
    </Box>
  )
}

OperationPersonnel.propTypes = {
  data: PropTypes.object,
  showLastLogin: PropTypes.any
}

export default OperationPersonnel
