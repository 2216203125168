import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../../hooks/fetchHook'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import WorkIcon from '@mui/icons-material/Work'
import Divider from '@mui/material/Divider'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { formatStage, getPropValue } from '../../../utils/functions'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AddChargeModal from '../../AddCharge/AddChargeModal'
import AdditionalCharges from './AdditionalCharges'

const Invoice = (props) => {
  const { booking, setToast } = props
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [reloadCount, setReloadCount] = useState(0)
  const [invoice, setInvoice] = useState({})
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let url = `/lf-booking/api/v1/admin/booking/charge?bookingId=${booking.id}&limit=1&page=${page}`

    useGetHook(
      url,
      (res) => {
        if (res.data.data.length > 0) {
          setInvoice(res.data.data[0])
          setTotal(res.data.count)
        }
      },
      () => setToast('Something went wrong', 'error'),
      true
    )
  }, [reloadCount, page])

  const reloadInvoice = () => {
    setReloadCount((oldValue) => oldValue + 1)
  }

  const calculateTotal = () => {
    if (invoice.tripCharges.length > 0) {
      let amounts = invoice.tripCharges.map((x) => x.shipperAmount)
      let total = amounts.reduce((partialSum, a) => partialSum + a, 0)
      return total
    }
    return 'N/A'
  }

  const next = () => {
    setPage((oldValue) => oldValue + 1)
  }

  const previous = () => {
    setPage((oldValue) => oldValue - 1)
  }

  // return <div>Invoice</div>

  return (
    <>
      {total > 0 ? (
        <>
          <Box p={2} mb={1}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h6">Total Invoice</Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="h6" color="primary">
                  &nbsp;({page} of {total})
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{ borderRadius: '41px' }}
                  size="small"
                  variant="contained"
                >
                  Email Now
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  sx={{ borderRadius: '41px' }}
                  size="small"
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Add Extra Charge
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  sx={{ borderRadius: '41px' }}
                  size="small"
                  variant="contained"
                  endIcon={<DownloadForOfflineIcon />}
                >
                  Download
                </Button>
              </Box>
            </Box>
            <Box mt={3} className="booking-metadata-box">
              <Box p={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography color="primary" variant="h6">
                      Invoice
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary" variant="h6">
                      {invoice?.id}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography color="primary" variant="h6">
                      {getPropValue('name', booking.company)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    color="primary"
                    variant="body2"
                    className="invoice-terms"
                  >
                    {getPropValue('nameEn', booking.company.companyAddress)}
                  </Typography>
                </Box>
                <Box
                  mt={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row-reverse"
                >
                  <Box>
                    <Chip
                      sx={{
                        background: '#ED6C02',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                      }}
                      label={formatStage(invoice.tripStatus)}
                    />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box p={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="body1">Trip ID</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Status</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Truck Type</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Item Type</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Amount</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ background: 'rgba(0, 0, 0, 0.04)' }}
                  mt={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                >
                  <Box>
                    <Typography variant="body1">
                      #{getPropValue('id', invoice)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary" variant="body1">
                      {formatStage(getPropValue('tripStatus', invoice))}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      {invoice?.truck?.truckType
                        ? invoice?.truck?.truckType?.truckTypeEn
                        : 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      {invoice?.booking?.bookingItems[0]?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      BDT {invoice.fareAmount}
                    </Typography>
                  </Box>
                </Box>

                {invoice.tripCharges && invoice.tripCharges.length > 0 ? (
                  <AdditionalCharges charges={invoice.tripCharges} />
                ) : null}
              </Box>
              <Divider />
              <Box mt={1} display="flex" p={1} justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton
                      sx={{
                        background: 'rgba(63, 81, 181, 0.08)',
                        borderRadius: '48px'
                      }}
                    >
                      <WorkIcon sx={{ fill: '#0071CD' }} />
                    </IconButton>
                  </Box>
                  <Box ml={1}>
                    <Typography variant="body2">Driver Name</Typography>
                    <Typography variant="body2">
                      {getPropValue('fullName', invoice.driver)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton
                      sx={{
                        background: 'rgba(63, 81, 181, 0.08)',
                        borderRadius: '48px'
                      }}
                    >
                      <LocalPhoneIcon sx={{ fill: '#0071CD' }} />
                    </IconButton>
                  </Box>
                  <Box ml={1}>
                    <Typography variant="body2">Driver Phone Number</Typography>
                    <Typography variant="body2">
                      {getPropValue('phone', invoice.driver)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton
                      sx={{
                        background: 'rgba(63, 81, 181, 0.08)',
                        borderRadius: '48px'
                      }}
                    >
                      <LocalShippingIcon sx={{ fill: '#0071CD' }} />
                    </IconButton>
                  </Box>
                  <Box ml={1}>
                    <Typography variant="body2">Truck Plate Number</Typography>
                    <Typography variant="body2">
                      {getPropValue('licensePlateNoTextEn', invoice.truck)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box p={2}>
                <Box
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="center"
                >
                  <Box ml={2}>
                    <Typography fontWeight="bold" variant="body1">
                      {calculateTotal()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontWeight="bold" variant="body1">
                      Sub-Total:{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="center"
                >
                  <Box ml={7}>
                    <Typography fontWeight="bold" variant="body1">
                      0
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontWeight="bold" variant="body1">
                      Advance:{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="center"
                >
                  <Box ml={2}>
                    <Typography fontWeight="bold" variant="body1">
                      {calculateTotal()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontWeight="bold" variant="body1">
                      Total BDT:{' '}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={previous}
                  disabled={page === 1}
                  size="small"
                >
                  Previous
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={next}
                  disabled={page === total}
                  size="small"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
          <AddChargeModal
            open={open}
            reloadInvoice={reloadInvoice}
            invoice={invoice}
            onClose={setOpen}
            setToast={setToast}
          />
        </>
      ) : (
        <Box p={2}>
          <Typography variant="h6">No Data Available</Typography>
        </Box>
      )}
    </>
  )
}

Invoice.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func
}

export default Invoice
