/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { CardItem } from './StyledComponents'
import { Typography } from '@mui/material'
import { formatReportStage, isEmptyObject } from '../../utils/functions'
import humanizeNumber from 'humanize-number'

function getCountColor(status) {
  switch (status) {
    case 'createdBookings':
      return '#1E293B'
    case 'createdTrips':
      return 'primary'
    case 'expiredTrips':
      return 'error'
    case 'activeTrips':
      return 'primary'
    case 'cancelledTrips':
      return 'error'
    case 'completedTrips':
      return '#4CAF50'
    case 'manualBidding':
      return 'error'
    case 'manualFareApproved':
      return 'error'
    case 'manualTripActivated':
      return '#1E293B'
    case 'shipperQuoted':
      return 'warning'
    case 'vendorSelected':
      return 'error'
    default:
      return '#ffb400'
  }
}

const AdditionalMetrics = (props) => {
  const { metrics } = props

  function getActionCounts() {
    let actionCounts = []
    if (!isEmptyObject(metrics.actionCounts)) {
      actionCounts = Object.entries(metrics.actionCounts).map((item, i) => {
        const [key, value] = item
        return (
          <CardItem key={i} className="card-box">
            <Typography
              color={getCountColor(key)}
              variant="h4"
              gutterBottom
              component="div"
            >
              {value}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {formatReportStage(key)}
            </Typography>
          </CardItem>
        )
      })
    }
    return actionCounts
  }

  function getTripCounts() {
    let tripCounts = []
    if (!isEmptyObject(metrics.tripCounts)) {
      tripCounts = Object.entries(metrics.tripCounts).map((item, i) => {
        const [key, value] = item
        return (
          <CardItem key={i} className="card-box">
            <Box>
              <Typography
                color={getCountColor(key)}
                variant="h4"
                gutterBottom
                component="div"
              >
                {value
                  ? humanizeNumber(value, {
                      delimiter: ',',
                      separator: '.'
                    })
                  : 0}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">{formatReportStage(key)}</Typography>
            </Box>
          </CardItem>
        )
      })
    }
    return tripCounts
  }

  return (
    <Box>
      <Box
        full
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mb={2}
        mt={2}
      >
        {getTripCounts()}
      </Box>
      <Box
        full
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        {getActionCounts()}
      </Box>
    </Box>
  )
}

AdditionalMetrics.propTypes = {
  metrics: PropTypes.object
}

export default AdditionalMetrics
