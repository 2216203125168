import * as React from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const CustomDatePicker2 = (props) => {
  const {
    onChange,
    size,
    label,
    defaultDate = null,
    required = false,
    ...rest
  } = props
  const [date, setDate] = React.useState(
    defaultDate ? dayjs(defaultDate) : null
  )

  const onFromDateChange = (newValue) => {
    setDate(newValue)
    onChange(newValue)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...rest}
        label={label || 'Select Date'}
        value={date}
        onChange={(newValue) => {
          onFromDateChange(newValue)
        }}
        renderInput={(params) => (
          <TextField
            required={required}
            size={size || 'medium'}
            fullWidth
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  )
}

CustomDatePicker2.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.string,
  label: PropTypes.string,
  defaultDate: PropTypes.string,
  required: PropTypes.bool,
  inputFormat: PropTypes.string
}

export default CustomDatePicker2
