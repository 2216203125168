import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'

const PayableBatchRequestListItem = ({
  bgcolor,
  color,
  title,
  count,
  textColor
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 1,
        // flexGrow: 1,
        bgcolor: bgcolor || '#ffffff',
        height: '100%'
        // p: 3
      }}
    >
      <Typography
        color={textColor || '#9E9E9E'}
        fontWeight={500}
        variant="body2"
      >
        {title}
      </Typography>
      <Typography
        textTransform="capitalize"
        fontWeight={700}
        color={color}
        variant="body2"
      >
        {count}
      </Typography>
    </Box>
  )
}

PayableBatchRequestListItem.propTypes = {
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.string | PropTypes.number,
  title: PropTypes.string,
  textColor: PropTypes.string
}

export default PayableBatchRequestListItem
