import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const AvgFullfillmentRateIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5Z"
        fill="#0071CD"
      />
      <path
        d="M27.45 47C28.6833 48.3 30.1333 49.2917 31.8 49.975C33.4667 50.6583 35.2 51 37 51C40.9 51 44.2083 49.6417 46.925 46.925C49.6417 44.2083 51 40.9 51 37C51 33.1 49.6417 29.7917 46.925 27.075C44.2083 24.3583 40.9 23 37 23V37L27.45 47ZM37 57C34.1667 57 31.5333 56.4917 29.1 55.475C26.6667 54.4583 24.55 53.05 22.75 51.25C20.95 49.45 19.5417 47.3333 18.525 44.9C17.5083 42.4667 17 39.8333 17 37C17 34.2 17.5083 31.5833 18.525 29.15C19.5417 26.7167 20.95 24.6 22.75 22.8C24.55 21 26.6667 19.5833 29.1 18.55C31.5333 17.5167 34.1667 17 37 17C39.8 17 42.4167 17.5167 44.85 18.55C47.2833 19.5833 49.4 21 51.2 22.8C53 24.6 54.4167 26.7167 55.45 29.15C56.4833 31.5833 57 34.2 57 37C57 39.8333 56.4833 42.4667 55.45 44.9C54.4167 47.3333 53 49.45 51.2 51.25C49.4 53.05 47.2833 54.4583 44.85 55.475C42.4167 56.4917 39.8 57 37 57ZM37 54C41.8333 54 45.875 52.375 49.125 49.125C52.375 45.875 54 41.8333 54 37C54 32.1667 52.375 28.125 49.125 24.875C45.875 21.625 41.8333 20 37 20C32.1667 20 28.125 21.625 24.875 24.875C21.625 28.125 20 32.1667 20 37C20 41.8333 21.625 45.875 24.875 49.125C28.125 52.375 32.1667 54 37 54Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default AvgFullfillmentRateIcon
