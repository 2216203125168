import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getPropValue } from '../../../utils/functions'
import { usePostHook } from '../../../hooks/fetchHook'
import UploadModal from '../UploadModal'

const SummaryData = ({ data, fromDate, toDate, company, setToast }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const downloadRequest = () => {
    const payload = {
      companyId: company?.id,
      fromDate,
      toDate
    }
    let url = '/lf-booking/api/v1/admin/booking/summary-req'
    usePostHook(
      url,
      payload,
      (res) => {
        setToast(res.data.message, 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box
        bgcolor="#FFFFFF"
        boxShadow="0px 0px 30px -6px rgba(0, 0, 0, 0.15)"
        border="20px"
        display="flex"
        flexDirection="column"
        gap={1}
        p={4}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary">
            Company Name
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Typography variant="body1" color="textSecondary">
              From Date:
            </Typography>
            <Typography variant="subtitle1">
              {getPropValue('fromDate', data)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="primary">
            {getPropValue('companyName', data)}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Typography variant="body1" color="textSecondary">
              To Date:
            </Typography>
            <Typography variant="subtitle1">
              {getPropValue('toDate', data)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Number of Trip
          </Typography>
          <Typography variant="subtitle1">
            {getPropValue('totalTrip', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Fare amount
          </Typography>
          <Typography color="primary" variant="subtitle1">
            &#2547;{getPropValue('shipperTripAmount', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Demurrage
          </Typography>
          <Typography color="primary" variant="subtitle1">
            &#2547;{getPropValue('shipperDemurrage', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Extra
          </Typography>
          <Typography color="primary" variant="subtitle1">
            &#2547;{getPropValue('shipperExtraCost', data)}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Vendor Fare
          </Typography>
          <Typography color="#ED6C02" variant="subtitle1">
            &#2547;{getPropValue('supplierTripAmount', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Demurrage
          </Typography>
          <Typography color="#ED6C02" variant="subtitle1">
            &#2547;{getPropValue('supplierDemurrage', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Extra
          </Typography>
          <Typography color="#ED6C02" variant="subtitle1">
            &#2547;{getPropValue('supplierExtraCost', data)}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Submitted Bill
          </Typography>
          <Typography variant="subtitle1">
            {getPropValue('totalBillSubmitted', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Not Submitted Bill
          </Typography>
          <Typography variant="subtitle1">
            {getPropValue('billNotSubmitted', data)}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Shipper Fare
          </Typography>
          <Typography color="primary" variant="subtitle1">
            &#2547;{getPropValue('shipperTotalAmount', data)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body1" color="textSecondary">
            Total Vendor Fare
          </Typography>
          <Typography color="primary" variant="subtitle1">
            &#2547;{getPropValue('supplierTotalAmount', data)}
          </Typography>
        </Box>
      </Box>
      {/* BUTTONS */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mt={2}
      >
        <Button size="large" onClick={onOpen} variant="contained">
          Upload Data
        </Button>
        {fromDate && toDate && company?.id && (
          <Button size="large" onClick={downloadRequest} variant="contained">
            Download request
          </Button>
        )}
        <UploadModal open={open} onClose={onClose} setToast={setToast} />
      </Box>
    </>
  )
}

SummaryData.propTypes = {
  data: PropTypes.object,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  company: PropTypes.object,
  setToast: PropTypes.func
}

export default SummaryData
