import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Snackbars from '../../components/Snackbar/Snackbar'
import { usePostHook } from '../../hooks/fetchHook'
import PassWordInput from '../../components/PassWordInput'

const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const setToast = (msg, severity) => {
    if (!msg) msg = 'Something went wrong'
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const onPasswordChange = (val) => setPassword(val)
  const onConfirmPasswordChange = (val) => setConfirmPassword(val)

  const changePassword = () => {
    if (password === confirmPassword) {
      let url = '/lf-user/api/v1/users/change-password'
      const payload = {
        password,
        confirmPassword
      }
      usePostHook(
        url,
        payload,
        () => {
          setToast('Password update successfull', 'success')
        },
        (err) => setToast(err?.response?.data?.message, 'error'),
        true
      )
    } else {
      setToast('Password must be the same', 'error')
    }
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PassWordInput label="New Password" onChange={onPasswordChange} />
        </Grid>
        <Grid item xs={6}>
          <PassWordInput
            label="Confirm Password"
            onChange={onConfirmPasswordChange}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: '100%' }}
            onClick={changePassword}
            color="primary"
            size="lg"
          >
            Confirm Now
          </Button>
        </Grid>
      </Grid>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  )
}

export default ChangePassword
