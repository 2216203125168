import { Alert, Snackbar, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { onMessageListener } from '../../messaging/fcm'
import { useStore } from '../../store'

const Notification = () => {
  const audioRef = useRef(null)
  const { notificationsStore } = useStore()
  const [notification, setNotification] = useState({ title: '', body: '' })
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (notification?.title) {
      handleOpen()
      var resp = audioRef.current.play()
      if (resp !== undefined) {
        resp
          .then(() => {
            audioRef.current.play()
          })
          .catch(() => {
            //show error
            // console.log(error)
          })
      }
    }
  }, [notification, audioRef])

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body
      })
      notificationsStore.updateNotifications({
        ...notificationsStore.notifications,
        unreadCount: (notificationsStore.notifications.unreadCount += 1)
      })
    })
    .catch(() => {})

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h6">
            {notification.title}
          </Typography>
          <Typography maxWidth="450px" variant="body1">
            {notification.body}
          </Typography>
        </Alert>
      </Snackbar>
      <audio
        ref={audioRef}
        id="myAudio"
        src="https://cdn.loopfreight.io/public/default_sound.mp3"
      />
    </>
  )
}

export default Notification
