import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import './index.css'

const StatusCount = (props) => {
  const { type, count, status, statusColor } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      className={type || 'basic'}
    >
      <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
        {count}
      </Typography>
      <Typography color={statusColor || 'inherit'} variant="subtitle2">
        {status}
      </Typography>
    </Box>
  )
}

export default StatusCount

StatusCount.propTypes = {
  type: PropTypes.string,
  count: PropTypes.string | PropTypes.number,
  status: PropTypes.string,
  statusColor: PropTypes.string
}
