import React from 'react'
import { getPropValue, timesAgo } from '../../utils/functions'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { ShipperRowItem } from '../../components/HomeContainer/StyledComponents/StyledComponents'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Pro = (props) => {
  const { data } = props
  const navigate = useNavigate()
  return (
    <Box onClick={() => navigate(`/home/user/pro/${data.userId}`)} pt={2}>
      <ShipperRowItem>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={1}>
            <Avatar
              alt={data.fullName}
              src={data.profilePicture ? data.profilePicture : 'as'}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className="col-head" variant="body2">
              Name
            </Typography>
            <Typography className="col-value" variant="body">
              {data.fullName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Mobile Number
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('phone', data)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Email Address
            </Typography>
            <Typography className="col-value" variant="body">
              {getPropValue('email', data)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Last Login
            </Typography>
            <Typography className="col-verified" variant="body">
              {data.lastLogin ? timesAgo(data.lastLogin) : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="col-head" variant="body2">
              Last Activity
            </Typography>
            <Typography className="col-verified" variant="body">
              {/* {data.lastLogin ? timesAgo(data.lastLogin) : 'N/A'} */}
              {data.lastActivity
                ? timesAgo(data.lastActivity)
                : timesAgo(data.lastLogin)}
            </Typography>
          </Grid>
        </Grid>
      </ShipperRowItem>
    </Box>
  )
}

Pro.propTypes = {
  data: PropTypes.object
}

export default Pro
