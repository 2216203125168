import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { usePutHook } from '../../hooks/fetchHook'
import PersonIcon from '@mui/icons-material/Person'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {
  BorderedColumn,
  ProfileInfoWrapper,
  IconAvatar,
  LabelText,
  ValueText
} from './StyledComponents'
import { getFormattedDate } from '../../utils/functions'
import tin from '../../assests/subsType/tin.svg'
import gold from '../../assests/subsType/gold.svg'
import silver from '../../assests/subsType/silver.svg'
import bronze from '../../assests/subsType/bronze.svg'
import platinum from '../../assests/subsType/platinum.svg'
import { subsTypes } from '../../constants'
import GppGood from '@mui/icons-material/GppGood'

const subsTypesImg = {
  tin,
  bronze,
  silver,
  gold,
  platinum
}

const CompanyBasicInfo = (props) => {
  const { vendor, setToast } = props
  const [isEnabled, setIsEnabled] = useState(vendor.isEnabled)
  const [isVerified, setIsVerified] = useState(vendor.isVerified)

  const handleVerifiedChange = (event) => {
    setIsVerified(event.target.checked)
    let url = `/lf-user/api/v1/admin/user/${vendor.userId}`
    usePutHook(
      url,
      { isVerified: event.target.checked },
      (res) => setToast(res.data?.message, 'success'),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const handleEnableChange = (event) => {
    setIsEnabled(event.target.checked)
    let url = `/lf-user/api/v1/admin/user/${vendor.userId}`
    usePutHook(
      url,
      { isEnabled: event.target.checked },
      (res) => setToast(res.data?.message, 'success'),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <BorderedColumn>
      <Box display={'flex'} justifyContent={'center'} gap={1} p={2}>
        <Avatar
          alt={vendor.companyName}
          src={vendor.profilePicture ? vendor.profilePicture : 'as'}
          sx={{ width: '150px', height: '150px' }}
        />
      </Box>
      <Box mb={1}>
        <Box
          display="flex"
          gap={0.5}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h6"
            color="primary"
            textAlign={'center'}
            fontWeight={'bold'}
            gutterBottom
          >
            {vendor.companyName}
          </Typography>
          {vendor.securitySponsor && <GppGood color="success" />}
        </Box>

        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          gap={1}
        >
          <Avatar
            alt={vendor.companyName}
            src={vendor.subsType ? subsTypesImg[vendor.subsType] : 'as'}
            sx={{ width: '36px', height: '36px' }}
          />
          <Typography
            variant="body1"
            color=" textSecondary "
            textAlign={'center'}
            fontWeight={'bold'}
            sx={{
              textTransform: 'capitalize'
            }}
          >
            {Object.values(subsTypes).find((item) => item == vendor.subsType)}
          </Typography>
        </Box>
      </Box>
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent={'space-between'}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={isEnabled} onChange={handleEnableChange} />
            }
            label={isEnabled ? 'Enable' : 'Disable'}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={isVerified} onChange={handleVerifiedChange} />
            }
            label={isVerified ? 'Verified' : 'Unverified'}
          />
        </FormGroup>
      </Box>

      <Divider />
      {/* <Box mt={1} mb={1} display="flex" justifyContent={'center'}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={verified} onChange={handleChange} />}
            label={verified ? 'Enable' : 'Disable'}
          />
        </FormGroup>
      </Box> */}
      <Divider />
      <Box mt={1} p={2}>
        <Typography fontWeight={'bold'}>Vendor Information</Typography>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <PersonIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Designation</LabelText>
            <ValueText>Vendor</ValueText>
          </Box>
        </ProfileInfoWrapper>

        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <PersonIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Full Name</LabelText>
            <ValueText>{vendor.fullName}</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <BusinessCenterIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Mobile Number</LabelText>
            <ValueText> {vendor.phone || 'N/A'}</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <BusinessCenterIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Email</LabelText>
            <ValueText> {vendor.email || 'N/A'}</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <BusinessCenterIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>NID Number</LabelText>
            <ValueText> {vendor.nid || 'N/A'}</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <BusinessCenterIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Date of birth</LabelText>
            <ValueText>
              {' '}
              {getFormattedDate(new Date(vendor.dob)) || 'N/A'}
            </ValueText>
          </Box>
        </ProfileInfoWrapper>
      </Box>
    </BorderedColumn>
  )
}

CompanyBasicInfo.propTypes = {
  vendor: PropTypes.object,
  setToast: PropTypes.func
}

export default CompanyBasicInfo
