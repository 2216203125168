import React from 'react'
import PropTypes from 'prop-types'
import { getHumanReadableDate, formatStage } from '../../utils/functions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import '../TruckList/style.css'
import { useNavigate } from 'react-router-dom'
import { Chip, Grid } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'

const Driver = (props) => {
  const { data } = props
  const navigate = useNavigate()

  return (
    <Box
      onClick={() => navigate(`/home/driver-profile/${data.id}`)}
      className="truck-item"
      mb={2}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography className="truck-item-label" variant="body2">
            Driver Name
          </Typography>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1">{data.fullName}</Typography>
            {data.isNidVerified ? (
              <CheckCircleIcon fontSize="small" color="success" />
            ) : (
              <UnpublishedIcon fontSize="small" color="error" />
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography className="truck-item-label" variant="body2">
            Driver Number
          </Typography>
          <Typography variant="body1">
            {data.phone ? data.phone : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography className="truck-item-label" variant="body2">
              Driver Status
            </Typography>
            <Chip
              className="truck-item-label"
              mt={1}
              label={`${formatStage(data.driverAvailability)} ${
                data.driverAvailability === 'in_trip'
                  ? `ID: ${data.tripId || 'N/A'}`
                  : ''
              } `}
              size="small"
              color={
                data.driverAvailability === 'available' ? 'success' : 'error'
              }
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography className="truck-item-label" variant="body2">
              Mode
            </Typography>
            <Chip
              className="truck-item-label"
              mt={1}
              label={data.status === 'active' ? 'Enabled' : 'Disabled'}
              size="small"
              color={data.status === 'active' ? 'success' : 'error'}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography className="truck-item-label" variant="body2">
            Created At
          </Typography>
          <Typography variant="body1">
            {getHumanReadableDate(data.createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

Driver.propTypes = {
  data: PropTypes.object
}

export default Driver
