import { createContext, useContext } from 'react'
import BookingStore from './BookingStore'
import GlobalSearchStore from './GlobalSearchStore'
import NotificationStore from './NotificationStore'
import UserStore from './UserStore'

const store = {
  userStore: new UserStore(),
  bookingStore: new BookingStore(),
  notificationsStore: new NotificationStore(),
  globalSearchStore: new GlobalSearchStore()
}

export const StoreContext = createContext(store)

export const useStore = () => {
  return useContext(StoreContext)
}

export default store
