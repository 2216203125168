import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Snackbars from '../../../components/Snackbar/Snackbar'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'
import { useForm } from 'react-hook-form'
import useToast from '../../../hooks/useToast'

const Profile = (props) => {
  const { userDetail } = props

  const { snackbarOpen, setSnackbarOpen, snackbarMessage, snackbarSeverity } =
    useToast()

  const {
    register,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      full_name: userDetail.fullName,
      zone: 'Dhaka',
      designation: 'pro',
      phone: userDetail.phone,
      email: userDetail.email
    }
  })

  // const setToast = (msg, severity) => {
  //   setSnackbarOpen(true)
  //   setSnackbarMessage(msg)
  //   setSnackbarSeverity(severity)
  // }

  // const setManualError = (name, message) => {
  //   setError(name, {
  //     type: 'manual',
  //     message
  //   })
  // }

  // const validateFields = () => {
  //   let valid = true
  //   if (getValues('full_name').trim() === '') {
  //     setManualError('full_name', 'Full Name cannot be empty')
  //     return false
  //   }
  //   return valid
  // }

  // const saveChanges = handleSubmit(() => {
  //   let isValid = validateFields()
  //   if (!isValid) return
  //   let formData = {
  //     fullName: getValues('full_name'),
  //     designation: getValues('designation'),
  //     office: getValues('zone')
  //   }

  //   let url = `/lf-user/api/v1/admin/hierarchy/user/detail/${userDetail.userId}`
  //   usePutHook(
  //     url,
  //     formData,
  //     (res) => {
  //       setToast('User profile updated', 'success')
  //       let { zone, designation } = res.data.data
  //       setUserDetail((s) => ({
  //         ...s,
  //         zone,
  //         designation
  //       }))
  //     },
  //     (error) => setToast(error?.response?.data?.message, 'error'),
  //     true
  //   )
  // })

  return (
    <>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={'Full Name*'}
              type="text"
              name={'full_name'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Name cannot be empty'
                },
                maxLength: {
                  value: 50,
                  message: 'Name cannot be larger than 50 characters'
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Email Address'}
              type="text"
              name={'email'}
              register={register}
              disabled
              errors={errors}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Cluster'}
              type="text"
              name={'cluster'}
              register={register}
              errors={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={'Phone Number*'}
              type="text"
              name={'phone'}
              register={register}
              disabled
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Phone Number cannot be empty'
                },
                maxLength: {
                  value: 14,
                  message: 'Phone Number cannot be larger than 14 characters'
                }
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Zone'}
              type="text"
              name={'zone'}
              register={register}
              errors={errors}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

Profile.propTypes = {
  userDetail: PropTypes.object,
  setUserDetail: PropTypes.func
}

export default Profile
