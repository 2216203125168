import { Box, Button, Pagination, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useGetHook, usePatchHook } from '../../../hooks/fetchHook'
import useFetch from '../../../hooks/useFetch'
import { isEmptyArray } from '../../../utils/functions'
import BatchRowItem from './BatchRowItem'
import PropTypes from 'prop-types'
import UploadDueModal from './UploadDueModal'

const PayableBatchRequestList = ({ setToast }) => {
  const [page, setPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  let url = '/lf-acc/api/v1/payable-batch-requests'
  const [{ data, pageCount }] = useFetch(
    url,
    true,
    {
      limit: 10,
      page
    },
    [page, reload]
  )

  const handleChange = (event, value) => {
    setPage(value)
  }

  const onApplyClick = (id) => {
    let url = `/lf-acc/api/v1/payable-batch-requests/${id}/start-task`

    let payload = {
      id
    }

    usePatchHook(
      url,
      payload,
      () => {
        setReload(!reload)
      },
      () => {},
      true
    )
  }

  const handleDueUpload = () => {
    let url =
      '/lf-acc/api/v1/payable-batch-requests/dues-upload-example-download'

    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'due_list'
    useGetHook(
      url,
      (res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        )
        link.click()
      },
      () => {},
      true,
      {
        responseType: 'blob'
      }
    )
  }

  return (
    <Box my={2}>
      <Box display="flex" mb={1} gap={2} justifyContent="end">
        <Button onClick={handleDueUpload} variant="outlined" color="info">
          Download sample csv file
        </Button>
        <Button onClick={onOpen} variant="contained" component="label">
          Due Upload
        </Button>
      </Box>
      {isEmptyArray(data) && (
        <Typography color="error">No data found!</Typography>
      )}

      {!isEmptyArray(data) &&
        data.map((row) => (
          <BatchRowItem key={row.id} data={row} onApplyClick={onApplyClick} />
        ))}

      <Box display="flex" justifyContent="end" alignItems="center" py={2}>
        <Pagination
          count={pageCount}
          color="primary"
          page={page}
          onChange={handleChange}
        />
      </Box>
      <UploadDueModal
        open={open}
        setReload={setReload}
        onClose={onClose}
        setToast={setToast}
      />
    </Box>
  )
}
PayableBatchRequestList.propTypes = {
  setToast: PropTypes.func
}
export default PayableBatchRequestList
