import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { usePostHook } from '../../hooks/fetchHook'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import {
  LightButton,
  PrimaryButton
} from '../../components/Landing/StyledComponents/StyledComponents'
import CustomDatePicker2 from '../../components/CustomDatePicker2'

const AddChargeModal = (props) => {
  const { open, onClose, setToast, invoice, reloadInvoice } = props
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [status, setStatus] = useState('demurrage')
  const [shipperAmount, setShipperAmount] = useState('')

  const handleChange = (e) => {
    let stat = e.target.value
    setStatus(stat)
  }

  const onFromDateChange = (newValue) => {
    setFromDate(newValue)
  }

  const onToDateChange = (newValue) => {
    setToDate(newValue)
  }

  const addCharge = () => {
    let startDate = new Date(fromDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    let endDate = new Date(toDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    let payload = {
      tripId: invoice.id,
      chargeType: status,
      startDate,
      endDate,
      shipperAmount: shipperAmount
    }
    let url = '/lf-booking/api/v1/admin/booking/charge'
    usePostHook(
      url,
      payload,
      (res) => {
        setToast(res.data.message, 'success')
        onClose(false)
        reloadInvoice()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5">Add Charges</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => onClose(false)}>
            <CloseIcon sx={{ fill: 'red' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box mt={1} p={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            label="Select Status"
            onChange={handleChange}
          >
            <MenuItem value={'demurrage'}>Demurrage</MenuItem>
            <MenuItem value={'cancellation'}>Cancellation</MenuItem>
            <MenuItem value={'extra_charge'}>Extra Charges</MenuItem>
          </Select>
        </FormControl>
        <Box mt={2} display="flex" alignItems="center" gap={1}>
          <Box flex={1}>
            <CustomDatePicker2
              label="Start Date*"
              onChange={onFromDateChange}
            />
          </Box>
          <Box flex={1}>
            <CustomDatePicker2 label="End Date*" onChange={onToDateChange} />
          </Box>
        </Box>
        <Box mt={2} display="flex" alignItems="center" gap={1}>
          <Box flex={1}>
            <TextField
              label="Shipper Amount*"
              type="number"
              onWheel={(event) => event.target.blur()}
              value={shipperAmount}
              onChange={(e) => setShipperAmount(e.target.value)}
              fullWidth
              onKeyDown={(e) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
              }
            />
          </Box>
          {/* <Box flex={1}>
            <TextField
              label="Vendor Amount*"
              type="number" onWheel={(event) => event.target.blur()}
              value={vendorAmount}
              onChange={(e) => setVendorAmount(e.target.value)}
              fullWidth
            />
          </Box> */}
        </Box>
        <Box mt={2} display="flex" alignItems="center" gap={1}>
          <Box flex={1}>
            <LightButton
              onClick={() => onClose(false)}
              variant="contained"
              fullWidth
            >
              Cancel
            </LightButton>
          </Box>
          <Box flex={1}>
            <PrimaryButton
              variant="contained"
              fullWidth
              disabled={!shipperAmount}
              onClick={addCharge}
            >
              Add Charge
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

AddChargeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  invoice: PropTypes.object,
  reloadInvoice: PropTypes.func
}

export default AddChargeModal
