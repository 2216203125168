import React, { useState, useEffect } from 'react'
import { useGetHook, usePostHook } from '../hooks/fetchHook'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import CustomTextField from './CustomTextField/CustomTextField'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const CreateInactiveCompanyModal = (props) => {
  const { onClose, open, setToast, prefillData, setRequestDetails } = props
  const [divisionId, setDivisionId] = useState('')
  const [locs, setLocs] = useState([])

  useEffect(() => {
    let url = '/lf-geolocation/api/v1/division'
    useGetHook(
      url,
      (res) => setLocs(res.data.data),
      () => {}
    )
  }, [])

  const {
    register,
    formState: { errors, isValid },
    getValues
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyName: prefillData.requestedCompanyName,
      tln: prefillData.requestedTln,
      designation: prefillData.requestedDesignation
    }
  })

  const saveChanges = () => {
    let payload = {
      name: getValues('companyName'),
      tln: getValues('tln')
    }
    if (divisionId) {
      let divisionIdx = locs.findIndex((x) => x.id === divisionId)
      payload.companyDivision = {
        id: divisionId,
        nameEn: locs[divisionIdx].nameEn,
        nameBn: locs[divisionIdx].nameBn
      }
    }
    let url = '/lf-company/api/v1/admin/company'
    usePostHook(
      url,
      payload,
      (res) => {
        setToast('Company has been created', 'success')
        onClose()
        setRequestDetails(res.data.data.requestDetail)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5">Create New Company</Typography>
        </Box>
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fill: 'red' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={'Company Name*'}
              type="text"
              name={'companyName'}
              register={register}
              errors={errors}
              criterions={{
                required: {
                  value: true,
                  message: 'Name cannot be empty'
                },
                maxLength: {
                  value: 50,
                  message: 'Name cannot be larger than 50 characters'
                }
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              label={'Trade license number*'}
              type="text"
              name={'tln'}
              register={register}
              errors={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Office Location</InputLabel>
              <Select
                value={divisionId}
                label="Office Location"
                onChange={(e) => setDivisionId(e.target.value)}
              >
                {locs.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: '100%' }}
              disabled={!isValid}
              onClick={saveChanges}
              color="primary"
            >
              Save Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

CreateInactiveCompanyModal.propTypes = {
  setRequestDetails: PropTypes.func,
  prefillData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setToast: PropTypes.func,
  refreshDriverList: PropTypes.func,
  fixedVendor: PropTypes.any
}

export default CreateInactiveCompanyModal
