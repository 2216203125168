import { initializeApp } from 'firebase/app'
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from 'firebase/messaging'
import { usePostHook } from '../hooks/fetchHook'
import { getConfigurationByKey } from '../utils/functions'

const analytics = getConfigurationByKey('analytics')
const firebaseApp = initializeApp(analytics.firebase)

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp)
    }
    // console.log('Firebase not supported this browser')
    return null
  } catch (err) {
    // console.log(err)
    return null
  }
})()

function handleSuccess() {}
function handleError() {}

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = messaging ? await messaging : null
      if (messagingResolve) {
        onMessage(messagingResolve, (payload) => {
          resolve(payload)
        })
      }
    })()
  )

export const getFcmToken = async (setTokenFound) => {
  try {
    const messagingResolve = await messaging
    const currentToken = await getToken(messagingResolve, {
      vapidKey: analytics.vapid
    })
    if (currentToken) {
      usePostHook(
        '/lf-notification/api/v1/user-token/',
        { deviceToken: currentToken },
        handleSuccess,
        handleError,
        true
      )
      // console.log('current token for client: ', currentToken)
      setTokenFound(true)
    } else {
      setTokenFound(false)
    }
  } catch (err) {
    // console.log('An error occurred while retrieving token. ', err)
  }
}

export const removeToken = async () => {
  const messagingResolve = messaging ? await messaging : null
  await deleteToken(messagingResolve)
}

// export const getFcmToken = (setTokenFound) => {
//   return getToken(messaging, { vapidKey: analytics.vapid })
//     .then((currentToken) => {
//       if (currentToken) {
//         usePostHook(
//           '/lf-notification/api/v1/user-token/',
//           { deviceToken: currentToken },
//           handleSuccess,
//           handleError,
//           true
//         )
//         console.log('current token for client: ', currentToken)
//         setTokenFound(true)
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log(
//           'No registration token available. Request permission to generate one.'
//         )
//         setTokenFound(false)
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err)
//       // catch error while creating client token
//     })
// }
