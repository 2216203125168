import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook, usePutHook } from '../../hooks/fetchHook'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getLoggedInUser, toQueryString } from '../../utils/functions'
import useDebouncedValue from '../../hooks/useDebounceValue'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useParams } from 'react-router-dom'

const ActiveSlugList = (props) => {
  const params = useParams()
  const {
    slug,
    setSlug,
    required,
    disabled,
    label,
    setSelectedSlugs,
    setToast
  } = props
  const [slugs, setSlugs] = useState([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const user = getLoggedInUser()

  useEffect(() => {
    const query = {
      search: debouncedSearch
    }

    let url = `/lf-company/api/v1/admin/slug/${toQueryString(query)}`

    useGetHook(
      url,
      (res) => {
        setSlugs(res.data.data.result)
      },
      () => {},
      true
    )
  }, [debouncedSearch])

  const onUserSelect = (e, newValue) => {
    if (!newValue) {
      setSlug({
        companyId: '',
        companyName: ''
      })
      return
    }

    if (newValue) {
      setSlug((prev) => ({
        ...prev,
        ...newValue
      }))
      if (newValue.companyId) {
        const payload = {
          companyId: newValue.companyId
        }
        let url
        url = `/lf-sup/api/v1/admin/supplier/${params.vendorId}`
        if (user.role === 'cluster_head' || user.role === 'kam') {
          url = `/lf-sup/api/v1/crm/supplier/${params.vendorId}`
        }
        usePutHook(
          url,
          payload,
          (res) => {
            setSelectedSlugs([...res.data.data.slugs])
          },
          (error) => {
            setToast(error?.response?.data?.message, 'error')
          },
          true
        )
      }
    }
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        value={slug}
        onChange={onUserSelect}
        filterOptions={(options) => {
          return options
        }}
        id={`free-solo-dialog-${slug + 1}`}
        options={slugs}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.companyName
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.companyId}>
            <Box
              display="flex"
              sx={{ borderBottom: '1px solid #eee', width: '100%' }}
              flexDirection="column"
            >
              <Typography variant="body1">{option.companyName}</Typography>
              <Typography
                variant="body2"
                color={option.isSlugActive ? 'success' : 'error'}
                gutterBottom
              >
                {option.isSlugActive ? 'Active' : 'Inactive'}
              </Typography>
            </Box>
          </li>
        )}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        clearOnBlur
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={label || 'Search User'}
          />
        )}
        sx={{ minWidth: 200 }}
      />
    </>
  )
}

ActiveSlugList.propTypes = {
  slug: PropTypes.object,
  setSlug: PropTypes.func,
  setToast: PropTypes.func,
  setSelectedSlugs: PropTypes.func,
  required: PropTypes.bool,
  role: PropTypes.string,
  team: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
}

export default ActiveSlugList
