import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import CustomTextField from '../../components/CustomTextField/CustomTextField'
import { usePostHook } from '../../hooks/fetchHook'
import { useForm } from 'react-hook-form'

const AddProUserModal = (props) => {
  const { open, onClose, setToast, refreshList } = props

  const {
    register,
    formState: { errors, isValid },
    getValues,
    resetField
  } = useForm({
    mode: 'onChange'
  })

  const saveUser = () => {
    let payload = {
      fullName: getValues('fullName'),
      email: getValues('email'),
      phone: getValues('phone'),
      password: getValues('password'),
      role: 'pro'
    }

    let url = '/lf-user/api/v1/admin/user'
    usePostHook(
      url,
      payload,
      () => {
        resetField('fullName')
        resetField('email')
        resetField('phone')
        resetField('password')
        setToast('Pro User has been created', 'success')
        onClose(false)
        refreshList()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Add Pro User</Typography>
        <IconButton onClick={() => onClose(false)}>
          <CloseIcon sx={{ fill: 'red' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        <Stack spacing={2}>
          <CustomTextField
            label={'Full Name*'}
            type="text"
            name={'fullName'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Name cannot be empty'
              },
              maxLength: {
                value: 50,
                message: 'Name cannot be larger than 50 characters'
              }
            }}
            fullWidth
          />

          <CustomTextField
            label={'Phone'}
            type="text"
            name={'phone'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Phone cannot be empty'
              },
              maxLength: {
                value: 14,
                message: 'Phone cannot be larger than 50 characters'
              }
            }}
            fullWidth
          />

          <CustomTextField
            label={'Email'}
            type="text"
            name={'email'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Email cannot be empty'
              }
            }}
            fullWidth
          />

          <CustomTextField
            label={'Password*'}
            type="password"
            name={'password'}
            register={register}
            errors={errors}
            criterions={{
              required: {
                value: true,
                message: 'Password cannot be empty'
              },
              minLength: {
                value: 6,
                message: 'Password cannot be lesser than 6 characters'
              }
            }}
            fullWidth
          />
        </Stack>
        <Box mt={2} display="flex" flexDirection="row-reverse">
          <Box>
            <Button
              variant="contained"
              color={'primary'}
              onClick={saveUser}
              disabled={!isValid}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

AddProUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setToast: PropTypes.func,
  refreshList: PropTypes.func
}

export default AddProUserModal
