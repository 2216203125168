/* eslint-disable no-undef */
/* eslint-disable indent */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { getHumanReadableDate } from '../../../../utils/functions'
import TruckTypeIcon from '../../../../components/SvgIcons/TruckTypeIcon'
import MoneyIcon from '../../../../components/SvgIcons/MoneyIcon'
import ItemTypeIcon from '../../../../components/SvgIcons/ItemTypeIcon'
import { IconAvatar } from '../../../CompanyProfile/StyledComponents'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import BillIcon from '../../../../components/SvgIcons/BillIcon'
import NoteIcon from '../../../../components/SvgIcons/NoteIcon'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import ClockIcon from '../../../../components/SvgIcons/ClockIcon'
import LoadingIcon from '../../../../components/SvgIcons/LoadingIcon'
import UnLoadingIcon from '../../../../components/SvgIcons/UnLoadingIcon'
import StoppageIcon from '../../../../components/SvgIcons/StoppageIcon'
import PaymentIcon from '../../../../components/SvgIcons/PaymentIcon'
import CustomGoogleMap from '../../../../components/CustomGoogleMap'

// var directionsService, directionsRenderer

const BookingDetailTab = (props) => {
  const { booking } = props
  const [call, setCall] = useState(false)
  // const handleApiLoaded = (map) => {
  //   // use map and maps objects
  //   try {
  //     //initiate direction service for drawing routes
  //     // eslint-disable-next-line no-undef
  //     directionsService = new google.maps.DirectionsService()
  //     // eslint-disable-next-line no-undef
  //     directionsRenderer = new google.maps.DirectionsRenderer({
  //       // suppressMarkers: true,
  //     })

  //     directionsRenderer.setMap(map)

  //     let origin = booking.fromLocation.address
  //     let destination = booking.toLocation.address
  //     var request = {
  //       origin: origin,
  //       destination: destination,
  //       waypoints: [],
  //       travelMode: 'DRIVING'
  //     }
  //     for (let i = 0; i < booking.stoppages.length; i++) {
  //       let item = booking.stoppages[i]
  //       request.waypoints.push({
  //         location: item.address,
  //         stopover: true
  //       })
  //     }

  //     directionsService.route(request, function (result, status) {
  //       if (status == 'OK') {
  //         directionsRenderer.setDirections(result)
  //       } else {
  //         //   setToast('Direction Request Failed', 'error')
  //       }
  //     })
  //     icons = {
  //       start: new google.maps.MarkerImage(
  //         LoadingIcon,
  //         new google.maps.Size(44, 32)
  //       ),
  //       end: new google.maps.MarkerImage(
  //         UnLoadingIcon,
  //         new google.maps.Size(44, 32)
  //       )
  //     }
  //   } catch (ex) {
  //     // setToast('Failed to load map', 'error')
  //   }
  // }

  // const Map = () =>
  //   memo(
  //     <CustomGoogleMap
  //       call={call}
  //       setCall={setCall}
  //       loadingP={{
  //         id: null,
  //         latitude: booking.fromLocation.latitude,
  //         longitude: booking.fromLocation.longitude,
  //         placeId: '',
  //         shortFormEn: '',
  //         formattedTextEn: '',
  //         textEn: '',
  //         type: ''
  //       }}
  //       unloadingP={{
  //         id: null,
  //         latitude: booking.toLocation.latitude,
  //         longitude: booking.toLocation.longitude,
  //         placeId: '',
  //         shortFormEn: '',
  //         formattedTextEn: '',
  //         textEn: '',
  //         type: ''
  //       }}
  //       handleLoadingPointDragLocation={(a, b) => {}}
  //       handleUnLoadingPointDragLocation={(a, b) => {}}
  //       handleStopageDragLocation={(a, b) => {}}
  //       waypointsList2={[...booking.stoppages]}
  //       isAddressModal={false}
  //     />
  //   )

  return (
    <Box p={2}>
      <Box className="booking-metadata-box" mb={3}>
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <Avatar
                alt={booking.shipper.fullName}
                src={
                  booking.shipper.profilePicture
                    ? booking.shipper.profilePicture
                    : 'asdasd'
                }
              />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                {booking.company.name}
              </Typography>
              <Typography variant="body2">
                {booking.shipper.fullName}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <ClockIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Start Date
              </Typography>
              <Typography variant="body2">
                {getHumanReadableDate(booking.pickupDate)}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <TruckTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Truck Type
              </Typography>
              <Typography variant="body2">
                {/* {booking.truckType.truckTypeEn} */}
                {booking.truckSize.nameEn}
                <b style={{ color: '#0071CD' }}>
                  &nbsp;x&nbsp;{booking.truckQuantity}
                </b>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <ItemTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Item Type
              </Typography>
              {booking.bookingItems[0].measurements?.length > 0 ? (
                <Typography variant="body2">
                  {booking.bookingItems[0].name}&nbsp;x&nbsp;
                  {booking.bookingItems[0].measurements.length > 0 &&
                    booking.bookingItems[0].measurements[0]?.value}
                  &nbsp;
                  {booking.bookingItems[0].measurements.length > 0 &&
                    booking.bookingItems[0].measurements[0]?.unit?.name}
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <IconAvatar>
                <CalendarTodayIcon sx={{ fill: '#0071CD' }} />
              </IconAvatar>
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Booking Date
              </Typography>
              <Typography variant="body2">
                {getHumanReadableDate(booking.createdAt)}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <MoneyIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Fare Amount Per Truck
              </Typography>
              <Typography variant="body2">
                {booking.fareHistory.fareAmount
                  ? booking.fareHistory.fareAmount
                  : 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <PaymentIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Item Quantity
              </Typography>
              {booking.bookingItems[0].measurements?.length > 1 ? (
                <Typography variant="body2">
                  {booking.bookingItems[0].measurements[1]?.value}
                  &nbsp; x &nbsp;
                  {booking.bookingItems[0].measurements[1]?.unit?.name}
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
          <Box flex={2} display="flex" alignItems="center" gap={1}>
            <Box>
              <BillIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Bill Of Landing No
              </Typography>
              <Typography variant="body2">{'N/A'}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={1} flexDirection="row-reverse" display="flex">
          <Box flex={2} display="flex" alignItems="start" gap={1}>
            <Box>
              <NoteIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Additional Note
              </Typography>
              <Typography
                sx={{
                  wordWrap: 'break-word',
                  maxWidth: '500px'
                }}
                variant="body2"
              >
                {booking.additionalNotes ? booking.additionalNotes : 'N/A'}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="start" gap={1}>
            <Box>
              <NoteIcon sx={{ width: '40px', height: '40px', color: 'red' }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  wordWrap: 'break-word',
                  maxWidth: '500px'
                }}
                variant="body1"
                color="primary"
              >
                Cancel Note
              </Typography>
              <Typography variant="body2">
                {booking.cancelReason ? booking.cancelReason : 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box width="100%" sx={{ height: '300px' }} mb={3}>
        {/* <GoogleMapReact
          bootstrapURLKeys={{
            key: getGoogleMapApiKey(),
            libraries: ['places']
          }}
          defaultCenter={center}
          defaultZoom={8}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ></GoogleMapReact> */}

        <CustomGoogleMap
          call={call}
          setCall={setCall}
          loadingP={{
            id: null,
            latitude: booking.fromLocation.latitude,
            longitude: booking.fromLocation.longitude,
            placeId: '',
            shortFormEn: '',
            formattedTextEn: '',
            textEn: '',
            type: ''
          }}
          unloadingP={{
            id: null,
            latitude: booking.toLocation.latitude,
            longitude: booking.toLocation.longitude,
            placeId: '',
            shortFormEn: '',
            formattedTextEn: '',
            textEn: '',
            type: ''
          }}
          handleLoadingPointDragLocation={() => {}}
          handleUnLoadingPointDragLocation={() => {}}
          handleStopageDragLocation={() => {}}
          waypointsList2={[...booking.stoppages]}
          isAddressModal={false}
        />
        {/* {Map()} */}
      </Box>
      <Box>
        <Stepper orientation="vertical">
          <Step completed={true}>
            <StepLabel StepIconComponent={LoadingIcon}>
              {booking.fromLocation.address}
            </StepLabel>
          </Step>
          {booking.stoppages.map((step) => (
            <Step completed={true} key={step.address}>
              <StepLabel StepIconComponent={StoppageIcon}>
                {step.address}
              </StepLabel>
            </Step>
          ))}
          <Step completed={true}>
            <StepLabel StepIconComponent={UnLoadingIcon}>
              {booking.toLocation.address}
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </Box>
  )
}

BookingDetailTab.propTypes = {
  booking: PropTypes.object
}

export default BookingDetailTab
