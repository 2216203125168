import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel, { a11yProps } from '../../../components/UI/TabPanel'
import Summary from './Summary'
import { getLoggedInUser } from '../../../utils/functions'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useGetHook } from '../../../hooks/fetchHook'
import PropTypes from 'prop-types'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { getAccessLevel } from '../../../accesscontrol'

const minDate = dayjs('2020-01-01T00:00:00.000')
const maxDate = dayjs('2025-01-01T00:00:00.000')

export default function ClusterHeadReports({ setToast }) {
  const [value, setValue] = useState(0)
  const [chUserId, setchUserId] = useState('')
  const [date, setDate] = useState(
    dayjs(`2023-${new Date().getMonth() + 1}-02`)
  )
  const [clusterHeads, setClusterHeads] = useState([])

  const handleTabChange = (event, newValue) => setValue(newValue)

  useEffect(() => {
    const role = getLoggedInUser()?.role
    if (role !== 'cluster_head') {
      let url = '/lf-user/api/v1/admin/hierarchy/user/cluster_head?count=100'
      useGetHook(
        url,
        (res) => {
          setClusterHeads(res.data.data.result)
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    }
  }, [])

  return (
    <Box p={2}>
      <Box display="flex" gap={1} alignItems="center" mb={2}>
        <Box flexGrow={1}>
          <Typography variant="h5">
            Hello {getLoggedInUser().fullName}!
          </Typography>
        </Box>
        {getAccessLevel('matrixFilter') && (
          <>
            <FormControl size="small" sx={{ minWidth: 240 }}>
              <InputLabel>{`Cluster Head ${
                chUserId ? '' : '(All)'
              }`}</InputLabel>
              <Select
                value={chUserId}
                label={`Cluster Head ${chUserId ? '' : '(All)'}`}
                onChange={(e) => setchUserId(e.target.value)}
              >
                <MenuItem value={''}>All</MenuItem>
                {clusterHeads?.map((item) => (
                  <MenuItem key={item.userId} value={item.userId}>
                    {item.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['month', 'year']}
                label="Month"
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                onChange={(val) => setDate(val)}
                renderInput={(params) => (
                  <TextField size="small" {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </>
        )}
      </Box>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab label="Summary" {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Summary
          date={date && new Date(date).toISOString()}
          chUserId={chUserId}
          setToast={setToast}
        />
      </TabPanel>
    </Box>
  )
}

ClusterHeadReports.propTypes = {
  setToast: PropTypes.func
}
