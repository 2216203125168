import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import googlePlay from '../../assests/homepage/google_play.svg'

const GPlayStoreBtn = () => {
  return (
    <div>
      <Box
        px={2}
        py={0.5}
        style={{
          background: '#1E293B',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow:
            '0 1px 2px rgba(0,s0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07),0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
          width: 'fit-content'
        }}
        display="flex"
        alignItems="center"
        gap={1}
      >
        <img
          src={googlePlay}
          style={{ height: '31px', objectFit: 'contain' }}
        />
        <Box>
          <Typography color="#fff" variant="caption">
            Avaliable on the
          </Typography>
          <Typography color="#fff" variant="body1">
            Play Store
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default GPlayStoreBtn
