import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Loader from '../../../components/Loader/Loader'
import PropTypes from 'prop-types'
import { toQueryString } from '../../../utils/functions'
import { useGetHook } from '../../../hooks/fetchHook'

export default function ShipperByTrips({ setToast, date, chUserId }) {
  // const url = '/lf-analytics/api/v1/operational-metrics/top-shippers'
  // const [{ data, loading }] = useFetch(url, true)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    setLoading(true)
    const query = {
      date,
      chUserId
    }
    let url = `/lf-analytics/api/v1/operational-metrics/top-shippers${toQueryString(
      query
    )}`

    useGetHook(
      url,
      (res) => {
        setData(res.data.data)
        setLoading(false)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
        setLoading(false)
      },
      true
    )
  }, [date, chUserId])

  return (
    <Box mt={5}>
      {!loading && !data && (
        <Typography color="error" textAlign="center" variant="subtitle1">
          Sorry cant load data!
        </Typography>
      )}
      {loading && <Loader />}
      {!loading && data && (
        <>
          <Typography>Top Shippers By Trips</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Shipper</TableCell>
                  <TableCell align="right">Benchmark (Highest Trips)</TableCell>
                  <TableCell align="right">Current Trips</TableCell>
                  <TableCell align="right">Trips Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.result.map((row) => (
                  <TableRow
                    key={row.company_name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.company_name}
                    </TableCell>
                    <TableCell align="right">{row.trip_benchmark}</TableCell>
                    <TableCell align="right">{row.current_trips}</TableCell>
                    <TableCell align="right">{row.trip_rate}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

ShipperByTrips.propTypes = {
  date: PropTypes.string,
  chUserId: PropTypes.string,
  setToast: PropTypes.func
}
