import React from 'react'
import PropTypes from 'prop-types'
import TripCardV2 from '../TripCardV2'
import Box from '@mui/material/Box'
import { isEmptyArray } from '../../../utils/functions'
import NoSearchResult from '../../../components/NoSearchResult'

const TripTabsContent = (props) => {
  const { data, status, onItemClick } = props

  return (
    <Box mt={2}>
      {isEmptyArray(data) ? (
        <NoSearchResult />
      ) : (
        <>
          {data.map((item) => (
            <TripCardV2
              tripData={item}
              key={item.id}
              status={status}
              onItemClick={onItemClick}
            />
          ))}
        </>
      )}
    </Box>
  )
}

TripTabsContent.propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  onItemClick: PropTypes.func
}

export default TripTabsContent
