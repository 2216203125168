import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useGetHook, usePostHook } from '../../../hooks/fetchHook'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { vendorDocumentTypes } from '../../../data/data'
import useToast from '../../../hooks/useToast'
import Snackbars from '../../../components/Snackbar/Snackbar'
import DocumentItem from './DocumentItem'
import { useParams } from 'react-router-dom'

const Input = styled('input')({
  display: 'none'
})

const Documents = ({ vendor }) => {
  const params = useParams()
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [documents, setDocuments] = useState([])

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  useEffect(() => {
    setLoading(true)
    let url = `/lf-sup/api/v1/admin/supplier/${params.vendorId}/document`
    useGetHook(
      url,
      (res) => {
        setDocuments(res.data.data)
        setLoading(false)
      },
      () => setLoading(false),
      true
    )
  }, [])

  const onFileChange = (event) => setFile(event.target.files[0])

  const saveChanges = () => {
    const formData = new FormData()
    formData.append('title', file.name)
    formData.append('tag', type)
    formData.append('document', file)
    let url = `/lf-sup/api/v1/admin/supplier/${vendor.id}/document`

    usePostHook(
      url,
      formData,
      (res) => {
        let uploadedDoc = res.data.data
        setDocuments((s) => [...s, uploadedDoc])
        setFile(null)
        setType()
        setLoading(false)
        setToast('Document uploaded successfully  !', 'success')
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            {loading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {documents.length > 0 ? (
                  <>
                    {documents.map((item) => (
                      <DocumentItem
                        data={item}
                        key={item.id}
                        setToast={setToast}
                        setDocuments={setDocuments}
                      />
                    ))}
                  </>
                ) : (
                  <Typography variant="h5" gutterBottom component="div">
                    No Documents Available
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={5}>
            <Box mb={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Document Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Select Document Type"
                  onChange={(e) => setType(e.target.value)}
                >
                  {vendorDocumentTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box mb={3}>
              <label htmlFor="contained-button-file1x1">
                <Input
                  accept="image/*"
                  id="contained-button-file1x1"
                  type="file"
                  onChange={onFileChange}
                />
                <Button
                  disabled={!type}
                  variant="contained"
                  fullWidth
                  component="span"
                >
                  Select Document {file ? `(${file.name})` : ''}
                </Button>
              </label>
            </Box>
            <Button
              variant="contained"
              fullWidth
              component="span"
              disabled={!file || !type}
              onClick={saveChanges}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

Documents.propTypes = {
  setToast: PropTypes.func,
  booking: PropTypes.object,
  vendor: PropTypes.object
}

export default Documents
