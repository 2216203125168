/* eslint-disable indent */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import {
  getHumanReadableDate,
  getLoggedInUser,
  isEmptyArray,
  isEmptyObject
} from '../../../utils/functions'
import TruckTypeIcon from '../../../components/SvgIcons/TruckTypeIcon'
import MoneyIcon from '../../../components/SvgIcons/MoneyIcon'
import ItemTypeIcon from '../../../components/SvgIcons/ItemTypeIcon'
import { IconAvatar } from '../../CompanyProfile/StyledComponents'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import BillIcon from '../../../components/SvgIcons/BillIcon'
import NoteIcon from '../../../components/SvgIcons/NoteIcon'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import ClockIcon from '../../../components/SvgIcons/ClockIcon'
import LoadingIcon from '../../../components/SvgIcons/LoadingIcon'
import UnLoadingIcon from '../../../components/SvgIcons/UnLoadingIcon'
import StoppageIcon from '../../../components/SvgIcons/StoppageIcon'
import PaymentIcon from '../../../components/SvgIcons/PaymentIcon'
import RangeIcon from '../../../components/SvgIcons/RangeIcon'
import CustomGoogleMap from '../../../components/CustomGoogleMap'
import { TextField } from '@mui/material'
import { usePutHook } from '../../../hooks/fetchHook'
import { getAccessLevel } from '../../../accesscontrol'

const BookingDetailTab = (props) => {
  const { booking, setToast } = props
  const role = getLoggedInUser()?.role
  const [call, setCall] = useState(false)
  const [editAdditionalNote, setEditAdditionalNote] = useState(false)
  const [additionalNotes, setAdditionalNote] = useState(booking.additionalNotes)

  const memoizedloadingP = useMemo(
    () => ({
      id: null,
      latitude: booking.fromLocation.latitude,
      longitude: booking.fromLocation.longitude,
      placeId: '',
      shortFormEn: null,
      formattedTextEn: '',
      textEn: '',
      type: ''
    }),
    []
  )

  const memoizedunloadingP = useMemo(
    () => ({
      id: null,
      latitude: booking.toLocation.latitude,
      longitude: booking.toLocation.longitude,
      placeId: '',
      shortFormEn: null,
      formattedTextEn: '',
      textEn: '',
      type: ''
    }),
    []
  )

  const memoizedwaypointsList = useMemo(() => booking.stoppages, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (role !== 'admin') {
      let url = `/lf-booking/api/v1/admin/booking/${booking.id}/`
      let payload = {
        additionalNotes
      }

      usePutHook(
        url,
        payload,
        () => {
          setEditAdditionalNote(false)
          setToast('Successfully updated additional note', 'success')
        },
        (err) => setToast(err.response?.data?.message, 'error'),
        true
      )
    } else setEditAdditionalNote(false)
  }

  return (
    <Box p={2}>
      <Box className="booking-metadata-box" mb={3}>
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <Avatar
                alt={booking.company?.name}
                src={
                  booking.company.companyPicture
                    ? booking.company.companyPicture
                    : 'ass'
                }
              />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                {booking.company.name}
              </Typography>
              <Typography variant="body2">
                {booking.shipper.fullName}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <ClockIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Start Date
              </Typography>
              <Typography variant="body2">
                {getHumanReadableDate(booking.pickupDate)}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <TruckTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Truck Type
              </Typography>
              <Typography variant="body2">
                {booking.truckSize.nameEn}
                <b>&nbsp;x&nbsp;{booking.truckQuantity}</b>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <ItemTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Item Type
              </Typography>
              {!isEmptyArray(booking.bookingItems[0]?.measurements) ? (
                <Typography textTransform="capitalize" variant="body2">
                  {booking.bookingItems[0].name}&nbsp;x&nbsp;
                  {booking.bookingItems[0].measurements.length > 0 &&
                    booking.bookingItems[0].measurements[0]?.value}
                  &nbsp;
                  {booking.bookingItems[0].measurements.length > 0 &&
                    booking.bookingItems[0].measurements[0]?.unit?.name}
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <IconAvatar>
                <CalendarTodayIcon sx={{ fill: '#0071CD' }} />
              </IconAvatar>
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Booking Date
              </Typography>
              <Typography variant="body2">
                {getHumanReadableDate(booking.createdAt)}
              </Typography>
            </Box>
          </Box>
          {getAccessLevel('fareAmountPerTruck') && (
            <Box flex={1} display="flex" alignItems="center" gap={1}>
              <Box>
                <MoneyIcon sx={{ width: '40px', height: '40px' }} />
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  Fare Amount Per Truck
                </Typography>
                <Typography variant="body2">
                  {booking.fareHistory.fareAmount
                    ? `৳${booking.fareHistory.fareAmount}`
                    : 'N/A'}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <PaymentIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Item Quantity
              </Typography>
              {!isEmptyArray(booking.bookingItems[0]?.measurements) ? (
                <Typography textTransform="capitalize" variant="body2">
                  {booking.bookingItems[0].measurements[1]?.value}
                  &nbsp; x &nbsp;
                  {booking.bookingItems[0].measurements[1]?.unit?.name}
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
          {role !== 'shipper' && (
            <Box flex={1} display="flex" alignItems="center" gap={1}>
              <Box>
                <RangeIcon sx={{ width: '40px', height: '40px' }} />
              </Box>
              <Box>
                <Typography variant="body1" color="primary">
                  Range
                </Typography>
                <Typography variant="body2">
                  ৳{booking.minPrice} - ৳{booking.maxPrice}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            flex={role !== 'shipper' ? 1 : 2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Box>
              <BillIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Bill Of Landing No
              </Typography>
              <Typography variant="body2">{'N/A'}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={1} display="flex">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box>
              <ItemTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Loading Agent
              </Typography>
              {!isEmptyObject(booking.loadingAgent) ? (
                <Typography variant="body2">
                  {booking.loadingAgent.phone}&nbsp;(
                  {booking.loadingAgent.fullName})
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
          <Box flex={2} display="flex" alignItems="center" gap={1}>
            <Box>
              <ItemTypeIcon sx={{ width: '40px', height: '40px' }} />
            </Box>
            <Box>
              <Typography variant="body1" color="primary">
                Unloading Agent
              </Typography>
              {!isEmptyObject(booking.unloadingAgent) ? (
                <Typography variant="body2">
                  {booking.unloadingAgent.phone}&nbsp;(
                  {booking.unloadingAgent.fullName})
                </Typography>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
        </Box>
        <Divider />

        <Box p={1} flex={1} display="flex" alignItems="start" gap={1}>
          <NoteIcon sx={{ width: '40px', height: '40px' }} />
          <Box>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="body1" color="primary">
                Additional Note
              </Typography>
            </Box>
            {editAdditionalNote ? (
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <TextField
                  size="small"
                  label="Edit"
                  fullWidth
                  variant="outlined"
                  value={additionalNotes}
                  onChange={(e) => setAdditionalNote(e.target.value)}
                />
                <button style={{ display: 'none' }} type="submit" />
              </form>
            ) : (
              <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
                {additionalNotes ? additionalNotes : 'N/A'}
              </Typography>
            )}
          </Box>
        </Box>
        <Divider />
        <Box p={1} flex={1} display="flex" alignItems="start" gap={1}>
          <Box>
            <NoteIcon sx={{ width: '40px', height: '40px', color: 'red' }} />
          </Box>
          <Box>
            <Typography variant="body1" color="primary">
              Cancel Note
            </Typography>
            <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
              {booking.cancelReason ? booking.cancelReason : 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: '300px' }} mb={3}>
        <CustomGoogleMap
          call={call}
          setCall={setCall}
          loadingP={memoizedloadingP}
          unloadingP={memoizedunloadingP}
          waypointsList2={memoizedwaypointsList}
          isAddressModal={false}
        />
      </Box>
      <Box>
        <Stepper orientation="vertical">
          <Step completed={true}>
            <StepLabel StepIconComponent={LoadingIcon}>
              {booking.fromLocation.address}
            </StepLabel>
          </Step>
          {booking.stoppages.map((step) => (
            <Step completed={true} key={step.address}>
              <StepLabel StepIconComponent={StoppageIcon}>
                {step.address}
              </StepLabel>
            </Step>
          ))}
          <Step completed={true}>
            <StepLabel StepIconComponent={UnLoadingIcon}>
              {booking.toLocation.address}
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </Box>
  )
}

BookingDetailTab.propTypes = {
  booking: PropTypes.object,
  setToast: PropTypes.func
}

export default BookingDetailTab
