import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import PropTypes from 'prop-types'
import { isEmptyArray, timesAgo } from '../../../../utils/functions'

const DownloadListTable = ({ data }) => {
  if (isEmptyArray(data))
    return (
      <Typography variant="subtitle2" color="error" textAlign="center">
        No data found!
      </Typography>
    )
  return (
    <>
      <TableContainer
        sx={{
          borderTop: '1px  solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px  solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell align="left">From Date</StyledTableCell>
              <StyledTableCell align="left">To Date</StyledTableCell>
              <StyledTableCell align="left">Generate Date</StyledTableCell>
              <StyledTableCell align="center">Number of Trip</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!isEmptyArray(data) &&
              data.map((row) => (
                <StyledTableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.companyName}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.fromDate}</StyledTableCell>
                  <StyledTableCell align="left">{row.toDate}</StyledTableCell>
                  <StyledTableCell align="left">
                    {timesAgo(row.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.totalTrip}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a
                      href={row.downloadUrl}
                      rel="noreferrer"
                      download
                      target="_blank"
                    >
                      <IconButton size="small" color="primary">
                        <DownloadForOfflineIcon />
                      </IconButton>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

DownloadListTable.propTypes = {
  data: PropTypes.object
}

export default DownloadListTable

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffffff',
    color: '#000'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  borderRight: '1px solid rgba(0, 0, 0, 0.12)'
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  borderRight: '1px solid rgba(0, 0, 0, 0.12)'
}))
