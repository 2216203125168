import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'

const CommonPagination = (props) => {
  const { count, page, onChange } = props

  return (
    <Box display="flex" flexDirection="row-reverse">
      <Pagination
        count={count}
        color="primary"
        page={page}
        onChange={onChange}
      />
    </Box>
  )
}

CommonPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func
}

export default CommonPagination
