import React, { useEffect, useState } from 'react'
import CompanyCard from './CompanyCard'
import { useNavigate } from 'react-router-dom'
import { getLoggedInUser, isEmptyArray } from '../../utils/functions'
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography
} from '@mui/material'
import { useGetHook } from '../../hooks/fetchHook'
import CircularProgress from '@mui/material/CircularProgress'
import { Search } from '@mui/icons-material'
import useDebouncedValue from '../../hooks/useDebounceValue'

const VendorTaxFormula = () => {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)

  useEffect(() => {
    const role = getLoggedInUser()?.role
    if (role !== 'finance') {
      navigate('/home/bookings')
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    let url = `/lf-company/api/v1/admin/company/fare-amount?count=10&page=${
      debouncedSearch ? 1 : page
    }`
    if (debouncedSearch) url += `&search=${debouncedSearch}`

    useGetHook(
      url,
      (res) => {
        let count = Math.ceil(res.data.data.total / 10)
        setPageCount(count)
        setCompanies(res.data.data.result)
        setLoading(false)
      },
      () => {
        setLoading(false)
      },
      true
    )
  }, [page, debouncedSearch])

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center" justifyContent="flex-end">
        <TextField
          outlined
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress size={60} />
        </Box>
      )}
      {!loading && isEmptyArray(companies) && (
        <Typography p={2} textAlign="center" color="error" variant="subtitle1">
          No companies found!
        </Typography>
      )}
      {!loading &&
        companies.map((item) => (
          <CompanyCard
            key={item.companyId}
            data={item}
            setCompanies={setCompanies}
          />
        ))}
      {!loading && (
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      )}
    </>
  )
}

export default VendorTaxFormula
