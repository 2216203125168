import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'
import './FeedCard.css'

const FeedCard = ({ number, title }) => {
  return (
    <Box className="feedCard">
      <Typography color="primary" variant="h4">
        {number || 0}
      </Typography>
      <Typography
        sx={{ textTransform: 'capitalize' }}
        color="textSecondary"
        variant="body1"
      >
        {title || 'N/A'}
      </Typography>
    </Box>
  )
}

export default FeedCard

FeedCard.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired
}
