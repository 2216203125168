import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const CardItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: 1,
  minHeight: '150px'
}))
