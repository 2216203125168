import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const LoadingIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 6.69177e-07 24.8366 1.49465e-06 16C2.32012e-06 7.16344 7.16345 -2.03199e-06 16 -1.30903e-06C24.8366 -5.86074e-07 32 7.16344 32 16Z"
        fill="#4CAF50"
      />
      <path
        d="M8 16L9.41 17.41L15 11.83L15 24L17 24L17 11.83L22.58 17.42L24 16L16 8L8 16Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default LoadingIcon
