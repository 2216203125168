import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  PrimaryButton,
  LightButton,
  OtpField
} from '../../../components/Landing/StyledComponents/StyledComponents'
import { usePostHook } from '../../../hooks/fetchHook'
import { useCountdown } from '../../../hooks/useCountDown'

const OtpInput = (props) => {
  const { submitOtp, goBack, phoneOrEmail, setToast } = props
  let fiveMinInMs = 1000 * 60 * 5
  const NOW_IN_MS = new Date().getTime()
  const dateTimeAfterFiveMins = NOW_IN_MS + fiveMinInMs
  const [, , minutes, seconds] = useCountdown(dateTimeAfterFiveMins)
  const [field1, setField1] = useState('')
  const [field2, setField2] = useState('')
  const [field3, setField3] = useState('')
  const [field4, setField4] = useState('')
  const [field5, setField5] = useState('')
  const [field6, setField6] = useState('')

  const fieldRef1 = useRef()
  const fieldRef2 = useRef()
  const fieldRef3 = useRef()
  const fieldRef4 = useRef()
  const fieldRef5 = useRef()
  const fieldRef6 = useRef()

  const onChangeField1 = (e) => {
    setField1(e.target.value[0])
    if (e.target.value) {
      fieldRef2.current.focus()
    }
  }

  const onChangeField2 = (e) => {
    setField2(e.target.value[0])
    if (e.target.value) {
      fieldRef3.current.focus()
    }
  }

  const onChangeField3 = (e) => {
    setField3(e.target.value[0])
    if (e.target.value) {
      fieldRef4.current.focus()
    }
  }

  const onChangeField4 = (e) => {
    setField4(e.target.value[0])
    if (e.target.value) {
      fieldRef5.current.focus()
    }
  }

  const onChangeField5 = (e) => {
    setField5(e.target.value[0])
    if (e.target.value) {
      fieldRef6.current.focus()
    }
  }

  const onChangeField6 = (e) => {
    setField6(e.target.value[0])
  }

  const onBackDelBtnPress = (e) => {
    const name = e.target.name
    var key = e.keyCode || e.charCode
    if (key == 8 || key == 46) {
      switch (name) {
        case 'field2':
          setField2('')
          fieldRef1.current.focus()
          break
        case 'field3':
          setField3('')
          fieldRef2.current.focus()
          break
        case 'field4':
          setField4('')
          fieldRef3.current.focus()
          break
        case 'field5':
          setField5('')
          fieldRef4.current.focus()
          break
        case 'field6':
          setField6('')
          fieldRef5.current.focus()
          break
        default:
          fieldRef1.current.focus()
      }
    }
  }

  const handleOtp = () => {
    let otp = field1 + field2 + field3 + field4 + field5 + field6
    submitOtp(otp)
  }

  const cancel = () => {
    setField1('')
    setField2('')
    setField3('')
    setField4('')
    setField5('')
    setField6('')
    goBack()
  }

  const resendOtp = () => {
    let url = '/lf-user/api/v1/public/user/resend-otp'
    const payload = {
      phoneOrEmail
    }
    usePostHook(
      url,
      payload,
      () => {
        setToast('OTP has been resend', 'success')
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <>
      <Typography
        variant="h4"
        color="primary"
        component="div"
        sx={{ minWidth: '330px', maxWidth: '330px' }}
      >
        OTP Verification ?
      </Typography>
      <Box
        mt={2}
        sx={{ minWidth: '330px', maxWidth: '330px' }}
        display="flex"
        gap={1}
      >
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          autoFocus
          name="field1"
          value={field1}
          onChange={onChangeField1}
          ref={fieldRef1}
          onKeyDown={onBackDelBtnPress}
        />
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          name="field2"
          value={field2}
          onChange={onChangeField2}
          ref={fieldRef2}
          onKeyDown={onBackDelBtnPress}
        />
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          name="field3"
          value={field3}
          onChange={onChangeField3}
          ref={fieldRef3}
          onKeyDown={onBackDelBtnPress}
        />
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          name="field4"
          value={field4}
          onChange={onChangeField4}
          ref={fieldRef4}
          onKeyDown={onBackDelBtnPress}
        />
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          name="field5"
          value={field5}
          onChange={onChangeField5}
          ref={fieldRef5}
          onKeyDown={onBackDelBtnPress}
        />
        <OtpField
          type="number"
          onWheel={(event) => event.target.blur()}
          maxLength="1"
          name="field6"
          value={field6}
          onChange={onChangeField6}
          ref={fieldRef6}
          onKeyDown={onBackDelBtnPress}
        />
      </Box>
      <Box
        mt={2}
        sx={{ minWidth: '330px', maxWidth: '330px' }}
        display="flex"
        gap={1}
      >
        <Typography
          variant="body1"
          component="div"
          sx={{
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          I didn&#39;t receive the code.
        </Typography>
        <Typography
          variant="body1"
          component="div"
          color={minutes + seconds <= 0 ? 'primary' : '#e9e9e9'}
          onClick={resendOtp}
          sx={{
            cursor: 'pointer',
            pointerEvents: minutes + seconds <= 0 ? 'all' : 'none'
          }}
        >
          Resend Code
        </Typography>
      </Box>
      {minutes + seconds > 0 && (
        <Box
          mt={1}
          sx={{ minWidth: '330px', maxWidth: '330px' }}
          display="flex"
          gap={1}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >
            {`${minutes}: ${seconds} minute left`}
          </Typography>
        </Box>
      )}
      <Box mt={2} sx={{ minWidth: '330px', maxWidth: '330px' }}>
        <PrimaryButton
          fullWidth
          variant="contained"
          onClick={handleOtp}
          disabled={
            !field1 || !field2 || !field3 || !field4 || !field5 || !field6
          }
        >
          Send
        </PrimaryButton>
      </Box>
      <Box mt={2} sx={{ minWidth: '330px', maxWidth: '330px' }}>
        <LightButton fullWidth variant="contained" onClick={cancel}>
          Back
        </LightButton>
      </Box>
    </>
  )
}

OtpInput.propTypes = {
  setToast: PropTypes.func,
  phoneOrEmail: PropTypes.string,
  submitOtp: PropTypes.func,
  goBack: PropTypes.func
}

export default OtpInput
