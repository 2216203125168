export const BANKS = [
  {
    value: 'eastern_bank',
    label: 'Eastern Limited'
  },
  {
    value: 'city_bank',
    label: 'City Bank Limited'
  },
  {
    value: 'islami_bank',
    label: 'Islami Bank Limited'
  },
  {
    value: 'dutch_bangla_bank',
    label: 'Dutch Bangla Bank'
  },
  { value: 'agrani_bank', label: 'Agrani Bank Limited' },
  {
    value: 'bangladesh_development_bank',
    label: 'Bangladesh Development Bank'
  },
  { value: 'janata_bank', label: 'Janata Bank Limited' },
  { value: 'bangladesh_krishi_bank', label: 'Bangladesh Krishi Bank' },
  {
    value: 'rajshahi_krishi_unnayan_bank',
    label: 'Rajshahi Krishi Unnayan Bank'
  },
  { value: 'probashi_kallyan_bank', label: 'Probashi Kallyan Bank' },
  { value: 'ab_bank_limited', label: 'AB Bank Limited' },
  {
    value: 'bangladesh_commerce_bank',
    label: 'Bangladesh Commerce Bank Limited'
  },
  { value: 'bengal_commercial_bank', label: 'Bengal Commercial Bank Limited' },
  { value: 'citizens_bank_plc', label: 'Citizens Bank PLC' },
  {
    value: 'community_bank_bangladesh',
    label: 'Community Bank Bangladesh Limited'
  },
  { value: 'dhaka_bank', label: 'Dhaka Bank Limited' },
  { value: 'ific_bank', label: 'IFIC Bank Limited' },
  { value: 'jamuna_bank', label: 'Jamuna Bank Limited' },
  { value: 'meghna_bank', label: 'Meghna Bank Limited' },
  { value: 'mercantile_bank', label: 'Mercantile Bank Limited' },
  { value: 'midland_bank', label: 'Midland Bank Limited' },
  { value: 'modhumoti_bank', label: 'Modhumoti Bank Limited' },
  { value: 'mutual_trust_bank', label: 'Mutual Trust Bank Limited' },
  { value: 'national_bank', label: 'National Bank Limited' },
  {
    value: 'national_credit_commerce_bank',
    label: 'National Credit & Commerce Bank Limited'
  },
  { value: 'nrb_bank', label: 'NRB Bank Limited' },
  { value: 'nrb_commercial_bank', label: 'NRB Commercial Bank Ltd' },
  { value: 'one_bank', label: 'One Bank Limited' },
  { value: 'padma_bank', label: 'Padma Bank Limited' },
  { value: 'premier_bank', label: 'Premier Bank Limited' },
  { value: 'prime_bank', label: 'Prime Bank Limited' },
  { value: 'pubali_bank', label: 'Pubali Bank Limited' },
  { value: 'shimanto_bank', label: 'Shimanto Bank Ltd' },
  { value: 'southeast_bank', label: 'Southeast Bank Limited' },
  {
    value: 'south_bangla_agriculture_and_commerce_bank',
    label: 'South Bangla Agriculture and Commerce Bank Limited'
  },
  { value: 'trust_bank', label: 'Trust Bank Limited' },
  { value: 'united_commercial_bank', label: 'United Commercial Bank Ltd' },
  { value: 'uttara_bank', label: 'Uttara Bank Limited' },
  { value: 'al_arafah_islami_bank', label: 'Al-Arafah Islami Bank Limited' },
  { value: 'exim_bank', label: 'EXIM Bank Limited' },
  {
    value: 'first_security_islami_bank',
    label: 'First Security Islami Bank Limited'
  },
  { value: 'global_islamic_bank', label: 'Global Islamic Bank Ltd' },
  { value: 'icb_islamic_bank', label: 'ICB Islamic Bank Limited' },
  { value: 'shahjalal_islami_bank', label: 'Shahjalal Islami Bank Limited' },
  { value: 'social_islami_bank', label: 'Social Islami Bank Limited' },
  { value: 'union_bank', label: 'Union Bank Limited' },
  { value: 'standard_bank', label: 'Standard Bank Limited' }
]
