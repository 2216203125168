import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const UnLoadingIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#E31B0C"
      />
      <path
        d="M24 16L22.59 14.59L17 20.17V8H15V20.17L9.42 14.58L8 16L16 24L24 16Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default UnLoadingIcon
