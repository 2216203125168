import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BillIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#F1F5F9"
      />
      <path
        d="M28.25 14.9165L27 13.6665L25.75 14.9165L24.5 13.6665L23.25 14.9165L22 13.6665L20.75 14.9165L19.5 13.6665L18.25 14.9165L17 13.6665V25.3332H14.5V27.8332C14.5 29.2165 15.6167 30.3332 17 30.3332H27C28.3833 30.3332 29.5 29.2165 29.5 27.8332V13.6665L28.25 14.9165ZM27.8333 27.8332C27.8333 28.2915 27.4583 28.6665 27 28.6665C26.5417 28.6665 26.1667 28.2915 26.1667 27.8332V25.3332H18.6667V16.1665H27.8333V27.8332Z"
        fill="#0071CD"
      />
      <path d="M24.5 17.8332H19.5V19.4998H24.5V17.8332Z" fill="#0071CD" />
      <path d="M27 17.8332H25.3333V19.4998H27V17.8332Z" fill="#0071CD" />
      <path d="M24.5 20.3332H19.5V21.9998H24.5V20.3332Z" fill="#0071CD" />
      <path d="M27 20.3332H25.3333V21.9998H27V20.3332Z" fill="#0071CD" />
    </SvgIcon>
  )
}

export default BillIcon
