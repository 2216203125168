import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardItem } from './StyledComponents'
import { getPropValue } from '../../utils/functions'

const TripCounts = (props) => {
  const { tripCounts } = props
  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        <CardItem className="card-box">
          <Box>
            <Typography variant="h4" gutterBottom component="div">
              {getPropValue('requestedCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Requested Trips
            </Typography>
          </Box>
        </CardItem>
        <CardItem className="card-box">
          <Box>
            <Typography
              color="#ffb400"
              variant="h4"
              gutterBottom
              component="div"
            >
              {getPropValue('processingCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Processing Trips
            </Typography>
          </Box>
        </CardItem>
        <CardItem className="card-box">
          <Box>
            <Typography
              color="primary"
              variant="h4"
              gutterBottom
              component="div"
            >
              {getPropValue('activatedCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Active Trips
            </Typography>
          </Box>
        </CardItem>
        <CardItem className="card-box">
          <Box>
            <Typography
              color="#4CAF50"
              variant="h4"
              gutterBottom
              component="div"
            >
              {getPropValue('completedCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Completed Trips
            </Typography>
          </Box>
        </CardItem>
        <CardItem className="card-box">
          <Box>
            <Typography
              color="#F82012"
              variant="h4"
              gutterBottom
              component="div"
            >
              {getPropValue('cancelledCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Cancelled Trips
            </Typography>
          </Box>
        </CardItem>
        <CardItem className="card-box">
          <Box>
            <Typography
              color="#D50D11"
              variant="h4"
              gutterBottom
              component="div"
            >
              {getPropValue('expiredCount', tripCounts)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="div">
              Expired Trips
            </Typography>
          </Box>
        </CardItem>
      </Box>
    </>
  )
}

TripCounts.propTypes = {
  tripCounts: PropTypes.object
}

export default TripCounts
