import React, { useState, useEffect } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import Shipper from './Shipper'
import Snackbars from '../../components/Snackbar/Snackbar'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import useDebouncedValue from '../../hooks/useDebounceValue'
import { isEmptyArray, toQueryString } from '../../utils/functions'
import useToggle from '../../hooks/useToggle'
import CreateInactiveCompanyModal from '../../components/CreateInactiveCompanyModal'
import { getAccessLevel } from '../../accesscontrol'
import UserListTitle from '../../components/UserListTitle'

const ShipperList = () => {
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [shippers, setShippers] = useState([])
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [isVerified, setIsVerified] = useState(null)
  const [kw, setKw] = useState('')
  const debounedKw = useDebouncedValue(kw, 500)
  const { open, handleClose, handleOpen } = useToggle()
  useEffect(() => {
    const queryObject = {
      page,
      count: pageSize,
      search: debounedKw,
      isVerified: isVerified !== null ? `${isVerified}` : ''
    }

    if (isVerified === 'inactive') {
      delete queryObject.isVerified
      queryObject.status = 'inactive'
    }

    let url = `/lf-company/api/v1/admin/company${toQueryString(queryObject)}`
    useGetHook(
      url,
      (res) => {
        setTotal(res.data.data.total)
        let count = Math.ceil(res.data.data.total / 10)
        setPageCount(count)
        setShippers(res.data.data.result)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [page, debounedKw, isVerified])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Grid mt={1} container spacing={2}>
        <Grid item xs={6}>
          <UserListTitle count={total} name="Shippers" />
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            alignItems="center"
          >
            {getAccessLevel('addCompanybtn') && (
              <Button onClick={handleOpen} variant="contained">
                Add Company
              </Button>
            )}
            <FormControl sx={{ minWidth: 140 }} size="small">
              <InputLabel id="demo-simple-select-label26">
                Select Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label26"
                id="demo-simple-select26"
                value={isVerified}
                label="Select Status"
                onChange={(e) => {
                  setIsVerified(e.target.value)
                  setPage(1)
                }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={true}>Verified</MenuItem>
                <MenuItem value={false}>Not Verfied</MenuItem>
                {getAccessLevel('inactiveOption') && (
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              color="primary"
              size="small"
              label="Search Shippers"
              value={kw}
              onChange={(e) => {
                setKw(e.target.value)
                setPage(1)
              }}
              sx={{ minWidth: 160 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        {isEmptyArray(shippers) && (
          <Typography
            variant="subtitle2"
            color="error"
            textAlign="center"
            p={2}
          >
            No shippers found!
          </Typography>
        )}
        {!isEmptyArray(shippers) &&
          shippers.map((item) => <Shipper key={item.companyId} data={item} />)}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CreateInactiveCompanyModal
        open={open}
        prefillData={{}}
        setToast={setToast}
        onClose={handleClose}
        setRequestDetails={() => {}}
      />
    </>
  )
}

export default ShipperList
