import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../hooks/fetchHook'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const CompanyAutoComplete2 = (props) => {
  const { defaultValue, disabled, onCompanyChange, label } = props
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState(defaultValue)
  const [search, setSearch] = useState('')

  useEffect(() => {
    let url = `/lf-company/api/v1/admin/company/list/search?search=${
      search ? search : ''
    }`
    useGetHook(
      url,
      (res) => {
        let results = res.data.data.map((data) => {
          return {
            id: data.companyId,
            title: data.companyName,
            companyDocumentVerified: data.companyDocumentVerified
          }
        })
        setCompanies(results)
      },
      () => {},
      true
    )
  }, [search])

  const onCompanySelect = (newValue) => {
    if (newValue) {
      onCompanyChange(newValue)
      setCompany(newValue)
    } else {
      setCompany({
        id: 0,
        title: ''
      })
    }
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        value={company}
        onChange={(event, newValue) => onCompanySelect(newValue)}
        filterOptions={(options) => {
          return options
        }}
        size="small"
        id="free-solo-dialog-xxx"
        options={companies}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.title
        }}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.title}
            {option.companyDocumentVerified && (
              <CheckCircleIcon
                sx={{ marginLeft: 'auto' }}
                fontSize="small"
                color="success"
              />
            )}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={label || 'Search Company'} />
        )}
        sx={{ minWidth: 200 }}
      />
    </>
  )
}

CompanyAutoComplete2.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onCompanyChange: PropTypes.func
}

export default CompanyAutoComplete2
