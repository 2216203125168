import { makeObservable, observable, action } from 'mobx'

class BookingStore {
  bookingDetails = ''
  constructor() {
    makeObservable(this, {
      bookingDetails: observable,
      updateBookingDetails: action
    })
    this.bookingDetails = null
  }

  updateBookingDetails(details) {
    this.bookingDetails = details
  }
}

export default BookingStore
