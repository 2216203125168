import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const BatchRowItemBox = ({ color, title, count }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 1,
        flexGrow: 1,
        // bgcolor: bgcolor || '#ffffff',
        py: 2,
        px: 1,
        '&:nth-child(even)': {
          bgcolor: '#FAFAFA'
        },
        '&:last-child': {
          bgcolor: '#ffffff'
        },
        '&:nth-child(1)': {
          flexBasis: '150px'
        },
        '&:nth-child(3)': {
          flexBasis: '120px'
        },
        '&:nth-child(9)': {
          flexBasis: '100px'
        }
      }}
    >
      <Typography color="#9E9E9E" fontWeight={500} variant="body2">
        {title}
      </Typography>
      <Typography
        textTransform="capitalize"
        fontWeight={700}
        color={color}
        variant="body1"
      >
        {count}
      </Typography>
    </Box>
  )
}

BatchRowItemBox.propTypes = {
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.string | PropTypes.number,
  title: PropTypes.string
}

export default BatchRowItemBox
