/* eslint-disable indent */
import React, { useEffect, useState, memo } from 'react'
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  DirectionsService,
  InfoWindow,
  useJsApiLoader
} from '@react-google-maps/api'
import PropTypes from 'prop-types'
import LoadingMarker from '../../assests/map/loading-marker.svg'
import UnLoadingMarker from '../../assests/map/unloading-marker.svg'
import StoppageIcon from '../../assests/map/stopageIcon.svg'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { getGoogleMapApiKey, isEmptyArray } from '../../utils/functions'

const containerStyle = {
  width: '100%',
  height: '100%'
}

const center = {
  lat: 23.810331,
  lng: 90.412521
}

function CustomGoogleMap(props) {
  const {
    call,
    setCall,
    loadingP,
    unloadingP,
    waypointsList2,
    handleLoadingPointDragLocation,
    handleUnLoadingPointDragLocation,
    handleStopageDragLocation,
    isAddressModal = false
  } = props

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: getGoogleMapApiKey()
  })

  const [infoBox, setInfoWindow] = useState({
    position: {
      lat: '',
      lng: ''
    },
    info: ''
  })

  const [directions, setDirections] = useState(null)

  useEffect(() => {
    if (loadingP.latitude && unloadingP.latitude) {
      setCall(true)
    }
  }, [loadingP.latitude, unloadingP.latitude])

  const directionsCallback = (response) => {
    if (response !== null) {
      // console.log('directionsCallback called')
      if (response.status === 'OK') {
        setCall(false)
        setDirections(response)
      }
    }
  }

  const infoBoxOptions = { closeBoxURL: '', enableEventPropagation: true }
  const getDirectionRender = () => {
    return (
      <DirectionsService
        options={{
          destination: {
            lat: unloadingP.latitude,
            lng: unloadingP.longitude
          },
          origin: {
            lat: loadingP.latitude,
            lng: loadingP.longitude
          },
          travelMode: 'DRIVING',
          waypoints: waypointsList2.map((elem) => {
            return {
              location: {
                lat: elem.latitude,
                lng: elem.longitude
              },
              stopover: false
            }
          })
        }}
        callback={directionsCallback}
      />
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={
          loadingP.latitude
            ? {
                lat: loadingP.latitude,
                lng: loadingP.longitude
              }
            : center
        }
        zoom={10}
        onClick={({ latLng }) => {
          isAddressModal &&
            handleLoadingPointDragLocation &&
            handleLoadingPointDragLocation(latLng.lat(), latLng.lng())
        }}
      >
        {loadingP.latitude &&
          unloadingP.latitude &&
          call &&
          getDirectionRender()}
        {directions && (
          <DirectionsRenderer
            options={{
              directions: directions,
              suppressMarkers: true
            }}
          />
        )}

        {infoBox.position.lat && (
          <InfoWindow
            options={infoBoxOptions}
            position={infoBox.position}
            onCloseClick={() =>
              setInfoWindow({
                position: {
                  lat: '',
                  lng: ''
                },
                info: ''
              })
            }
          >
            <p> {infoBox.info}</p>
          </InfoWindow>
        )}

        <Marker
          draggable
          onDragEnd={({ latLng }) => {
            handleLoadingPointDragLocation(latLng.lat(), latLng.lng())
          }}
          position={{ lat: loadingP.latitude, lng: loadingP.longitude }}
          icon={LoadingMarker}
          onClick={() =>
            setInfoWindow({
              ...infoBox,
              position: {
                lat: loadingP.latitude,
                lng: loadingP.longitude
              },
              info: loadingP.textEn
            })
          }
        />
        <Marker
          draggable
          onDragEnd={({ latLng }) => {
            handleUnLoadingPointDragLocation &&
              handleUnLoadingPointDragLocation(latLng.lat(), latLng.lng())
          }}
          position={{
            lat: unloadingP.latitude,
            lng: unloadingP.longitude
          }}
          icon={UnLoadingMarker}
          onClick={() =>
            setInfoWindow({
              ...infoBox,
              position: {
                lat: unloadingP.latitude,
                lng: unloadingP.longitude
              },
              info: unloadingP.textEn
            })
          }
        />
        {!isEmptyArray(waypointsList2) &&
          waypointsList2.map((item, index) => {
            return (
              <Marker
                key={index}
                draggable
                onDragEnd={({ latLng }) => {
                  handleStopageDragLocation &&
                    handleStopageDragLocation(latLng.lat(), latLng.lng(), index)
                }}
                position={{
                  lat: item.latitude,
                  lng: item.longitude
                }}
                icon={StoppageIcon}
                onClick={() =>
                  setInfoWindow({
                    ...infoBox,
                    position: {
                      lat: item.latitude,
                      lng: item.longitude
                    },
                    info: item.textEn
                  })
                }
              />
            )
          })}
      </GoogleMap>
    )
  }
  return isLoaded ? (
    renderMap()
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
      <CircularProgress />
    </Box>
  )
}

CustomGoogleMap.propTypes = {
  call: PropTypes.bool,
  setCall: PropTypes.func,
  handleStopageDragLocation: PropTypes.func,
  handleLoadingPointDragLocation: PropTypes.func,
  handleUnLoadingPointDragLocation: PropTypes.func,
  waypointsList2: PropTypes.array,
  loadingP: PropTypes.object,
  unloadingP: PropTypes.object,
  isAddressModal: PropTypes.bool
}

export default memo(CustomGoogleMap)

// (prevProps, nextProps) => {
//   let shouldRerender =
//     prevProps.call === nextProps.call ||
//     prevProps.waypointsList2 === nextProps.waypointsList2 ||
//     prevProps.loadingP === nextProps.loadingP ||
//     prevProps.unloadingP === nextProps.unloadingP

//   return shouldRerender
// }
