import { getLoggedInUser } from '../utils/functions'

const ACCESS_CONTROL = {
  Global: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    fareAmountPerTruck: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team !== 'business_team') {
          //   // edited 2024
          //   return true
          // }

          return false
        }
      },
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            // edited 2024
            return true
          }
        }
      }
    },
    searchVendorsList: {
      write: ['admin', 'gm', 'cluster_head', 'kam'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'vendor_team') {
          //   return true
          // }
          return false
        }
      }
    },
    showRemarks: {
      write: ['admin', 'kam', 'cluster_head'], // edited nov 2024
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'fulfillment_team') {
          //   return true
          // }
          return false
        }
      }
    },
    changeTripStatus: {
      write: [
        // 'admin', 'gm', 'cluster_head', 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (
          //   user.team &&
          //   user.team !== 'fulfillment_team' &&
          //   user.team !== 'business_team'
          // ) {
          //   return true
          // }
          // return false
          return true
        }
      },
      checkClusterTeam: function () {
        // let user = getLoggedInUser()
        // if (user.role === 'cluster_head') {
        //   // if (user.team && user.team === 'vendor_team') { // edited nov 2024
        //   //   return true
        //   // }
        //   return false
        // }
        return true
      }
    },
    createBidding: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        //  'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'vendor_team') {
            return true
          }
          return false
        }
      }
    },
    editBooking: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          // return false

          return true
        }
      }
    },
    showEditBtn: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          // return false

          return true
        }
      }
    },
    activeTrip: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (
            user.team &&
            (user.team === 'business_team' || user.team === 'fulfillment_team')
          ) {
            return true
          }
          return false
        }
      }
    },
    cancelBooking: {
      write: ['admin', 'gm', 'cluster_head', 'pro', 'kam', 'shipper'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    cancelTrip: {
      write: ['admin', 'gm', 'cluster_head', 'kam', 'pro'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'business_team') {
            return false
          }

          return true
        }
      }
    },
    acceptAndEditBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'business_team') {
            return false
          }
          return true
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { edited nov 2024
          //   return true
          // }
          return false
        }
      }
    },
    editBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'vendor_team') {
            return false
          }
          return true
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { edited nov 2024
          //   return true
          // }
          return false
        }
      }
    },
    rejectBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        //  'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },

    quoteShipper: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    updateTripStatus: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team === 'business_team') {
          //   return true
          // }
          return false
        }
      }
    },
    removeUploadAndSelect: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { // edited nov 2024
          //   return true
          // }
          return false
        }
      },
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'vendor_team') {
            return true
          }
          return false
        }
      }
    },
    fareCalculator: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { // edited nov 2024
          //   return true
          // }
          return false
        }
      }
    }
  },
  Home: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    fareAmountPerTruck: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team !== 'business_team') {
          //   // edited 2024
          //   return true
          // }

          return false
        }
      },
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            // edited 2024
            return true
          }
        }
      }
    },

    showRemarks: {
      write: ['admin', 'kam', 'cluster_head'], // edited 2024
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'fulfillment_team') {
            return true
          }
          return false
        }
      }
    },
    showEditBtn: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          // return false
          return true
        }
      }
    },
    removeUploadAndSelect: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') {  // edited 2024
          //   return true
          // }
          return false
        }
      },
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'vendor_team') {
            return true
          }
          return false
        }
      }
    },
    fareCalculator: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'pro',
        'kam'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { // edited nov 2024
          //   return true
          // }
          return false
        }
      }
    },
    updateCompanyProfile: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    searchVendorsList: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'vendor_team' ) {
          //   return true
          // }
          return false
        }
      }
    },
    manageUsers: {
      write: ['admin', 'gm', 'cluster_head', 'kam'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    verifyAndStatusControl: {
      write: ['admin', 'gm', 'cluster_head']
    },
    verifyDocument: {
      write: ['admin', 'gm', 'cluster_head']
    },
    controlSlug: {
      write: ['admin', 'gm', 'cluster_head']
    },
    editBooking: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          // return false

          return true
        }
      }
    },
    cancelBooking: {
      write: ['admin', 'gm', 'cluster_head', 'pro', 'kam'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    cancelTrip: {
      write: ['admin', 'gm', 'cluster_head', 'kam', 'pro'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'business_team') {
            return false
          }

          return true
        }
      }
    },
    quoteShipper: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    createBidding: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        //  'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'vendor_team') {
            return true
          }
          return false
        }
      }
    },
    changeTripStatus: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (
          //   user.team &&
          //   user.team !== 'fulfillment_team' &&
          //   user.team !== 'business_team'
          // ) {
          //   return true
          // }
          // return false
          return true
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { // edited nov
          //   return true
          // }
          return false
        }
      }
    },
    acceptAndEditBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'business_team') {
            return false
          }
          return true
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { edited nov 2024
          //   return true
          // }
          return false
        }
      }
    },
    editBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team === 'vendor_team') {
            return false
          }
          return true
        }
      },
      checkClusterTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'cluster_head') {
          // if (user.team && user.team === 'vendor_team') { edited nov 2024
          //   return true
          // }
          return false
        }
      }
    },
    rejectBid: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        //  'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    activeTrip: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        'kam'
        // 'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (
            user.team &&
            (user.team === 'business_team' || user.team === 'fulfillment_team')
          ) {
            return true
          }
          return false
        }
      }
    },
    updateTripStatus: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team === 'business_team') {
          //   return true
          // }
          return false
        }
      }
    }
  },
  Users: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    subCompany: {
      write: ['admin', 'gm', 'cluster_head'] // edited nov 2024
    },
    changeTruck: {
      write: ['admin', 'cluster_head'] // edited nov 2024
    },
    inactiveOption: {
      write: ['admin', 'gm', 'cluster_head'] // edited nov 2024
    },
    manageUsers: {
      write: ['admin', 'gm', 'cluster_head', 'kam'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    addCompanybtn: {
      write: ['admin', 'gm', 'cluster_head', 'cluster_head'], // edited nov 2024
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    updateCompanyProfile: {
      write: ['admin', 'gm', 'cluster_head', 'kam'],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    },
    verifyAndStatusControl: {
      write: ['admin', 'gm', 'cluster_head']
    },
    verifyDocument: {
      write: ['admin', 'gm', 'cluster_head']
    },
    controlSlug: {
      write: ['admin', 'gm', 'cluster_head']
    },
    editBooking: {
      write: [
        'admin',
        'gm',
        'cluster_head',
        // 'kam',
        'pro'
      ],
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          // return false

          return true
        }
      }
    }
  },
  UserRequest: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    userReqAcceptAndReject: {
      write: ['admin', 'gm', 'cluster_head']
    }
  },
  Address: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    addNewAddress: {
      read: ['admin', 'pro', 'cluster_head', 'kam'], // edit nov 2024
      write: ['admin', 'pro', 'kam', 'cluster_head'], // edit nov 2024
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          // if (user.team && user.team !== 'business_team') {
          //   return true
          // }
          return false
        }
      }
    },
    updateAddress: {
      read: ['admin', 'pro', 'kam', 'cluster_head'], // edit nov 2024
      write: ['admin', 'pro', 'kam', 'cluster_head'], // edit nov 2024
      checkKamTeam: function () {
        let user = getLoggedInUser()
        if (user.role === 'kam') {
          if (user.team && user.team !== 'business_team') {
            return true
          }
          return false
        }
      }
    }
  },
  Reports: {
    showCreateBookingBtn: {
      write: ['admin', 'gm', 'cluster_head', 'pro']
    },
    matrixFilter: { write: ['admin', 'gm', 'cluster_head'] } // edit nov 2024
  }
}

export function getAccessLevel(action) {
  let menuTitle = sessionStorage.getItem('currentMenu')

  // ========== Call getLoggedInUser Function On Each Call ========== //
  let loggedInUser = getLoggedInUser()

  let canWrite = ACCESS_CONTROL[menuTitle][action]['write']?.findIndex(
    (x) => x === loggedInUser?.role
  )

  if (ACCESS_CONTROL[menuTitle][action].checkKamTeam) {
    let result = ACCESS_CONTROL[menuTitle][action]?.checkKamTeam()
    if (result) {
      canWrite = -1
    }
  }

  if (ACCESS_CONTROL[menuTitle][action].checkClusterTeam) {
    let result = ACCESS_CONTROL[menuTitle][action]?.checkClusterTeam()
    if (result) {
      canWrite = -1
    }
  }

  if (canWrite > -1) return true

  return false
}
