import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Landing from '../Landing/Landing'
import LoginForm from '../../features/Login/components/LoginForm'
import TabsContainer from '../../features/ShipperList/TabsContainer'
import ShipperRegistration from '../../features/Signup/Shipper/ShipperRegistration'
import ChangePassword from '../../features/ChangePassword'
import './App.css'
import VendorProfile from '../../features/VendorProfile/VendorProfile'
import ProtectedRoutes from './ProtectedRoutes'
import LoggedInUserProfile from '../../features/LoggedInUserProfile/LoggedInUserProfile'
import Finance from '../../features/Finance'
import Billing from '../../features/Billing'
import ShipperPannel from '../../features/shipper-pannel'
import Address from '../../features/Address'
import ReportTabsContainer from '../../features/Reports/ReportTabsContainer'
import ProProfile from '../../features/ProProfile'
import Notification from './Notification'
import GlobalSearchResult from '../../features/GlobalSearchResult'
import FeedReports from '../../features/FeedReports'
import ScrollToTop from '../ScrollToTop'
import VendorDCTable from '../../features/Finance/VendorDCTable'
import BillingDownloadList from '../../features/Billing/DownloadList'
import PayableBatchRequestListItems from '../../features/Finance/PayableBatchRequestListItems'

const CreateBooking = lazy(() =>
  import('../../features/CreateBooking/components/CreateBooking')
)
const CompanyProfile = lazy(() =>
  import('../../features/CompanyProfile/CompanyProfile')
)
const VendorAccelerationHome = lazy(() =>
  import('../../features/VendorAccelerationHome')
)
const VendorAccelerationBookings = lazy(() =>
  import('../../features/VendorAccelerationHome/VendorAccelerationBookings')
)
const TruckProfile = lazy(() =>
  import('../../features/TruckProfile/TruckProfile')
)
const DriverProfile = lazy(() =>
  import('../../features/DriverProfile/DriverProfile')
)
const RequestShipperContainer = lazy(() =>
  import('../../features/RequestShippers/TabsContainer')
)
const UserProfile = lazy(() => import('../../features/UserProfile/UserProfile'))

const VendorRegistration = lazy(() =>
  import('../../features/Signup/Vendor/VendorRegistration')
)
const ForgetPassword = lazy(() =>
  import('../../features/ForgetPassword/components/ForgetPassword')
)
const ForgetPasswordPhone = lazy(() =>
  import('../../features/ForgetPassword/components/ForgetPasswordPhone')
)
const HomeContainer = lazy(() => import('../HomeContainer/HomeContainer'))
const ForgetPasswordEmail = lazy(() =>
  import('../../features/ForgetPassword/components/ForgetPasswordEmail')
)

const RequestShipperProfileContainer = lazy(() =>
  import('../../features/RequestShippers/RequestShipperProfileContainer')
)
const EditBooking = lazy(() => import('../../features/EditBooking/EditBooking'))
const Bookings = lazy(() => import('../../features/Bookings/Bookings'))
const BookingUpdateRequests = lazy(() =>
  import('../../features/BookingUpdateRequests/BookingUpdateRequests')
)
const BookingList = lazy(() =>
  import('../../features/LatestBookings/BookingList')
)

const ResetPasswordForm = lazy(() =>
  import('../../features/ForgetPassword/components/ResetPasswordForm')
)

const Reporting = lazy(() => import('../../features/Reporting/Reporting'))
const VendorClusterHome = lazy(() => import('../../features/VendorClusterHome'))

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />}>
            <Route path="/" element={<LoginForm />} />
            <Route path="/signup/shipper" element={<ShipperRegistration />} />
            <Route path="/signup/vendor" element={<VendorRegistration />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route
              path="/forget-password-phone"
              element={<ForgetPasswordPhone />}
            />
            <Route
              path="/forget-password-email"
              element={<ForgetPasswordEmail />}
            />
            <Route
              path="/reset-password-form"
              element={<ResetPasswordForm />}
            />
          </Route>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/home" element={<HomeContainer />}>
              <Route path="/home/vendor" element={<VendorAccelerationHome />} />
              <Route path="/home/create-booking" element={<CreateBooking />} />
              <Route
                path="/home/company-profile/:companyId"
                element={<CompanyProfile />}
              />
              <Route path="/home/bookings" element={<Bookings />} />
              <Route
                path="/home/bookings-trips"
                element={<GlobalSearchResult />}
              />
              <Route
                path="/home/vendor-cluster"
                element={<VendorClusterHome />}
              />
              <Route
                path="/home/va/bookings/:vendorId/:status"
                element={<VendorAccelerationBookings />}
              />
              <Route path="/home/users" element={<TabsContainer />} />
              <Route path="/home/reporting" element={<Reporting />} />
              <Route path="/home/reports" element={<ReportTabsContainer />} />
              <Route
                path="/home/request-shippers"
                element={<RequestShipperContainer />}
              />
              <Route
                path="/home/booking-requests"
                element={<BookingUpdateRequests />}
              />
              <Route
                path="/home/request-shippers/:requestId"
                element={<RequestShipperProfileContainer />}
              />
              <Route
                path="/home/user/:role/:userId"
                element={<UserProfile />}
              />
              <Route path="/home/user/pro/:userId" element={<ProProfile />} />

              <Route
                path="/home/booking-details/:companyId"
                element={<BookingList />}
              />

              <Route
                path="/home/booking-edit/:bookingId"
                element={<EditBooking />}
              />
              <Route
                path="/home/shipper/booking-details"
                element={<ShipperPannel />}
              />
              <Route
                path="/home/truck-list/truck-profile/:truckId"
                element={<TruckProfile />}
              />
              <Route
                path="/home/driver-profile/:driverId"
                element={<DriverProfile />}
              />
              <Route
                path="/home/vendor-profile/:vendorId"
                element={<VendorProfile />}
              />
              <Route
                path="/home/change-password"
                element={<ChangePassword />}
              />
              <Route path="/home/profile" element={<LoggedInUserProfile />} />
              <Route path="/home/finance" element={<Finance />} />
              <Route
                path="/home/finance/:vendorId/dc"
                element={<VendorDCTable />}
              />
              <Route
                path="/home/finance/payable-batch-requests-item-list/:batchId"
                element={<PayableBatchRequestListItems />}
              />
              <Route path="/home/address" element={<Address />} />
              <Route path="/home/feed-reports" element={<FeedReports />} />
              <Route path="/home/billing" element={<Billing />} />
              <Route
                path="/home/billing/download-list"
                element={<BillingDownloadList />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
      <Notification />
    </Suspense>
  )
}

export default App
