import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const RangeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#F1F5F9"
      />
      <path
        d="M21.9998 13.6666C17.3998 13.6666 13.6665 17.4 13.6665 22C13.6665 26.6 17.3998 30.3333 21.9998 30.3333C26.5998 30.3333 30.3332 26.6 30.3332 22C30.3332 17.4 26.5998 13.6666 21.9998 13.6666ZM23.1748 27.075V28.6666H20.9498V27.0583C19.5248 26.7583 18.3165 25.8416 18.2248 24.225H19.8582C19.9415 25.1 20.5415 25.7833 22.0665 25.7833C23.6998 25.7833 24.0665 24.9666 24.0665 24.4583C24.0665 23.7666 23.6998 23.1166 21.8415 22.675C19.7748 22.175 18.3582 21.325 18.3582 19.6166C18.3582 18.1833 19.5165 17.25 20.9498 16.9416V15.3333H23.1748V16.9583C24.7248 17.3333 25.4998 18.5083 25.5498 19.7833H23.9165C23.8748 18.8583 23.3832 18.225 22.0665 18.225C20.8165 18.225 20.0665 18.7916 20.0665 19.5916C20.0665 20.2916 20.6082 20.75 22.2915 21.1833C23.9748 21.6166 25.7748 22.3416 25.7748 24.4416C25.7665 25.9666 24.6248 26.8 23.1748 27.075Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default RangeIcon
