import React, { useState } from 'react'
import { usePostHook } from '../../../hooks/fetchHook'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { TextField } from '@mui/material'
import { getLoggedInUser } from '../../../utils/functions'
import { AttachFile } from '@mui/icons-material'

const Demurrage = (props) => {
  const { onClose, open, setToast, tripId, type } = props
  const [payableAmount, setPaybleAmount] = useState('')
  const [receivableAmount, setReceivableAmount] = useState('')
  const [challanImage, setChallanImage] = useState({ name: '' })
  let user = getLoggedInUser()

  const submitDemurrage = () => {
    const form = new FormData()
    form.append('tripId', tripId)
    form.append('supDocUrl', challanImage)
    form.append('chargeType', type)
    form.append('supplierCharge', payableAmount)
    form.append('shipperCharge', receivableAmount)

    let url = '/lf-booking/api/v1/admin/booking/charge'
    usePostHook(
      url,
      form,
      () => {
        setToast(
          `${
            type === 'demurrage' ? 'Demurrage' : 'Extra Payment'
          } successfully submitted!`,
          'success'
        )
        setReceivableAmount('')
        setPaybleAmount('')
        setChallanImage({ name: '' })
        onClose()
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <Dialog
      sx={{
        maxWidth: '450px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '4px'
      }}
      onClose={onClose}
      fullWidth
      open={open}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ bgcolor: '#0071cd' }}
      >
        <Box>
          <Typography fontWeight={'bold'} color="#fff" variant="subtitle1">
            {type === 'demurrage' ? 'Demurrage' : 'Extra Payment'}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fill: '#ffffff' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        gap={2}
      >
        <Typography color="textSecondary" variant="body2">
          Trip ID: {tripId}
        </Typography>
        <TextField
          type="number"
          onWheel={(event) => event.target.blur()}
          size="medium"
          fullWidth
          label="Payable amount for vendor"
          required
          value={payableAmount}
          onChange={(e) => setPaybleAmount(e.target.value)}
          onKeyDown={(e) =>
            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
          }
        />
        <TextField
          type="number"
          onWheel={(event) => event.target.blur()}
          size="medium"
          fullWidth
          label="Receivable amount from client"
          required
          value={receivableAmount}
          onChange={(e) => setReceivableAmount(e.target.value)}
          onKeyDown={(e) =>
            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
          }
        />
        <TextField
          label="Upload supporting document"
          value={challanImage.name}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <label htmlFor="challanImage">
                <TextField
                  id="challanImage"
                  label="Upload supporting document"
                  onChange={(e) => setChallanImage(e.target.files[0])}
                  accept="image/*"
                  type="file"
                  sx={{ display: 'none' }}
                />
                <AttachFile />
              </label>
            )
          }}
        />
        <Button
          size="medium"
          onClick={submitDemurrage}
          fullWidth
          variant="contained"
          disabled={
            !payableAmount ||
            !receivableAmount ||
            !challanImage.name ||
            user?.role !== 'kam'
          }
        >
          Submit Now
        </Button>
      </Box>
    </Dialog>
  )
}

Demurrage.propTypes = {
  tripId: PropTypes.number,
  setRequestDetails: PropTypes.func,
  requestedShipperData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setToast: PropTypes.func,
  refreshDriverList: PropTypes.func,
  fixedVendor: PropTypes.any,
  type: PropTypes.string
}

export default Demurrage
