import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatStage, getLoggedInUser } from '../../utils/functions'
import { usePostHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import jwt_decode from 'jwt-decode'
import Avatar from '@mui/material/Avatar'
import BadgeIcon from '@mui/icons-material/Badge'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailIcon from '@mui/icons-material/Mail'
import Typography from '@mui/material/Typography'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import Divider from '@mui/material/Divider'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import { useStore } from '../../store'
import PreviewModal from './PreviewModal'
import {
  BorderedColumn,
  ProfileInfoWrapper,
  IconAvatar,
  LabelText,
  FileInputButton,
  ValueText
} from '../CompanyProfile/StyledComponents'
import { IconButton, Popover } from '@mui/material'

var canvas = null

const UserBasicInfo = (props) => {
  const { userStore } = useStore()
  const { userDetail, setToast } = props
  const [show, setShow] = useState(false)
  const [photoUrl, setPhotoUrl] = useState(userDetail.profilePicture)
  let cropper = null
  const role = getLoggedInUser()?.role
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const previewPhoto = (e) => {
    let file = e.target.files[0]
    e.target.value = ''
    let fileExtension = file.name.split('.').pop()
    if (
      fileExtension === 'png' ||
      fileExtension === 'jpg' ||
      fileExtension === 'webp' ||
      fileExtension === 'jpeg'
    ) {
      setShow(true)
      setTimeout(() => {
        let previewer = document.getElementById('output')
        var that = previewer
        previewer.src = URL.createObjectURL(file)
        previewer.onload = function () {
          cropper = new Cropper(that, {
            autoCropArea: 0.5,
            cropBoxResizable: true,
            scalable: false,
            zoomable: true,
            dragMode: 'move',
            crop: () => {
              canvas = cropper.getCroppedCanvas()
            }
          })
        }
      }, 100)
    } else {
      setToast('Please upload an image', 'error')
    }
  }

  const cropAndUploadPhoto = () => {
    setShow(false)
    canvas.toBlob((blob) => {
      let formdata = new FormData()
      formdata.append('image', blob, 'photo.jpg')
      let url = '/lf-user/api/v1/users/upload-image'
      usePostHook(
        url,
        formdata,
        (res) => {
          setToast('Photo has been uploaded successfully', 'success')
          cropper = null
          canvas = null
          setPhotoUrl(res.data.data.user.profilePicture)
          localStorage.setItem('loop-token', res.data.data.accessToken)
          localStorage.setItem('loop-refresh-token', res.data.data.refreshToken)
          let userProfile = jwt_decode(res.data.data.accessToken)
          localStorage.setItem('loop-user', JSON.stringify(userProfile))
          userStore.updateProfilePhotoUrl(res.data.data.user.profilePicture)
        },
        (err) => {
          setToast(err.response?.data?.message, 'error')
          cropper = null
          canvas = null
        },
        true
      )
    })
  }

  const dismissModal = () => {
    try {
      let previewer = document.getElementById('output')
      URL.revokeObjectURL(previewer.src) // free memory
      setShow(false)
      cropper = null
      canvas = null
    } catch (ex) {
      // console.log('ex: ', ex)
    }
  }

  return (
    <>
      <BorderedColumn>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <Box position="relative">
            <Avatar
              alt={userDetail.fullName}
              src={photoUrl ? photoUrl : userDetail.fullName}
              sx={{ width: 150, height: 150 }}
            />
            <label htmlFor="contained-button-file">
              <FileInputButton
                accept="image/*"
                onChange={previewPhoto}
                id="contained-button-file"
                type="file"
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                sx={{
                  bgcolor: '#e7e7e7',
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  zIndex: 100,
                  '&:hover': {
                    bgcolor: '#f7f7f7'
                  }
                }}
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>
          <Typography
            variant="body1"
            color="primary"
            textAlign={'center'}
            fontWeight={'bold'}
          >
            {userDetail.fullName}
          </Typography>
        </Box>

        <Divider />
        <Box mt={1} p={2}>
          <Typography fontWeight={'bold'}>My Information</Typography>

          <ProfileInfoWrapper>
            <Box>
              <IconAvatar>
                <BadgeIcon sx={{ fill: '#0071CD' }} />
              </IconAvatar>
            </Box>
            <Box>
              <LabelText>User Type</LabelText>
              <ValueText>{role}</ValueText>
            </Box>
          </ProfileInfoWrapper>

          {userDetail?.team && (
            <ProfileInfoWrapper>
              <Box>
                <IconAvatar>
                  <BadgeIcon sx={{ fill: '#0071CD' }} />
                </IconAvatar>
              </Box>
              <Box>
                <LabelText>Team</LabelText>
                <ValueText>{formatStage(userDetail.team)}</ValueText>
              </Box>
            </ProfileInfoWrapper>
          )}

          {userDetail?.zone && (
            <ProfileInfoWrapper>
              <Box>
                <IconAvatar>
                  <BadgeIcon sx={{ fill: '#0071CD' }} />
                </IconAvatar>
              </Box>
              <Box>
                <LabelText>Zone</LabelText>
                <ValueText>{formatStage(userDetail.zone)}</ValueText>
              </Box>
            </ProfileInfoWrapper>
          )}

          <ProfileInfoWrapper>
            <Box>
              <IconAvatar>
                <PhoneInTalkIcon sx={{ fill: '#0071CD' }} />
              </IconAvatar>
            </Box>
            <Box>
              <LabelText>Mobile Number</LabelText>
              <ValueText>
                {userDetail.phone ? userDetail.phone : 'N/A'}
              </ValueText>
            </Box>
          </ProfileInfoWrapper>
          <ProfileInfoWrapper>
            <Box>
              <IconAvatar>
                <MailIcon sx={{ fill: '#0071CD' }} />
              </IconAvatar>
            </Box>
            <Box>
              <LabelText>Email Address</LabelText>
              <ValueText>
                {userDetail.email ? (
                  userDetail.email.length > 16 ? (
                    <span onMouseOver={handleClick}>
                      {userDetail.email.substring(0, 16)}...
                    </span>
                  ) : (
                    userDetail.email
                  )
                ) : (
                  'N/A'
                )}
              </ValueText>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Typography sx={{ p: 2 }}>{userDetail.email}</Typography>
              </Popover>
            </Box>
          </ProfileInfoWrapper>
        </Box>
      </BorderedColumn>
      <PreviewModal
        open={show}
        onClose={setShow}
        dismiss={dismissModal}
        cropAndUploadPhoto={cropAndUploadPhoto}
      />
    </>
  )
}

UserBasicInfo.propTypes = {
  role: PropTypes.string,
  userDetail: PropTypes.object,
  setToast: PropTypes.func
}

export default UserBasicInfo
//Do you want to recieve mail?
