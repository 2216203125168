// import axios from 'axios'
import loopAxios from '../utils/loop-axios'
import { getApiEndPoint, getRequestHeaders } from '../utils/functions'

export function usePostHook(
  apiEndpoint,
  body,
  successCallback,
  errorCallback,
  authorized,
  options = {}
) {
  let urlBase = getApiEndPoint()
  let fullUrl = urlBase + apiEndpoint
  let headers = getRequestHeaders(authorized)
  loopAxios
    .post(fullUrl, body, {
      headers: {
        ...headers
      },
      ...options
    })
    .then(function (response) {
      successCallback(response)
    })
    .catch(function (error) {
      errorCallback(error)
      // console.log(error)
    })
}

export function usePutHook(
  apiEndpoint,
  body,
  successCallback,
  errorCallback,
  authorized
) {
  let urlBase = getApiEndPoint()
  let fullUrl = urlBase + apiEndpoint
  let headers = getRequestHeaders(authorized)
  loopAxios
    .put(fullUrl, body, {
      headers: {
        ...headers
      }
    })
    .then(function (response) {
      successCallback(response)
    })
    .catch(function (error) {
      errorCallback(error)
    })
}

export function usePatchHook(
  apiEndpoint,
  body,
  successCallback,
  errorCallback,
  authorized
) {
  let urlBase = getApiEndPoint()
  let fullUrl = urlBase + apiEndpoint
  let headers = getRequestHeaders(authorized)
  loopAxios
    .patch(fullUrl, body, {
      headers: {
        ...headers
      }
    })
    .then(function (response) {
      successCallback(response)
    })
    .catch(function (error) {
      errorCallback(error)
    })
}

export function useGetHook(
  apiEndpoint,
  successCallback,
  errorCallback,
  authorized
) {
  let urlBase = getApiEndPoint()
  let fullUrl = urlBase + apiEndpoint
  let headers = getRequestHeaders(authorized)

  loopAxios
    .get(fullUrl, {
      headers: {
        ...headers
      }
    })
    .then(function (response) {
      successCallback(response)
    })
    .catch(function (error) {
      errorCallback(error)
      // === RESPONSE 401 MEANS INVALID TOKEN === //
      if (error.response?.status === 401) {
        localStorage.clear()

        sessionStorage.clear()

        // === KNOCK OUT TO LOGIN PAGE WITH FULL REFRESH === //
        window.location.href = '/'
      }
    })
}

export function useDeleteHook(
  apiEndpoint,
  successCallback,
  errorCallback,
  authorized
) {
  let urlBase = getApiEndPoint()
  let fullUrl = urlBase + apiEndpoint
  let headers = getRequestHeaders(authorized)

  loopAxios
    .delete(fullUrl, {
      headers: {
        ...headers
      }
    })
    .then(function (response) {
      successCallback(response)
    })
    .catch(function (error) {
      errorCallback(error)
    })
}
