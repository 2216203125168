import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import LoopThemeLight from './config/Theme/light-theme'
import App from './components/App/App'
import store, { StoreContext } from './store'
import { ThemeProvider } from '@mui/material/styles'
import reportWebVitals from './reportWebVitals'
import ErrorBoundary from './components/ErrorBoundary'

// window.onerror = (msg, url, line, col, error) => {
//   // Note that col & error are new to the HTML 5 spec and may not be
//   // supported in every browser.  It worked for me in Chrome.
//   var extra = !col ? '' : '\ncolumn: ' + col
//   extra += !error ? '' : '\nerror: ' + error

//   // You can view the information in an alert to see things working like this:
//   console.error('Error: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra)

//   // TODO: Report this error via ajax so you can keep track
//   //       of what pages have JS issues

//   var suppressErrorAlert = true
//   // If you return true, then error alerts (like in older versions of
//   // Internet Explorer) will be suppressed.
//   return suppressErrorAlert
// }

window.onunhandledrejection = (e) => {
  // console.error('Unhandled Rehection Error' + e)
  throw new Error(e.reason.stack)
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={LoopThemeLight}>
      <StoreContext.Provider value={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </StoreContext.Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function..
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// 01758689854
