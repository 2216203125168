import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../../hooks/fetchHook'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AddDriverModal from '../../DriverList/AddDriverModal'
import { Chip, Stack, Typography } from '@mui/material'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import {
  formatStage,
  getLoggedInUser,
  getTripStatusColor
} from '../../../utils/functions'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import { Box } from '@mui/system'
import AddCircle from '@mui/icons-material/AddCircle'

const DriverAutoComplete = (props) => {
  const {
    vendorId,
    driver,
    setDriver,
    refreshDriverList,
    fixedVendor,
    setToast
  } = props
  const [drivers, setDrivers] = useState([])
  const [open, toggleOpen] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const user = getLoggedInUser()

  useEffect(() => {
    if (vendorId) {
      let url = `/lf-supa/api/v1/admin/driver/supplier/${vendorId}`

      if (user.role === 'cluster_head' || user.role === 'kam') {
        url = `/lf-supa/api/v1/crm/driver/supplier/${vendorId}`
      }

      if (debouncedSearch) url = url + `?query=${debouncedSearch}`
      useGetHook(
        url,
        (res) => {
          let results = res.data.data.map((item) => {
            return {
              id: item.id,
              title: item.fullName,
              phone: item.phone,
              driverAvailability: item.driverAvailability,
              isNidVerified: item.isNidVerified
            }
          })
          setDrivers(results)
        },
        () => {},
        true
      )
    }
  }, [vendorId, debouncedSearch])

  const onDriverSelect = (newValue) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true)
      })
    } else if (newValue && newValue.title === 'Add New Driver') {
      toggleOpen(true)
    } else {
      if (newValue) {
        setDriver(newValue)
      } else {
        setDriver({
          id: 0,
          title: ''
        })
      }
    }
  }

  return (
    <>
      <Autocomplete
        value={driver}
        onChange={(event, newValue) => onDriverSelect(newValue)}
        filterOptions={(options, params) => {
          options.push({
            inputValue: params.inputValue,
            title: 'Add New Driver'
          })
          return options
        }}
        id="free-solo-dialog-demoshjdd"
        options={drivers}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.title
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        sx={{ width: '200px' }}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack sx={{ borderBottom: '1px solid #e9e9e9', width: '100%' }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography> {option.title}</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  {option.isNidVerified ? (
                    <CheckCircleIcon fontSize="small" color="success" />
                  ) : option.title !== 'Add New Driver' ? (
                    <UnpublishedIcon fontSize="small" color="error" />
                  ) : (
                    <AddCircle size="small" color="success" />
                  )}
                  {option.title !== 'Add New Driver' && (
                    <Chip
                      size="small"
                      label={formatStage(option.driverAvailability)}
                      sx={{
                        bgcolor: getTripStatusColor(option.driverAvailability)[
                          'bg'
                        ],
                        color: getTripStatusColor(option.driverAvailability)[
                          'color'
                        ]
                      }}
                    />
                  )}
                </Box>
              </Stack>
              <Typography>{option.phone}</Typography>
            </Stack>
          </li>
        )}
        size="small"
        renderInput={(params) => (
          <TextField {...params} placeholder="Search Drivers" />
        )}
      />
      <AddDriverModal
        open={open}
        refreshDriverList={refreshDriverList}
        setToast={setToast}
        onClose={toggleOpen}
        fixedVendor={fixedVendor}
      />
    </>
  )
}

DriverAutoComplete.propTypes = {
  setToast: PropTypes.func,
  vendorId: PropTypes.string,
  drivers: PropTypes.array,
  setDrivers: PropTypes.func,
  driver: PropTypes.object,
  setDriver: PropTypes.func,
  defaultDrivers: PropTypes.array,
  refreshDriverList: PropTypes.func,
  fixedVendor: PropTypes.any
}

export default DriverAutoComplete
