import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePutHook } from '../../hooks/fetchHook'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import MailIcon from '@mui/icons-material/Mail'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {
  BorderedColumn,
  ProfileInfoWrapper,
  IconAvatar,
  LabelText,
  FileInputButton,
  ValueText
} from '../CompanyProfile/StyledComponents'

const ProBasicInfo = (props) => {
  const { userDetail, setToast } = props
  const [active, setActive] = useState(userDetail.isEnabled)

  const handleChange = (event) => {
    setActive(event.target.checked)
    let url = `/lf-user/api/v1/admin/user/${userDetail.userId}`
    usePutHook(
      url,
      { isEnabled: event.target.checked },
      (res) => setToast(res.data?.message, 'success'),
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  return (
    <BorderedColumn>
      <label
        style={{ position: 'absolute', top: '265px', left: '322px' }}
        htmlFor="icon-button-file"
      >
        <FileInputButton accept="image/*" id="icon-button-file" type="file" />
      </label>
      <Box display={'flex'} justifyContent={'center'} p={2}>
        <Box>
          <Avatar
            alt={userDetail.fullName}
            src={userDetail.profilePicture ? userDetail.profilePicture : 'as'}
            sx={{ width: 150, height: 150 }}
          />
        </Box>
      </Box>
      <Box mb={1}>
        <Typography
          variant="body1"
          color="primary"
          textAlign={'center'}
          fontWeight={'bold'}
        >
          {userDetail.fullName}
        </Typography>
      </Box>
      <Divider />
      <Box mt={1} mb={1} display="flex" justifyContent={'center'}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={active} onChange={handleChange} />}
            label={active ? 'Enabled' : 'Disabled'}
          />
        </FormGroup>
      </Box>
      <Divider />
      <Box mt={1} p={2}>
        <Typography fontWeight={'bold'}>Pro Information</Typography>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <BusinessCenterIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Designation</LabelText>
            <ValueText>Pro</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <PhoneInTalkIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Mobile Number</LabelText>
            <ValueText>{userDetail.phone ? userDetail.phone : 'N/A'}</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <MailIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Email Address</LabelText>
            <ValueText>{userDetail.email ? userDetail.email : 'N/A'}</ValueText>
          </Box>
        </ProfileInfoWrapper>

        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <AdminPanelSettingsIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Department</LabelText>
            <ValueText>Operations</ValueText>
          </Box>
        </ProfileInfoWrapper>
        <ProfileInfoWrapper>
          <Box>
            <IconAvatar>
              <LocationOnIcon sx={{ fill: '#0071CD' }} />
            </IconAvatar>
          </Box>
          <Box>
            <LabelText>Zone</LabelText>
            <ValueText>Dhaka</ValueText>
          </Box>
        </ProfileInfoWrapper>
      </Box>
    </BorderedColumn>
  )
}

ProBasicInfo.propTypes = {
  role: PropTypes.string,
  userDetail: PropTypes.object,
  setToast: PropTypes.func
}

export default ProBasicInfo
