import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BlockChain = (props) => {
  return (
    <SvgIcon
      {...props}
      width="91"
      height="90"
      viewBox="0 0 91 90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M42.1571 90C41.361 89.5547 40.4925 89.1797 39.817 88.6172C38.9485 87.8906 38.5383 86.8594 38.5142 85.7344C38.4901 84.3047 38.4901 82.875 38.5142 81.4453C38.5624 78.8672 40.396 77.0156 43.0257 76.8984C44.4973 76.8281 45.969 76.8281 47.4406 76.8984C50.0944 77.0156 51.9038 78.8672 51.9521 81.4453C51.9762 82.7578 51.9762 84.0703 51.9521 85.3828C51.9279 87.6094 50.7941 89.1094 48.5986 89.8359C48.5021 89.8828 48.4056 89.9531 48.3091 90C46.2585 90 44.2319 90 42.1571 90Z" />
      <path d="M48.3333 0C50.1186 0.632812 51.542 1.66406 51.9039 3.58593C52.0728 4.52343 52.0004 5.50781 52.0004 6.46875C52.0004 7.17187 52.0245 7.875 52.0004 8.57812C51.928 11.1562 50.1186 13.0078 47.4889 13.125C46.0173 13.1953 44.5456 13.1953 43.074 13.125C40.4202 13.0078 38.6108 11.1562 38.5625 8.57812C38.5384 7.28906 38.5384 6 38.5625 4.71094C38.5867 2.41406 39.6964 0.914065 41.916 0.164065C42.0125 0.11719 42.109 0.046875 42.2055 0C44.232 0 46.2585 0 48.3333 0Z" />
      <path d="M43.7014 54.797C43.7014 57.6329 43.7014 60.4689 43.7014 63.3282C43.7014 64.7579 43.2913 64.9923 42.0367 64.336C37.1634 61.7579 32.3142 59.1798 27.4408 56.6251C26.6447 56.2032 26.3311 55.6407 26.3311 54.797C26.3552 49.0782 26.3311 43.3829 26.3311 37.6642C26.3311 36.5157 26.8136 36.2345 27.8751 36.797C32.8691 39.4454 37.863 42.0939 42.8811 44.7189C43.5084 45.047 43.7255 45.4689 43.7014 46.1251C43.6773 48.9845 43.7014 51.8907 43.7014 54.797Z" />
      <path d="M46.7893 54.7264C46.7893 51.8671 46.8134 48.9843 46.7651 46.1249C46.7651 45.4218 47.0064 44.9999 47.6578 44.6718C52.5794 42.0936 57.501 39.4921 62.4225 36.8905C63.7494 36.1874 64.1596 36.4218 64.1596 37.8983C64.1596 43.453 64.1355 49.0311 64.1837 54.5858C64.1837 55.6171 63.7977 56.2733 62.8568 56.7421C58.0317 59.2499 53.2308 61.828 48.4057 64.3593C47.2476 64.9686 46.8134 64.7108 46.8134 63.4452C46.7893 60.5155 46.7893 57.6327 46.7893 54.7264Z" />
      <path d="M45.2453 42.4687C44.9799 42.3515 44.7387 42.2812 44.4974 42.164C39.5035 39.5156 34.5095 36.8906 29.5155 34.2187C29.2019 34.0547 28.7917 33.6562 28.7917 33.3515C28.7917 33.0703 29.2019 32.6719 29.5396 32.5078C34.3647 29.9297 39.2139 27.375 44.039 24.7969C44.8593 24.3515 45.5831 24.3281 46.4033 24.7734C51.2525 27.375 56.1259 29.9297 60.9751 32.5078C61.2887 32.6719 61.6747 33.0469 61.6506 33.3047C61.6265 33.6328 61.2887 34.0312 60.951 34.1953C55.957 36.8672 50.963 39.4922 45.9691 42.1406C45.7761 42.2578 45.5348 42.3515 45.2453 42.4687Z" />
      <path d="M83.5083 19.3359C84.232 19.3359 84.9558 19.3124 85.6796 19.3359C88.0439 19.3827 89.9739 21.0468 90.1428 23.3437C90.2634 25.0312 90.2634 26.7421 90.1428 28.4296C89.998 30.539 88.2851 32.2265 86.1138 32.3671C84.3527 32.4843 82.5674 32.4843 80.7821 32.3671C78.4902 32.203 76.7532 30.3046 76.729 28.0546C76.7049 26.6015 76.7049 25.1249 76.729 23.6718C76.7773 21.3046 78.7315 19.4062 81.1681 19.3359C81.9401 19.3124 82.7363 19.3359 83.5083 19.3359Z" />
      <path d="M7.05475 70.6641C6.30687 70.6641 5.55897 70.6875 4.78696 70.6641C2.44679 70.6172 0.492625 68.9297 0.323747 66.6328C0.20312 64.9453 0.20312 63.2344 0.323747 61.5469C0.4685 59.4375 2.1814 57.7734 4.37682 57.6094C6.13798 57.4922 7.92327 57.4922 9.70855 57.6094C11.9763 57.7734 13.6893 59.6484 13.7616 61.8281C13.8099 63.3281 13.8099 64.8047 13.7616 66.3047C13.7134 68.6719 11.7592 70.5703 9.29842 70.6406C8.55053 70.6875 7.80264 70.6641 7.05475 70.6641Z" />
      <path d="M76.729 64.1015C76.729 63.3749 76.7048 62.6484 76.729 61.8984C76.8013 59.6953 78.4901 57.7968 80.7338 57.6328C82.5191 57.4921 84.3526 57.5156 86.1379 57.6328C88.2609 57.7734 89.9738 59.4374 90.1186 61.5234C90.2392 63.2343 90.2392 64.9687 90.1186 66.7031C89.9497 68.9531 88.0197 70.6171 85.7036 70.664C84.2078 70.6874 82.6879 70.6874 81.1922 70.664C78.7072 70.6171 76.7531 68.6953 76.7048 66.2812C76.7048 65.5546 76.729 64.8281 76.729 64.1015Z" />
      <path d="M13.7617 25.9219C13.7617 26.6484 13.7858 27.375 13.7617 28.125C13.6893 30.3281 12.0005 32.2031 9.73271 32.3672C7.94743 32.4844 6.1139 32.4844 4.32862 32.3672C2.20558 32.2266 0.492669 30.5391 0.347917 28.4766C0.227289 26.7656 0.227289 25.0312 0.347917 23.2969C0.516795 21.0469 2.44683 19.3828 4.76287 19.3359C6.25865 19.3125 7.77856 19.3125 9.27433 19.3359C11.7593 19.3828 13.6893 21.3047 13.7617 23.7188C13.7858 24.4453 13.7617 25.1719 13.7617 25.9219Z" />
      <path d="M36.0536 11.9765C36.0778 12.7734 35.5229 13.1719 34.8956 13.5C31.2768 15.3281 27.6339 17.1562 24.015 18.9844C22.2539 19.875 20.4927 20.7656 18.7316 21.6562C17.7183 22.1719 16.8256 21.9375 16.4155 21.1406C16.0054 20.2969 16.319 19.5 17.3564 19.0078C22.7605 16.2656 28.1887 13.5234 33.5928 10.8047C34.8474 10.125 36.0295 10.7344 36.0536 11.9765Z" />
      <path d="M74.2682 20.0624C74.2682 21.6093 73.1102 22.289 71.9522 21.7265C70.2875 20.9296 68.647 20.0624 66.9823 19.2186C63.1946 17.2968 59.4069 15.3983 55.6192 13.453C55.1608 13.2186 54.606 12.7499 54.5336 12.3046C54.4371 11.8358 54.7025 11.0624 55.0885 10.8046C55.5227 10.5233 56.343 10.4999 56.8255 10.7343C59.3346 11.9061 61.8195 13.1952 64.2803 14.4608C67.2477 15.9608 70.2151 17.4374 73.1584 18.9843C73.6651 19.2655 74.027 19.828 74.2682 20.0624Z" />
      <path d="M36.0536 77.625C36.0536 79.2656 34.8473 79.875 33.5928 79.2422C31.0837 77.9766 28.5747 76.711 26.0656 75.4453C23.1223 73.9688 20.2031 72.4922 17.2598 70.9922C16.2224 70.4766 15.9329 69.4688 16.5361 68.6719C17.0427 67.9922 17.7906 67.8985 18.7797 68.3906C22.7846 70.4063 26.7894 72.4219 30.7942 74.4375C32.2176 75.1406 33.641 75.8203 35.0162 76.5938C35.4987 76.875 35.8364 77.4141 36.0536 77.625Z" />
      <path d="M55.764 79.6641C55.378 79.3125 54.7507 79.0078 54.6059 78.5625C54.4612 78.0938 54.7266 77.4844 54.9196 76.9688C55.0161 76.7578 55.378 76.6172 55.6433 76.5C60.9027 73.8516 66.162 71.1797 71.4214 68.5313C71.6868 68.3906 71.9522 68.25 72.2417 68.1563C72.9896 67.8984 73.7374 68.1797 74.0993 68.8125C74.4612 69.4687 74.3164 70.2656 73.7133 70.7109C73.4479 70.8984 73.1584 71.0391 72.8689 71.2031C67.7061 73.8281 62.5191 76.4297 57.3321 79.0547C56.8737 79.2422 56.4154 79.4063 55.764 79.6641Z" />
      <path d="M8.55059 44.9766C8.55059 47.5547 8.55059 50.1329 8.55059 52.711C8.55059 53.9063 7.99571 54.5625 6.98244 54.5391C6.01742 54.5157 5.46252 53.8828 5.46252 52.7344C5.46252 47.5547 5.46252 42.375 5.46252 37.1954C5.46252 36.4219 5.72791 35.7891 6.52405 35.5313C7.17544 35.3204 7.82682 35.4375 8.21283 36.0235C8.42996 36.375 8.52647 36.8438 8.55059 37.2657C8.57472 39.8438 8.55059 42.4219 8.55059 44.9766Z" />
      <path d="M81.9402 44.9999C81.9402 42.4218 81.9402 39.8436 81.9402 37.2655C81.9402 36.0936 82.4468 35.4608 83.4118 35.4374C84.4251 35.414 85.0282 36.0468 85.0282 37.2421C85.0282 42.4218 85.0282 47.6015 85.0282 52.7811C85.0282 53.5546 84.7628 54.1874 83.9667 54.4452C83.3153 54.6561 82.6639 54.5389 82.2779 53.953C82.0608 53.6014 81.9643 53.1327 81.9402 52.7108C81.916 50.1561 81.9402 47.578 81.9402 44.9999Z" />
      <path d="M22.6396 32.6719C22.6155 34.1016 21.5539 34.8282 20.4924 34.3594C19.2862 33.8203 18.1281 33.211 16.9701 32.5782C16.2222 32.1563 16.0051 31.3125 16.3911 30.6094C16.7771 29.9063 17.5974 29.6016 18.3935 29.9766C19.5757 30.5391 20.7578 31.125 21.8917 31.7813C22.3018 31.9922 22.519 32.4844 22.6396 32.6719Z" />
      <path d="M69.3707 34.5C68.5987 34.4765 68.1403 34.1015 67.9232 33.4687C67.706 32.8359 67.8025 32.2031 68.4298 31.8515C69.6602 31.1718 70.8906 30.5156 72.1934 29.9296C72.9412 29.6015 73.6409 29.8593 74.051 30.5624C74.437 31.2187 74.4129 31.9921 73.7615 32.3671C72.4587 33.1406 71.0595 33.7734 69.7085 34.4531C69.5878 34.4999 69.4672 34.4765 69.3707 34.5Z" />
      <path d="M17.5492 60.375C17.139 59.9766 16.5842 59.6251 16.3429 59.1563C16.0293 58.5704 16.2223 57.8907 16.8495 57.5391C18.1041 56.836 19.3827 56.1563 20.6855 55.5938C21.3851 55.2891 22.0606 55.5938 22.4466 56.2735C22.8085 56.9063 22.8568 57.6329 22.2295 58.0313C20.9026 58.8282 19.4792 59.461 18.1041 60.1641C18.0076 60.211 17.8628 60.211 17.7422 60.2344C17.6698 60.2813 17.5974 60.3282 17.5492 60.375Z" />
      <path d="M72.6277 60.2109C72.4347 60.164 72.1694 60.0937 71.9281 59.9765C70.8183 59.4375 69.7327 58.8984 68.647 58.3125C67.8027 57.8437 67.5855 57.0703 68.0198 56.2969C68.4299 55.5469 69.1778 55.2656 70.0222 55.664C71.1802 56.2031 72.3382 56.8125 73.4721 57.3984C74.1718 57.7734 74.413 58.3828 74.1959 59.1094C74.0029 59.8359 73.4721 60.164 72.6277 60.2109Z" />
      <path d="M46.7894 71.7657C46.7894 73.1954 46.2828 73.8751 45.2213 73.8516C44.0874 73.8282 43.6531 73.1016 43.7013 71.3673C43.7255 70.1719 44.2804 69.5626 45.3178 69.6094C46.3793 69.6329 46.7894 70.2423 46.7894 71.7657Z" />
      <path d="M46.7892 18.3048C46.7892 19.8048 46.2826 20.461 45.1728 20.4142C44.1113 20.3673 43.7012 19.7579 43.7012 18.2345C43.7012 16.8282 44.1596 16.1954 45.197 16.1485C46.2826 16.1251 46.7892 16.8048 46.7892 18.3048Z" />
    </SvgIcon>
  )
}

export default BlockChain
