import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../hooks/fetchHook'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import useDebouncedValue from '../../hooks/useDebounceValue'
import { IconButton, Stack, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box } from '@mui/system'

const LocationAutoComplete = (props) => {
  const {
    size,
    location,
    setLocation,
    onLocationChange,
    disabled,
    label,
    sessionId,
    setSessionId,
    error,
    helpertext
  } = props
  const [locations, setLocations] = useState([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)

  useEffect(() => {
    let url = `/lf-geolocation/api/v1/address?text=${
      debouncedSearch ? debouncedSearch : ''
    }&sessionId=${sessionId ? sessionId : ''}`

    if (location.pathname == '/home/address')
      url = `/lf-geolocation/api/v1/admin/map-address?text=${
        debouncedSearch ? debouncedSearch : ''
      }&sessionId=${sessionId ? sessionId : ''}`

    useGetHook(
      url,
      (res) => {
        setSessionId(res.data.data.sessionId)
        setLocations(res.data.data.result)
      },
      () => {},
      true
    )
  }, [debouncedSearch])

  const onLoacationSelect = (newValue) => {
    if (newValue) {
      setLocation(newValue)
      onLocationChange(newValue)
    }
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        value={location}
        onChange={(event, newValue) => onLoacationSelect(newValue)}
        filterOptions={(options) => {
          return options
        }}
        size={size ? size : 'medium'}
        id="free-solo-dialog-demo1w"
        options={locations}
        getOptionLabel={(option) => option.formattedTextEn}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.textEn}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <IconButton sx={{ bgcolor: '#eee' }}>
                  <LocationOnIcon color="primary" />
                </IconButton>
                <Box>
                  <Typography color="#0071CD" variant="subtitle2">
                    {option.shortFormEn ? option.shortFormEn : option.textEn}
                  </Typography>
                  <Typography color="#1E293B" variant="body1">
                    {option.textEn}
                  </Typography>
                </Box>
              </Stack>
            </li>
          )
        }}
        handleHomeEndKeys
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={error ? helpertext : ''}
            {...params}
            label={label || 'Search'}
          />
        )}
        fullWidth
      />
    </>
  )
}

LocationAutoComplete.propTypes = {
  size: PropTypes.string,
  setDefaultLocation: PropTypes.func,
  sessionId: PropTypes.string,
  setSessionId: PropTypes.func,
  label: PropTypes.string,
  setToast: PropTypes.func,
  onLocationChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  location: PropTypes.object,
  setLocation: PropTypes.func,
  defaultLocation: PropTypes.object,
  error: PropTypes.bool,
  helpertext: PropTypes.string
}

export default LocationAutoComplete
