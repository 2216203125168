import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoadingIcon from '../SvgIcons/LoadingIcon'
import StoppageIcon from '../SvgIcons/StoppageIcon'
import UnLoadingIcon from '../SvgIcons/UnLoadingIcon'
import TruckTypeIcon from '../SvgIcons/TruckTypeIcon'
import ClockIcon from '../SvgIcons/ClockIcon'
import { StyledFlexDiv } from '../../features/LatestBookings/StyledComponents/StyledComponents'
import { getPropValue, getHumanReadableDate } from '../../utils/functions'
import { Tooltip } from '@mui/material'

export default function LocationAndTruckInfo({
  fromLocation,
  stoppages,
  toLocation,
  truckSize,
  truckQuantity,
  pickupTime,
  pickupDate
}) {
  return (
    <Grid container px={1} py={2} alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <StyledFlexDiv>
          <Box>
            <LoadingIcon sx={{ width: 40, height: 40 }} />
          </Box>
          <Box>
            <Typography variant="body2">
              {getPropValue('address', fromLocation)}
            </Typography>
          </Box>
        </StyledFlexDiv>
      </Grid>
      <Grid item xs={2}>
        <StyledFlexDiv>
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${stoppages?.length} Stoppages`}
          >
            <StoppageIcon sx={{ width: 40, height: 40 }} />
          </Tooltip>
          <Typography variant="body2">
            {stoppages ? stoppages?.length : 'N/A'} Stoppages
          </Typography>
        </StyledFlexDiv>
      </Grid>
      <Grid item xs={2.5}>
        <StyledFlexDiv>
          <UnLoadingIcon sx={{ width: 40, height: 40 }} />
          <Typography variant="body2">
            {getPropValue('address', toLocation)}
          </Typography>
        </StyledFlexDiv>
      </Grid>
      <Grid item xs={2}>
        <StyledFlexDiv>
          <Box>
            <TruckTypeIcon sx={{ width: 40, height: 40 }} />
          </Box>
          <Box>
            <Typography variant="body2">
              {truckSize?.nameEn}&nbsp;
              <span style={{ color: '#757de8', fontWeight: 'bold' }}>
                x {truckQuantity}
              </span>
            </Typography>
          </Box>
        </StyledFlexDiv>
      </Grid>
      <Grid item xs={2}>
        <StyledFlexDiv>
          <Box>
            <ClockIcon sx={{ width: 40, height: 40 }} />
          </Box>
          <Box>
            <Typography textAlign="left" variant="body2">
              {pickupTime?.start} - {pickupTime?.end},{' '}
              {getHumanReadableDate(pickupDate)}
            </Typography>
          </Box>
        </StyledFlexDiv>
      </Grid>
    </Grid>
  )
}

LocationAndTruckInfo.propTypes = {
  fromLocation: PropTypes.object,
  stoppages: PropTypes.array,
  toLocation: PropTypes.object,
  truckSize: PropTypes.any,
  truckQuantity: PropTypes.number,
  pickupTime: PropTypes.object | PropTypes.any,
  pickupDate: PropTypes.object | PropTypes.any
}
