import { Button } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useState } from 'react'
import CompanyAutoComplete2 from '../../components/CompanyAutoComplete2'
import CustomDatePicker2 from '../../components/CustomDatePicker2'
import Snackbars from '../../components/Snackbar/Snackbar'
import { useGetHook } from '../../hooks/fetchHook'
import useToast from '../../hooks/useToast'
import { toQueryString } from '../../utils/functions'
import ImportData from './ImportData'
import SummaryData from './SummaryData'

const Billing = () => {
  const [summaryData, setSummaryData] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [company, setCompany] = useState('')

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  const onFromDateChange = (newValue) => {
    setFromDate(newValue)
  }

  const onToDateChange = (newValue) => {
    setToDate(newValue)
  }

  const applyFilters = () => {
    const query = {
      companyId: company?.id,
      fromDate,
      toDate
    }
    let url = `/lf-booking/api/v1/admin/booking/summary${toQueryString(query)}`
    useGetHook(
      url,
      (res) => setSummaryData(res.data.data),
      () => {},
      true
    )
  }

  const onCompanyChange = (value) => {
    setCompany(value)
  }

  return (
    <Box py={4}>
      <Container maxWidth="md">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={3}
        >
          <CustomDatePicker2
            fullWidth
            label="From Date"
            size="small"
            onChange={onFromDateChange}
          />
          <CustomDatePicker2
            fullWidth
            label="To Date"
            size="small"
            onChange={onToDateChange}
          />
          <CompanyAutoComplete2
            label="Select Company"
            onCompanyChange={onCompanyChange}
          />
          <Button onClick={applyFilters} sx={{ px: 5 }} variant="contained">
            Apply
          </Button>
        </Box>
        {summaryData ? (
          <SummaryData
            data={summaryData}
            fromDate={fromDate}
            toDate={toDate}
            company={company}
            setToast={setToast}
          />
        ) : (
          <ImportData />
        )}
      </Container>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  )
}

export default Billing
