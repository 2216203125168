import React from 'react'
import PropTypes from 'prop-types'
import BookingCard from '../BookingCard'
import Box from '@mui/material/Box'
import NoSearchResult from '../../../components/NoSearchResult'
import { isEmptyArray } from '../../../utils/functions'

const BookingTabsContent = (props) => {
  const { data, status, onItemClick } = props

  return (
    <Box mt={2}>
      {isEmptyArray(data) ? (
        <NoSearchResult />
      ) : (
        <>
          {data.map((item) => (
            <BookingCard
              booking={item}
              key={item.id}
              onItemClick={onItemClick}
              status={status}
            />
          ))}
        </>
      )}
    </Box>
  )
}

BookingTabsContent.propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  onItemClick: PropTypes.func
}

export default BookingTabsContent
