import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Snackbars from '../../components/Snackbar/Snackbar'
import { useGetHook } from '../../hooks/fetchHook'
import useToast from '../../hooks/useToast'
import { isEmptyArray } from '../../utils/functions'
import PropTypes from 'prop-types'
import { shipperPannelsTabData } from '../../data/data'
import { Search } from '@mui/icons-material'
import BookingCard from './Card'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Close } from '@mui/icons-material'
import BookingDetailTabsContainer from './BookingDetail/BookingDetail'
import useDebouncedValue from '../../hooks/useDebounceValue'
import CustomDatePicker2 from '../../components/CustomDatePicker2'

const ShipperPannel = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState({ company: { id: '' } })
  const [companyNames, setCompanyNames] = useState([])
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [bookingId, setBookingId] = useState('')
  const [status, setStatus] = useState('look_at_bid')
  const [loading, setLoading] = useState(false)
  const [pageSize] = useState(10)
  const [value, setValue] = useState(0)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState()
  const [bookingDetail, setBookingDetail] = useState({})
  const [total, setTotal] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const debouncedBID = useDebouncedValue(bookingId, 500)

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()

  useEffect(() => {
    let url = '/lf-company/api/v1/company/detail/me3'
    useGetHook(
      url,
      (res) => {
        if (isEmptyArray(res.data.data.companys)) {
          setToast('You are not assigned to a company!', 'error')
        } else {
          setCompanyNames(res.data.data.companys)
          setCompany(res.data.data.companys[0])
        }
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
      },
      true
    )
  }, [])

  useEffect(() => {
    const item = shipperPannelsTabData.filter((item) => item.tabIndex === value)
    !isEmptyArray(item) && setStatus(item[0].tabValue)
  }, [value])

  useEffect(() => {
    if (company.company.id) {
      setLoading(true)
      let url = `/lf-booking/api/v1/admin/booking?page=${page}&limit=${pageSize}&companyId=${company.company.id}`
      if (fromDate && toDate)
        url += `&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`

      if (debouncedBID && debouncedBID.trim() !== '') {
        url = url + '&id=' + debouncedBID
      }

      if (!debouncedBID && status) {
        url = url + '&bookingStatus=' + status
      }

      status &&
        useGetHook(
          url,
          (res) => {
            setTotal(res.data.count)
            let count = Math.ceil(res.data.count / 10)
            setPageCount(count)
            setCompanies(res.data.data)
            if (debouncedBID && res.data.data[0]?.bookingStatus) {
              const item = shipperPannelsTabData.filter(
                (item) => item.tabValue === res.data.data[0].bookingStatus
              )
              item && setValue(item[0].tabIndex)
            }
            setLoading(false)
          },
          (err) => {
            setToast(err.response?.data?.message, 'error')
            setLoading(false)
          },
          true
        )
    }
  }, [fromDate, page, toDate, status, debouncedBID, company.company.id])

  const closeDrawer = () => {
    setShowDrawer(false)
  }

  const handleTabChange = (event, newValue) => {
    setStatus('')
    setBookingId('')
    setValue(newValue)
    setPage(1)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const onItemClick = (data) => {
    let bookingId = data.id
    let url = `/lf-booking/api/v1/admin/booking/${bookingId}`
    // ?companyId=${company.company.id}
    useGetHook(
      url,
      (res) => {
        setBookingDetail(res.data.data)
        setShowDrawer(true)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const updateBooking = (data) => {
    setBookingDetail((s) => ({
      ...s,
      fareHistory: data
    }))
  }

  const updateBookingStage = (updatedStage) => {
    setBookingDetail((s) => ({
      ...s,
      stage: updatedStage
    }))
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleFromDateChange = (newValue) => setFromDate(newValue)
  const handleToDateChange = (newValue) => setToDate(newValue)

  return (
    <div>
      <Drawer anchor={'right'} open={showDrawer} onClose={closeDrawer}>
        <BookingDetailTabsContainer
          booking={bookingDetail}
          updateBooking={updateBooking}
          updateBookingStage={updateBookingStage}
        />
      </Drawer>

      <Box p={2}>
        <FormControl sx={{ minWidth: 250, mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Switch Company</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={company}
            label="Switch Company"
            onChange={(e) => setCompany(e.target.value)}
          >
            {!isEmptyArray(companyNames) &&
              companyNames.map((company) => (
                <MenuItem key={company.company.id} value={company}>
                  {company.company.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Divider light />

        <Box mt={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {shipperPannelsTabData.map((item, i) => (
                <Tab
                  key={i}
                  label={item.tabName.toUpperCase()}
                  {...a11yProps(item.tabIndex)}
                />
              ))}
            </Tabs>
            <Box display="flex" gap={2} alignItems="center">
              <TextField
                color="primary"
                size="small"
                label="Booking ID"
                value={bookingId}
                onChange={(e) => setBookingId(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />

              <IconButton>
                <FilterAltIcon
                  onClick={(e) => {
                    handleClick(e)
                    e.stopPropagation()
                  }}
                />
              </IconButton>
              <Popover
                sx={{ marginTop: 1 }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                  handleClose()
                  e.stopPropagation()
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Box>
                  <Box
                    pl={2}
                    pr={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography>Filter by date</Typography>
                    </Box>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          setAnchorEl(null)
                          e.stopPropagation()
                        }}
                      >
                        <Close sx={{ color: '#F82012' }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />
                  <Box onClick={(e) => e.stopPropagation()} p={2}>
                    <Stack spacing={2}>
                      <CustomDatePicker2
                        label="From Date*"
                        onChange={handleFromDateChange}
                        disableFuture
                      />
                      <CustomDatePicker2
                        label="To Date*"
                        onChange={handleToDateChange}
                        disableFuture
                      />
                    </Stack>
                    <Box
                      mt={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            setAnchorEl(null)
                            setFromDate('')
                            setToDate('')
                            e.stopPropagation()
                          }}
                        >
                          Clear
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Box>

          <Divider light sx={{ my: 2 }} />
          {!loading && isEmptyArray(companies) && (
            <Typography mt={2} textAlign="center">
              No data available!
            </Typography>
          )}
          {loading && (
            <Stack
              p={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Stack>
          )}

          {!loading && (
            <Box mb={2}>
              <Typography>Total {total} Results</Typography>
            </Box>
          )}

          {shipperPannelsTabData.map((item) => (
            <TabPanel key={item.tabIndex} value={value} index={item.tabIndex}>
              {!loading &&
                companies.map((item) => (
                  <BookingCard
                    onItemClick={onItemClick}
                    data={item}
                    key={item.id}
                    setToast={setToast}
                  />
                ))}
            </TabPanel>
          ))}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={2}
          >
            {!loading && (
              <Pagination
                count={pageCount}
                color="primary"
                page={page}
                onChange={handleChange}
              />
            )}
          </Box>

          <Snackbars
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </Box>
      </Box>
    </div>
  )
}

export default ShipperPannel

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
