import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Metrics from './Metrics'
import ShipperByTrips from './ShipperByTrips'
import VendorsByBid from './VendorsByBid'
import FunnelChartView from './FunnelChartView'
import LineChartView from './LineChartView'
import Loader from '../../../components/Loader/Loader'
import PropTypes from 'prop-types'
import { toQueryString } from '../../../utils/functions'
import { useGetHook } from '../../../hooks/fetchHook'
import { Typography } from '@mui/material'

export default function Summary({ setToast, date, chUserId }) {
  // const [{ data, loading }] = useFetch(url, true, { date, chUserId })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const memoToast = useCallback(() => setToast, [setToast])

  useEffect(() => {
    setLoading(true)
    const query = {
      date,
      chUserId
    }
    let url = `/lf-analytics/api/v1/operational-metrics/summary${toQueryString(
      query
    )}`

    useGetHook(
      url,
      (res) => {
        setData(res.data.data)
        setLoading(false)
      },
      (err) => {
        setToast(err.response?.data?.message, 'error')
        setLoading(false)
      },
      true
    )
  }, [date, chUserId])

  return (
    <Box>
      {!loading && !data && (
        <Typography color="error" textAlign="center" variant="subtitle1">
          Sorry cant load data!
        </Typography>
      )}
      {loading && <Loader />}
      {!loading && data && (
        <>
          <Grid container>
            <Grid item xs={5}>
              <Metrics data={data} />
            </Grid>
            <Grid item xs={7}>
              <FunnelChartView data={data.funnel} />
            </Grid>
          </Grid>
          <ShipperByTrips
            setToast={memoToast}
            date={date}
            chUserId={chUserId}
          />
          <VendorsByBid setToast={memoToast} date={date} chUserId={chUserId} />
          <LineChartView setToast={memoToast} date={date} chUserId={chUserId} />
        </>
      )}
    </Box>
  )
}

Summary.propTypes = {
  date: PropTypes.string,
  chUserId: PropTypes.string,
  setToast: PropTypes.func
}
