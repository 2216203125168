import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BorderedColumn } from './StyledComponents'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Profile from './VendorProfileTabs/Profile'
import Snackbars from '../../components/Snackbar/Snackbar'
import { useGetHook } from '../../hooks/fetchHook'
import { useParams } from 'react-router-dom'
import PaymentInfo from './VendorProfileTabs/PaymentInfo'
import Documents from './VendorProfileTabs/Documents'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const VendorProfileTabsContainer = (props) => {
  const { vendor, setTruck, setVendor } = props
  const params = useParams()
  const [value, setValue] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [paymentInfo, setPaymentInfo] = useState()

  useEffect(() => {
    let url = `/lf-user/api/v1/admin/user/${params.vendorId}/banking`
    useGetHook(
      url,
      (res) => setPaymentInfo(res.data.data),
      () => {},
      true
    )
  }, [])

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <BorderedColumn p={2}>
      <Box>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Payment Info" {...a11yProps(1)} />
          <Tab label="Document" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Profile
          vendor={vendor}
          setVendor={setVendor}
          setTruck={setTruck}
          setToast={setToast}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {paymentInfo && (
          <PaymentInfo
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            setToast={setToast}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Documents setToast={setToast} vendor={vendor} />
      </TabPanel>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BorderedColumn>
  )
}

VendorProfileTabsContainer.propTypes = {
  setVendor: PropTypes.func,
  setTruck: PropTypes.func,
  vendor: PropTypes.object
}

export default VendorProfileTabsContainer
