import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGetHook } from '../../hooks/fetchHook'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { toQueryString } from '../../utils/functions'
import useDebouncedValue from '../../hooks/useDebounceValue'

const UserAutoComplete = (props) => {
  const {
    user,
    setValue,
    required,
    disabled = false,
    label,
    team,
    role
    // parentUserId = ''
  } = props
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)

  useEffect(() => {
    const query = {
      team,
      search: debouncedSearch
      // parentUserId
    }

    let url = `/lf-user/api/v1/admin/hierarchy/user/${role}${toQueryString(
      query
    )}`

    useGetHook(
      url,
      (res) => {
        setUsers(res.data.data.result)
      },
      () => {},
      true
    )
  }, [debouncedSearch])

  const onUserSelect = (e, newValue) => {
    if (newValue) {
      setValue((prev) => ({
        ...prev,
        ...newValue
      }))
    } else {
      setValue((prev) => ({
        ...prev,
        email: '',
        fullName: '',
        id: 0,
        phone: '',
        profilePicture: '',
        userId: ''
      }))
    }
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        value={user}
        onChange={onUserSelect}
        filterOptions={(options) => {
          return options
        }}
        id={`free-solo-dialog-${team}`}
        options={users}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.fullName
        }}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={label || 'Search User'}
          />
        )}
        sx={{ minWidth: 200 }}
      />
    </>
  )
}

UserAutoComplete.propTypes = {
  user: PropTypes.object,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  // parentUserId: PropTypes.string,
  role: PropTypes.string,
  team: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
}

export default UserAutoComplete
