import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import Driver from './Driver'
import AddDriverModal from './AddDriverModal'
import { useGetHook } from '../../hooks/fetchHook'
import {
  InputLabel,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import useDebouncedValue from '../../hooks/useDebounceValue'
import SearchIcon from '@mui/icons-material/Search'
import { getLoggedInUser, toQueryString } from '../../utils/functions'

import UserListTitle from '../../components/UserListTitle'

const DriverList = (props) => {
  const { setToast } = props
  const [open, setOpen] = useState(false)
  const [isAvailable, setIsAvailable] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [pageCount, setPageCount] = useState()
  const [total, setTotal] = useState(0)
  const [drivers, setDrivers] = useState([])
  const [kw, setKw] = useState('')
  const debounedKw = useDebouncedValue(kw, 500)
  const user = getLoggedInUser()
  const isBusinessCluster = Boolean(
    user.role === 'cluster_head' && user.team === 'business_team'
  )

  useEffect(() => {
    const queryObject = {
      page,
      limit: pageSize,
      query: debounedKw,
      driverAvailability: `${isAvailable !== null ? isAvailable : ''}`
    }
    let url = `/lf-supa/api/v1/admin/driver${toQueryString(queryObject)}`

    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = `/lf-supa/api/v1/crm/driver${toQueryString(queryObject)}`
    }

    useGetHook(
      url,
      (res) => {
        setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setDrivers(res.data.data)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }, [page, debounedKw, isAvailable])

  const handleChange = (event, value) => {
    setPage(value)
  }

  const reloadData = () => {
    let url = `/lf-supa/api/v1/admin/driver?page=${page}&limit=${pageSize}`

    if (user.role === 'cluster_head' || user.role === 'kam') {
      url = `/lf-supa/api/v1/crm/driver?page=${page}&limit=${pageSize}`
    }

    useGetHook(
      url,
      (res) => {
        setTotal(res.data.count)
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setDrivers(res.data.data)
      },
      (err) => setToast(err?.response?.data?.message, 'error'),
      true
    )
  }

  const refreshDriverList = (data) => {
    setDrivers((prev) => [...prev, data])
  }

  return (
    <>
      <Box p={2}>
        <Box display="flex" gap={2} alignItems="center" mb={2}>
          <Box flexGrow={1}>
            <UserListTitle count={total} name="Drivers" />
          </Box>

          {!isBusinessCluster && (
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              color="primary"
            >
              Add Driver
            </Button>
          )}

          <FormControl sx={{ minWidth: 180 }} size="small">
            <InputLabel id="demo-simple-select-label26">
              Select Availability
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label26"
              id="demo-simple-select26"
              value={isAvailable}
              label="Select Availability"
              onChange={(e) => {
                setIsAvailable(e.target.value)
                setPage(1)
              }}
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={'available'}>Available</MenuItem>
              <MenuItem value={'in_trip'}>In Trip</MenuItem>
            </Select>
          </FormControl>

          <Box>
            <TextField
              color="primary"
              size="small"
              label="Search Drivers"
              value={kw}
              onChange={(e) => {
                setKw(e.target.value)
                setPage(1)
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
        {drivers.map((item) => (
          <Driver key={item.id} data={item} />
        ))}
        <Box mt={3} display="flex" flexDirection="row-reverse">
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <AddDriverModal
        refreshDriverList={refreshDriverList}
        open={open}
        reloadData={reloadData}
        setToast={setToast}
        onClose={setOpen}
      />
    </>
  )
}

DriverList.propTypes = {
  setToast: PropTypes.func
}

export default DriverList
