import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { getApiEndPoint } from './functions'

export const checkTokenVerification = () => {
  const accessToken = localStorage.getItem('loop-token')

  if (!accessToken) {
    return false
  }

  const loggedInUser = jwt_decode(accessToken)

  const currentTime = new Date() / 1000 // === CONVERTED TO SECONDS === //

  const expiryTime = loggedInUser.exp // === GIVES IN SECONDS === //

  // =========== FORCE REFRESH 1 YEAR TOKENS ============= //
  if (
    // process.env.NODE_ENV === 'development' &&
    expiryTime - currentTime >
    1200
  ) {
    return false
  }

  // ====== GREATER THAN CHECK 10 NEEDS TO BE ADDED ====== //
  // 31535990
  if (expiryTime - currentTime > 10) {
    // console.log('expires in', expiryTime - currentTime)
    return true
  }

  return false
}

// =========== ALSO RETURNS NEW ACCESS TOKEN ============== //
export async function refreshTokenAndResetLocalStorage() {
  // ========= INITIALIZED INDEPENDENTLY =========== //
  const urlBase = getApiEndPoint()

  const fullUrl = urlBase + '/lf-user/api/v1/public/user/refreshtoken/'

  const body = JSON.stringify({
    refreshToken: localStorage.getItem('loop-refresh-token')
  })

  try {
    const response = await axios.post(fullUrl, body, {
      headers: { 'Content-Type': 'application/json' }
    })

    localStorage.setItem('loop-token', response.data.data.accessToken)

    localStorage.setItem('loop-refresh-token', response.data.data.refreshToken)

    const userProfile = jwt_decode(response.data.data.accessToken)

    localStorage.setItem('loop-user', JSON.stringify(userProfile))

    return response.data.data.accessToken
  } catch (error) {
    // console.log('refresh token error', error)
    localStorage.clear()

    sessionStorage.clear()

    // ======== KNOCK OUT TO LOGIN PAGE WITH FULL REFRESH ======== //
    window.location.href = '/'
  }
}
export const setSessionStorage = (pathname) => {
  const setSession = (name) => sessionStorage.setItem('currentMenu', name)

  // NOTE: since we used regex, the most distinct pattern has been placed at the top
  switch (true) {
    case /\/home\/bookings-trips/.test(pathname):
      setSession('Global')
      break

    case /\/home\/va\/bookings/.test(pathname):
    case /\/home\/booking.*/.test(pathname):
      setSession('Home')
      break

    case /\/home\/driver-p.*/.test(pathname):
    case /\/home\/truck-l.*/.test(pathname):
    case /\/home\/company-p.*/.test(pathname):
    case /\/home\/vendor-p.*/.test(pathname):
    case /\/home\/user.*/.test(pathname):
      setSession('Users')
      break

    case /\/home\/reports/.test(pathname):
      setSession('Reports')
      break

    case /\/home\/request-shippers/.test(pathname):
      setSession('UserRequest')
      break

    case /\/home\/address/.test(pathname):
      setSession('Address')
      break

    case /\/home\/feed-reports/.test(pathname):
      setSession('Feed')
      break

    default:
      setSession('Home')
  }
}

/*
All routes
__________

/home/request-shippers
/home/address
/home/booking-requests
/home/feed-reports
/home/profile
/home/bookings-trips
/home/create-booking

Booking routes
/home/bookings
/home/booking-details/id

Request
/home/request-shippers/
/home/request-shippers/id

Users
/home/users
/home/driver-profile/
/home/truck-list/truck-profile/
/home/user/pro/id
/home/user/kam/id
/home/user/cluster_head/id
/home/user/gm/id
/home/vendor-profile/id
/home/company-profile/id

*/
