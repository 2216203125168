import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import ClockIcon from '../../components/SvgIcons/ClockIcon'
import { formatStage, getLoggedInUser, timesAgo } from '../../utils/functions'
import './booking-detail.css'
import { useStore } from '../../store'
import TruckTypeIcon from '../../components/SvgIcons/TruckTypeIcon'
import { useLocation } from 'react-router-dom'

function getColor(stage) {
  switch (stage) {
    case 'look_at_bid':
      return {
        bg: '#FFB547',
        color: '#FFFFFF'
      }
    case 'shipper_approved':
      return {
        bg: '#0071CD',
        color: '#FFFFFF'
      }
    case 'quote_shipper':
      return {
        bg: '#ED6C02',
        color: '#FFFFFF'
      }
    case 'select_vendor':
      return {
        bg: '#F82012',
        color: '#FFFFFF'
      }
    case 'get_truck_info':
      return {
        bg: '#4CAF50',
        color: '#FFFFFF'
      }
    case 'completed':
      return {
        bg: '#4CAF50',
        color: '#FFFFFF'
      }

    default:
      return {
        bg: '#A569BD',
        color: '#FFFFFF'
      }
  }
}

const BookingDetailHeader = (props) => {
  const { booking } = props
  const { bookingStore } = useStore()
  const role = getLoggedInUser()?.role
  const location = useLocation()
  return (
    <Box
      display="flex"
      p={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flexGrow={1}>
        <Typography color="primary" variant="subtitle2" fontWeight={'bold'}>
          Booking ID: {booking.id}
        </Typography>
      </Box>
      <Box flexGrow={1} display="flex" alignItems="center" gap={1}>
        <TruckTypeIcon sx={{ width: '40px', height: '40px' }} />
        <Typography variant="body2">
          {booking.truckSize.nameEn}
          <b>&nbsp;x&nbsp;{booking.truckQuantity}</b>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={4}>
        <Box display="flex" alignItems="center" gap={1}>
          <ClockIcon />
          <Typography variant="subtitle2">
            {timesAgo(booking.createdAt)}
          </Typography>
        </Box>

        {/* <Box
        sx={{
          background: '#FFB547',
          borderRadius: '40px',
          padding: 1
        }}
      >
        <Typography sx={{ color: '#fff' }} variant="body2">
          {formatStage(booking.stage)}
        </Typography>
      </Box> */}
        {role !== 'shipper' &&
          location.pathname !== '/home/booking-requests' && (
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              className={`circle ${booking.biddingCount >= 3 ? 'green' : ''}`}
            >
              <Typography
                sx={{
                  color: '#fff',
                  textAlign: 'center',
                  marginTop: '2px',
                  fontWeight: 'bold'
                }}
                variant="body2"
              >
                {booking.biddingCount}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">Bids</Typography>
            </Box>
          </Box>
        )}
        <Chip
          label={formatStage(bookingStore.bookingDetails?.bookingStatus)}
          sx={{
            background: getColor(bookingStore.bookingDetails?.bookingStatus)[
              'bg'
            ],
            color: getColor(bookingStore.bookingDetails?.bookingStatus)['color']
          }}
        />
      </Box>
    </Box>
  )
}

BookingDetailHeader.propTypes = {
  booking: PropTypes.object
}

export default BookingDetailHeader
