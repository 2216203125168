import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TotalKiloIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 37.5C0 16.7893 16.7893 0 37.5 0V0C58.2107 0 75 16.7893 75 37.5V37.5C75 58.2107 58.2107 75 37.5 75V75C16.7893 75 0 58.2107 0 37.5V37.5Z"
        fill="#0071CD"
      />
      <path
        d="M53.7599 30.1402L51.2999 33.8402C52.4862 36.2064 53.067 38.8299 52.9902 41.4757C52.9134 44.1215 52.1814 46.7069 50.8599 49.0002H23.1399C21.4222 46.0205 20.7104 42.5665 21.1101 39.1504C21.5098 35.7343 22.9997 32.5379 25.3588 30.0351C27.718 27.5323 30.8208 25.8563 34.2073 25.2556C37.5939 24.6549 41.0838 25.1615 44.1599 26.7002L47.8599 24.2402C44.0928 21.8247 39.6245 20.7423 35.1696 21.1661C30.7148 21.59 26.5307 23.4956 23.2868 26.5783C20.0429 29.6609 17.9266 33.7424 17.2763 38.1699C16.626 42.5974 17.4794 47.115 19.6999 51.0002C20.0489 51.6048 20.55 52.1075 21.1535 52.4583C21.757 52.8092 22.4418 52.996 23.1399 53.0002H50.8399C51.5447 53.003 52.2378 52.8195 52.8489 52.4683C53.46 52.1171 53.9674 51.6107 54.3199 51.0002C56.1626 47.8081 57.0874 44.1688 56.9921 40.4841C56.8968 36.7995 55.7851 33.2129 53.7799 30.1202L53.7599 30.1402ZM34.1799 43.8202C34.5513 44.1921 34.9925 44.4872 35.4781 44.6885C35.9637 44.8898 36.4842 44.9934 37.0099 44.9934C37.5355 44.9934 38.056 44.8898 38.5416 44.6885C39.0272 44.4872 39.4684 44.1921 39.8399 43.8202L51.1599 26.8402L34.1799 38.1602C33.808 38.5317 33.5129 38.9729 33.3116 39.4585C33.1103 39.9441 33.0067 40.4646 33.0067 40.9902C33.0067 41.5159 33.1103 42.0364 33.3116 42.522C33.5129 43.0076 33.808 43.4487 34.1799 43.8202Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default TotalKiloIcon
