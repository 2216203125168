import { makeObservable, observable, action } from 'mobx'

class NotificationStore {
  notifications = { unreadCount: 0, items: [] }
  constructor() {
    makeObservable(this, {
      notifications: observable,
      updateNotifications: action
    })
    this.notifications = { unreadCount: 0, items: [] }
  }

  updateNotifications(notifications) {
    this.notifications = notifications
  }
}

export default NotificationStore
