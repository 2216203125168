import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const TruckTypeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#F1F5F9"
      />
      <path
        d="M32 20H29V16H15C13.9 16 13 16.9 13 18V29H15C15 30.66 16.34 32 18 32C19.66 32 21 30.66 21 29H27C27 30.66 28.34 32 30 32C31.66 32 33 30.66 33 29H35V24L32 20ZM18 30.5C17.17 30.5 16.5 29.83 16.5 29C16.5 28.17 17.17 27.5 18 27.5C18.83 27.5 19.5 28.17 19.5 29C19.5 29.83 18.83 30.5 18 30.5ZM31.5 21.5L33.46 24H29V21.5H31.5ZM30 30.5C29.17 30.5 28.5 29.83 28.5 29C28.5 28.17 29.17 27.5 30 27.5C30.83 27.5 31.5 28.17 31.5 29C31.5 29.83 30.83 30.5 30 30.5Z"
        fill="#0071CD"
      />
    </SvgIcon>
  )
}

export default TruckTypeIcon
