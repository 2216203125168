import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import OtpInput from '../../ForgetPassword/components/OtpInput'
import { usePostHook } from '../../../hooks/fetchHook'
import {
  FormFlexContainer,
  FixedWidthBox,
  PrimaryButton
} from '../../../components/Landing/StyledComponents/StyledComponents'
import Snackbars from '../../../components/Snackbar/Snackbar'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { useStore } from '../../../store'
import PassWordInput from '../../../components/PassWordInput'
import { getFcmToken } from '../../../messaging/fcm'
import { isSupported } from 'firebase/messaging'

const LoginForm = () => {
  const { userStore } = useStore()
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const [userNameErr, setUserNameErr] = useState('')
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState('')
  const [showOtpFields, setShowOtpFields] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const handleSuccess = (response) => {
    if (response.data.data.isOtpSent) {
      setShowOtpFields(true)
    } else {
      localStorage.setItem('loop-token', response.data.data.accessToken)
      localStorage.setItem(
        'loop-refresh-token',
        response.data.data.refreshToken
      )
      let userProfile = jwt_decode(response.data.data.accessToken)
      localStorage.setItem('loop-user', JSON.stringify(userProfile))
      userStore.updateFullName(userProfile.fullName)
      userStore.updateProfilePhotoUrl(userProfile.profilePicture)

      if (userProfile.role === 'shipper') {
        navigate('/home/shipper/booking-details')
      } else if (userProfile.role === 'finance') {
        navigate('/home/finance')
      } else if (
        userProfile.role === 'kam' &&
        userProfile.team === 'vendor_team'
      ) {
        navigate('/home/vendor')
      } else if (userProfile.role === 'billing') {
        navigate('/home/billing')
      } else {
        navigate('/home/bookings')
      }
    }
    isSupported() && getFcmToken(function () {})
  }

  const handleError = (error) => {
    if (
      error?.response?.data?.message ===
      'You have a active OTP session! Please wait some time.'
    ) {
      setShowOtpFields(true)
    } else {
      setToast(error?.response?.data?.message, 'error')
    }
  }

  const onUserNameChange = (e) => {
    setUserName(e.target.value)
    setUserNameErr('')
  }

  const login = () => {
    if (userName.trim() === '') {
      setUserNameErr('Phone or Email cannot be empty')
      return
    }
    if (password.trim() === '') {
      setPasswordErr('Password cannot be empty')
      return
    }
    let url = '/lf-user/api/v1/public/user/login/'
    let body = {
      phoneOrEmail: userName,
      password,
      // pushToken: 123,
      allowedUserRoles: [
        'admin',
        'kam',
        'gm',
        'cluster_head',
        'pro',
        'shipper',
        'finance',
        'billing'
      ]
    }
    usePostHook(url, body, handleSuccess, handleError)
  }

  const setToast = (msg, severity) => {
    setSnackbarOpen(true)
    setSnackbarMessage(msg)
    setSnackbarSeverity(severity)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  }

  const verifyOtp = (otp) => {
    let url = '/lf-user/api/v1/public/user/verify-otp/'

    let data = {
      phoneOrEmail: userName,
      otpNumber: otp
    }
    usePostHook(
      url,
      data,
      (response) => {
        if (response.status === 200) {
          localStorage.setItem('loop-token', response.data.data.accessToken)
          localStorage.setItem(
            'loop-refresh-token',
            response.data.data.refreshToken
          )
          let userProfile = jwt_decode(response.data.data.accessToken)
          userStore.updateProfilePhotoUrl(userProfile.profilePicture)
          localStorage.setItem('loop-user', JSON.stringify(userProfile))
          navigate('/home/bookings')
        }
      },
      (error) => {
        setToast(error?.response?.data?.message, 'error')
      }
    )
  }

  const backToForm = () => setShowOtpFields(false)

  // GLOBAL KEYDOWN EVENT LISTENER
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    // CLEAR EVENT LISTENER WHEN GOES TO ANOTHER PAGE
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <>
      <FormFlexContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {!showOtpFields ? (
          <>
            <Box mb={2}>
              <Typography variant="h3" gutterBottom component="div">
                Your Local
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                component="div"
                color="primary"
              >
                Freight Partner
              </Typography>
            </Box>
            <FixedWidthBox>
              <TextField
                label="Phone or Email"
                type="text"
                error={userNameErr ? true : false}
                helperText={userNameErr}
                onChange={onUserNameChange}
                fullWidth
                // onKeyDown={handleKeyDown} // LISTENING TO GLOBAL EVENT LISTENER
              />
              <Box mt={2}>
                <PassWordInput
                  error={passwordErr ? true : false}
                  helperText={passwordErr}
                  onChange={(value) => setPassword(value)}
                  // onKeyDown={handleKeyDown} // LISTENING TO GLOBAL EVENT LISTENER
                />
              </Box>
            </FixedWidthBox>
            <FixedWidthBox
              display="flex"
              mt={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked size="small" />}
                    label="Remember"
                  />
                </FormGroup>
              </Box>
              <Box
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/forget-password')}
              >
                <Typography variant="body2" color="primary">
                  Forgot Password ?
                </Typography>
              </Box>
            </FixedWidthBox>
            <FixedWidthBox mt={2}>
              <PrimaryButton
                variant="contained"
                fullWidth
                disabled={!userName || !password}
                onClick={login}
              >
                LOGIN
              </PrimaryButton>
            </FixedWidthBox>
            {/* <FixedWidthBox mt={2}>
              <LightButton variant="contained" fullWidth>
                SIGNUP
              </LightButton>
            </FixedWidthBox> */}
          </>
        ) : (
          <OtpInput submitOtp={verifyOtp} goBack={backToForm} />
        )}
      </FormFlexContainer>

      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default LoginForm
