import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetHook } from '../../hooks/fetchHook'
import useDebouncedValue from '../../hooks/useDebounceValue'
import PropTypes from 'prop-types'

const DistricAutoComplete = ({ size, label, district, setDistrict }) => {
  const [districts, setDistricts] = useState([])
  const [search, setSearch] = useState('')
  const denoucedSearch = useDebouncedValue(search, 500)

  useEffect(() => {
    let url = '/lf-geolocation/api/v1/public/district'
    if (denoucedSearch) {
      url = url + '?nameEn=' + denoucedSearch
    }
    useGetHook(
      url,
      (res) => {
        let locations = res.data.data.map((loc) => {
          return {
            id: loc.id,
            title: loc.nameEn
          }
        })
        setDistricts(locations)
      },
      () => {
        // console.log('err: ', err)
      }
    )
  }, [])

  const onDistrictSelect = (newValue) => {
    if (newValue) {
      setDistrict({
        ...newValue
      })
    } else {
      setDistrict({
        id: 0,
        title: ''
      })
    }
  }

  const onSearchInputChange = (val) => {
    setSearch(val)
  }

  return (
    <Autocomplete
      value={district}
      onChange={(event, newValue) => {
        onDistrictSelect(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        onSearchInputChange(newInputValue)
      }}
      options={districts}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          size={size || 'medium'}
          fullWidth
          {...params}
          label={label || 'Where your trucks are located'}
        />
      )}
    />
  )
}

export default DistricAutoComplete
DistricAutoComplete.propTypes = {
  vendor: PropTypes.object,
  label: PropTypes.string,
  district: PropTypes.object,
  setDistrict: PropTypes.func,
  size: PropTypes.string
}
