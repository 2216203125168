import { Box, Drawer, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Snackbars from '../../components/Snackbar/Snackbar'
import { useGetHook } from '../../hooks/fetchHook'
import useToast from '../../hooks/useToast'
import { useStore } from '../../store'
import BookingDetail from '../BookingDetail/BookingDetail'
import BookingTabsContent from '../LatestBookings/Tabs/BookingTabsContent'
import TripTabsContent from '../LatestBookings/Tabs/TripTabsContent'

const GlobalSearchResult = () => {
  const { globalSearchStore } = useStore()
  const [bookingDetail, setBookingDetail] = useState({})
  const [showDrawer, setShowDrawer] = useState(false)
  const { bookingStore } = useStore()
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setToast
  } = useToast()
  const [, setSearchParams] = useSearchParams()

  const onItemClick = (data) => {
    let bookingId = data.id
    let url = `/lf-booking/api/v1/admin/booking/${bookingId}`
    useGetHook(
      url,
      (res) => {
        if (res.data.data.company.id) {
          setSearchParams({ companyId: res.data.data.company.id })
        }
        setBookingDetail(res.data.data)
        bookingStore.updateBookingDetails(res.data.data)
        setShowDrawer(true)
      },
      (err) => setToast(err.response?.data?.message, 'error'),
      true
    )
  }

  const closeDrawer = () => {
    setShowDrawer(false)
  }

  const updateBooking = (data) => {
    setBookingDetail((s) => ({
      ...s,
      fareHistory: data
    }))
  }

  const updateBookingStage = (updatedStage) => {
    setBookingDetail((s) => ({
      ...s,
      stage: updatedStage
    }))
  }

  return (
    <>
      <Drawer anchor={'right'} open={showDrawer} onClose={closeDrawer}>
        <BookingDetail
          booking={bookingDetail}
          updateBooking={updateBooking}
          updateBookingStage={updateBookingStage}
        />
      </Drawer>
      <Box p={2}>
        <Typography variant="h5">Search Result</Typography>
        {globalSearchStore.type === 'booking' ? (
          <BookingTabsContent
            data={globalSearchStore.bookings}
            status={''}
            onItemClick={onItemClick}
          />
        ) : (
          <TripTabsContent
            data={globalSearchStore.trips}
            status={''}
            onItemClick={onItemClick}
          />
        )}
      </Box>
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default observer(GlobalSearchResult)
