import ArrowBack from '@mui/icons-material/ArrowBack'
import Search from '@mui/icons-material/Search'
import {
  Box,
  IconButton,
  Pagination,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetHook } from '../../../hooks/fetchHook'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import { isEmptyArray, toQueryString } from '../../../utils/functions'
import PayableBatchRequestListItemsCard from './PayableBatchRequestListItemsCard'

const PayableBatchRequestListItems = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [batchListItems, setBatchListItems] = useState([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 500)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    const query = { page, search: debouncedSearch }

    let url = `/lf-acc/api/v1/payable-batch-requests/${
      params.batchId
    }/items${toQueryString(query)}`

    useGetHook(
      url,
      (res) => {
        let count = Math.ceil(res.data.count / 10)
        setPageCount(count)
        setCount(res.data.count)
        setBatchListItems(res.data.data)
      },
      () => {},
      true
    )
  }, [debouncedSearch, page, params?.batchId])

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <Box p={2}>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack color="primary" />
          </IconButton>
          <Box>
            <Typography variant="h5">Payable Batch Request</Typography>
            <Typography variant="body1">Total Vendor {count}</Typography>
          </Box>
        </Box>
        <TextField
          label="Search Vendor"
          size="small"
          InputProps={{
            endAdornment: <Search />
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
            setPage(1)
          }}
        />
      </Box>

      {isEmptyArray(batchListItems) && (
        <Typography textAlign="center" variant="subtitle1" color="error">
          No data found!
        </Typography>
      )}

      {!isEmptyArray(batchListItems) &&
        batchListItems.map((batch) => (
          <PayableBatchRequestListItemsCard key={batch.id} data={batch} />
        ))}

      <Box display="flex" justifyContent="end" alignItems="center" py={2}>
        <Pagination
          count={pageCount}
          color="primary"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

export default PayableBatchRequestListItems
